import { aspectRatios, IGraphqlM0049, RATIOS } from '@bemer/base';
import React from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0049 extends IGraphqlM0049 {}

const styles: IStylesObject = {
  wrapper: {
    alignItems: 'center',
  },
  image: {
    gridColumn: ['2 / -2', '2 / span 6', '2 / span 6'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  textWrapper: {
    gridColumn: ['2 / -2', '9 / span 5', '9 / span 5'],
  },
  title: {
    pb: ASIDE_HEADLINE_PB,
  },
};

const BemM0049 = ({
  media,
  title,
  text,
  link,
}: IPropsBemM0049): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper}>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
      sx={styles.image}
    />
    <Box sx={styles.textWrapper} data-testid="BemM0049-textWrapper">
      {title ? (
        <BemHeading
          data-testid="BemM0049Title"
          as="h2"
          variant="h2"
          sx={styles.title}
        >
          {title}
        </BemHeading>
      ) : null}
      <Text data-testid="BemM0049Text" as="p" variant="bodyText">
        {text}
      </Text>
      {link?.length ? (
        <BemActionArea data-testid="BemM0049-link">
          <BemLink
            to={link}
            variant="links.buttonPrimary"
            iconAfter={
              link[0]._type === 'externalLink' ? (
                <HiOutlineExternalLink />
              ) : undefined
            }
          />
        </BemActionArea>
      ) : null}
    </Box>
  </BemModuleWrapper>
);

export { BemM0049, IPropsBemM0049 };
