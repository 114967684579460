import {
  aspectRatios,
  IGraphqlM0044,
  IGraphqlM0044Item2,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemAccordion,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemRoundImage,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0044 extends IGraphqlM0044 {}

const PREFIX = 'M0044';

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  image: {
    gridRow: [4, 3, 3],
    gridColumn: '1 / -1',
    pt: [0, 24, 36],
  },
  imageOverlayGrid: {
    gridRow: 3,
    pb: [6, 0, 0],
  },
  imageOverlay: {
    gridColumn: ['1 / -1', '1 / span 4', '1 / span 4'],
    pt: [6, 14, 36],
    zIndex: 1,
  },
  itemsGrid: {
    gridColumn: '1 / -1',
    pt: [60, 60, 80],
    pb: [12, 12, 24],
    mt: [-32, -32, -40],
    bg: 'background',
  },
  itemsWrapper: {
    gridColumn: ['1/ -1', '2/ -2', '2/ -2'],
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: defaultGridGap,
  },
  roundImage: {
    gridColumn: 'span 2',
    pb: [5, 0, 0],
  },
};

interface IPropsItem2 {
  item: IGraphqlM0044Item2;
}

const Item2 = ({ item }: IPropsItem2) => (
  <BemRoundImage
    key={item._key}
    image={item.image}
    text={item.text}
    variant="column-centered"
    sx={styles.roundImage}
  />
);

interface IPropsItems {
  items: IGraphqlM0044Item2[];
}

const ItemsOnTabletOrLarger = ({ items }: IPropsItems) => (
  <>
    {items.map((item) => (
      <Item2 key={item._key} item={item} />
    ))}
  </>
);

const ItemsOnMobile = ({ items }: IPropsItems) => (
  <BemTouchSlider
    items={items}
    itemRenderer={Item2}
    key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
    hideShadow
    itemWidth="45%"
  />
);

const BemM0044 = ({
  title,
  items1,
  media,
  text,
  items2,
}: IPropsBemM0044): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading variant="h1" as="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    <BemAccordion items={items1} />
    <BemMedia
      media={media[0]}
      sx={styles.image}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_2].ratio}
    />
    <Grid variant="contentGrid" sx={styles.imageOverlayGrid}>
      <Text as="p" variant="caption.small" sx={styles.imageOverlay}>
        {text}
      </Text>
    </Grid>
    <BemThemeWrapper themeName={THEME_NAMES.HUMAN_LINE}>
      <Grid variant="contentGrid" sx={styles.itemsGrid}>
        <Grid sx={styles.itemsWrapper}>
          {useViewportRenderer([
            <ItemsOnMobile
              items={items2}
              key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
            />,
            <ItemsOnTabletOrLarger
              items={items2}
              key={`${PREFIX}_${VIEWPORTS.TABLET}`}
            />,
            <ItemsOnTabletOrLarger
              items={items2}
              key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
            />,
          ])}
        </Grid>
      </Grid>
    </BemThemeWrapper>
  </BemModuleWrapper>
);

export { BemM0044, IPropsBemM0044 };
