import {
  aspectRatios,
  IGraphqlM0080,
  IGraphqlM0080Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';

interface IPropsBemM0080 extends IGraphqlM0080 {}

const PREFIX = 'M0080';

const styles: IStylesObject = {
  heading: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  item: {
    gridColumn: 'span 6',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: defaultGridGap,
    pb: [0, 8, 10],
    '&:nth-last-of-type(-n+2)': {
      pb: 0,
    },
  },
  image: {
    gridColumn: '1/-1',
    pb: 2,
  },
  text: {
    gridColumn: ['span 3', 'span 3', 'span 4'],
    pb: [4, 0, 0],
    pl: [4, 0, 0],
    alignSelf: 'center',
  },
  link: {
    gridColumn: ['span 3', 'span 3', 'span 2'],
    textAlign: 'right',
    pb: [4, 0, 0],
    pr: [4, 0, 0],
    alignSelf: 'center',
  },
};

interface IPropsBemM0080Item {
  item: IGraphqlM0080Item;
  index: number;
}

const Item = ({ item, index }: IPropsBemM0080Item) => (
  <Grid key={item._key} data-testid={item._key} sx={styles.item}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
      sx={styles.image}
      additionalTrackingIdInfo={index + 1}
    />
    <Text as="p" variant="caption.small" sx={styles.text}>
      {item.text}
    </Text>
    <BemLink
      to={item.downloadLink}
      variant="links.boldUppercase"
      sx={styles.link}
      additionalTrackingIdInfo={index + 1}
      data-testid={`link-${item._key}`}
    />
  </Grid>
);

interface IPropsBemM0080Items {
  items: IGraphqlM0080Item[];
}

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0080Items) => (
  <>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </>
);

const BemM0080 = ({ title, items }: IPropsBemM0080): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      {useViewportRenderer([
        <BemTouchSlider
          items={items}
          itemRenderer={({ item }, index) => <Item item={item} index={index} />}
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0080, IPropsBemM0080 };
