import { BemSpinner } from '@bemer/ui-library';
import React, { ReactNode } from 'react';

interface IAppCenteredSpinner {
  children?: ReactNode;
}

const AppCenteredSpinner = ({ children }: IAppCenteredSpinner): JSX.Element => (
  <div style={{ display: 'grid', width: '100vw', height: '100vh' }}>
    <BemSpinner sx={{ alignSelf: 'center', justifySelf: 'center' }}>
      {children}
    </BemSpinner>
  </div>
);

// eslint-disable-next-line import/prefer-default-export
export { AppCenteredSpinner };
