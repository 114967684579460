import {
  aspectRatios,
  IGraphqlM0047,
  IGraphqlM0047Item,
  RATIOS,
} from '@bemer/base';
import React, { useContext } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemRichtext,
  BemRoundImage,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MOBILE_MODULE_TEXT_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0047 extends IGraphqlM0047 {}

const PREFIX = 'M0047';

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
  },
  heading: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  image1: {
    gridColumn: ['1 / -1', '1 / 8', '1 / 8'],
    gridRow: ['auto', 2, 2],
    pb: [MOBILE_MEDIA_PB, 8, 16],
  },
  text1Grid: {
    gridRow: ['auto', 2, 2],
    alignSelf: 'center',
    pb: [MOBILE_MODULE_TEXT_PB, 8, 16],
  },
  text1: {
    gridColumn: ['1 / -1', '8 / span 4', '8 / span 4'],
  },
  textWrapperGrid: {
    gridRow: [5, 3, 3],
    alignSelf: 'center',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 8, 16],
  },

  // This style is used only for text2 and text3 and not for text1
  textWrapper: {
    gridColumn: ['1 / -1', '2 / span 4', '2 / span 4'],
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
  },

  // This style is used only for text2 and text3 and not for text1
  text: {
    pb: 8,
  },
  video: {
    gridColumn: ['1 / -1', '8 / -1', '8 / -1'],
    gridRow: [4, 3, 3],
    pb: [MOBILE_MEDIA_PB, 14, 24],
  },
  itemWrapper: {
    gridColumn: ['1 / -1', '7 / -1', '7 / -1'],
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: defaultGridGap,
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  image3: {
    gridColumn: '1 / -1',
    pt: [0, 14, 24],
  },
  avatar: {
    pb: [5, 0, 0],
  },
};

interface IPropsItem {
  item: IGraphqlM0047Item;
}
interface IPropsItems {
  items: IGraphqlM0047Item[];
}

const Item = ({ item }: IPropsItem) => (
  <BemRoundImage
    key={item._key}
    text={item.text}
    image={item.image}
    variant="column-centered"
    sx={styles.avatar}
  />
);

const ItemsOnMobile = ({ items }: IPropsItems) => (
  <BemTouchSlider
    items={items}
    itemRenderer={Item}
    hideShadow
    itemWidth="45%"
  />
);

const ItemsOnTabletsOrLarger = ({ items }: IPropsItems) => (
  <>
    {items.map((item) => (
      <BemRoundImage
        key={item._key}
        text={item.text}
        image={item.image}
        variant="column-centered"
      />
    ))}
  </>
);

const BemM0047 = ({
  title,
  block,
  media1,
  text2,
  downloadLink,
  media3,
  text3A,
  text3B,
  items,
  media2,
  theme: moduleTheme,
}: IPropsBemM0047): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0047-bemModuleWrapper"
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        <BemMedia
          media={media1[0]}
          sx={styles.image1}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          additionalTrackingIdInfo={1}
        />
        <Grid variant="contentGrid" sx={styles.text1Grid}>
          <BemRichtext blocks={block} sx={styles.text1} />
        </Grid>
        <Grid variant="contentGrid" sx={styles.textWrapperGrid}>
          <Box sx={styles.textWrapper} data-testid="BemM0047-textWrapper">
            <Text as="p" variant="bodyText" sx={styles.text}>
              {text2}
            </Text>
            {downloadLink ? (
              <BemLink
                to={downloadLink}
                variant="links.buttonGhostDark"
                iconAfter={<FaDownload />}
                data-testid="BemM0047-downloadLink"
              />
            ) : null}
          </Box>
        </Grid>
        <BemMedia
          media={media3[0]}
          sx={styles.video}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
          autoplayPreview
          additionalTrackingIdInfo={2}
        />
        <Grid variant="contentGrid">
          <Box sx={styles.textWrapper}>
            <Text as="p" variant="bodyText" sx={styles.text}>
              {text3A}
            </Text>
            <BemHeading as="p" variant="h3">
              {text3B}
            </BemHeading>
          </Box>
          <Grid sx={styles.itemWrapper}>
            {useViewportRenderer([
              <ItemsOnMobile
                items={items}
                key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
              />,
              <ItemsOnTabletsOrLarger
                items={items}
                key={`${PREFIX}_${VIEWPORTS.TABLET}`}
              />,
              <ItemsOnTabletsOrLarger
                items={items}
                key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
              />,
            ])}
          </Grid>
        </Grid>
        <BemMedia
          media={media2[0]}
          sx={styles.image3}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
          additionalTrackingIdInfo={3}
        />
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0047, IPropsBemM0047 };
