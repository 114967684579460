import { aspectRatios, IGraphqlM0092, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemBreadcrumb,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_TEXT_PB,
  MOBILE_MEDIA_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { PathNamesContext } from '../../providers';

interface IPropsBemM0092 extends IGraphqlM0092 {}

const styles: IStylesObject = {
  leftBox: {
    pt: [0, 6, 8],
    pb: [0, 4, 6],
    gridColumn: ['2 / -2', '2 / span 6', '2 / span 5'],
    gridRow: ['2', '1', '1'],
  },
  breadcrumb: {
    pb: [6, 8, 12],
  },
  heading: {
    pb: ASIDE_HEADLINE_PB,
  },
  text: {
    pb: ASIDE_TEXT_PB,
  },
  itemsWrapper: {
    display: 'flex',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'space-between',
    pr: 4,
    justifyContent: 'space-between',

    '&:last-of-type': {
      '.heading': {
        position: 'relative',
        '&::after': {
          content: '"*"',
          color: 'textMuted',
          position: 'absolute',
          top: 0,
          left: '100%',
          lineHeight: '100%',
        },
      },
    },
  },
  itemText: {
    pb: [4, 4, 6],
  },
  itemTitle: {
    width: '100%',
  },
  itemTitleInner: {
    display: 'inline',
  },
  link: {
    alignSelf: 'end',
  },
  image: {
    gridColumn: ['1 / -1', '8 / -1', '8 / -1'],
    gridRow: '1',
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
};
const BemM0092 = ({
  title,
  text,
  media,
  items,
  link,
}: IPropsBemM0092): JSX.Element => {
  const pathNames = useContext(PathNamesContext);

  return (
    <BemModuleWrapper>
      <Box sx={styles.leftBox} data-testid="BemM0092-box">
        <BemBreadcrumb links={pathNames} sx={styles.breadcrumb} />
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        <Text as="p" variant="caption.small" sx={styles.text}>
          {text}
        </Text>
        <Box sx={styles.itemsWrapper}>
          {items.map((item) => (
            <Box key={item._key} data-testid={item._key} sx={styles.item}>
              <Text as="p" variant="small" sx={styles.itemText}>
                {item.text}
              </Text>
              <BemHeading as="h3" variant="h3" sx={styles.itemTitle}>
                <Box sx={styles.itemTitleInner} className="heading">
                  {item.title}
                </Box>
              </BemHeading>
            </Box>
          ))}
        </Box>
        {link?.length ? (
          <BemActionArea data-testid="BemM0092-link">
            <BemLink to={link} variant="links.buttonPrimary" sx={styles.link} />
          </BemActionArea>
        ) : null}
      </Box>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_10_9].ratio}
        sx={styles.image}
      />
    </BemModuleWrapper>
  );
};

export { BemM0092, IPropsBemM0092 };
