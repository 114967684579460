import {
  aspectRatios,
  doesPartnerSupportSignup,
  IGraphqlM0131,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Paragraph } from 'theme-ui';
import {
  BemButton,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MODULE_INNER_SECTION_PB,
  TABLET_MODULE_INNER_SECTION_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ApiContext, ThemeHierarchyContext } from '../../providers';
import { extendLinkWithPartnerHandle } from '../../utils/extendLinkWithPartnerHandle';

interface IPropsBemM0131 extends IGraphqlM0131 {}

const styles: IStylesObject = {
  wrapper: {
    gridTemplateRows: 'auto 1fr 1fr 1fr',
    bg: ['transparent', 'background', 'background'],
    mb: 0,
  },
  lightBackgroundArea: {
    gridColumn: '1 / -1',
    gridRow: '1 / 3',
    bg: 'background',
  },
  textWrapper: {
    gridRow: 1,
    zIndex: 1,
    pb: [MOBILE_MODULE_INNER_SECTION_PB, TABLET_MODULE_INNER_SECTION_PB, 24],
  },
  heading: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
    label: 'heroStartsWithText',
  },
  linkButton: {
    gridColumn: '1 / -1',
    justifySelf: ['initial', 'center', 'center'],
  },
  video: {
    gridColumn: ['2 / -2', '3 / -3', '2 / -2'],
    gridRow: '2 / span 4',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  text: (isLinkVisible: boolean) => ({
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    textAlign: 'center',
    pb: isLinkVisible ? 8 : 0,
  }),
};

const BemM0131 = ({
  title,
  text,
  link,
  media,
  theme: moduleTheme,
}: IPropsBemM0131): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  const {
    partner: { getPartnerByHandle },
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);
  const { data: visitorSettings, isFetching: isFetchingGetVisitorSettings } =
    getVisitorSettings();
  const { data: partner } = getPartnerByHandle(visitorSettings?.partnerHandle, {
    enabled: !!visitorSettings?.partnerHandle,
  });

  let button = null;
  if (link?.length) {
    const linkWithPartner = extendLinkWithPartnerHandle(
      link,
      visitorSettings?.partnerHandle
    );
    if (!visitorSettings?.partnerHandle || doesPartnerSupportSignup(partner)) {
      button = isFetchingGetVisitorSettings ? (
        <BemButton variant="buttons.primary" disabled sx={styles.linkButton}>
          {link[0]?.label}
        </BemButton>
      ) : (
        <BemLink
          to={linkWithPartner}
          variant="links.buttonPrimary"
          sx={styles.linkButton}
        />
      );
    }
  }

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0131ModuleWrapper">
        <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
          <Box sx={styles.lightBackgroundArea} />
          <Grid variant="contentGrid" sx={styles.textWrapper}>
            <BemHeading
              as="h2"
              variant="h1WithSeparatorCentered"
              sx={styles.heading}
              themeName={THEME_NAMES.DEFAULT}
            >
              {title}
            </BemHeading>
            <Paragraph
              variant="caption.small"
              sx={calculatedStyles.text(!!link)}
            >
              {text}
            </Paragraph>
            {button}
          </Grid>
        </BemThemeWrapper>
        <BemMedia
          media={media[0]}
          sx={styles.video}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_17_8].ratio}
          autoplayPreview
          data-testid="BemM0139Media"
        />
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0131, IGraphqlM0131, IPropsBemM0131 };
