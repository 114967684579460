import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { Box, Grid, ThemeUIStyleObject } from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';

enum SpinnerSizes {
  large = 130,
  medium = 50,
  small = 20,
}

const getSpinnerCircumference = (diameter: number) => Math.PI * diameter;

const getDashArray = (diameter: number) => {
  const circumference = getSpinnerCircumference(diameter);
  const strokeVisibleArea = 1 / 3;
  const dashPart = circumference * strokeVisibleArea;
  const gapPart = circumference - dashPart;
  return `${dashPart}px, ${gapPart}px`;
};

const styles: IStylesObject = {
  wrapper: {
    gridColumnTemplate: '1fr',
    gridRowTemplate: '1fr ',
    '& > *': {
      gridRow: 1,
      gridColumn: 1,
    },
  },
  bgCircle: {
    stroke: 'spinnerBackground',
    fill: 'transparent',
  },
  childrenWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  spinningCircle: (diameter: number) => ({
    strokeDasharray: getDashArray(diameter),
    stroke: 'spinnerStroke',
    fill: 'transparent',
    transformOrigin: 'center',
  }),
};

interface IPropsBemSpinner {
  size?: keyof typeof SpinnerSizes;
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
}

const BemSpinner = ({
  sx,
  children,
  size = 'large',
}: IPropsBemSpinner): JSX.Element => {
  const pixelSize = SpinnerSizes[size];

  const LOADER_WIDTH = pixelSize;
  const LOADER_HEIGHT = pixelSize;
  const STROKE_WIDTH = 4;
  const VIEW_BOX_WIDTH = LOADER_WIDTH + STROKE_WIDTH;
  const VIEW_BOX_HEIGHT = VIEW_BOX_WIDTH;
  const RADIUS = LOADER_WIDTH / 2;
  const CX = VIEW_BOX_WIDTH / 2;
  const CY = VIEW_BOX_HEIGHT / 2;

  const MotionSpinningCircle = motion(Box);

  return (
    <Grid
      sx={{
        ...styles.wrapper,
        width: `${LOADER_WIDTH}px`,
        height: `${LOADER_HEIGHT}px`,
        ...sx,
      }}
    >
      <svg
        viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}
        width={LOADER_WIDTH}
        height={LOADER_HEIGHT}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Box
          as="circle"
          // @ts-ignore
          cx={CX}
          cy={CY}
          r={RADIUS}
          sx={{ ...styles.bgCircle, strokeWidth: STROKE_WIDTH }}
          data-testid="BemSpinner"
        />
        <MotionSpinningCircle
          as="circle"
          // @ts-ignore
          cx={CX}
          cy={CY}
          r={RADIUS}
          sx={{
            ...calculatedStyles.spinningCircle(LOADER_WIDTH),
            strokeWidth: STROKE_WIDTH,
          }}
          animate={{
            rotate: [0, 360],
          }}
          transition={{
            duration: DURATION.VERY_LONG,
            repeat: Infinity,
            ease: 'linear',
          }}
        />
      </svg>
      {children && <Box sx={styles.childrenWrapper}>{children}</Box>}
    </Grid>
  );
};

export { BemSpinner, IPropsBemSpinner, SpinnerSizes };
