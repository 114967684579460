import { IGraphqlM0007 } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import { BemAccordion, BemHeading, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0007 extends IGraphqlM0007 {}

const styles: IStylesObject = {
  wrapper: {
    gridColumn: '2 / -2',
  },
  heading: {
    gridColumn: 'span 12',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  description: {
    gridColumn: '3 / -3',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
};

const BemM0007 = ({
  title,
  description,
  items,
  isImagePositionRight,
}: IPropsBemM0007): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.wrapper}>
      <BemHeading variant="h2WithSeparatorCentered" as="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      {description ? (
        <Text as="p" sx={styles.description}>
          {description}
        </Text>
      ) : null}
    </Box>
    <BemAccordion items={items} isImagePositionRight={isImagePositionRight} />
  </BemModuleWrapper>
);

export { BemM0007, IPropsBemM0007 };
