import { IGraphqlM0068, IGraphqlTranslation, TVisibleEvent } from '@bemer/base';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { asEffect, useMachine } from '@xstate/react';
import { motion } from 'framer-motion';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext, useEffect, useRef } from 'react';
import { FaMapMarkedAlt, FaSearchLocation } from 'react-icons/fa';
import { Box, Button, Flex, Text } from 'theme-ui';
import { BemHeading, BemModuleWrapper, BemSpinner } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { GRID_ROW_GAP } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useGetPartnerData } from '../../hooks/useGetPartnerData';
import { VIEWPORT_INDEX } from '../../hooks/useViewportRenderer';
import { ApiContext } from '../../providers';
import { LocalisationContext } from '../../providers/LocalisationProvider/BemLocalisationProvider';
import { getTranslation } from '../../utils/translations';
import { debugXState } from '../../utils/xStateHelper';
import { m0068Machine } from './BemM0068.machine';
import { BemEventCard } from './components/EventCard/BemEventCard';
import { TEventCardMachineActorRef } from './components/EventCard/BemEventCard.machine';
import { Filter } from './components/Filter';
import { Map } from './components/Map';
import { getSortedEvents, sortByDate } from './utils/events';

mapboxgl.accessToken =
  process.env.STORYBOOK_MAPBOX_ACCESS_TOKEN ||
  process.env.GATSBY_MAPBOX_ACCESS_TOKEN ||
  '';

interface IPropsBemM0068 extends IGraphqlM0068 {}

const componentName = 'BemM0068';

const MotionBox = motion(Box);

const mapHeight = ['60vh', '70vh', '70vh'];

const styles: IStylesObject = {
  headerWrapper: {
    mb: GRID_ROW_GAP,
  },
  eventResultsWrapper: {
    overflow: 'hidden',
    position: 'relative',
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'start',
    textAlign: 'center',
    pb: [9, 9, 9],
    pt: [8, 8, 12],
  },
  eventCardsWrapper: {
    gridColumn: ['2 / -2', '2 / 7', '2 / 6'],
    overflowY: 'scroll',
    pt: 1,
    pl: 3,
    pr: 3,
    ml: -3, // to offset the shadow padding
    mr: -3, // to offset the shadow padding
    scrollSnapType: 'y proximity',
    gridRow: [3, 'auto', 'auto'],
    zIndex: 3,
    bg: 'white',
    height: mapHeight,
  },
  mapBoxWrapper: {
    gridColumn: ['1 / -1', 'span 7', 'span 8'],
    gridRow: [3, 'auto', 'auto'],
    height: mapHeight,
  },
  searchOnMapMoveLabelWrapper: {
    position: 'absolute',
    bg: 'white',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    top: 5,
    left: 5,
    borderRadius: 1,
    p: 2,
  },
  eventCard: {
    mb: 4,
  },
  infoWrapper: {
    borderBottomWidth: 'px',
    borderBottomStyle: 'solid',
    borderColor: 'gray',
    pb: 10,
  },
  infoHeadline: {
    pb: 6,
  },
  infoIcon: {
    pl: 2,
  },
  mapButtonWrapper: {
    position: 'absolute',
    bottom: 10,
    display: ['block', 'none', 'none'],
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 4,
  },
  mapButton: {
    width: 'auto',
  },
  mapButtonIcon: {
    display: 'inline',
    pl: 2,
  },
};

const NoResults = ({ T }: { T: IGraphqlTranslation[] }) => (
  <Box sx={styles.infoWrapper}>
    <Flex>
      <BemHeading as="h4" variant="h4" sx={styles.infoHeadline}>
        {getTranslation('NO_EVENTS_FOUND_HEADLINE', T)}
      </BemHeading>
      <Box sx={styles.infoIcon}>
        <FaSearchLocation />
      </Box>
    </Flex>
    <Text as="p" variant="bodyText">
      {getTranslation('NO_EVENTS_FOUND_TEXT', T)}
    </Text>
  </Box>
);

interface IPropsCardList {
  items: TVisibleEvent<TEventCardMachineActorRef>[];
  T: IGraphqlTranslation[];
}

const CardList = ({ items, T }: IPropsCardList) => {
  const partnerData = useGetPartnerData();

  if (!items || !items.length) return <NoResults T={T} />;

  const sortedEvents = getSortedEvents(sortByDate(items), partnerData);

  return (
    <>
      {sortedEvents.map(({ event, cardRef }) =>
        cardRef ? (
          <BemEventCard
            key={event.id}
            event={event}
            cardRef={cardRef}
            sx={styles.eventCard}
            T={T}
          />
        ) : (
          <>missing cardRef</>
        )
      )}
    </>
  );
};

const animationVariants = {
  listWrapper: {
    hidden: {
      transform: 'translateY(100%)',
      transition: {
        animation: 'ease-in-out',
      },
    },
    visible: {
      transform: 'translateY(0%)',
      transition: {
        animation: 'ease-in-out',
      },
    },
  },
  mapButtonWrapper: {
    hidden: {
      bottom: '-100px',
      transition: {
        animation: 'ease-in-out',
      },
    },
    visible: {
      bottom: '40px',
      transition: {
        animation: 'ease-in-out',
      },
    },
  },
};

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0068 = ({ title, translations: T }: IPropsBemM0068): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Get event API provider
  const {
    events: { getEvents },
  } = useContext(ApiContext);

  // Fetch all events
  const { data } = getEvents(false);

  const { locale } = useContext(LocalisationContext);

  // Init machine
  const [current, send] = useMachine(m0068Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    // machine gets locale id from outside for mapbox country suggestion language
    context: { localeId: locale.id },
    actions: {
      scrollTo: asEffect((_context, event) => {
        if (document && event.type === 'MOUSE_ENTER_MARKER') {
          const child = document.querySelector<HTMLElement>(
            `#eventCard-${event.id}`
          );

          if (child) {
            const { offsetTop } = child;
            const shadowSpacing = 4;

            containerRef?.current?.scrollTo({
              top: offsetTop - shadowSpacing,
              behavior: 'smooth',
            });
          }
        }
      }),
    },
  });

  useEffect(() => {
    if (!data?.events) return;

    // this will set all events, event if they are not in view
    send({
      type: 'SET_EVENTS',
      events: data.events,
    });
  }, [data]);

  const breakpointIndex = useBreakpointIndex();
  const isMobile = breakpointIndex === VIEWPORT_INDEX.MOBILE;

  useEffect(() => {
    if (isMobile) {
      send({ type: 'HIDE_MAP' });
    }
  }, [breakpointIndex]);

  const { isMapVisible, filterActorRef } = current.context;
  const cardsAnimate = isMapVisible && isMobile ? 'hidden' : 'visible';

  return (
    <>
      <BemModuleWrapper sx={styles.headerWrapper}>
        {filterActorRef ? (
          <Filter filterActorRef={filterActorRef} T={T} />
        ) : null}

        <BemHeading as="h2" variant="h3" sx={styles.heading}>
          {title}
        </BemHeading>
      </BemModuleWrapper>
      <BemModuleWrapper sx={styles.eventResultsWrapper}>
        <MotionBox
          sx={styles.eventCardsWrapper}
          ref={containerRef}
          animate={cardsAnimate}
          variants={animationVariants.listWrapper}
          data-testid={`${componentName}-cardList`}
        >
          {!current.matches('idle') && !current.matches('delay') ? (
            <BemSpinner size="small" />
          ) : (
            <CardList items={current.context.visibleEvents} T={T} />
          )}
        </MotionBox>

        <MotionBox
          sx={styles.mapButtonWrapper}
          animate={cardsAnimate}
          variants={animationVariants.mapButtonWrapper}
          data-testid={`${componentName}-mapButton`}
        >
          <Button
            variant="primary"
            sx={styles.mapButton}
            onClick={() => send({ type: 'SHOW_MAP' })}
          >
            {getTranslation('BUTTON_MAP_LABEL', T)}
            <Box sx={styles.mapButtonIcon}>
              <FaMapMarkedAlt />
            </Box>
          </Button>
        </MotionBox>

        <Box sx={styles.mapBoxWrapper} data-testid={`${componentName}-map`}>
          <Map
            events={current.context.visibleEvents}
            current={current}
            send={send}
            coords={current.context.changedViewport}
            T={T}
          />
        </Box>
      </BemModuleWrapper>
    </>
  );
};

export { BemM0068, IPropsBemM0068 };
