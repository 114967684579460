import { aspectRatios, IGraphqlM0018, RATIOS } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_TEXT_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0018 extends IGraphqlM0018 {}

const styles: IStylesObject = {
  text: {
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
    textAlign: 'center',
    pb: [MOBILE_MODULE_TEXT_PB, 8, 8],
  },
  link: {
    justifySelf: 'center',
    gridColumn: '1 / -1',
    width: ['100%', 'auto', 'auto'],
    mb: [0, 16, 24],
  },
  image: {
    gridColumn: '1 / -1',
    gridRow: [1, 'auto', 'auto'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  heading: (addPadding: boolean) => ({
    gridColumn: '1 / -1',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
    label: addPadding ? 'heroStartsWithText' : undefined,
  }),
};

const BemM0018 = ({
  title,
  text,
  link,
  media,
}: IPropsBemM0018): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <BemHeading
        as="h2"
        variant="h1"
        sx={calculatedStyles.heading(!isMobile())}
      >
        {title}
      </BemHeading>
      <Text as="p" variant="caption.small" sx={styles.text}>
        {text}
      </Text>
      {link?.length ? (
        <BemLink to={link} variant="links.buttonPrimary" sx={styles.link} />
      ) : null}
      {media ? (
        <BemMedia
          media={media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_17_8].ratio}
          sx={styles.image}
        />
      ) : null}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0018, IPropsBemM0018 };
