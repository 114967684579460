import { IGraphqlM0087Line } from '@bemer/base';
import { assign, Machine, StateSchema } from 'xstate';

interface IM0087Schema {
  states: {
    init: StateSchema<any>;
    productTypeSelected: StateSchema<any>;
  };
}

interface IM0087Context {
  selectedLineIndex: number;
  selectedProductTypeIndex?: number;
  lines: IGraphqlM0087Line[];
}

type TM0087Events =
  | { type: 'toggleVideoPanel' }
  | { type: 'toggleManualPanel' }
  | { type: 'toggleTechnicalSpecificationPanel' }
  | { type: 'setLine'; key: string }
  | { type: 'setProductType'; key: string };

const initialContext = {
  selectedLineIndex: 0,
  selectedProductTypeIndex: 0,
  lines: [],
};

const m0087Machine = Machine<IM0087Context, IM0087Schema, TM0087Events>(
  {
    id: 'm0087',
    initial: 'init',
    context: initialContext,
    states: {
      init: {
        entry: ['resetProductType', 'updateLine'],
        always: {
          target: 'productTypeSelected',
          cond: (context) => context.selectedProductTypeIndex !== undefined,
        },
        on: {},
      },
      productTypeSelected: {
        entry: ['updateProductType'],
        on: {},
        type: 'parallel',
        states: {
          videoPanel: {
            id: 'videoPanel',
            initial: 'closed',
            states: {
              open: {
                on: {
                  toggleVideoPanel: {
                    target: 'closed',
                  },
                },
              },
              closed: {
                on: {
                  toggleVideoPanel: {
                    target: 'open',
                  },
                },
              },
            },
          },
          manualPanel: {
            id: 'manualPanel',
            initial: 'closed',
            states: {
              open: {
                on: {
                  toggleManualPanel: {
                    target: 'closed',
                  },
                },
              },
              closed: {
                on: {
                  toggleManualPanel: {
                    target: 'open',
                  },
                },
              },
            },
          },
          technicalSpecificationPanel: {
            id: 'technicalSpecificationPanel',
            initial: 'closed',
            states: {
              open: {
                on: {
                  toggleTechnicalSpecificationPanel: {
                    target: 'closed',
                  },
                },
              },
              closed: {
                on: {
                  toggleTechnicalSpecificationPanel: {
                    target: 'open',
                  },
                },
              },
            },
          },
        },
      },
    },
    on: {
      setProductType: [
        {
          target: 'productTypeSelected',
        },
      ],
      setLine: {
        target: 'init',
      },
    },
  },
  {
    actions: {
      resetProductType: assign({
        selectedProductTypeIndex: (_context, _event) =>
          initialContext.selectedProductTypeIndex,
      }),
      updateLine: assign({
        selectedLineIndex: (context, event) => {
          let newIndex = -1;
          if (event.type === 'setLine') {
            newIndex = context.lines.findIndex(
              (line) => line._key === event.key
            );
          }

          return newIndex === -1 ? context.selectedLineIndex : newIndex;
        },
      }),
      updateProductType: assign({
        selectedProductTypeIndex: (context, event) => {
          let newIndex = -1;
          if (event.type === 'setProductType' && event.key) {
            newIndex = context.lines[
              context.selectedLineIndex
            ].productTypes.findIndex(
              (productType) => productType._key === event.key
            );
          }
          return newIndex === -1 ? context.selectedProductTypeIndex : newIndex;
        },
      }),
    },
    guards: {},
  }
);
export { m0087Machine, TM0087Events, IM0087Context };
