import { aspectRatios, IGraphqlM0005, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ASIDE_MEDIA_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0005 extends IGraphqlM0005 {}

const styles: IStylesObject = {
  textWrapper: {
    gridColumn: ['2 / -2', '2 / 6', '2 / 6'],
    pt: [0, 8, 8],
    gridRow: [2, 1, 1],
  },
  imageWrapper: {
    gridColumn: ['2 / -2', '7 / -2', '7 / -2'],
    gridRow: 1,
    pb: ASIDE_MEDIA_PB,
  },
  usageWrapper: {
    gridColumn: 'span 6',
    pb: [4, 6, 8],
    justifySelf: 'center',
  },
};

const BemM0005 = ({
  title,
  link,
  media,
  beforeUsage,
  afterUsage,
}: IPropsBemM0005): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.textWrapper} data-testid="BemM0005-textWrapper">
      <BemHeading
        as="h2"
        variant="h2WithSeparatorForHeadlineWithoutPaddingBottom"
      >
        {title}
      </BemHeading>
      {link?.length ? (
        <BemActionArea data-testid="BemM0005-link">
          <BemLink to={link} variant="links.buttonPrimary" />
        </BemActionArea>
      ) : null}
    </Box>
    <Box sx={styles.imageWrapper}>
      <Grid variant="contentGrid">
        <Box sx={styles.usageWrapper}>
          <Text as="p" variant="caption.tiny">
            {beforeUsage}
          </Text>
        </Box>
        <Box sx={styles.usageWrapper}>
          <Text as="p" variant="caption.tiny">
            {afterUsage}
          </Text>
        </Box>
      </Grid>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_14_5].ratio}
      />
    </Box>
  </BemModuleWrapper>
);

export { BemM0005, IPropsBemM0005 };
