import {
  aspectRatios,
  IGraphqlM0039,
  IGraphqlM0039Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_CARD_PADDING,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0039 extends IGraphqlM0039 {}

const styles: IStylesObject = {
  heading: {
    pb: TOP_HEADLINE_V1_PB,
  },
  headingLeft: {
    gridColumn: '2 / span 8',
    justifySelf: 'start',
    textAlign: 'left',
  },
  headingCenter: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
  },
  topButtonWrapper: {
    gridColumn: 'span 4',
    justifySelf: 'end',
    pb: [8, 0, 0],
  },
  itemsWrapper: {
    gridColumn: '2 / -2',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  item: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'min-content 1fr min-content',
    gridGap: defaultGridGap,
    gridColumn: 'span 1',
    border: '1px solid',
    borderColor: 'gray.2',
    pt: 14,
    pb: 11,
    height: '100%',
  },
  itemImage: {
    gridColumn: '2 / span 2',
    pb: 6,
  },
  itemDescription: {
    gridColumn: '1 / -1',
    textAlign: 'center',
    px: 7,
  },
  itemHeading: {
    pb: 5,
  },
  itemText: {
    pb: 7,
  },
  itemLinkWrapper: {
    px: [MOBILE_CARD_PADDING, 0, 0],
    gridColumn: '1 / -1',
    justifySelf: ['auto', 'center', 'center'],
  },
};

interface IPropsItemsOnTabletOrLarger {
  items: IGraphqlM0039Item[];
}

interface IPropsItem {
  item: IGraphqlM0039Item;
  index: number;
}

const Item = ({ item, index }: IPropsItem) => (
  <Grid key={item._key} sx={styles.item} data-testid={item._key}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      sx={styles.itemImage}
      additionalTrackingIdInfo={index + 1}
    />
    <Box sx={styles.itemDescription}>
      <BemHeading as="h3" variant="h6" sx={styles.itemHeading}>
        {item.title}
      </BemHeading>
      <Text as="p" variant="small" sx={styles.itemText}>
        {item.text}
      </Text>
    </Box>
    {item.link?.length ? (
      <Box sx={styles.itemLinkWrapper}>
        <BemLink
          to={item.link}
          variant="links.buttonPrimary"
          additionalTrackingIdInfo={index + 1}
          data-testid={`${item._key}-link`}
        />
      </Box>
    ) : null}
  </Grid>
);

const renderItem = ({ item, index }: IPropsItem) => (
  <Item item={item} index={index} />
);

const ItemsOnDesktop = ({ items }: IPropsItemsOnTabletOrLarger) => (
  <Grid sx={styles.itemsWrapper}>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </Grid>
);

const ItemsOnMobile = ({ items }: IPropsItemsOnTabletOrLarger) => (
  <BemTouchSlider
    items={items}
    itemRenderer={(data, index) => renderItem({ item: data.item, index })}
    hideShadow
  />
);

const ItemsOnTablet = ({ items }: IPropsItemsOnTabletOrLarger) => (
  <BemTouchSlider
    items={items}
    itemRenderer={(data, index) => renderItem({ item: data.item, index })}
    hideShadow
    itemWidth="45%"
  />
);

const BemM0039 = ({
  title,
  link,
  items,
  _type,
}: IPropsBemM0039): JSX.Element => {
  const headingVariant = link ? 'h2WithSeparator' : 'h2WithSeparatorCentered';
  return (
    <BemModuleWrapper data-testid="BemM0039Wrapper">
      <BemHeading
        as="h2"
        variant={headingVariant}
        sx={
          link
            ? { ...styles.heading, ...styles.headingLeft }
            : { ...styles.heading, ...styles.headingCenter }
        }
      >
        {title}
      </BemHeading>
      {link?.length ? (
        <Box sx={styles.topButtonWrapper} data-testid="BemM0039Link">
          <BemLink to={link} variant="links.buttonSecondary" />
        </Box>
      ) : null}

      {useViewportRenderer([
        <ItemsOnMobile key={`${_type}_${VIEWPORTS.MOBILE}`} items={items} />,
        <ItemsOnTablet key={`${_type}_${VIEWPORTS.TABLET}`} items={items} />,
        <ItemsOnDesktop key={`${_type}_${VIEWPORTS.DESKTOP}`} items={items} />,
      ])}
    </BemModuleWrapper>
  );
};

export { BemM0039, IPropsBemM0039 };
