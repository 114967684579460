import { aspectRatios, IGraphqlM0056Item, RATIOS } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Box, Grid, Text, ThemeUIStyleObject } from 'theme-ui';
import { BemCloudImage, BemQuoteWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { LocalisationContext } from '../../providers';

const MotionBox = motion(Box);

const CAPTION_WRAPPER_ACTIVE = 'active';
const CAPTION_WRAPPER_INACTIVE = 'inactive';

const styles: IStylesObject = {
  itemCaptionWrapper: {
    gridColumn: '1 / -1',
    gridRow: 1,
    background: 'white',
    p: 6,
    m: 6,
    alignSelf: 'flex-end',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    minHeight: '6.5rem',
  },
  textWrapper: {
    position: 'relative',
    pl: 6,
  },
  image: {
    gridRow: '1',
    gridColumn: '1 / -1',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  imageWrapper: () => ({
    scrollSnapAlign: 'start',
  }),
  captionText: (isItemNameSet: boolean, isJobTitleSet: boolean) => ({
    pb: isItemNameSet || isJobTitleSet ? 4 : 0,
  }),
  itemName: (isCaptionTextSet: boolean, isJobTitleSet: boolean) => {
    if (isCaptionTextSet) {
      return {
        pb: isJobTitleSet ? 2 : 0,
      };
    }
    return {
      pb: isJobTitleSet ? 6 : 0,
    };
  },
};
const animationVariants = {
  captionWrapper: {
    [CAPTION_WRAPPER_ACTIVE]: {
      opacity: 1,
    },
    [CAPTION_WRAPPER_INACTIVE]: {
      opacity: 0,
    },
  },
};
interface IPropsImageCard {
  item: IGraphqlM0056Item;
  sx?: ThemeUIStyleObject;
  isActive: boolean;
  isMobileOrTabletView?: boolean;
  index: number;
}

const ImageCard = ({
  item,
  sx,
  isActive,
  isMobileOrTabletView,
  index,
}: IPropsImageCard): JSX.Element => {
  const { locale } = useContext(LocalisationContext);
  const additionalTrackingIdInfo = index + 1;
  const isCaptionTextSet = !!item.captionText;
  const isItemNameSet = !!item.name;
  const isJobTitleSet = !!item.jobTitle;
  return (
    <Grid sx={{ ...calculatedStyles.imageWrapper(), ...sx }}>
      {item.media[0]._type === 'imageWithAlt' ? (
        <>
          <BemCloudImage
            image={item.media[0]}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
            sx={styles.image}
            additionalTrackingIdInfo={additionalTrackingIdInfo}
          />
          {!isMobileOrTabletView &&
          (item.captionText || item.name || item.jobTitle) ? (
            <MotionBox
              sx={styles.itemCaptionWrapper}
              variants={animationVariants.captionWrapper}
              animate={
                isActive ? CAPTION_WRAPPER_ACTIVE : CAPTION_WRAPPER_INACTIVE
              }
            >
              <Box sx={styles.textWrapper}>
                {item.captionText ? (
                  <Text
                    variant="bodyText"
                    as="p"
                    sx={calculatedStyles.captionText(
                      isItemNameSet,
                      isJobTitleSet
                    )}
                  >
                    <BemQuoteWrapper localeId={locale.id}>
                      {item.captionText}
                    </BemQuoteWrapper>
                  </Text>
                ) : null}
                {item.name ? (
                  <Text
                    variant="h5"
                    as="h5"
                    sx={calculatedStyles.itemName(
                      isCaptionTextSet,
                      isJobTitleSet
                    )}
                  >
                    {item.name}
                  </Text>
                ) : null}
                {item.jobTitle ? (
                  <Text as="p" variant="bodyText">
                    {item.jobTitle}
                  </Text>
                ) : null}
              </Box>
            </MotionBox>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

export { ImageCard };
