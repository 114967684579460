import { aspectRatios, IGraphqlM0057, RATIOS } from '@bemer/base';
import React from 'react';
import { Flex, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0057 extends IGraphqlM0057 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  buttonWrapper: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    pb: 12,
    width: ['100%', 'auto', 'auto'],
  },
  link: {
    width: 'inherit',
  },
  itemsGrid: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(268px, 1fr))',
  },
  itemImage: {
    borderRadius: 'full',
    overflow: 'hidden',
    gridColumn: 'span 1',
  },
  itemHeading: {
    gridColumn: 'span 4',
    pt: 4,
    pb: 2,
  },
  itemText: {
    gridColumn: 'span 4',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  text: (isLinkVisible: boolean) => ({
    textAlign: 'center',
    gridColumn: '2 / -2',
    pb: isLinkVisible ? 8 : TOP_HEADLINE_V2_PB,
  }),
  item: (numberOfCols) => ({
    // gridColumn: ['span 6', 'span 4', 'span 4'],
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'auto auto 1fr',
    gap: defaultGridGap,
    pb: 16,
    [`&:nth-last-of-type(-n+${numberOfCols})`]: {
      pb: 0,
    },
  }),
};

const BemM0057 = ({
  title,
  text,
  link,
  items,
}: IPropsBemM0057): JSX.Element => {
  const numberOfCols = isMobile() ? 1 : 3;

  return (
    <BemModuleWrapper>
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      <Text as="p" variant="caption.small" sx={calculatedStyles.text(!!link)}>
        {text}
      </Text>
      {link?.length ? (
        <Flex sx={styles.buttonWrapper} data-testid="BemM0057Link">
          <BemLink to={link} variant="links.buttonSecondary" sx={styles.link} />
        </Flex>
      ) : null}
      <Grid variant="contentGrid" sx={styles.itemsGrid}>
        {items.map((item) => (
          <Grid
            key={item._key}
            data-testid={item._key}
            sx={calculatedStyles.item(numberOfCols)}
          >
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
              sx={styles.itemImage}
            />
            <BemHeading as="h3" variant="h4" sx={styles.itemHeading}>
              {item.title}
            </BemHeading>
            <Text as="p" variant="small" sx={styles.itemText}>
              {item.text}
            </Text>
          </Grid>
        ))}
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0057, IPropsBemM0057 };
