import { aspectRatios, IGraphqlM0038, RATIOS } from '@bemer/base';
import React from 'react';
import { Box } from 'theme-ui';
import { BemHeading, BemMedia, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0038 extends IGraphqlM0038 {}

const styles: IStylesObject = {
  imageWrapper: {
    gridColumn: '2 / -2',
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
};

const BemM0038 = ({ title, media }: IPropsBemM0038): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    <Box sx={styles.imageWrapper}>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
      />
    </Box>
  </BemModuleWrapper>
);

export { BemM0038, IPropsBemM0038 };
