import {
  aspectRatios,
  IGraphqlM0034,
  IGraphqlM0034Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Grid } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  FEATURES_LIST_PT,
  GRID_ROW_GAP_TO_ZERO,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

const PREFIX = 'M0034';

interface IPropsBemM0034 extends IGraphqlM0034 {}

interface IPropsBemM0034Item {
  item: IGraphqlM0034Item;
}
interface IPropsBemM0034ItemWithIndex extends IPropsBemM0034Item {
  index: number;
}
interface IPropsBemM0034Items {
  items: IGraphqlM0034Item[];
}

const styles: IStylesObject = {
  heading: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  item: {
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    gridTemplateColumns: ['repeat(12,1fr)', 'repeat(6, 1fr)', 'repeat(6, 1fr)'],
    gap: defaultGridGap,
    bg: 'gray.2',
  },
  itemHeader: {
    gridRow: 2,
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
  },
  itemImage: {
    pt: 10,
    gridColumn: ['4 / -4', '3 / -3', '3 / -3'],
  },
  itemTitle: {
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
    textAlign: 'center',
    pb: 10,
    pt: 2,
  },
  features: {
    pt: FEATURES_LIST_PT,
    gridRow: 3,
    rowGap: GRID_ROW_GAP_TO_ZERO,
  },
  shadowBox: {
    gridColumn: ['1 / -1', '2 / span 6', '2 / span 6'],
    gridRow: '2 / span 2',
    boxShadow: 'smallCardShadow',
    '&:last-of-type': {
      gridColumn: ['1 / -1', '8 / span 6', '8 / span 6'],
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  featureItem: (index) => ({
    gridRow: index + 1,
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    pt: 10,
    pl: 6,
    pr: 6,
    pb: 10,
    borderBottomWidth: 'px',
    borderBottomColor: 'gray.2',
    borderBottomStyle: 'solid',
  }),
};

const ItemHeader = ({
  item,
  index,
}: IPropsBemM0034ItemWithIndex): JSX.Element => (
  <Grid sx={styles.item}>
    <BemMedia
      media={item.media[0]}
      sx={styles.itemImage}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      additionalTrackingIdInfo={index + 1}
    />
    <BemHeading sx={styles.itemTitle} as="h3" variant="h3">
      {item.title}
    </BemHeading>
  </Grid>
);
const ItemFeatures = ({ item }: IPropsBemM0034Item): JSX.Element => (
  <>
    {item.features.map((feature, index) => (
      <BemHeading
        variant="h4"
        sx={calculatedStyles.featureItem(index)}
        key={`${item._key}${feature}`}
        data-testid={`${item._key}${feature}`}
      >
        {feature}
      </BemHeading>
    ))}
  </>
);

const ItemMobile = (
  { item }: IPropsBemM0034Item,
  index: number
): JSX.Element => (
  <>
    <Grid variant="contentGrid" sx={styles.itemHeader}>
      <ItemHeader item={item} index={index} />
    </Grid>
    <Grid variant="contentGrid" sx={styles.features}>
      <ItemFeatures item={item} />
    </Grid>
    <Box sx={styles.shadowBox} />
    <Box sx={styles.shadowBox} />
  </>
);

const ItemsOnMobile = ({ items }: IPropsBemM0034Items): JSX.Element => (
  <BemTouchSlider items={items} itemRenderer={ItemMobile} />
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0034Items): JSX.Element => (
  <>
    <Grid variant="contentGrid" sx={styles.itemHeader}>
      {items.map((item, index) => (
        <ItemHeader key={item._key} item={item} index={index} />
      ))}
    </Grid>
    <Grid variant="contentGrid" sx={styles.features}>
      {items.map((item) => (
        <ItemFeatures key={item._key} item={item} />
      ))}
    </Grid>
    <Box sx={styles.shadowBox} />
    <Box sx={styles.shadowBox} />
  </>
);

const Items = ({ items }: IPropsBemM0034Items): JSX.Element | null =>
  useViewportRenderer([
    <ItemsOnMobile items={items} key={`${PREFIX}_${VIEWPORTS.MOBILE}`} />,
    <ItemsOnTabletOrLarger
      items={items}
      key={`${PREFIX}_${VIEWPORTS.TABLET}`}
    />,
    <ItemsOnTabletOrLarger
      items={items}
      key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
    />,
  ]);

const BemM0034 = ({ title, items }: IPropsBemM0034): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
    </Grid>
    <Items items={items} />
  </BemModuleWrapper>
);

export { BemM0034, IPropsBemM0034 };
