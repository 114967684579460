import { aspectRatios, IGraphqlM0015, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { GRID_GAP_MOBILE } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0015 extends IGraphqlM0015 {}

const styles: IStylesObject = {
  cardsWrapper: {
    gridColumn: ['2 / -2'],
    gridTemplateColumns: '1fr',
    '[data-number-of-cards="2"] &': {
      gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)'],
    },
    '[data-number-of-cards="3"] &': {
      gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
    },
  },
  card: {
    pt: 9,
    pb: 12,
    bg: 'gray.2',
    px: [6, 12, 24],
    gridColumn: 'span 1',
    '[data-number-of-cards="3"] &': {
      px: 6,
      '&:last-of-type': {
        gridColumn: ['span 1', 'span 2', 'span 1'],
      },
    },
    mb: [GRID_GAP_MOBILE, 0, 0],
  },
  cardContent: {
    textAlign: 'center',
    flexDirection: 'column',
    height: '100%',
    width: ['100%', '80%', '50%'],
    mx: 'auto',
    '[data-number-of-cards="2"] &, [data-number-of-cards="3"] &': {
      width: '100%',
    },
  },
  cardImage: {
    width: 24,
    height: 24,
    mx: 'auto',
    borderRadius: 'full',
    border: '1px solid transparent',
    overflow: 'hidden',
  },
  cardTitle: {
    pt: 6,
    pb: 6,
  },
  linkWrapper: {
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    pt: 6,
    gap: 4,
  },
};
const BemM0015 = ({ cards }: IPropsBemM0015): JSX.Element => (
  <BemModuleWrapper data-number-of-cards={cards?.length}>
    <Grid
      variant="contentGrid"
      sx={styles.cardsWrapper}
      role="list"
      as="section"
    >
      {cards.map((card, i) => (
        <Box
          key={card._key}
          sx={styles.card}
          role="listitem"
          data-testid={`BemM0015-${card._key}`}
        >
          <Flex sx={styles.cardContent}>
            <BemCloudImage
              image={card.image}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
              sx={styles.cardImage}
            />
            <BemHeading as="h3" variant="h4" sx={styles.cardTitle}>
              {card.title}
            </BemHeading>
            <Text as="p">{card.text}</Text>

            {card.link?.length ? (
              <Flex sx={styles.linkWrapper} data-testid={`${card._key}-link`}>
                {card.link.map((link, j) => (
                  <BemLink
                    key={link._key}
                    to={link}
                    variant={
                      link._type === 'internalLink'
                        ? 'links.buttonPrimary'
                        : 'links.buttonTertiary'
                    }
                    additionalTrackingIdInfo={`card-${i + 1}-link-${j + 1}`}
                  />
                ))}
              </Flex>
            ) : null}
          </Flex>
        </Box>
      ))}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0015, IPropsBemM0015 };
