import { RefObject, useEffect, useRef, useState } from 'react';
import { getHash, removeHash } from '../utils/urlUtils';

const MODULE_NAME_NAVIGATION = 'BemM0019';
const SCROLL_MARGIN_TOP = 10;
const SCROLL_MAX_DELTA = 250;
const SCROLL_SMOOTHING = 0.5;
const HASH_CHECK_INTERVAL = 500;

const computeScrollMarginTop = (): number => {
  if (typeof window === 'undefined') return 0;
  const header = window.document.getElementById(MODULE_NAME_NAVIGATION);
  const additionalSafeMargin = SCROLL_MARGIN_TOP;
  const offset = header ? header.getBoundingClientRect().bottom : 0;
  return offset + additionalSafeMargin;
};

/**
 * Custom hook called useScrollToModuleHash is responsible for smoothly scrolling the viewport to a certain target on the page.
 * The hook uses useEffect and useState to manage the scrolling state and control the lifecycle of the scrolling.
 *
 * The hook is activated when the current hash in the URL matches the id of the element that the passed ref is pointing to.
 *
 * The hook will stop scrolling and clean up by cancelling any remaining animation frames when the component using the hook unmounts, or when the isScrolling state changes.
 *
 * @param {RefObject} moduleRef - A React Ref object pointing to the target element.
 * @returns {number} Returns the computed scroll distance from the top of the viewport.
 */
const useScrollToModuleHash = (
  moduleRef: RefObject<HTMLDivElement>
): number => {
  const scrollMarginTop = computeScrollMarginTop();
  const interval = useRef<number | undefined>();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return () => undefined;
    if (typeof document === 'undefined') return () => undefined;
    const checkModuleHash = () => {
      if (getHash() === moduleRef.current?.id) {
        setIsScrolling(true);
      }
    };
    interval.current = window.setInterval(checkModuleHash, HASH_CHECK_INTERVAL);
    // Clean up listener on component unmount
    return () => {
      window?.clearInterval(interval.current);
    };
  }, [moduleRef.current]);

  useEffect(() => {
    if (!isScrolling) return () => undefined;
    console.log('scroll to partner finder');
    let requestId: number;

    const scroll = () => {
      // reference to the target element
      const target = moduleRef.current;
      if (!target) {
        // if the target does not exist yet, request another animation frame
        requestId = requestAnimationFrame(scroll);
        return;
      }
      // compute the top margin for the scroll
      const marginTop = computeScrollMarginTop();
      // get the y coordinate for the target element
      const yCoordinate = target.getBoundingClientRect().top + window.scrollY;
      // calculate the final Y-coordinate for the scroll
      const targetY = Math.round(yCoordinate - marginTop);
      // calculate the delta for the scroll
      let delta: number = (targetY - window.scrollY) * SCROLL_SMOOTHING;
      // if the delta is less than 1, then round it to 0 or 1 depending on its sign to prevent it from becoming too small
      if (Math.abs(delta) < 1 && delta !== 0) {
        delta = Math.sign(delta);
      }
      if (Math.abs(delta) > 1) {
        delta = Math.sign(delta) * Math.min(Math.abs(delta), SCROLL_MAX_DELTA);
      }
      // scroll by the calculated delta
      window.scrollBy(0, delta);
      // if we have reached the target, stop scrolling and remove the hash from the URL
      // or if the scroll position hasn't changed, we've hit the bottom or top of the page
      const targetReached = Math.abs(window.scrollY - targetY) < 1;
      const bottomReached =
        window.scrollY + window.innerHeight ===
        document.scrollingElement?.scrollHeight;
      const scrollingElementFound =
        (document.scrollingElement?.scrollHeight ?? 0) > window.innerHeight;
      if (
        targetReached || // target is reached
        (bottomReached && scrollingElementFound)
      ) {
        console.log('successfully scrolled to partnerfinder');
        window?.clearInterval(interval.current);
        setIsScrolling(false);
        removeHash();
      } else {
        requestId = requestAnimationFrame(scroll);
      }
    };

    // request the first animation frame
    requestId = requestAnimationFrame(scroll);

    return () => {
      if (requestId) {
        // if there's still an animation frame requested, cancel the animation frame
        cancelAnimationFrame(requestId);
      }
    };
  }, [isScrolling]);

  return scrollMarginTop;
};

export { useScrollToModuleHash };
