import { IGraphqlM0087Line } from '@bemer/base';
import React from 'react';
import { Box, Flex } from 'theme-ui';
import { BemButton } from '../../components/Button';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TM0087Events } from './BemM0087.machine';

const styles: IStylesObject = {
  lineWrapper: {
    gridColumn: ['1 / -1', '8 / -1', '8 / -1'],
    justifySelf: ['start', 'end', 'end'],
    pb: [8, 0, 0],
  },
  lineButton: {
    background: 'transparent',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    py: 4,
    color: 'gray.7',
  },
};

interface IPropsLines {
  items: IGraphqlM0087Line[];
  activeLine: number;
  send: (arg0: TM0087Events) => void;
}

const Lines = ({ items, send, activeLine }: IPropsLines): JSX.Element => (
  <Flex sx={styles.lineWrapper}>
    {items.map((item, index) => (
      <Box key={item._key}>
        <BemButton
          variant="buttons.nav"
          className={index === activeLine ? 'active' : ''}
          sx={styles.lineButton}
          onClick={() =>
            send({
              type: 'setLine',
              key: item._key,
            })
          }
          additionalTrackingIdInfo={`line-${index + 1}`}
        >
          {item.title}
        </BemButton>
      </Box>
    ))}
  </Flex>
);

export { Lines };
