import { assign, Machine, StateSchema } from 'xstate';

interface IBemAccordionWithExpanderContext {
  activeElement: number | undefined | null;
}
interface IBemAccordionWithExpanderSchema {
  states: {
    open: StateSchema;
    closed: StateSchema;
  };
}

type TBemAccordionWithExpanderEvents = {
  type: 'click';
  payload: number | undefined | null;
};

const BemAccordionWithExpanderMachine = Machine<
  IBemAccordionWithExpanderContext,
  IBemAccordionWithExpanderSchema,
  TBemAccordionWithExpanderEvents
>(
  {
    id: 'items',
    initial: 'closed',
    context: {
      activeElement: undefined,
    },
    states: {
      closed: {
        on: {
          click: {
            target: 'open',
            actions: ['activateElement'],
          },
        },
      },
      open: {
        on: {
          click: [
            {
              target: 'closed',
              actions: ['resetActiveElement'],
              cond: 'isAccordionItemAlreadyOpen',
            },
            {
              actions: ['activateElement'],
            },
          ],
        },
      },
    },
  },
  {
    guards: {
      isAccordionItemAlreadyOpen: (context, event) =>
        context.activeElement === event.payload,
    },
    actions: {
      activateElement: assign({
        activeElement: (_context, event) => event.payload,
      }),
      resetActiveElement: assign({
        activeElement: (_context) => undefined,
      }),
    },
  }
);

export {
  BemAccordionWithExpanderMachine,
  TBemAccordionWithExpanderEvents,
  IBemAccordionWithExpanderContext,
};
