import {
  defaultLocale,
  MAP_BOX_SEARCH_API,
  TEvent,
  TLocaleId,
  TLongCoords,
} from '@bemer/base';
import { ContextFrom, EventFrom, sendParent } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { ActorRefFrom } from 'xstate/lib/types';
import { buildGeoJson, IFeatureCollection } from '../utils/mapBox';

const MAP_BOX_ACCESS_TOKEN =
  process.env.STORYBOOK_MAPBOX_ACCESS_TOKEN ||
  process.env.GATSBY_MAPBOX_ACCESS_TOKEN ||
  '';

const MINIMUM_SEARCH_TERM_LENGTH = 3;

const fetchSearchResults = (
  searchTerm: string,
  localeId: TLocaleId
): Promise<IFeatureCollection> => {
  if (!searchTerm) {
    return Promise.reject();
  }
  const url = new URL(`${MAP_BOX_SEARCH_API}/${encodeURI(searchTerm)}.json`);

  const mapBoxLocale = localeId.replace('_', '-');

  url.searchParams.set('access_token', MAP_BOX_ACCESS_TOKEN);
  url.searchParams.set('language', mapBoxLocale);
  url.searchParams.set('cachebuster', Date.now().toString());
  url.searchParams.set(
    'types',
    ['country', 'region', 'postcode', 'place', 'poi'].join(',')
  );

  return fetch(url.toString()).then((response) => response.json());
};

const emptyResults: IFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

const searchModel = createModel(
  {
    searchTerm: '',
    events: [] as TEvent[],
    results: emptyResults,
    localeId: defaultLocale.id as TLocaleId,
  },
  {
    events: {
      'done.invoke.fetchSearchResults': (data: IFeatureCollection) => ({
        data,
      }),
      SELECT_SUGGESTION: (coords: { longitude: number; latitude: number }) => ({
        coords,
      }),
      SET_SEARCH_TERM: (value: string) => ({ value }),
      SET_SEARCHABLE_EVENTS: (events: TEvent[]) => ({ events }),
      HAS_RECEIVED_EVENTS: (events: TEvent[]) => ({ events }),
      RESET_SEARCH_TERM: () => ({}),
      CHANGE_VIEWPORT: (coords: TLongCoords) => ({ coords }),
    },
  }
);

const searchMachine = searchModel.createMachine(
  {
    initial: 'loading',
    context: searchModel.initialContext,
    id: 'searchMachine',
    on: {
      HAS_RECEIVED_EVENTS: {
        actions: ['setEvents'],
      },
      RESET_SEARCH_TERM: {
        actions: [
          sendParent((_context, _event) => ({
            type: 'RESET_SEARCH_TERM',
          })),
        ],
      },
      SET_SEARCH_TERM: [
        {
          target: 'loading',
          actions: ['updateSearchTerm'],
          cond: 'hasMinimumLength',
        },
        {
          actions: ['updateSearchTerm'],
        },
      ],
    },
    states: {
      loading: {
        invoke: {
          id: 'fetchSearchResults',
          src: (context, _event) =>
            fetchSearchResults(context.searchTerm, context.localeId),
          onDone: {
            target: 'hasValue',
            actions: ['setResults'],
          },
          onError: {
            target: 'failure',
          },
        },
      },
      showResults: {},
      hasValue: {
        on: {
          SELECT_SUGGESTION: {
            actions: [
              sendParent((_context, event) => ({
                ...event,
                type: 'CHANGE_VIEWPORT',
              })),
            ],
          },
        },
      },
      failure: {
        entry: [
          (context) => {
            if (context.searchTerm)
              console.error(
                'there was an error requesting search results from mapbox'
              );
          },
        ],
      },
    },
  },
  {
    actions: {
      setEvents: searchModel.assign(
        (_context, event) => ({
          events: event.events,
        }),
        'HAS_RECEIVED_EVENTS'
      ),
      resetResults: searchModel.assign({ results: undefined }),
      updateSearchTerm: searchModel.assign(
        (_context, event) => ({
          searchTerm: event.value,
        }),
        'SET_SEARCH_TERM'
      ),
      setResults: searchModel.assign((context, event) => {
        if (
          !Array.isArray(event.data?.features) ||
          !Array.isArray(context.events)
        ) {
          return { results: context.results };
        }

        const filteredEvents = context.events.filter((singleEvent) => {
          const matchingName =
            singleEvent.name
              ?.toLowerCase()
              .search(context.searchTerm.toLowerCase()) !== -1;

          const matchingCoaches = singleEvent.coaches
            ? singleEvent.coaches
                .map((coach) => coach.name)
                .join(',')
                ?.toLowerCase()
                .search(context.searchTerm.toLowerCase()) !== -1
            : false;

          const matchingVenue =
            `${singleEvent.venue.city} ${singleEvent.venue.country}`
              ?.toLowerCase()
              .search(context.searchTerm.toLowerCase()) !== -1;

          return matchingName || matchingCoaches || matchingVenue;
        });

        const results: IFeatureCollection = {
          ...event.data,
          features: [
            ...buildGeoJson(filteredEvents).features.splice(0, 5),
            ...event.data.features.splice(0, 5),
          ],
        };

        return { results };
      }, 'done.invoke.fetchSearchResults'),
    },
    guards: {
      hasMinimumLength: (_context, event) =>
        event.type === 'SET_SEARCH_TERM'
          ? event.value.length >= MINIMUM_SEARCH_TERM_LENGTH
          : false,
    },
  }
);

type TSearchMachineContext = ContextFrom<typeof searchModel>;
type TSearchMachineEvent = EventFrom<typeof searchModel>;
type TSearchMachineActorRef = ActorRefFrom<typeof searchMachine>;

export {
  searchMachine,
  TSearchMachineEvent,
  TSearchMachineContext,
  TSearchMachineActorRef,
};
