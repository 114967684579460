import {
  aspectRatios,
  IGraphqlM0079,
  IGraphqlM0079Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_CAPTION_BIG_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';

interface IPropsBemM0079 extends IGraphqlM0079 {}

const PREFIX = 'M0079';

const styles: IStylesObject = {
  text: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_CAPTION_BIG_PB,
  },
  item: {
    gridColumn: 'span 4',
  },
  itemImage: {
    pb: 6,
  },
  itemText: {
    pb: 4,
    px: [4, 0, 0],
  },
  itemLink: {
    px: [4, 0, 0],
    mb: [4, 0, 0],
    display: ['block', 'inline-block', 'inline-block'],
  },
};

interface IPropsBemM0079Item {
  item: IGraphqlM0079Item;
  index: number;
}

const Item = ({ item, index }: IPropsBemM0079Item) => (
  <Box sx={styles.item} data-testid={item._key}>
    <BemMedia
      media={item.media[0]}
      sx={styles.itemImage}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
      additionalTrackingIdInfo={index + 1}
    />
    <Text sx={styles.itemText} as="p" variant="bodyText">
      {item.text}
    </Text>
    <BemLink
      to={item.link}
      variant="links.boldUppercase"
      sx={styles.itemLink}
      additionalTrackingIdInfo={index + 1}
      data-testid={`link-${item._key}`}
    />
  </Box>
);

interface IPropsBemM0079Items {
  items: IGraphqlM0079Item[];
}

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0079Items) => (
  <>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </>
);

const BemM0079 = ({ text, items }: IPropsBemM0079): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <Text as="p" variant="caption.big" sx={styles.text}>
        {text}
      </Text>
      {useViewportRenderer([
        <BemTouchSlider
          items={items}
          itemRenderer={({ item }, index) => <Item item={item} index={index} />}
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0079, IPropsBemM0079 };
