import {
  IGraphqlPdf,
  IVideo,
  TAdditionalTrackingIdInfo,
  TGraphqlRichTextBlocks,
} from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Box, Flex, Grid, Text } from 'theme-ui';
import {
  BemCloudPdf,
  BemHeading,
  BemRichtext,
  BemTouchSlider,
} from '../../components';
import { BemButton } from '../../components/Button';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ModuleContext } from '../../providers/ModuleProvider/BemModuleProvider';
import { IM0087Context } from './BemM0087.machine';
import { VideoItem } from './VideoItem';

const PREFIX = 'M0087_Panel';

const MotionBox = motion(Box);
const MotionGrid = motion(Grid);

const componentName = 'Panel';

const styles: IStylesObject = {
  panelWrapper: {
    borderBottomWidth: 'px',
    borderBottomColor: 'gray.3',
    borderBottomStyle: 'solid',
    '&:first-of-type': {
      pt: [4, 6, 8],
    },
  },
  panelHead: {
    position: 'relative',
    alignItems: 'center',
    cursor: 'pointer',
  },
  panelContentManualWrapper: {
    gridColumn: ['span 3', 'span 6', 'span 3'],
  },
  panelContentTechnicalSpecificationWrapper: {
    gridColumn: '1 / -1',
  },
  panelHeading: {
    alignItems: 'center',
    py: [6, 8, 10],
    pr: 11,
  },
  panelHeadingAddon: {
    display: 'inline-block',
    color: 'textMuted',
    pl: '2',
  },
  panelContentGrid: {
    pt: 2,
    pb: [8, 10, 12],
  },
};
const EXPANDER_PANEL_OPEN = 'open';
const EXPANDER_PANEL_CLOSED = 'closed';
const animationVariants = {
  expanderIcon: {
    [EXPANDER_PANEL_OPEN]: {
      rotate: -180,
    },
    [EXPANDER_PANEL_CLOSED]: {
      rotate: 0,
    },
  },
  panelContent: {
    [EXPANDER_PANEL_OPEN]: {
      height: 'auto',
      overflowY: 'visible',
      transition: {
        when: 'beforeChildren',
        animation: 'ease-in-out',
      },
    },
    [EXPANDER_PANEL_CLOSED]: {
      height: 0,
      overflowY: 'hidden',
      transition: {
        when: 'afterChildren',
        staggerChildren: DURATION.MEDIUM,
        animation: 'ease-in-out',
      },
    },
  },
  panelContentGrid: {
    [EXPANDER_PANEL_OPEN]: {
      opacity: 1,
    },
    [EXPANDER_PANEL_CLOSED]: {
      opacity: 0,
    },
  },
} as const;

type TPropsPanelItem = IGraphqlPdf | IVideo | TGraphqlRichTextBlocks;

interface IPropsPanelItem {
  item: TPropsPanelItem;
  current?: { value: any; context: IM0087Context };
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
  index?: number;
}
interface IPropsPanelItems {
  items: TPropsPanelItem[];
  current: { value: any; context: IM0087Context };
  additionalTrackingIdInfo: TAdditionalTrackingIdInfo;
}

interface IPropsPanel {
  onToggle: () => void;
  title: string;
  items: TPropsPanelItem[];
  type: 'video' | 'manual' | 'technicalSpecification';
  current: { value: any; context: IM0087Context };
  additionalTrackingIdInfo: TAdditionalTrackingIdInfo;
}

const VideosOnTabletOrLarger = ({
  items,
  current,
  additionalTrackingIdInfo,
}: IPropsPanelItems) => {
  const videos = items as IVideo[];
  return (
    <>
      {videos.map(({ video, title: videoTitle, _key }, index) => (
        <VideoItem
          video={video}
          title={videoTitle}
          key={_key}
          current={current}
          additionalTrackingIdInfo={`${additionalTrackingIdInfo}-video-${
            index + 1
          }`}
        />
      ))}
    </>
  );
};

const getRenderItemVideoMobile = ({
  item,
  current,
  additionalTrackingIdInfo,
  index,
}: IPropsPanelItem): JSX.Element => {
  const itemVideo = item as IVideo;
  const { video, title } = itemVideo;
  return (
    <VideoItem
      video={video}
      title={title}
      current={current}
      additionalTrackingIdInfo={`${additionalTrackingIdInfo}-video-${
        index ? index + 1 : 0
      }`}
    />
  );
};

const VideosOnMobile = ({
  items,
  current,
  additionalTrackingIdInfo,
}: IPropsPanelItems): JSX.Element => {
  const videos = items as IVideo[];

  return (
    <BemTouchSlider
      items={videos}
      itemRenderer={(data, index) =>
        getRenderItemVideoMobile({
          item: data.item,
          current,
          additionalTrackingIdInfo,
          index,
        })
      }
      hideShadow
    />
  );
};

const Manual = ({
  item,
  current: _current,
  additionalTrackingIdInfo,
  index,
}: IPropsPanelItem): JSX.Element => {
  const pdf = item as IGraphqlPdf;

  return (
    <Box sx={styles.panelContentManualWrapper}>
      <BemCloudPdf
        pdf={pdf}
        additionalTrackingIdInfo={`${additionalTrackingIdInfo}-manual-${
          index ? index + 1 : 0
        }`}
      />
    </Box>
  );
};

const getRenderItemManualMobile = ({
  item,
  current,
  additionalTrackingIdInfo,
  index,
}: IPropsPanelItem): JSX.Element => {
  const pdf = item as IGraphqlPdf;
  return (
    <Manual
      item={pdf}
      current={current}
      index={index}
      additionalTrackingIdInfo={additionalTrackingIdInfo}
    />
  );
};

const ManualsOnMobile = ({
  items,
  current,
  additionalTrackingIdInfo,
}: IPropsPanelItems): JSX.Element => {
  const pdfs = items as IGraphqlPdf[];
  return (
    <BemTouchSlider
      items={pdfs}
      itemRenderer={(data, index) =>
        getRenderItemManualMobile({
          item: data.item,
          current,
          additionalTrackingIdInfo,
          index,
        })
      }
      hideShadow
    />
  );
};

const ManualsOnTabletOrLarger = ({
  items,
  current,
  additionalTrackingIdInfo,
}: IPropsPanelItems) => {
  const pdfs = items as IGraphqlPdf[];
  return (
    <>
      {pdfs.map((pdf, index) => (
        <Manual
          key={pdf._key}
          item={pdf}
          current={current}
          index={index}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />
      ))}
    </>
  );
};

const Panel = ({
  onToggle,
  title,
  items,
  type,
  current,
  additionalTrackingIdInfo,
}: IPropsPanel): JSX.Element => {
  let content;

  switch (type) {
    case 'video': {
      const videos = items as IVideo[];
      content = useViewportRenderer([
        <VideosOnMobile
          key={`${PREFIX}_${type}_${VIEWPORTS.MOBILE}`}
          items={videos}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
        <VideosOnTabletOrLarger
          key={`${PREFIX}_${type}_${VIEWPORTS.TABLET}`}
          items={videos}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
        <VideosOnTabletOrLarger
          key={`${PREFIX}_${type}_${VIEWPORTS.DESKTOP}`}
          items={videos}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
      ]);
      break;
    }
    case 'manual': {
      const documents = items as IGraphqlPdf[];

      content = useViewportRenderer([
        <ManualsOnMobile
          key={`${PREFIX}_${type}_${VIEWPORTS.MOBILE}`}
          items={documents}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
        <ManualsOnTabletOrLarger
          key={`${PREFIX}_${type}_${VIEWPORTS.TABLET}`}
          items={documents}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
        <ManualsOnTabletOrLarger
          key={`${PREFIX}_${type}_${VIEWPORTS.DESKTOP}`}
          items={documents}
          current={current}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />,
      ]);
      break;
    }
    case 'technicalSpecification': {
      const blocks = items as unknown as TGraphqlRichTextBlocks;
      content = (
        <BemRichtext
          blocks={blocks}
          sx={styles.panelContentTechnicalSpecificationWrapper}
        />
      );
      break;
    }
    default: {
      content = null;
    }
  }

  const numberItems = type === 'technicalSpecification' ? 0 : items.length;

  const { moduleName } = useContext(ModuleContext);
  const trackingId = `${moduleName}-${componentName}${
    additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
  }`;

  return (
    <MotionBox
      animate={
        current.value?.productTypeSelected?.[`${type}Panel`] ||
        EXPANDER_PANEL_CLOSED
      }
      initial={EXPANDER_PANEL_CLOSED}
      sx={styles.panelWrapper}
    >
      <Flex
        sx={styles.panelHead}
        onClick={onToggle}
        data-trackingid={trackingId}
      >
        <BemHeading variant="h4" as="h3" sx={styles.panelHeading}>
          {title}
          {numberItems ? (
            <Text as="span" variant="h4" sx={styles.panelHeadingAddon}>
              {numberItems}
            </Text>
          ) : null}
        </BemHeading>
        <BemButton
          variant="buttons.itemExpander"
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        >
          <MotionBox variants={animationVariants.expanderIcon}>
            <Box style={{ height: '2rem', width: '2rem' }}>
              <MdExpandMore size="100%" />
            </Box>
          </MotionBox>
        </BemButton>
      </Flex>
      <MotionBox
        key="content"
        variants={animationVariants.panelContent}
        sx={styles.panelContent}
      >
        <MotionGrid
          variant="contentGrid"
          variants={animationVariants.panelContentGrid}
          sx={styles.panelContentGrid}
        >
          {content}
        </MotionGrid>
      </MotionBox>
    </MotionBox>
  );
};

export { Panel };
