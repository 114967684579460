import { IGraphqlM0114 } from '@bemer/base';
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Box } from 'theme-ui';
import { BemLink, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0114 extends IGraphqlM0114 {}

const styles: IStylesObject = {
  contentGrid: {
    py: 4,
  },
  linksWrapper: {
    display: 'flex',
    gridColumn: '2 / -2',
    justifyContent: 'space-between',
  },
  supportLink: {
    mr: 0,
    textAlign: 'right',
  },
};

const BemM0114 = ({ backLink, supportLink }: IPropsBemM0114): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.linksWrapper}>
      <BemLink
        iconBefore={<FaArrowLeft />}
        to={backLink}
        variant="links.nav"
        additionalTrackingIdInfo={1}
      />
      <BemLink
        to={supportLink}
        variant="links.nav"
        sx={styles.supportLink}
        additionalTrackingIdInfo={2}
      />
    </Box>
  </BemModuleWrapper>
);

export { BemM0114, IPropsBemM0114 };
