import {
  aspectRatios,
  IGraphqlM0007Item,
  IGraphqlM0044Item1,
  RATIOS,
  TAdditionalTrackingIdInfo,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Box, Button, Grid, Text } from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  CARD_TEXT_PX,
  MOBILE_CARD_PADDING,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useTheme } from '../../gatsby-plugin-theme-ui/utils/useTheme';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ModuleContext } from '../../providers';
import { debugXState } from '../../utils/xStateHelper';
import { BemHeading } from '../Heading';
import { BemMedia } from '../Media';
import { BemTouchSlider } from '../TouchSlider';
import {
  blockMachine,
  IAccordionContext,
  TAccordionEvents,
} from './BemAccordion.machine';

const PREFIX = 'Accordion';

type TAccordionItemType = IGraphqlM0007Item | IGraphqlM0044Item1;

interface IPropsBemAccordion {
  items: TAccordionItemType[];
  isImagePositionRight?: boolean;
}
interface IPropsAccordion {
  item: TAccordionItemType;
  isOpen: boolean;
  setOpen: (event: TAccordionEvents) => void;
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

interface IPropsMobileItem {
  item: TAccordionItemType;
}

const MotionBemMedia = motion(Box);
const MotionBox = motion(Box);
const MotionText = motion(Text);

const TEXT_OPEN = 'open';
const TEXT_COLLAPSED = 'collapsed';
const LIST_OPEN = 'isOpen';
const LIST_CLOSED = 'isClosed';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const styles: IStylesObject = {
  wrapper: {
    gridColumn: '1 / -1',
    margin: 'initial',
  },
  accordionItem: {
    mb: 6,
    cursor: 'pointer',
    borderRadius: 1,
    py: '0_5',
    px: [8, 8, 10],
    overflow: 'hidden',
    '&:last-of-type': {
      mb: 0,
    },
  },
  accordionItemHeading: {
    cursor: 'pointer',
    textAlign: 'left',
    py: [6, 2, 2],
    px: CARD_TEXT_PX,
  },
  accordionItemText: {
    pt: [0, 6, 8],
    pb: [4, 6, 8],
    px: CARD_TEXT_PX,
  },
  mobileItemWrapper: {
    pb: MOBILE_CARD_PADDING,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemImage: (isImagePositionRight: boolean) => {
    let gridColumn = ['1', '1', '1'];
    gridColumn = isImagePositionRight
      ? ['8 / span 7', '8 / span 7', '8 / span 7']
      : ['1 / span 7', '1 / span 7', '1 / span 7'];
    return {
      gridRow: '1',
      gridColumn,
    };
  },
  accordion: (isImagePositionRight: boolean) => {
    let gridColumn = ['1', '1', '1'];
    gridColumn = isImagePositionRight
      ? ['2 / -8', '2 / -8', '2 / -9']
      : ['8 / -2', '8 / -2', '9 / -2'];
    return {
      gridColumn,
      alignSelf: 'center',
      listStyleType: 'none',
      p: 0,
      gridRow: '1',
    };
  },
};

const Panel = ({
  item,
  isOpen,
  setOpen,
  additionalTrackingIdInfo = '',
}: IPropsAccordion) => {
  const { moduleName } = useContext(ModuleContext);
  const handleClick = (e: React.SyntheticEvent<EventTarget>) => {
    if (e.type === 'click') {
      setOpen({ type: e.type, payload: item._key });
    }
  };

  const {
    theme: { space, shadows },
  } = useTheme();

  const animationVariants = {
    listItem: {
      [LIST_OPEN]: {
        boxShadow: shadows.cardShadow,
        paddingTop: space['8'],
        paddingBottom: space['10'],
      },
      [LIST_CLOSED]: {
        boxShadow: shadows.none,
        paddingTop: space['0_5'],
        paddingBottom: space['0_5'],
      },
    },
    textWrapper: {
      [TEXT_OPEN]: {
        height: 'auto',
        transition: {
          delayChildren: 0.4,
          animation: 'ease-in-out',
        },
      },
      [TEXT_COLLAPSED]: {
        height: 0,
        transition: {
          staggerChildren: 0.4,
        },
      },
    },
    text: {
      [TEXT_OPEN]: {
        opacity: 1,
      },
      [TEXT_COLLAPSED]: {
        opacity: 0,
      },
    },
  };

  const trackingId = `${moduleName}-accordionPanel${
    additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
  }`;

  return (
    <MotionBox
      as="li"
      key={item._key}
      sx={styles.accordionItem}
      variants={animationVariants.listItem}
      animate={isOpen ? LIST_OPEN : LIST_CLOSED}
    >
      <Button variant="buttons.divWrapper" onClick={handleClick}>
        <BemHeading
          as="h4"
          variant="h4"
          sx={styles.accordionItemHeading}
          data-trackingid={trackingId}
        >
          {item.title}
        </BemHeading>
      </Button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial={TEXT_COLLAPSED}
            animate={TEXT_OPEN}
            exit={TEXT_COLLAPSED}
            variants={animationVariants.textWrapper}
          >
            <MotionText
              as="p"
              sx={styles.accordionItemText}
              variants={animationVariants.text}
            >
              {item.text}
            </MotionText>
          </motion.div>
        )}
      </AnimatePresence>
    </MotionBox>
  );
};

const MobileItem = ({ item }: IPropsMobileItem) => (
  <Box sx={styles.mobileItemWrapper}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
    />
    <BemHeading as="h4" variant="h4" sx={styles.accordionItemHeading}>
      {item.title}
    </BemHeading>
    <Text as="p" sx={styles.accordionItemText}>
      {item.text}
    </Text>
  </Box>
);

interface IPropsItemsOnTabletOrLarger {
  items: TAccordionItemType[];
  current: { value: any; context: IAccordionContext };
  send: (event: TAccordionEvents) => void;
  isImagePositionRight: boolean;
}

const ItemsOnTabletOrLarger = ({
  items,
  current,
  send,
  isImagePositionRight,
}: IPropsItemsOnTabletOrLarger): JSX.Element | null => {
  const {
    context: { activeElement },
  } = current;

  return (
    <Grid variant="fullWidthGrid" sx={styles.wrapper}>
      {items.map((item: TAccordionItemType) => (
        <AnimatePresence key={item._key}>
          {activeElement === item._key && (
            <MotionBemMedia
              sx={calculatedStyles.itemImage(isImagePositionRight)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <BemMedia
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
                media={item.media[0]}
              />
            </MotionBemMedia>
          )}
        </AnimatePresence>
      ))}

      <AnimateSharedLayout>
        <MotionBox
          as="ul"
          sx={calculatedStyles.accordion(isImagePositionRight)}
          layout
        >
          {items.map((item, index) => (
            <Panel
              key={item._key}
              additionalTrackingIdInfo={index + 1}
              item={item}
              isOpen={activeElement === item._key}
              setOpen={send}
            />
          ))}
        </MotionBox>
      </AnimateSharedLayout>
    </Grid>
  );
};

const BemAccordion = ({
  items,
  isImagePositionRight,
}: IPropsBemAccordion): JSX.Element => {
  const [current, send] = useMachine(blockMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      activeElement: items[0]?._key,
    },
  });

  const DisplayComponent = () =>
    useViewportRenderer([
      <BemTouchSlider
        key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        items={items}
        itemRenderer={MobileItem}
      />,
      <ItemsOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        items={items}
        current={current}
        send={send}
        isImagePositionRight={isImagePositionRight || false}
      />,
      <ItemsOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        items={items}
        current={current}
        send={send}
        isImagePositionRight={isImagePositionRight || false}
      />,
    ]);

  return <DisplayComponent />;
};

export { BemAccordion, IPropsBemAccordion };
