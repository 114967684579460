import { aspectRatios, IGraphqlM0016, RATIOS } from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useContext } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemLink,
  BemLogo,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { BemButton } from '../../components/Button';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MODULE_INNER_SECTION_PB,
  TABLET_MODULE_INNER_SECTION_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ApiContext, ThemeHierarchyContext } from '../../providers';
import { debugXState } from '../../utils/xStateHelper';
import { BemM0077, IPropsBemM0077 } from '../M0077';
import {
  m0077Machine,
  m0077Model,
  TMachineEvent,
  TMachineState,
} from '../M0077/BemM0077.machine';

interface IPropsBemM0016 extends IGraphqlM0016 {}

interface IPropsContactModule {
  module?: IPropsBemM0077;
  machineRef: { current: TMachineState; send: (event: TMachineEvent) => void };
}

const styles: IStylesObject = {
  moduleWrapper: {
    pb: [8, 12, 16],
    pt: [8, 10, 12],
    mb: [0, 0, 0],
  },
  logoTextWrapper: {
    gridColumn: ['2 / -2', '2 / -2', '2 / span 3'],
    pb: [MOBILE_MODULE_INNER_SECTION_PB, TABLET_MODULE_INNER_SECTION_PB, 0],
  },
  bemLogo: {
    gridColumn: 'span 2',
    color: 'text',
  },
  text: {
    pt: 2,
    pb: 8,
    color: 'textSecondary',
  },
  textWrapper: {
    pt: 4,
    gridColumn: ['2 / -2', '2 / -2', '6 / -2'],
    flexDirection: ['column', 'row', 'row'],
    justifyContent: 'space-between',
    pb: [12, 16, 20],
  },
  pagesWrapper: {
    flexDirection: 'column',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
    '&:last-of-type': {
      pb: 0,
    },
  },
  pageCategory: {
    pb: 6,
    fontWeight: 'bold',
    color: 'text',
  },
  pageCategoryWithoutChildLinks: {
    pb: 8,
    fontWeight: 'bold',
    color: 'text',
  },
  pageLabel: {
    pb: 4,
    color: 'text',
  },
  socialMediaWrappper: {
    gridColumn: ['2 / -2', '2 / span 2', '2 / span 2'],
    flexDirection: 'row',
  },
  socialMediaIcon: {
    width: 10,
    height: 10,
    pr: 5,
  },
  legalLinksWrapper: {
    flexWrap: 'wrap',
    flexDirection: ['column', 'row', 'row'],
    gridColumn: ['2/ -2', '4 / -2', '4 / -2'],
    justifyContent: ['flex-start', 'flex-end', 'flex-end'],
    alignItems: ['flex-start', 'center', 'center'],
    pb: 5,
  },
  legalLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'text',
    pb: 5,
    pl: [0, 8, 8],
    '&:first-of-type': {
      pl: 0,
    },
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    color: 'textSecondary',
    pl: [0, 8, 8],
    pb: 5,
  },
  paymentIconsWrapper: {
    pt: 7,
    gridColumn: '2 / -2',
    justifyContent: 'center',
  },
  paymentIcon: {
    width: 16,
    height: 8,
    mr: 5,
    '&:last-of-type': {
      mr: 0,
    },
  },
};

const ContactModule = ({ module, machineRef }: IPropsContactModule) => {
  if (!module) {
    return null;
  }
  return <BemM0077 {...(module as IPropsBemM0077)} machineRef={machineRef} />;
};

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0016 = ({
  logoLink,
  text,
  buttonText,
  links,
  socialMediaLogos,
  paymentLogos,
  legalLinks,
  contactModule,
  theme: moduleTheme,
}: IPropsBemM0016): JSX.Element => {
  const [current, send] = useMachine(m0077Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
  });

  const leftLinks = links.filter((link) => link.childLinks?.length);
  const rightLinks = links.filter((link) => !link.childLinks?.length);
  const [theme] = useContext(ThemeHierarchyContext);

  const {
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);

  const { data: visitorSettings } = getVisitorSettings();
  const handleClickOnButton = () => {
    send(m0077Model.events.openModal(visitorSettings?.partnerHandle));
  };

  return (
    <>
      <BemThemeWrapper themeName={moduleTheme || theme}>
        <BemModuleWrapper
          isFullWidth
          sx={styles.moduleWrapper}
          data-testid="BemM0016-moduleWrapper"
        >
          <Box
            sx={styles.logoTextWrapper}
            data-testid="BemM0016-logoTextWrapper"
          >
            {logoLink ? (
              <BemLink to={logoLink}>
                <BemLogo sx={styles.bemLogo} />
              </BemLink>
            ) : (
              <BemLogo sx={styles.bemLogo} />
            )}
            <Box sx={styles.contentWrapper}>
              <Text as="p" variant="small" sx={styles.text}>
                {text}
              </Text>
              {buttonText ? (
                <BemButton
                  onClick={handleClickOnButton}
                  variant="buttons.primary"
                >
                  {buttonText}
                </BemButton>
              ) : null}
            </Box>
          </Box>
          <Flex sx={styles.textWrapper}>
            {leftLinks.map((left, index) => (
              <Flex key={left.categoryLink[0].label} sx={styles.pagesWrapper}>
                <BemLink
                  to={left.categoryLink}
                  sx={styles.pageCategory}
                  variant="links.footerLinks"
                  additionalTrackingIdInfo={`linkLeft-${index + 1}`}
                >
                  {left.categoryLink[0].label}
                </BemLink>
                {left.childLinks && left.childLinks.length > 0
                  ? left.childLinks.map((childLink, indexChild) => (
                      <BemLink
                        to={childLink}
                        key={childLink.label}
                        sx={styles.pageLabel}
                        variant="links.footerLinks"
                        partiallyActive
                        additionalTrackingIdInfo={`linkLeft-${
                          index + 1
                        }-childLink-${indexChild + 1}`}
                      />
                    ))
                  : null}
              </Flex>
            ))}
            <Flex sx={styles.pagesWrapper}>
              {rightLinks.map((right, index) => (
                <BemLink
                  to={right.categoryLink}
                  sx={styles.pageCategoryWithoutChildLinks}
                  key={right.categoryLink[0].label}
                  variant="links.footerLinks"
                  partiallyActive
                  additionalTrackingIdInfo={`linkRight-${index + 1}`}
                >
                  {right.categoryLink[0].label}
                </BemLink>
              ))}
            </Flex>
          </Flex>

          <Flex sx={styles.socialMediaWrappper}>
            {socialMediaLogos && socialMediaLogos.length > 0
              ? socialMediaLogos.map((socialMediaLogo, index) => (
                  <BemLink
                    to={socialMediaLogo.socialMediaLogoLink}
                    key={socialMediaLogo._key}
                    data-testid={socialMediaLogo._key}
                    additionalTrackingIdInfo={`linkSocial-${index + 1}`}
                  >
                    <BemCloudImage
                      image={socialMediaLogo.image}
                      sx={styles.socialMediaIcon}
                    />
                  </BemLink>
                ))
              : null}
          </Flex>

          <Flex sx={styles.legalLinksWrapper}>
            {legalLinks.map((legalLink, index) => (
              <BemLink
                key={legalLink._key}
                to={legalLink}
                sx={styles.legalLink}
                variant="links.footerLinks"
                additionalTrackingIdInfo={`linkLegal-${index + 1}`}
              />
            ))}
            <Text variant="small" sx={styles.copyright}>
              © 2023 Bemer Group
            </Text>
          </Flex>

          <Flex sx={styles.paymentIconsWrapper}>
            {paymentLogos && paymentLogos.length > 0
              ? paymentLogos.map((paymentLogo) => (
                  <BemCloudImage
                    key={paymentLogo._key}
                    image={paymentLogo}
                    forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
                    sx={styles.paymentIcon}
                  />
                ))
              : null}
          </Flex>
        </BemModuleWrapper>
      </BemThemeWrapper>
      <ContactModule
        module={contactModule?.module?.[0]}
        machineRef={{ current, send }}
      />
    </>
  );
};

export { BemM0016, IPropsBemM0016 };
