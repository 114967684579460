import { aspectRatios, IGraphqlM0010, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP,
  GRID_ROW_GAP_TO_ZERO,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  TABLET_MEDIA_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0010 extends IGraphqlM0010 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  itemsGrid: {
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)'],
  },
  card: {
    gridColumn: 'span 1',
  },
  cardGrid: {
    gridTemplate: '1fr',
    gridRowGap: GRID_ROW_GAP,
    rowGap: GRID_ROW_GAP_TO_ZERO,
  },
  cardImage: {
    gridRow: 1,
    gridColumn: 1,
    zIndex: 1,
    pb: [MOBILE_MEDIA_PB, TABLET_MEDIA_PB, 0],
  },
  cardOverlay: {
    gridRow: [2, 2, 1],
    gridColumn: 1,
    zIndex: 2,
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 8, 11],
    alignItems: 'center',
    alignSelf: 'end',
    rowGap: GRID_ROW_GAP_TO_ZERO,
  },
  cardTitle: {
    gridColumn: ['span 12', 'span 12', '2 / 7'],
  },
  cardButton: {
    gridColumn: ['span 12', 'span 12', '7 / -2'],
    justifySelf: ['start', 'start', 'end'],
    width: ['100%', '100%', 'auto'],
    mt: [4, 6, 0],
  },
  bottomLink: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pt: [4, 4, 16],
  },
};

const BemM0010 = ({ title, items, link }: IPropsBemM0010): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2WithSeparatorCentered" sx={styles.heading}>
      {title}
    </BemHeading>
    <Grid variant="contentGrid" sx={styles.itemsGrid}>
      {items.map((item, index: number) =>
        item.link?.length ? (
          <BemLink
            key={item._key}
            to={item.link}
            sx={styles.card}
            data-testid={item._key}
            additionalTrackingIdInfo={index + 1}
          >
            <Grid sx={styles.cardGrid}>
              <BemMedia
                media={item.media[0]}
                sx={styles.cardImage}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
                additionalTrackingIdInfo={index + 1}
              />
              <Grid
                sx={styles.cardOverlay}
                variant="contentGrid"
                data-testid={`${item._key}-withLink`}
              >
                <BemThemeWrapper
                  themeName={[
                    THEME_NAMES.DARK_TEXT,
                    THEME_NAMES.DARK_TEXT,

                    // TODO: refactor quick fixes for having videos, images and video sets
                    item.media[0]._type === 'videoSet'
                      ? getLanguageSpecificVideo(item.media[0]).themeName
                      : item.media[0].themeName,
                  ]}
                >
                  <Text as="p" variant="caption.big" sx={styles.cardTitle}>
                    {item.title}
                  </Text>
                  <BemLink
                    variant="links.buttonPrimary"
                    sx={styles.cardButton}
                    to={item.link}
                  >
                    {item.link[0].label}
                  </BemLink>
                </BemThemeWrapper>
              </Grid>
            </Grid>
          </BemLink>
        ) : (
          <Box key={item._key} sx={styles.card} data-testid={item._key}>
            <Grid sx={styles.cardGrid}>
              <BemMedia
                media={item.media[0]}
                sx={styles.cardImage}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
                additionalTrackingIdInfo={index + 1}
              />
              <Grid sx={styles.cardOverlay} variant="contentGrid">
                <BemThemeWrapper
                  themeName={[
                    THEME_NAMES.DARK_TEXT,
                    THEME_NAMES.DARK_TEXT,
                    // TODO: refactor quick fixes for having videos, images and video sets
                    item.media[0]._type === 'videoSet'
                      ? getLanguageSpecificVideo(item.media[0]).themeName
                      : item.media[0].themeName,
                  ]}
                >
                  <Text as="p" variant="caption.big" sx={styles.cardTitle}>
                    {item.title}
                  </Text>
                </BemThemeWrapper>
              </Grid>
            </Grid>
          </Box>
        )
      )}
    </Grid>
    <BemLink to={link} variant="links.boldUppercase" sx={styles.bottomLink} />
  </BemModuleWrapper>
);

export { BemM0010, IPropsBemM0010 };
