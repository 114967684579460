import { assign, Machine, StateSchema } from 'xstate';

interface IM0031Context {
  activeElementKey: string;
}
interface IM0031Schema {
  states: {
    idle: StateSchema;
  };
}

type TM0031Events = { type: 'click'; payload: string };

const m0031Machine = Machine<IM0031Context, IM0031Schema, TM0031Events>(
  {
    id: 'items',
    initial: 'idle',
    context: {
      activeElementKey: '',
    },
    states: {
      idle: {
        on: {
          click: {
            actions: ['activeElementKey'],
          },
        },
      },
    },
  },
  {
    actions: {
      activeElementKey: assign({
        activeElementKey: (_context, event) => event.payload,
      }),
    },
  }
);
export { m0031Machine, TM0031Events, IM0031Context };
