import { IGraphqlM0019, TGraphqlModulePosition } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import {
  BemBreadcrumb,
  BemButton,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { TTheme } from '../../gatsby-plugin-theme-ui';
import {
  defaultGridGap,
  MAX_WIDTH_MAIN_COLUMNS,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import {
  getMarginStylesForModulePosition,
  MODULE_MB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useTheme } from '../../gatsby-plugin-theme-ui/utils/useTheme';
import {
  ApiContext,
  LocalisationContext,
  NavigationContext,
  PathNamesContext,
} from '../../providers';
import { isDesktop } from '../../utils/breakpointIndices';
import { extendLinkWithPartnerHandle } from '../../utils/extendLinkWithPartnerHandle';
import { getLocalizedAmount } from '../../utils/localisation';

interface IPropsBemM0019 extends IGraphqlM0019 {}

const MotionGrid = motion(Grid);
const MotionBox = motion(Box);

const VARIANTS_GRID_CONTENT_WIDTH = 'contentWidth';
const VARIANTS_GRID_FULL_WIDTH = 'fullWidth';

const styles: IStylesObject = {
  stickyCheckerWrapper: {
    position: 'relative',
  },
  linkButton: {
    whiteSpace: 'nowrap',
    gridColumn: ['span 2', 'span 1', 'span 1'],
  },
  infoWrapper: {
    alignItems: 'center',
    pb: [2, 0, 0],
  },
  price: {
    flex: '1 0 auto',
    pt: [0, '0_5', '0_5'],
    pr: defaultGridGap,
    pl: defaultGridGap,
    textAlign: 'right',
  },
  outerContentWrapper: {
    gridColumn: '1 / -1',
  },
};
const topMarginIfM0001IsSticky = '6.75rem';

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (modulePosition: TGraphqlModulePosition, isM0001Sticky: boolean) => {
    const isOnDesktop = isDesktop();
    return {
      ...getMarginStylesForModulePosition(modulePosition),
      position: 'sticky',
      top: isM0001Sticky && isOnDesktop ? topMarginIfM0001IsSticky : 16,
      zIndex: 5,
      boxShadow: 'cardShadow',
      pb: ['2_5', '5', '5'],
      px: [2, 3, 4],
    };
  },
  contentWrapper: (isSticky: boolean) => ({
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr min-content',
      '1fr repeat(2, min-content)',
      '1fr repeat(2, min-content)',
    ],
    width: '100%',
    margin: 'auto',
    maxWidth: isSticky ? MAX_WIDTH_MAIN_COLUMNS : 'auto',
    gridGap: [0, 0, 0],
  }),
  stickyChecker: (
    theme: TTheme,
    modulePosition: TGraphqlModulePosition,
    isM0001Sticky: boolean
  ) => {
    // Uses the size of the minified M0002 and the MODULE_MB for the belowHero position.
    const top =
      modulePosition === 'belowHero'
        ? MODULE_MB.map(
            (v) =>
              `calc((${theme.space[`${v}` as keyof TTheme['space']]} + ${
                theme.space['16']
              }) * (-1))`
          )
        : `-${theme.space['16']}`;
    const topWithStickyM0001 =
      modulePosition === 'belowHero'
        ? MODULE_MB.map(
            (v) =>
              `calc((${theme.space[`${v}` as keyof TTheme['space']]} + ${
                theme.space['24']
              }) * (-1))`
          )
        : `-${theme.space['24']}`;
    const isOnDesktop = isDesktop();
    return {
      bg: 'transparent',
      position: 'absolute',
      // Works better, when stickyChecker is bigger, so lets use 100px square.
      height: '100px',
      width: '100px',
      right: 0,
      top: isM0001Sticky && isOnDesktop ? topWithStickyM0001 : top,
    };
  },
};

const BemM0019 = ({
  product,
  link,
  modulePosition = 'belowHero',
}: IPropsBemM0019): JSX.Element | null => {
  const isDesktopViewport = isDesktop();
  const { locale } = useContext(LocalisationContext);
  const pathNames = useContext(PathNamesContext);
  const {
    send: navSend,
    current: {
      context: { isM0001Sticky, isM0019Sticky },
    },
  } = useContext(NavigationContext);
  const {
    products: { getProduct },
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);
  const { data } = getProduct(locale, product.productId);
  const { data: visitorSettings, isFetching: isFetchingGetVisitorSettings } =
    getVisitorSettings();

  const { theme } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const linkWithPartner = extendLinkWithPartnerHandle(
    link,
    visitorSettings?.partnerHandle
  );

  useEffect(() => {
    const cachedRef = ref.current;

    const observer = new IntersectionObserver(
      ([e]) =>
        navSend({
          type: 'updateIsM0019Sticky',
          data: {
            isSticky: e.intersectionRatio < 1 && e.boundingClientRect.top <= 0,
          },
        }),
      { threshold: [1] }
    );
    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  const variantsGrid = {
    [VARIANTS_GRID_CONTENT_WIDTH]: {
      transition: { duration: DURATION.SHORT, animation: 'ease-in-out' },
    },
    [VARIANTS_GRID_FULL_WIDTH]: {
      transition: { duration: DURATION.SHORT, animation: 'ease-in-out' },
    },
  };

  const themeUiToPixel = (themeUiValue: number) => `${themeUiValue * 4}px`;

  const animationVariants = {
    wrapper: {
      [VARIANTS_GRID_CONTENT_WIDTH]: {
        paddingTop: themeUiToPixel(5),
        transition: { duration: DURATION.SHORT, animation: 'ease-in-out' },
      },
      [VARIANTS_GRID_FULL_WIDTH]: {
        paddingTop: 0,
        transition: { duration: DURATION.SHORT, animation: 'ease-in-out' },
      },
    },
  };
  return (
    <>
      <Box sx={styles.stickyCheckerWrapper}>
        <Box
          sx={calculatedStyles.stickyChecker(
            theme,
            modulePosition,
            isM0001Sticky
          )}
          ref={ref}
        />
      </Box>
      <BemModuleWrapper
        id="BemM0019"
        isFullWidth={isM0019Sticky && isDesktopViewport}
        sx={calculatedStyles.wrapper(modulePosition, isM0001Sticky)}
        data-testid="BemM0019-moduleWrapper"
      >
        <MotionBox
          sx={styles.outerContentWrapper}
          initial={VARIANTS_GRID_CONTENT_WIDTH}
          variants={animationVariants.wrapper}
          animate={
            isDesktopViewport && isM0019Sticky
              ? VARIANTS_GRID_FULL_WIDTH
              : VARIANTS_GRID_CONTENT_WIDTH
          }
        >
          <MotionGrid
            initial={VARIANTS_GRID_CONTENT_WIDTH}
            animate={
              isDesktopViewport && isM0019Sticky
                ? VARIANTS_GRID_FULL_WIDTH
                : VARIANTS_GRID_CONTENT_WIDTH
            }
            variants={variantsGrid}
            variant="contentGrid"
            sx={calculatedStyles.contentWrapper(isM0019Sticky)}
            layout
          >
            <Flex sx={styles.infoWrapper}>
              <BemBreadcrumb
                links={pathNames}
                limitationOfPath={2}
                addPaddingTop
              />
              <Text as="p" sx={styles.price}>
                <strong>
                  {data && data.price !== null
                    ? getLocalizedAmount(locale, data.price, data.currency)
                    : null}
                </strong>
              </Text>
            </Flex>
            {isFetchingGetVisitorSettings ? (
              <BemButton
                variant="buttons.primary"
                disabled
                sx={styles.linkButton}
              >
                {link[0].label}
              </BemButton>
            ) : (
              <BemLink
                to={linkWithPartner}
                variant="links.buttonPrimary"
                sx={styles.linkButton}
              />
            )}
          </MotionGrid>
        </MotionBox>
      </BemModuleWrapper>
    </>
  );
};

export { BemM0019, IPropsBemM0019 };
