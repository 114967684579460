import { aspectRatios, IGraphqlM0120, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import { BemHeading, BemMedia, BemModuleWrapper } from '../../components';
import { GRID_GAP_MOBILE } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  MOBILE_MODULE_TEXT_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0120 extends IGraphqlM0120 {}

const styles: IStylesObject = {
  textWrapper: {
    gridColumn: ['2 / -2', '2 / span 3', '2 / span 3'],
    gridRow: 1,
  },
  title: {
    pt: [0, 9, 9],
    pb: ASIDE_HEADLINE_PB,
  },
  text: {
    pb: [MOBILE_MODULE_TEXT_PB, 0, 0],
  },
  imageGrid: {
    gridRow: [2, 1, 1],
    gridTemplateRows: 'auto 1fr',
    gridColumn: ['2 / -2', '2 / -2', '2 / -2'],
  },
  firstImage: {
    gridColumn: ['1 / span 7', '5 / span 5', '5 / span 5'],
    '&& > *': {
      height: '100%',
    },
    pb: [GRID_GAP_MOBILE, 'inherit', 'inherit'],
  },
  secondImage: {
    gridColumn: ['8 / span 5', '10 / span 3', '10 / span 3'],
    pb: [GRID_GAP_MOBILE, 'inherit', 'inherit'],
    '&& > *': {
      height: '100%',
    },
  },
  thirdImage: {
    gridColumn: ['1 / span 6', '5 / span 4', '5 / span 4'],
  },
  fourthImage: {
    gridColumn: ['7 / span 6', '9 / span 4', '9 / span 4'],
  },
};

const BemM0120 = ({ title, text, items }: IPropsBemM0120): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.textWrapper}>
      <BemHeading as="h2" variant="h2" sx={styles.title}>
        {title}
      </BemHeading>
      <Text as="p" variant="caption.small" sx={styles.text}>
        {text}
      </Text>
    </Box>
    <Grid
      variant="contentGrid"
      sx={styles.imageGrid}
      data-testid="BemM0120imageGrid"
    >
      <BemMedia
        media={items[0].media[0]}
        sx={styles.firstImage}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_4_3].ratio}
        additionalTrackingIdInfo={1}
      />
      <BemMedia
        media={items[1].media[0]}
        sx={styles.secondImage}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_6].ratio}
        additionalTrackingIdInfo={2}
      />
      <BemMedia
        media={items[2].media[0]}
        sx={styles.thirdImage}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
        additionalTrackingIdInfo={3}
      />
      <BemMedia
        media={items[3].media[0]}
        sx={styles.fourthImage}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
        additionalTrackingIdInfo={4}
      />
    </Grid>
  </BemModuleWrapper>
);

export { BemM0120, IPropsBemM0120 };
