import {
  determineUsedComponentTheme,
  TAdditionalTrackingIdInfo,
} from '@bemer/base';
import { ButtonProps } from '@theme-ui/components';
import React, { ForwardedRef, useContext } from 'react';
import { Button } from 'theme-ui';
import { TButtonVariant } from '../../gatsby-plugin-theme-ui/buttons';
import { ModuleContext, ThemeHierarchyContext } from '../../providers';
import { BemThemeWrapper } from '../ThemeWrapper';

interface IPropsBemButton extends ButtonProps {
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
  variant?: TButtonVariant;
}

const componentName = 'BemButton';
// eslint-disable-next-line react/display-name
const BemButton = React.forwardRef(
  (
    {
      additionalTrackingIdInfo = '',
      children,
      variant = 'buttons.primary',
      ...props
    }: IPropsBemButton,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element | null => {
    const [theme] = useContext(ThemeHierarchyContext);
    const { moduleTheme } = useContext(ModuleContext);

    const usedThemeName = determineUsedComponentTheme(theme, moduleTheme);

    const trackingId = `${moduleTheme}-${componentName}-${variant?.replace(
      '.',
      '_'
    )}${additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''}`;
    return (
      <BemThemeWrapper themeName={usedThemeName}>
        <Button
          data-trackingid={trackingId}
          variant={variant}
          {...props}
          ref={ref}
        >
          {children}
        </Button>
      </BemThemeWrapper>
    );
  }
);

export { BemButton, IPropsBemButton };
