import {
  buildInternalRoute,
  IGraphqlInternalNavigationImageLink,
  IGraphqlInternalNavigationLink,
  IGraphqlM0002,
  TAllM0002FlyoutGraphqlModule,
} from '@bemer/base';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useMachine } from '@xstate/react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { IoMenuOutline } from 'react-icons/io5';
import { MdArrowDropDown } from 'react-icons/md';
import { Box, Flex, Grid } from 'theme-ui';
import { BemLink, BemLogo, BemModuleWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { useTheme } from '../../gatsby-plugin-theme-ui/utils/useTheme';
import { VIEWPORT_INDEX } from '../../hooks/useViewportRenderer';
import { LocalisationContext, NavigationContext } from '../../providers';
import { isDesktop } from '../../utils/breakpointIndices';
import { isPartialMatch } from '../../utils/isPartialMatch';
import { debugXState } from '../../utils/xStateHelper';
import { BemM0002A, IPropsBemM0002A } from '../M0002A';
import { BemM0002B, IPropsBemM0002B } from '../M0002B';
import { BemM0002C, IPropsBemM0002C } from '../M0002C';
import { BemM0002Mobile } from './BemM0002-mobile';
import { navMachine } from './BemM0002.machine';

interface IPropsBemM0002 extends IGraphqlM0002 {}

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const MotionFlex = motion(Flex);
const MotionBox = motion(Box);
const MotionGrid = motion(Grid);
const AnimatedBox = motion(Box);

const HOVER_INDICATOR_OPEN = 'open';
const HOVER_INDICATOR_CLOSED = 'closed';

const NAVIGATION_SIZE_MINIMIZED = 'minimized';
const NAVIGATION_SIZE_NORMAL = 'normal';

const WITH_SHADOW = 'withShadow';
const WITHOUT_SHADOW = 'withoutShadow';

const styles: IStylesObject = {
  stickyCheckerWrapper: {
    position: 'relative',
  },
  textWrapper: {
    gridColumn: '2 / -2',
  },
  logo: {
    mr: 4,
    color: 'black',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 120,
  },
  hoverIndicator: {
    backgroundColor: 'text',
    width: '100%',
    height: '2px',
    position: 'absolute',
    bottom: 0,
    pointerEvents: 'none',
  },
  navItem: {
    alignItems: 'center',
    position: 'relative',
    height: 24,
    borderWidth: 0,
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  navRow: {
    flexGrow: 0,
    mr: 4,
    display: ['none', 'none', 'flex'],
  },
  navigationLinksWrapper: {
    gridColumn: '1 / -1',
    maxWidth: 'unset',
    margin: 0,
    boxShadow: 'smallCardShadow',
  },
  subNavigationLink: {
    mr: 6,
    color: 'gray.7',
    border: 'none',
    '&.active': {
      color: 'text',
      border: 'none',
      fontWeight: 700,
    },
    '&:hover': {
      color: 'currentColor',
      border: 'none',
    },
  },
  additionalLinkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    pb: 3,
  },
  subNavigationLinks: {
    display: 'flex',
    alignItems: 'center',
    pb: 3,
  },
  subNavigationLinksWrapper: {
    justifyContent: 'space-between',
    gridColumn: '2/ -2',
    display: ['none', 'none', 'flex'],
  },
  burger: {
    display: ['block', 'block', 'none'],
    pl: 4,
  },
  mobileNav: {
    height: '100vh',
    position: 'absolute',
    boxShadow: 'cardShadow',
    backgroundColor: 'background',
    overflow: 'scroll',
    zIndex: 2,

    px: 6,
    pb: 4,
  },
  navigationLink: {
    display: 'flex',
    '&:hover': {
      color: 'currentColor',
    },
    '&.active': {
      color: 'text',
      fontWeight: 700,
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (isM0001Sticky: boolean) => ({
    mb: 0,
    position: 'sticky',
    top: isM0001Sticky ? [0, 0, 11] : 0,
    zIndex: 100,
  }),
  activeLineLink: (isActive: boolean) =>
    isActive
      ? {
          color: 'text',
          fontWeight: 700,
        }
      : {
          color: 'gray.7',
          fontWeight: 400,
        },
  stickyChecker: (isM0001Sticky: boolean, isM0001Rendered: boolean) => {
    const stickyCheckerTop = isM0001Rendered ? '-1px' : 0;
    return {
      bg: 'transparent',
      position: 'absolute',
      // Works better, when stickyChecker is bigger, so lets use 100px square.
      height: '100px',
      width: '100px',
      right: 0,
      top: isM0001Sticky ? -10 : stickyCheckerTop,
    };
  },
};

const createSubNavigationLink = (
  link: IGraphqlInternalNavigationImageLink | IGraphqlInternalNavigationLink,
  index: number
) => {
  const label = link?.subNavLabel ? link.subNavLabel : link.label;
  return (
    <BemLink
      variant="links.nav"
      key={link._key}
      to={{ ...link, label }}
      sx={styles.subNavigationLink}
      additionalTrackingIdInfo={index + 1}
    />
  );
};

const getSubNavLinks = (module?: TAllM0002FlyoutGraphqlModule) => {
  if (!module) {
    return null;
  }

  const baseLinks = module.links
    .filter((link) => link.baseLink)
    .map((link, index) => createSubNavigationLink(link, index));

  return [
    ...baseLinks.reverse(),
    module.links
      .filter((link) => !link.baseLink)
      .map((link, index) => createSubNavigationLink(link, index)),
  ];
};

const getAdditionalLink = (
  activeSubNavigationLink?:
    | IGraphqlInternalNavigationImageLink
    | IGraphqlInternalNavigationLink
) => {
  if (!activeSubNavigationLink) return null;
  if (!activeSubNavigationLink.additionalLink) return null;

  return (
    <Box sx={styles.additionalLinkWrapper}>
      <BemLink
        to={activeSubNavigationLink.additionalLink}
        variant="links.buttonSecondary"
        iconBefore={<FaDownload />}
        additionalTrackingIdInfo="additionalLink"
      />
    </Box>
  );
};

const Flyout = ({ module }: { module?: TAllM0002FlyoutGraphqlModule }) => {
  if (!module) {
    return null;
  }
  switch (module._type) {
    case 'm0002A': {
      return <BemM0002A {...(module as IPropsBemM0002A)} />;
    }
    case 'm0002B': {
      return <BemM0002B {...(module as IPropsBemM0002B)} />;
    }
    case 'm0002C': {
      return <BemM0002C {...(module as IPropsBemM0002C)} />;
    }
    default: {
      throw new Error(`Unhandled _type in module: ${module}`);
    }
  }
};

const BemM0002 = ({ nav, logoLink, theme }: IPropsBemM0002): JSX.Element => {
  const { localeIdentifier } = useContext(LocalisationContext);
  const breakpointIndex = useBreakpointIndex();
  const windowPathName =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const {
    send: navSend,
    isSubNavigationHidden,
    current: {
      context: { isM0001Sticky, isM0002Sticky, isM0019Sticky, isM0001Rendered },
    },
  } = useContext(NavigationContext);
  const [current, send] = useMachine(navMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
  });
  const [navigationSize, setNavigationSize] = useState(NAVIGATION_SIZE_NORMAL);
  const stickyCheckerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cachedRef = stickyCheckerRef.current;

    const observer = new IntersectionObserver(
      ([e]) => {
        const isStickyCheckerOutsideTheTop =
          e.intersectionRatio < 1 && e.boundingClientRect.top <= 0;
        navSend({
          type: 'updateIsM0002Sticky',
          data: {
            isSticky: isStickyCheckerOutsideTheTop,
          },
        });
        setNavigationSize(
          isStickyCheckerOutsideTheTop
            ? NAVIGATION_SIZE_MINIMIZED
            : NAVIGATION_SIZE_NORMAL
        );
      },
      { threshold: [1] }
    );
    if (cachedRef) {
      observer.observe(cachedRef);
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  useEffect(() => {
    navSend({
      type: 'updateData',
      data: nav,
    });
  }, [nav]);

  useEffect(() => {
    if (breakpointIndex === VIEWPORT_INDEX.DESKTOP) {
      navSend({ type: 'closeMobileNav' });
    }
  }, [breakpointIndex]);

  const variants = {
    hoverIndicator: {
      [HOVER_INDICATOR_OPEN]: {
        opacity: 1,
        transition: { duration: DURATION.LONG },
      },
      [HOVER_INDICATOR_CLOSED]: {
        opacity: 0,
        transition: { duration: DURATION.LONG },
      },
    },
  };

  const activeNavigationLink = nav.find((navItem) =>
    isPartialMatch(
      buildInternalRoute(navItem.to, localeIdentifier),
      windowPathName
    )
  );

  const activeSubNavigationLink =
    activeNavigationLink?.flyoutModule?.module[0].links.find((navLink) =>
      isPartialMatch(
        buildInternalRoute(navLink.to, localeIdentifier),
        windowPathName
      )
    );

  const {
    theme: { space, shadows },
  } = useTheme();

  const animationVariants = {
    navigationWrapper: {
      [NAVIGATION_SIZE_MINIMIZED]: {
        height: space['16'],
        shadow: shadows.none,
      },
      [NAVIGATION_SIZE_NORMAL]: { height: space['24'] },
    },
    subNavLinks: {
      [NAVIGATION_SIZE_MINIMIZED]: { height: space['0'], opacity: 0 },
      [NAVIGATION_SIZE_NORMAL]: { height: space['16'], opacity: 1 },
    },
    grid: {
      [WITHOUT_SHADOW]: {
        boxShadow: shadows.none,
      },
      [WITH_SHADOW]: { boxShadow: shadows.smallCardShadow },
    },
  };

  const showSubNavigationLinks =
    !isSubNavigationHidden &&
    activeNavigationLink?.flyoutModule &&
    !isM0002Sticky;

  const animateSubnavigationLinks = !isSubNavigationHidden && !isDesktop();

  return (
    <>
      <Box sx={styles.stickyCheckerWrapper}>
        <Box
          sx={calculatedStyles.stickyChecker(isM0001Sticky, isM0001Rendered)}
          ref={stickyCheckerRef}
        />
      </Box>
      <BemModuleWrapper
        isFullWidth
        sx={calculatedStyles.wrapper(isM0001Sticky)}
        data-testid="BemM0002-desktop"
      >
        <MotionGrid
          initial={NAVIGATION_SIZE_NORMAL}
          animate={isM0019Sticky ? WITHOUT_SHADOW : WITH_SHADOW}
          variants={animationVariants.grid}
          variant="fullWidthGrid"
          sx={styles.navigationLinksWrapper}
          transition={{ duration: DURATION.SHORT }}
        >
          <Box sx={styles.textWrapper}>
            <Flex
              data-testid="wrapper__navbar"
              onMouseLeave={() => {
                send({ type: 'mouseleave' });
              }}
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              {logoLink ? (
                <BemLink to={logoLink} additionalTrackingIdInfo="Logo">
                  <BemLogo sx={styles.logo} />
                </BemLink>
              ) : (
                <BemLogo sx={styles.logo} />
              )}

              <AnimateSharedLayout>
                <Box as="nav" sx={styles.navRow}>
                  {nav.map((navItem, index) => {
                    const { label, _key, flyoutModule } = navItem;
                    const isOpen = current.context.activeElement === _key;

                    const hasFlyout = !!flyoutModule;

                    const isActive = activeNavigationLink?._key === _key;

                    return (
                      <AnimatedBox key={_key} animate={current.value}>
                        <>
                          <AnimatePresence>
                            <MotionFlex
                              transition={{ duration: DURATION.SHORT }}
                              initial={NAVIGATION_SIZE_NORMAL}
                              animate={navigationSize}
                              variants={animationVariants.navigationWrapper}
                              variant="links.nav"
                              onMouseEnter={() =>
                                send({ type: 'mouseenter', payload: _key })
                              }
                              onMouseLeave={() => {
                                send({ type: 'decline' });
                              }}
                              sx={styles.navItem}
                            >
                              {isOpen && (
                                <AnimatedBox
                                  layoutId="underline"
                                  variants={variants.hoverIndicator}
                                  sx={styles.hoverIndicator}
                                />
                              )}
                              <BemLink
                                to={navItem}
                                sx={{
                                  ...styles.navigationLink,
                                  ...calculatedStyles.activeLineLink(isActive),
                                }}
                                partiallyActive
                                additionalTrackingIdInfo={index + 1}
                                data-testid={navItem._key}
                              >
                                {label}{' '}
                                {hasFlyout && (
                                  <Box
                                    style={{ height: '1rem', width: '1rem' }}
                                  >
                                    <MdArrowDropDown size="100%" />
                                  </Box>
                                )}
                              </BemLink>
                            </MotionFlex>
                          </AnimatePresence>
                          {hasFlyout && isOpen && (
                            <AnimatedBox
                              key={`flyout_${flyoutModule?.module[0]._key}`}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              sx={{
                                ...styles.overlay,
                                display: isOpen ? 'block' : 'none',
                              }}
                            >
                              <Flyout module={flyoutModule?.module?.[0]} />
                            </AnimatedBox>
                          )}
                        </>
                      </AnimatedBox>
                    );
                  })}
                </Box>
              </AnimateSharedLayout>
              <Flex sx={{ alignItems: 'center', justifySelf: 'flex-end' }}>
                <Box
                  sx={styles.burger}
                  title="toggle navigation"
                  onClick={() =>
                    navSend({
                      type: 'toggleMobileNav',
                    })
                  }
                >
                  <Box style={{ height: '1.8rem', width: '1.8rem' }}>
                    <IoMenuOutline size="100%" />
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </MotionGrid>
        <AnimatePresence>
          {showSubNavigationLinks ? (
            <MotionBox
              transition={{ duration: DURATION.SHORT }}
              sx={styles.subNavigationLinksWrapper}
              variants={animationVariants.subNavLinks}
              initial={NAVIGATION_SIZE_NORMAL}
              animate={animateSubnavigationLinks ? '' : navigationSize}
              exit={animateSubnavigationLinks ? '' : NAVIGATION_SIZE_MINIMIZED}
            >
              <Box sx={styles.subNavigationLinks}>
                {getSubNavLinks(activeNavigationLink?.flyoutModule?.module[0])}
              </Box>
              {getAdditionalLink(activeSubNavigationLink)}
            </MotionBox>
          ) : null}
        </AnimatePresence>
      </BemModuleWrapper>
      <BemM0002Mobile theme={theme} />
    </>
  );
};

export { BemM0002, IPropsBemM0002 };
