import { IPartnerFinderData } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import { BemAvatar, BemLink } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsPartnerCard {
  partnerData: IPartnerFinderData;
}

const styles: IStylesObject = {
  wrapper: {
    gridTemplateColumns: 'max-content auto',
  },
};

const PartnerCard = ({ partnerData }: IPropsPartnerCard): JSX.Element => (
  <Grid variant="contentGrid" sx={styles.wrapper}>
    <BemAvatar forcePartnerId={partnerData.partnerData.customerNo} />
    <Text as="span">
      <strong>{partnerData.finderData.partnerFinderName}</strong>
      <br />
      {partnerData.finderData.shortAddress
        ? partnerData.finderData.shortAddress
        : null}
      <br />
      {partnerData.finderData.partnerFinderCompany
        ? partnerData.finderData.partnerFinderCompany
        : null}
      <br />
      <BemLink
        to={{
          _key: 'key-externalLink-url',
          _type: 'externalLink',
          label: `${partnerData.partnerData.handle}.bemergroup.com`,
          target: '_blank',
          externalLink: `https://${partnerData.partnerData.handle}.bemergroup.com`,
        }}
      />
      <br />
      {partnerData.finderData.partnerFinderPhone ? (
        <BemLink
          to={{
            _key: 'key-externalLink-tel',
            _type: 'externalLink',
            label: partnerData.finderData.partnerFinderPhone || '',
            target: '',
            externalLink: `tel:${partnerData.finderData.partnerFinderPhone.replace(
              ' ',
              ''
            )}`,
          }}
        />
      ) : null}
    </Text>
  </Grid>
);

export { PartnerCard };
