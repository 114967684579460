import { aspectRatios, IGraphqlM0045, RATIOS, THEME_NAMES } from '@bemer/base';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaDownload, FaPlay } from 'react-icons/fa';
import { Box } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { BemButton } from '../../components/Button';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ON_MEDIA_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0045 extends IGraphqlM0045 {}

const MotionBox = motion(Box);

const PREFIX = 'M0045';

const styles: IStylesObject = {
  video: {
    gridColumn: '1 / -1',
    gridRow: 1,
  },
  playButtonWrapper: {
    textAlign: 'center',
  },
  videoTitle: {
    pb: ON_MEDIA_HEADLINE_V1_PB,
    pt: [6, 8, 0],
    gridColumn: ['2 / -2', '2 / -2', 'auto'],
    textAlign: 'center',
  },
  downloadLinkWrapper: {
    gridColumn: '8 / -1',
    textAlign: 'right',
    zIndex: 1,
    gridRow: 1,
    alignSelf: 'end',
    pb: 8,
    pr: 8,
  },
};

const BemM0045 = ({
  title,
  media,
  downloadLink,
}: IPropsBemM0045): JSX.Element => {
  const [playing, setPlaying] = useState(false);

  const downloadButton = (
    <AnimatePresence>
      {downloadLink && !playing ? (
        <MotionBox
          sx={styles.downloadLinkWrapper}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <BemLink
            to={downloadLink}
            variant={
              // TODO: refactor quick fixes for having videos, images and video sets
              (media[0]._type !== 'videoSet' &&
                media[0].themeName === THEME_NAMES.LIGHT_TEXT) ||
              (media[0]._type === 'videoSet' &&
                media[0].languageDefaultVideo.themeName ===
                  THEME_NAMES.LIGHT_TEXT)
                ? 'links.buttonGhostLight'
                : 'links.buttonGhostDark'
            }
            iconAfter={<FaDownload />}
          />
        </MotionBox>
      ) : null}
    </AnimatePresence>
  );

  return (
    <BemModuleWrapper>
      <BemMedia
        media={media[0]}
        sx={styles.video}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_14_5].ratio}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
        playing={playing}
        autoplayPreview
        playButton={
          <Box sx={styles.playButtonWrapper}>
            {title
              ? useViewportRenderer([
                  null,
                  null,
                  <BemHeading
                    key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
                    as="h2"
                    variant="h2WithSeparatorCentered"
                    sx={styles.videoTitle}
                    themeName={
                      // TODO: refactor quick fixes for having videos, images and video sets
                      media[0]._type === 'videoSet'
                        ? getLanguageSpecificVideo(media[0]).themeName
                        : media[0].themeName
                    }
                  >
                    {title}
                  </BemHeading>,
                ])
              : null}
            <BemButton
              variant="buttons.play"
              onClick={() => setPlaying(true)}
              data-testid="BemCloudVideoPlayButton"
            >
              <FaPlay />
            </BemButton>
          </Box>
        }
      />
      {useViewportRenderer([null, downloadButton, downloadButton])}
      {title
        ? useViewportRenderer([
            <BemHeading
              key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
              as="h1"
              variant="h1WithSeparatorCentered"
              sx={styles.videoTitle}
            >
              {title}
            </BemHeading>,
            <BemHeading
              key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
              as="h1"
              variant="h1WithSeparatorCentered"
              sx={styles.videoTitle}
            >
              {title}
            </BemHeading>,
            null,
          ])
        : null}
    </BemModuleWrapper>
  );
};

export { BemM0045, IPropsBemM0045 };
