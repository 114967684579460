import { aspectRatios, IGraphqlM0067, RATIOS } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP,
  MOBILE_MODULE_INNER_SECTION_PT,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0067 extends IGraphqlM0067 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '1 / -1',
    pb: 6,
    pt: [MOBILE_MODULE_INNER_SECTION_PT, 10, 14],
    '&:first-of-type': {
      pt: 0,
      label: 'heroStartsWithText',
    },
  },
  itemLink: {
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    display: 'grid',
    gridTemplateColumns: [
      'repeat(12, 1fr)',
      'repeat(6, 1fr)',
      'repeat(6, 1fr)',
    ],
    gap: defaultGridGap,
    rowGap: GRID_ROW_GAP,
    alignItems: 'center',
    pb: 2,
  },
  itemImage: {
    gridColumn: ['span 2', 'span 1', 'span 1'],
  },
  itemText: {
    gridColumn: ['3 / -1', 'span 4', 'span 4'],
    color: 'text',
    '&:hover': {
      color: 'primary.8',
    },
  },
};

const BemM0067 = ({ items }: IPropsBemM0067): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      {items.map((item, categoryIndex) => (
        <React.Fragment key={item._key}>
          <BemHeading as="h2" variant="h3" sx={styles.heading}>
            {item.title}
          </BemHeading>

          {item.links.map((link, index) => (
            <BemLink
              to={link}
              data-testid={link._key}
              key={link._key}
              sx={styles.itemLink}
              additionalTrackingIdInfo={`category-${categoryIndex + 1}-link-${
                index + 1
              }`}
            >
              {link.image ? (
                <BemCloudImage
                  forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
                  image={link.image}
                  sx={styles.itemImage}
                  additionalTrackingIdInfo={`category-${
                    categoryIndex + 1
                  }-link-${index + 1}`}
                />
              ) : null}

              <Text variant="small" sx={styles.itemText}>
                {link.label}
              </Text>
            </BemLink>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0067, IPropsBemM0067 };
