import {
  IGraphqlExternalLink,
  IGraphqlInternalLink,
  TGraphqlRichTextBlocks,
  TThemeName,
} from '@bemer/base';
import BlockContent, {
  IPropsBlocks,
  IPropsImageRender,
  IPropsListRenderer,
  IPropsMarkContent,
  IPropsRichtextTableRender,
  IPropsVideoRender,
} from '@sanity/block-content-to-react';
import React, { useContext } from 'react';
import { Box, Paragraph, Text, ThemeUIStyleObject } from 'theme-ui';
import colors from '../../gatsby-plugin-theme-ui/colors';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { getHeadingSeparatorTildeUrl } from '../../gatsby-plugin-theme-ui/utils/headingSeparatorLineHelpers';
import {
  LocalisationContext,
  ModuleContext,
  ThemeHierarchyContext,
} from '../../providers';
import { BemCloudImage } from '../CloudImage';
import { BemCloudVideo } from '../CloudVideo';
import { BemHeading } from '../Heading';
import { BemLink } from '../Link';
import { BemQuoteWrapper } from '../QuoteWrapper';
import { BemRichtextTable } from '../RichtextTable';

const SIZE_SMALL = 'small';
interface IPropsBlockContent {
  sx?: ThemeUIStyleObject;
  blocks: TGraphqlRichTextBlocks;
  additionalMarks?: {
    [key: string]: ({ node }: any) => JSX.Element;
  };
  additionalTypes?: {
    [key: string]: ({ node }: any) => JSX.Element;
  };
}

const styles: IStylesObject = {
  paragraph: {
    pt: 5,
    pb: 5,
    ' &:first-of-type': {
      pt: 0,
    },
    '& ~ h3': {
      pt: 11,
    },
    '& ~ .richtext-image': {
      pt: 7,
    },
  },
  wrapper: {
    '&>div': {
      display: 'grid',
    },
    '.richtext': {
      gridTemplateColumns: 'repeat(8, 1fr)',
    },
    '.richtext > *': {
      gridColumn: '1 / -1',
    },
    '.richtext > *:last-child': {
      pb: 0,
    },
  },
  list: {
    display: 'block',
    pl: 2,
    listStyle: 'none',
  },
  listText: {
    pl: 2,
    ':before , :after': {
      content: 'none',
    },
    display: 'list-item',
  },
  decimal: {
    listStyleType: 'decimal',
  },
  headline: {
    pb: 12,
  },
  image: {
    gridColumn: '1 / -1',
    pb: 16,
  },
  richtextTable: {
    gridColumn: '1 / -1',
    pb: 16,
  },
  video: {
    gridColumn: '1 / -1',
    pb: 16,
  },
  blockquote: {
    pb: 16,
    pt: 2,
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: defaultGridGap,
    blockquote: {
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    '&>*': {
      gridColumn: '2 / span 6',
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  listItem: (theme: TThemeName) => ({
    display: 'block',
    mt: 5,
    mb: 6,
    pl: theme === 'VETERINARY_LINE' ? 6 : [2, 4, 6],
    ':before , :after': {
      content: 'none',
    },
  }),
  bullets: (theme: TThemeName, textSize: string) => {
    const textSizeMobile = textSize === SIZE_SMALL ? 17 : 22;
    const textSizeTabletAndDesktop = textSize === SIZE_SMALL ? 22 : 24;

    if (theme === 'VETERINARY_LINE') {
      return {
        ...calculatedStyles.listItem(theme),
        listStyleType: 'none',
        backgroundImage: [
          getHeadingSeparatorTildeUrl(
            textSizeMobile,
            textSizeMobile,
            colors.headingSeparatorLine
          ),
          getHeadingSeparatorTildeUrl(
            textSizeTabletAndDesktop,
            textSizeTabletAndDesktop,
            colors.headingSeparatorLine
          ),
          getHeadingSeparatorTildeUrl(
            textSizeTabletAndDesktop,
            textSizeTabletAndDesktop,
            colors.headingSeparatorLine
          ),
        ],
        backgroundRepeat: 'no-repeat',
      };
    }
    return {
      ...calculatedStyles.listItem(theme),
      listStyleType: 'disc',
    };
  },
};

const ImageRenderer = ({ node }: IPropsImageRender) => (
  <BemCloudImage image={node} sx={styles.image} className="richtext-image" />
);

const VideoRenderer = ({ node }: IPropsVideoRender) => (
  <BemCloudVideo video={node} sx={styles.video} />
);

const RichtextTableRenderer = ({ node }: IPropsRichtextTableRender) => (
  <BemRichtextTable {...node} sx={styles.richtextTable} />
);

const BlockRenderer = ({ node, children }: IPropsBlocks) => {
  const {
    locale: { id: localeId },
  } = useContext(LocalisationContext);

  switch (node.style) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
      return (
        <BemHeading variant={node.style} as={node.style} sx={styles.headline}>
          {children}
        </BemHeading>
      );
    case 'blockquote':
      return (
        <Box sx={styles.blockquote}>
          <BemHeading as="blockquote" variant="h4">
            <BemQuoteWrapper localeId={localeId}>{children}</BemQuoteWrapper>
          </BemHeading>
        </Box>
      );
    case 'normal':
      return (
        <Paragraph variant="text.default" sx={styles.paragraph}>
          {children}
        </Paragraph>
      );
    case SIZE_SMALL:
      return (
        <Paragraph variant="text.small" sx={styles.paragraph}>
          {children}
        </Paragraph>
      );

    default:
      return (
        <Text>
          {node._type}
          {children}
        </Text>
      );
  }
};

const LinkRenderer = <Type extends 'internalLink' | 'externalLink'>(
  props: IPropsMarkContent<Type>
) => {
  const {
    _key,
    children,
    mark: { _type },
  } = props;

  if (_type === 'externalLink') {
    const {
      mark: { target, externalLink },
    } = props as IPropsMarkContent<'externalLink'>;
    const link: IGraphqlExternalLink = {
      _key,
      _type,
      target,
      label: '',
      externalLink,
    };

    return <BemLink to={link}>{children}</BemLink>;
  }
  const {
    mark: { to },
  } = props as IPropsMarkContent<'internalLink'>;

  const link: IGraphqlInternalLink = {
    _key,
    _type,
    label: '',
    to,
  };
  return <BemLink to={link}>{children}</BemLink>;
};

const ListRenderer = (props: IPropsListRenderer): JSX.Element => {
  const { children, type } = props;

  switch (type) {
    case 'number':
      return (
        <Box as="ol" sx={{ ...styles.list, ...styles.decimal }}>
          {children}
        </Box>
      );

    default:
      return (
        <Box as="ul" sx={{ ...styles.list }}>
          {children}
        </Box>
      );
  }
};

const ListItemRenderer = ({ children, node }: IPropsBlocks): JSX.Element => {
  const { listItem, style: textSize } = node;
  const [theme] = useContext(ThemeHierarchyContext);
  const { moduleTheme } = useContext(ModuleContext);
  const usedTheme = moduleTheme || theme;

  switch (textSize) {
    case SIZE_SMALL:
      return (
        <Box
          as="li"
          variant="text.small"
          sx={
            listItem === 'number'
              ? calculatedStyles.listItem(usedTheme)
              : calculatedStyles.bullets(usedTheme, textSize)
          }
        >
          <Text as="span" variant="text.small" sx={styles.listText}>
            {children}
          </Text>
        </Box>
      );
    default:
      return (
        <Box
          as="li"
          variant="text.default"
          sx={
            listItem === 'number'
              ? calculatedStyles.listItem(usedTheme)
              : calculatedStyles.bullets(usedTheme, textSize)
          }
        >
          <Text as="span" sx={styles.listText}>
            {children}
          </Text>
        </Box>
      );
  }
};

const BemRichtext = ({
  blocks,
  additionalTypes = {},
  additionalMarks = {},
  sx,
}: IPropsBlockContent): JSX.Element => (
  <Box sx={{ ...styles.wrapper, ...sx }}>
    <BlockContent
      renderContainerOnSingleChild
      className="richtext"
      blocks={blocks}
      serializers={{
        /**
         * place a content block like this to have internalLinks und externalLinks in text
         *
         * ```js
         *  {
         *    name: 'richtext',
         *    title: 'Richtext',
         *    type: 'array',
         *    of: [
         *      {
         *        type: 'block',
         *        marks: {
         *          annotations: [
         *            {
         *              name: 'externalLink',
         *              type: 'externalLinkAnnotation',
         *              title: 'External link',
         *            },
         *            {
         *              name: 'internalLink',
         *              type: 'internalLinkAnnotation',
         *              title: 'Internal link',
         *            },
         *          ],
         *        },
         *      },
         *    ],
         *  },
         */
        marks: {
          internalLink: LinkRenderer,
          externalLink: LinkRenderer,
          ...additionalMarks,
        },
        /**
         * add all blocks with a renderer you defined in any of the
         * block schema definitions, eg.
         * ```js
         * {
         *   name: 'richtext',
         *   title: 'Richtext',
         *   type: 'array',
         *   of: [{ type: 'block' }, { type: 'imageWithAlt' }],
         *   validation: (Rule: IRuleType): IRuleType => Rule.required(),
         * }
         * ```
         * this need a `imageWithAlt` type renderer
         *  */
        types: {
          block: BlockRenderer,
          imageWithAlt: ImageRenderer,
          video: VideoRenderer,
          richtextTable: RichtextTableRenderer,
          ...additionalTypes,
        },
        listItem: ListItemRenderer,
        list: ListRenderer,
      }}
    />
  </Box>
);

export { BemRichtext, IPropsBlockContent };
