import {
  aspectRatios,
  IGraphqlM0012,
  IGraphqlM0012Item,
  RATIOS,
  TGraphqlLinkItem,
  TGraphqlMediaItem,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_HEADLINE_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0012 extends IGraphqlM0012 {}

const PREFIX = 'M0012';

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  contentWrapper: {
    gridColumn: ['2 / -2', '3 / 6', '3 / 6'],
    alignSelf: 'center',
    gridRow: [2, 1, 1],
  },
  imageWrapper: {
    gridColumn: ['2 / -2', '8 / -1', '8 / -1'],
    gridRow: [2, 1, 1],
  },
  contentItem: { alignItems: 'center', pt: 32 },
};

const mobileStyles: IStylesObject = {
  title: {
    pb: MOBILE_HEADLINE_PB,
    gridRow: [1, 1, 1],
    gridColumn: ['2 / -2', '1 / -1', '1 / -1'],
  },
  actionArea: {
    gridRow: [3, 1, 1],
    gridColumn: ['2 / -2', '1 / -1', '1 / -1'],
    mb: MOBILE_MODULE_INNER_SECTION_PB,
  },
  item: {
    p: 4,
    bg: ['background', 'transparent', 'transparent'],
    height: '100%',
  },
  itemImage: {
    pb: 4,
  },
};

interface IPropsBemM0012Item {
  item: IGraphqlM0012Item;
  index: number;
}
interface IPropsBemM0012Items {
  items: IGraphqlM0012Item[];
}
interface IPropsBemM0012TeaserImage {
  media: TGraphqlMediaItem;
}
interface IPropsBemM0012TeaserContent {
  title: string;
  link?: TGraphqlLinkItem;
}

const ItemOnMobile = ({ item, index }: IPropsBemM0012Item): JSX.Element => (
  <Box sx={mobileStyles.item}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_6].ratio}
      sx={mobileStyles.itemImage}
      additionalTrackingIdInfo={index + 1}
    />
    <Text variant="caption.small" as="p">
      {item.text}
    </Text>
  </Box>
);

const ItemsOnMobile = ({ items }: IPropsBemM0012Items): JSX.Element => (
  <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
    <BemTouchSlider
      items={items}
      itemRenderer={({ item }, index) => (
        <ItemOnMobile item={item} index={index} />
      )}
    />
  </BemThemeWrapper>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0012Items): JSX.Element => (
  <>
    {items.map((item, index) => {
      const elementOrder =
        index % 2
          ? {
              image: {
                gridRow: 1,
                gridColumn: '8/span 5',
              },
              text: {
                gridRow: 1,
                gridColumn: '2/span 5',
              },
            }
          : {
              image: {
                gridRow: 1,
                gridColumn: '1/span 5',
              },
              text: {
                gridRow: 1,
                gridColumn: '7/span 5',
              },
            };
      return (
        <Grid variant="contentGrid" sx={styles.contentItem} key={item._key}>
          <Text variant="caption.small" as="p" sx={elementOrder.text}>
            {item.text}
          </Text>
          <BemMedia
            media={item.media[0]}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
            sx={elementOrder.image}
            additionalTrackingIdInfo={index + 1}
          />
        </Grid>
      );
    })}
  </>
);

const TeaserContentOnMobile = ({
  title,
  link,
}: IPropsBemM0012TeaserContent): JSX.Element => (
  <>
    <BemHeading
      as="h2"
      variant="h2WithSeparatorForHeadlineWithoutPaddingBottom"
      sx={mobileStyles.title}
    >
      {title}
    </BemHeading>
    {link ? (
      <BemActionArea sx={mobileStyles.actionArea}>
        <BemLink to={link} variant="links.buttonPrimary" />
      </BemActionArea>
    ) : null}
  </>
);

const TeaserContentOnTabletOrLarger = ({
  title,
  link,
}: IPropsBemM0012TeaserContent): JSX.Element => (
  <Box sx={styles.contentWrapper}>
    <BemHeading
      as="h2"
      variant="h2WithSeparatorForHeadlineWithoutPaddingBottom"
    >
      {title}
    </BemHeading>
    {link ? (
      <BemActionArea>
        <BemLink to={link} variant="links.buttonPrimary" />
      </BemActionArea>
    ) : null}
  </Box>
);

const TeaserImage = ({ media }: IPropsBemM0012TeaserImage): JSX.Element => (
  <Box sx={styles.imageWrapper} data-testid="BemM0012-imageWrapper">
    <BemMedia
      media={media}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      additionalTrackingIdInfo="hero"
    />
  </Box>
);

const TeaserContent = ({ title, link }: IPropsBemM0012TeaserContent) =>
  useViewportRenderer([
    <TeaserContentOnMobile
      title={title}
      link={link || undefined}
      key={`${PREFIX}_TeaserContent_${VIEWPORTS.MOBILE}`}
    />,
    <TeaserContentOnTabletOrLarger
      title={title}
      link={link || undefined}
      key={`${PREFIX}_TeaserContent_${VIEWPORTS.TABLET}`}
    />,
    <TeaserContentOnTabletOrLarger
      title={title}
      link={link || undefined}
      key={`${PREFIX}_TeaserContent_${VIEWPORTS.DESKTOP}`}
    />,
  ]);

const Items = ({ items }: IPropsBemM0012Items) =>
  useViewportRenderer([
    <ItemsOnMobile items={items} key={`${PREFIX}_Items_${VIEWPORTS.MOBILE}`} />,
    <ItemsOnTabletOrLarger
      items={items}
      key={`${PREFIX}_Items_${VIEWPORTS.TABLET}`}
    />,
    <ItemsOnTabletOrLarger
      items={items}
      key={`${PREFIX}_Items_${VIEWPORTS.DESKTOP}`}
    />,
  ]);

const BemM0012 = ({
  title,
  link,
  media,
  items,
  theme: moduleTheme,
}: IPropsBemM0012): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0012-moduleWrapper"
      >
        {link ? (
          <TeaserContent title={title} link={link} />
        ) : (
          <TeaserContent title={title} />
        )}
        <TeaserImage media={media[0]} />
        <Items items={items} />
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0012, IPropsBemM0012 };
