import { buildInternalRoute, IGraphqlM0001 } from '@bemer/base';
import React, { useContext, useEffect } from 'react';
import { Button, Flex, Text } from 'theme-ui';
import { BemLink, BemModuleWrapper, BemThemeWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ApiContext,
  LocalisationContext,
  NavigationContext,
  ThemeHierarchyContext,
} from '../../providers';
import { ModalContext } from '../../providers/ModalProvider';
import { extendLinkWithPartnerHandle } from '../../utils/extendLinkWithPartnerHandle';
import { isPartialMatch } from '../../utils/isPartialMatch';

interface IPropsBemM0001 extends IGraphqlM0001 {}

const styles: IStylesObject = {
  lineLinksWrapper: {
    gridColumn: '2 / span 6',
    alignItems: 'center',
  },
  accountLinksWrapper: {
    gridColumn: 'span 6',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  accountLink: {
    ml: 6,
    mr: 0,
  },
  languagePickerText: {
    mr: 0,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (isM0001Sticky: boolean) => ({
    mb: 0,
    position: isM0001Sticky ? 'sticky' : 'relative',
    top: 0,
    zIndex: 100,
    display: ['none', 'none', 'grid'],
    borderTopWidth: '1_5',
    borderTopStyle: 'solid',
    borderTopColor: 'accentColorOppositeTheme',
  }),
  activeLineLink: (isActive: boolean) =>
    isActive
      ? {
          borderColor: 'currentColor',
          fontWeight: 'bold',
        }
      : {},
};

const BemM0001 = ({
  isSticky,
  links,
  accountLinks = [],
  theme: moduleTheme,
}: IPropsBemM0001): JSX.Element => {
  const { send } = useContext(ModalContext);

  const { locale, localeIdentifier } = useContext(LocalisationContext);
  const windowPathName =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const activeLineLink = links.find((link) =>
    isPartialMatch(
      buildInternalRoute(link.to, localeIdentifier),
      windowPathName
    )
  );

  const {
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);
  const { data: visitorSettings } = getVisitorSettings();
  const accountLinksWithPartner = accountLinks.map((link) =>
    extendLinkWithPartnerHandle(
      link,
      visitorSettings?.partnerHandle || undefined
    )
  );
  const { send: navSend, current } = useContext(NavigationContext);

  useEffect(() => {
    navSend({
      type: 'updateIsM0001Sticky',
      data: {
        isSticky,
      },
    });
    navSend({
      type: 'updateIsM0001Rendered',
      data: {
        isRendered: true,
      },
    });
    return () => {
      navSend({
        type: 'updateIsM0001Sticky',
        data: {
          isSticky: false,
        },
      });
      navSend({
        type: 'updateIsM0001Rendered',
        data: {
          isRendered: false,
        },
      });
    };
  }, []);

  useEffect(() => {
    navSend({
      type: 'updateMetaData',
      data: {
        lineLinks: links,
        accountLinks: accountLinksWithPartner,
      },
    });
  }, [links, accountLinks, visitorSettings?.partnerHandle]);

  const openModalM0134 = () => {
    send({ type: 'openLanguageSelectionModal' });
  };

  const [theme] = useContext(ThemeHierarchyContext);

  const lineLinks = links.map((link, index) => {
    const isActive = activeLineLink?._key === link._key;
    return (
      <BemLink
        key={link._key}
        to={link}
        variant="links.nav"
        sx={calculatedStyles.activeLineLink(isActive)}
        partiallyActive
        additionalTrackingIdInfo={index + 1}
      />
    );
  });

  const accLinks = current.context?.accountLinks?.map((link, index) => (
    <BemLink
      key={link._key}
      to={link}
      variant="links.nav"
      sx={styles.accountLink}
      partiallyActive
      additionalTrackingIdInfo={index + 1}
    />
  ));

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        isFullWidth
        sx={calculatedStyles.wrapper(current.context.isM0001Sticky)}
        data-testid="BemM0001-bemModuleWrapper"
      >
        <Flex as="nav" sx={styles.lineLinksWrapper}>
          {lineLinks}
        </Flex>
        {accountLinks.length > 0 ? (
          <Flex as="nav" sx={styles.accountLinksWrapper}>
            {accLinks}
            <Button
              variant="buttons.textWrapper"
              onClick={openModalM0134}
              sx={styles.accountLink}
            >
              <Text variant="links.nav" sx={styles.languagePickerText}>
                {locale.languageCode.toUpperCase()} | {locale.countryCode}
              </Text>
            </Button>
          </Flex>
        ) : null}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0001, IPropsBemM0001 };
