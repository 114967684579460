import {
  aspectRatios,
  IGraphqlImage,
  IGraphqlVideo,
  IGraphqlVideoSet,
  RATIOS,
  TGraphqlMediaItem,
  TThemeName,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ForwardedRef, ReactNode, useContext, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaPlay } from 'react-icons/fa';
import { Box, Button, Grid, ThemeUIStyleObject } from 'theme-ui';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { ModuleContext, ThemeHierarchyContext } from '../../providers';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';
import { debugXState } from '../../utils/xStateHelper';
import { BemButton } from '../Button';
import { BemCloudImage } from '../CloudImage';
import { BemCloudVideo } from '../CloudVideo';
import { BemThemeWrapper } from '../ThemeWrapper';
import { BemTouchSlider, TItemWidth } from '../TouchSlider';
import {
  bemGalleryMachine,
  CURRENT_INDEX,
  NEXT,
  PREV,
} from './BemGallery.machine';

const TEST_ID_MEDIA_BOX_PREFIX = 'MEDIA_BOX';
const TEST_ID_PREVIEW_GALLERY_PREFIX = 'PREVIEW_GALLERY';

type TNavigationButtonsStyle = 'inside' | 'outside';

const ANIMATION_DIRECTION_FORWARD = 'forward';
const ANIMATION_DIRECTION_BACKWARD = 'backward';

const GALLERY_ENTER = 'enter';
const GALLERY_CENTER = 'center';
const GALLERY_EXIT = 'exit';

type TAnimationDirection =
  | typeof ANIMATION_DIRECTION_FORWARD
  | typeof ANIMATION_DIRECTION_BACKWARD;

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

interface IPropsBemGallery {
  forcedAspectRatio?: number;
  items: TGraphqlMediaItem[];
  navigationButtonsStyle?: TNavigationButtonsStyle;
  previewItemForcedAspectRatio?: number;
  previewItemWidth?: TItemWidth;
  showCarousel?: boolean;
  sx?: ThemeUIStyleObject;
  theme?: TThemeName;
}

interface IPropsMediaBox {
  items: TGraphqlMediaItem[];
  activeItem: number;
  forcedAspectRatio?: number;
}

interface IPropsMediaElement {
  currentMedia: TGraphqlMediaItem;
  forcedAspectRatio?: number;
  isClickable?: boolean;
  isPreviewElement?: boolean;
  testIdPrefix?: string;
}

interface IPropsNavigationButtons {
  children: ReactNode;
  navigationButtonsStyle: TNavigationButtonsStyle;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

interface IPropsPreviewGallery {
  activeItem: number;
  forcedAspectRatio?: number;
  items: TGraphqlMediaItem[];
  onItemClick: (index: number) => void;
  previewItemForcedAspectRatio?: number;
  previewItemWidth: TItemWidth;
}

interface IPropsPreviewItem {
  activeItem: number;
  forcedAspectRatio?: number;
  index: number;
  item: TGraphqlMediaItem;
  onItemClick: (index: number) => void;
  previewItemForcedAspectRatio?: number;
}

const styles: IStylesObject = {
  wrapper: {
    userSelect: 'none',
  },
  mainGrid: {
    gridTemplateColumns: 'min-content 1fr min-content',
    gap: defaultGridGap,
    alignItems: 'center',
  },
  button: {
    width: 14,
    height: 14,
    p: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: 0.5,
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: 0.75,
    },
    color: 'text',
  },
  firstNavigationButtonWrapper: {
    gridRow: 1,
    gridColumn: '1 / span 1',
    zIndex: 2,
  },
  secondNavigationButtonWrapper: {
    gridRow: 1,
    gridColumn: '3 / span 1',
    zIndex: 3,
  },
  previewGrid: {
    justifyContent: 'center',
    gridColumn: '1 / -1',
    gridRow: 2,
    gap: defaultGridGap,
    mx: 6,
    pt: 2,
    pb: 4,
  },
  cloudImage: {
    width: '100%',
    pointerEvents: 'none',
  },
  playButton: {
    p: 1,
    bg: 'transparent',
    color: 'white',

    '& > svg': {
      filter: 'drop-shadow(1px 1px 3px rgb(0 0 0))',
    },
  },
  touchSliderWrapper: {
    px: 0,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  previewItem: (isActive: boolean) => ({
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: isActive ? 'primary' : 'transparent',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  previewVideo: (isClickable) => ({
    pointerEvents: isClickable ? 'initial' : 'none',
  }),
  mediaWrapper: (navigationButtonsStyle: TNavigationButtonsStyle) => ({
    gridRow: 1,
    gridColumn: navigationButtonsStyle === 'inside' ? '1 / -1' : '2 / span 1',
    zIndex: 1,
  }),
};

const videoPreviewPlayButton = (
  <Box sx={{ ...styles.playButton }}>
    <FaPlay />
  </Box>
);

const componentName = 'BemGallery';

// eslint-disable-next-line react/display-name
const MediaElement = React.memo(
  ({
    currentMedia,
    forcedAspectRatio = aspectRatios[RATIOS.RATIO_1_1].ratio,
    isClickable = true,
    isPreviewElement = false,
    testIdPrefix = TEST_ID_PREVIEW_GALLERY_PREFIX,
  }: IPropsMediaElement) => {
    const mediaType = currentMedia?._type;
    if (mediaType === 'imageWithAlt') {
      return (
        <BemCloudImage
          sx={styles.cloudImage}
          forcedAspectRatio={forcedAspectRatio}
          image={currentMedia as IGraphqlImage}
          testId={`${testIdPrefix}${currentMedia._key}`}
        />
      );
    }
    if (mediaType === 'videoSet' || mediaType === 'video') {
      return (
        <BemCloudVideo
          forcedAspectRatio={forcedAspectRatio}
          sx={calculatedStyles.previewVideo(isClickable)}
          video={
            mediaType === 'videoSet'
              ? getLanguageSpecificVideo(currentMedia)
              : currentMedia
          }
          playButton={isPreviewElement ? videoPreviewPlayButton : undefined}
          testId={`${testIdPrefix}${currentMedia._key}`}
        />
      );
    }
    return <Box>Unknown MediaItem</Box>;
  }
);

// eslint-disable-next-line react/display-name
const MediaBox = React.forwardRef(
  (
    {
      items,
      activeItem = 0,
      forcedAspectRatio = aspectRatios[RATIOS.RATIO_1_1].ratio,
    }: IPropsMediaBox,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Box ref={ref}>
      <MediaElement
        currentMedia={items[activeItem]}
        forcedAspectRatio={forcedAspectRatio}
        testIdPrefix={TEST_ID_MEDIA_BOX_PREFIX}
      />
    </Box>
  )
);

const NavigationButtons = ({
  children,
  navigationButtonsStyle = 'inside',
  onPreviousClick,
  onNextClick,
}: IPropsNavigationButtons) => {
  const { moduleName } = useContext(ModuleContext);
  return (
    <>
      <Box sx={styles.firstNavigationButtonWrapper}>
        <BemButton
          sx={styles.button}
          onClick={onPreviousClick}
          type="button"
          variant="buttons.divWrapper"
          data-testid={`${componentName}-backwardButton`}
          additionalTrackingIdInfo={`${moduleName}-${componentName}-backwardButton`}
        >
          <FaChevronLeft />
        </BemButton>
      </Box>
      <Box sx={calculatedStyles.mediaWrapper(navigationButtonsStyle)}>
        {children}
      </Box>
      <Box sx={styles.secondNavigationButtonWrapper}>
        <BemButton
          sx={styles.button}
          onClick={onNextClick}
          type="button"
          variant="buttons.divWrapper"
          data-testid={`${componentName}-forwardButton`}
          additionalTrackingIdInfo={`${moduleName}-${componentName}-forwardButton`}
        >
          <FaChevronRight />
        </BemButton>
      </Box>
    </>
  );
};

const renderPreviewItem = ({
  activeItem,
  forcedAspectRatio,
  index,
  item,
  onItemClick,
  previewItemForcedAspectRatio,
}: IPropsPreviewItem) => {
  const { moduleName } = useContext(ModuleContext);
  return (
    <Button variant="buttons.divWrapper" onClick={() => onItemClick(index)}>
      <Box
        tabIndex={0}
        sx={calculatedStyles.previewItem(activeItem === index)}
        data-trackingid={`${moduleName}-${componentName}-PreviewItem-${
          index + 1
        }`}
      >
        <MediaElement
          currentMedia={item}
          forcedAspectRatio={previewItemForcedAspectRatio || forcedAspectRatio}
          isClickable={false}
          isPreviewElement
        />
      </Box>
    </Button>
  );
};

const PreviewGallery = ({
  activeItem,
  forcedAspectRatio,
  items,
  onItemClick,
  previewItemForcedAspectRatio,
  previewItemWidth,
}: IPropsPreviewGallery) => (
  <Grid sx={styles.previewGrid}>
    <BemTouchSlider
      items={items}
      itemRenderer={({ item }, index) =>
        renderPreviewItem({
          activeItem,
          forcedAspectRatio,
          index,
          item,
          onItemClick,
          previewItemForcedAspectRatio,
        })
      }
      itemWidth={previewItemWidth}
      hideShadow
      sx={styles.touchSliderWrapper}
    />
  </Grid>
);

/**
 * This component takes an array of media items and renders in a gallery.
 *
 * The gallery consist of
 * - a MediaBox as main display for a selected media item,
 * - navigation buttons and
 * - an optional preview gallery rendered as carousel.
 *
 * @param items array of BemCloudImage or BemCloudVideo items
 * @param forcedAspectRatio number which defines the aspect ratio of the media box and the preview gallery items; default 3 / 2
 * @param navigationButtonsStyle 'inside' | 'outside' position of the navigation buttons; default 'inside'
 * @param previewItemForcedAspectRatio number which defines the aspect ratio of the preview gallery items; default is a fallback to forcedAspectRatio
 * @param previewItemWidth the width of the PreviewItems; default '74px'
 * @param showCarousel show or switch off the preview gallery; default true
 * @param sx the ThemeUIStyleObject can be used to style the BemGallery (e.g. set a width directly with a value like "{width: '600px'}")
 * @param theme
 */
const BemGallery = ({
  items,
  forcedAspectRatio = aspectRatios[RATIOS.RATIO_3_2].ratio,
  navigationButtonsStyle = 'inside',
  previewItemForcedAspectRatio,
  previewItemWidth = '74px',
  showCarousel = true,
  sx,
  theme: moduleTheme,
}: IPropsBemGallery): JSX.Element => {
  const [current, send] = useMachine(bemGalleryMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      items,
    },
  });

  const [direction, setDirection] = useState<TAnimationDirection>(
    ANIMATION_DIRECTION_FORWARD
  );

  const MotionMediaBox = motion(MediaBox);

  const handleClick = (clickedItemIndex: number) => {
    const { currentActiveIndex } = current.context;
    if (currentActiveIndex < clickedItemIndex) {
      setDirection(ANIMATION_DIRECTION_FORWARD);
    }
    if (currentActiveIndex > clickedItemIndex) {
      setDirection(ANIMATION_DIRECTION_BACKWARD);
    }
    send({ type: CURRENT_INDEX, clickedItemIndex });
  };

  const [theme] = useContext(ThemeHierarchyContext);
  // TODO: make this optional again
  // quick fixes for having videos and video sets
  let themeName = theme || moduleTheme;
  if (navigationButtonsStyle === 'inside') {
    themeName =
      items[current.context.currentActiveIndex]._type === 'videoSet'
        ? (items[current.context.currentActiveIndex] as IGraphqlVideoSet)
            .languageDefaultVideo.themeName
        : (
            items[current.context.currentActiveIndex] as
              | IGraphqlImage
              | IGraphqlVideo
          ).themeName;
  }

  const variants = {
    [GALLERY_ENTER]: (animDirection: string) => ({
      x: animDirection === ANIMATION_DIRECTION_FORWARD ? 1000 : -1000,
      opacity: 0,
    }),
    [GALLERY_CENTER]: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    [GALLERY_EXIT]: (animDirection: string) => ({
      zIndex: 0,
      x: animDirection === ANIMATION_DIRECTION_BACKWARD ? 1000 : -1000,
      opacity: 0,
    }),
  };

  const motionBoxProps = {
    custom: direction,
    variants,
    initial: GALLERY_ENTER,
    animate: GALLERY_CENTER,
    exit: GALLERY_EXIT,
    transition: {
      x: { type: 'spring', stiffness: 300, damping: 30 },
      opacity: { duration: DURATION.SHORT },
    },
  };

  return (
    <Box sx={{ ...styles.wrapper, ...sx }}>
      <Grid sx={styles.mainGrid}>
        <BemThemeWrapper themeName={themeName}>
          <NavigationButtons
            navigationButtonsStyle={navigationButtonsStyle}
            onPreviousClick={() => {
              setDirection(ANIMATION_DIRECTION_BACKWARD);
              send(PREV);
            }}
            onNextClick={() => {
              setDirection(ANIMATION_DIRECTION_FORWARD);
              send(NEXT);
            }}
          >
            <AnimatePresence>
              <MotionMediaBox
                activeItem={current.context.currentActiveIndex}
                forcedAspectRatio={forcedAspectRatio}
                items={items}
                {...motionBoxProps}
              />
            </AnimatePresence>
          </NavigationButtons>
        </BemThemeWrapper>
        {showCarousel ? (
          <PreviewGallery
            items={items}
            activeItem={current.context.currentActiveIndex}
            forcedAspectRatio={
              previewItemForcedAspectRatio || forcedAspectRatio
            }
            onItemClick={handleClick}
            previewItemWidth={previewItemWidth}
          />
        ) : null}
      </Grid>
    </Box>
  );
};

export {
  BemGallery,
  IPropsBemGallery,
  TEST_ID_MEDIA_BOX_PREFIX,
  TEST_ID_PREVIEW_GALLERY_PREFIX,
};
