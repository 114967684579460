import React from 'react';
import { Box, Grid, ThemeUIStyleObject } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

type TItemWidth = '74px' | '45%' | '90%' | '100%';

interface IItemBase {
  _key?: string;
}

interface IPropsItemRenderer<ItemsType> {
  item: ItemsType;
}

/*
 * Explanation of usage of generic type `ItemsType`:
 *
 * The part `<ItemsType extends IItemBase>` in `const BemTouchSlider = <ItemsType extends IItemBase>`
 * tells TypeScript: `ItemsType` is a generic type and can be different all the time.
 * All that is required: it must have a `_key` property.
 *
 * In the IPropsBemTouchSlider interface, TypeScript gets the information:
 * The generic type `ItemsType` is the type of the property `items` (as an array) of the `BemTouchSlider` component.
 * This syntax allows to have a generic type, which is automatically inferred by TypeScript
 * by checking the type of the property `items`. So we do not need to explicitly tell TypeScript the type of `items`,
 * (we also can't tell the type of `items`, because `BemTouchSlider` can be used with many different `items`)
 *
 * A benefit of this generic type is it's re-usage for the type definition of the `itemRenderer` function:
 * The given render function must receive one prop: an object containing the property `item`. `item` is
 * already typed, without actually knowing the type by using the inferred type of `items` in the interface IPropsItemRenderer.
 *
 * That way, when using the `BemTouchSlider`, the implementation of the render function is required to accept
 * the same type for `item` as has been given to the `BemTouchSlider`'s prop `items`.
 */
interface IPropsBemTouchSlider<ItemsType> {
  /**
   * The items to be rendered in the itemsRenderer render function / React Functional Component.
   */
  items: ItemsType[];

  /**
   * A render function / React Functional Component, to take in an element of the items array and renders the output.
   *
   * @param item
   */
  itemRenderer: (
    { item }: IPropsItemRenderer<ItemsType>,
    index: number
  ) => JSX.Element;

  /**
   * Option to hide the shadows of the cards which are wrapping the items.
   *
   * Default: false
   */
  hideShadow?: boolean;

  /**
   * Option to change the width of a rendered card.
   * Only predefined options are available:
   * - 74px
   * - 45%
   * - 90%
   * - 100%
   *
   * Default: 90%
   */
  itemWidth?: TItemWidth;
  sx?: ThemeUIStyleObject;
}

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (
    numberOfItems: number,
    itemWidth: TItemWidth,
    hideShadow: boolean
  ) => ({
    gridColumn: '1 / -1',
    gridTemplateColumns: `repeat(${numberOfItems}, ${itemWidth})`,
    gap: defaultGridGap,
    overflow: 'auto',
    // This "willChange: 'transform'" fixes the webkit bug to not apply borderRadius.
    // @see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
    willChange: 'transform',
    scrollSnapType: 'x proximity',
    selfAlign: 'start',
    gridAutoFlow: 'column',
    pt: hideShadow ? 0 : 2,
    px: 4,
    pb: hideShadow ? 0 : 6,
    width: '100%',
    justifyContent:
      numberOfItems * parseFloat(itemWidth) < 100 ? 'center' : 'start',
  }),
  itemWrapper: (hideShadow: boolean) => ({
    boxShadow: hideShadow ? 'none' : 'smallCardShadow',
    scrollSnapAlign: 'center',
    overflow: 'hidden',
  }),
};

const BemTouchSlider = <ItemsType extends IItemBase>({
  items,
  itemRenderer,
  hideShadow = false,
  itemWidth = '90%',
  sx,
}: IPropsBemTouchSlider<ItemsType>): JSX.Element => (
  <Grid
    sx={{
      ...calculatedStyles.wrapper(items.length, itemWidth, hideShadow),
      ...sx,
    }}
  >
    {items.map((item, index) => (
      <Box
        key={item._key || `TouchSliderItemWithoutKey_${uuidv4()}`}
        sx={calculatedStyles.itemWrapper(hideShadow)}
      >
        {itemRenderer({ item }, index)}
      </Box>
    ))}
  </Grid>
);

export { BemTouchSlider, IPropsBemTouchSlider, TItemWidth };
