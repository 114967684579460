import { THEME_NAMES, TThemeName } from '@bemer/base';
import { ThemeProvider } from '@theme-ui/core';
import { useBreakpointIndex } from '@theme-ui/match-media';
import React from 'react';
import {
  darkTextDefaultVeterinaryTheme,
  darkTextTheme,
  defaultVeterinaryTheme,
  humanLineTheme,
  lightTextDefaultVeterinaryTheme,
  lightTextTheme,
  neutralTheme,
  theme,
  veterinaryLineTheme,
} from '../../gatsby-plugin-theme-ui';

interface IPropsBemThemeWrapper {
  themeName: TThemeName | TThemeName[];
  children: React.ReactNode;
}

// Helper to get the theme object. An empty object {} as default inherits the parent theme.
const getThemeForThemeName = (themeName: TThemeName) => {
  switch (themeName) {
    case THEME_NAMES.HUMAN_LINE:
      return humanLineTheme;
    case THEME_NAMES.VETERINARY_LINE:
      return veterinaryLineTheme;
    case THEME_NAMES.NEUTRAL:
      return neutralTheme;
    case THEME_NAMES.LIGHT_TEXT:
      return lightTextTheme;
    case THEME_NAMES.DARK_TEXT:
      return darkTextTheme;
    case THEME_NAMES.DEFAULT_VETERINARY:
      return defaultVeterinaryTheme;
    case THEME_NAMES.LIGHT_TEXT_DEFAULT_VETERINARY:
      return lightTextDefaultVeterinaryTheme;
    case THEME_NAMES.DARK_TEXT_DEFAULT_VETERINARY:
      return darkTextDefaultVeterinaryTheme;
    case THEME_NAMES.DEFAULT:
    default:
      return theme;
  }
};

/**
 * This component can be used to wrap the given `children` in a `ThemeProvider` and apply the
 * given theme (@see THEME_NAMES) to them.
 *
 * Properly setup components will use the background and text color of the
 * given theme without any additional changes.
 *
 */

const BemThemeWrapper = ({
  themeName,
  children,
}: IPropsBemThemeWrapper): JSX.Element => {
  const breakpointIndex = useBreakpointIndex();

  let themeNameForCurrentViewport: TThemeName = THEME_NAMES.DEFAULT;
  if (typeof themeName === 'string') {
    themeNameForCurrentViewport = themeName;
  }
  if (Array.isArray(themeName) && themeName.length > 0) {
    themeNameForCurrentViewport = themeName[breakpointIndex] || themeName[0];
  }

  const foundTheme = getThemeForThemeName(themeNameForCurrentViewport);
  return <ThemeProvider theme={foundTheme}>{children}</ThemeProvider>;
};

export { BemThemeWrapper, IPropsBemThemeWrapper };
