import { aspectRatios, IGraphqlM0040, RATIOS, THEME_NAMES } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_MEDIA_PB,
  MOBILE_MODULE_TEXT_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0040 extends IGraphqlM0040 {}

const styles: IStylesObject = {
  wrapper: {
    gridTemplateRows: [
      'none',
      'min-content min-content 80px min-content',
      'min-content min-content 160px min-content',
    ],
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  image1: {
    gridColumn: ['2 / -2', '1 / span 7', '1 / span 7'],
    gridRow: ['auto', '2 / span 2', '2 / span 2'],

    '&& > *': {
      height: '100%',
    },

    pb: ASIDE_MEDIA_PB,
  },
  text1: {
    pt: [0, 14, 28],
    pb: [MOBILE_MODULE_TEXT_PB, 14, 28],
    gridColumn: ['2 / -2', '8/ span 6', '9 / span 5'],
  },
  backgroundBox: {
    gridColumn: ['1 / -1'],
    gridRow: ['4 / span 2', '3 / span 3', '3 / span 3'],
    bg: 'background',
  },
  text2: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    gridRow: [4, 4, 4],
    zIndex: 1,
    pt: [8, 16, 28],
    pb: [MOBILE_MODULE_TEXT_PB, 16, 16],
    color: 'text',
  },
  image2: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    gridRow: [5, 5, 5],
    zIndex: 1,
    pb: [8, 20, 20],
  },
};

const BemM0040 = ({
  title,
  media1,
  text1,
  media2,
  text2,
  theme: moduleTheme,
}: IPropsBemM0040): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemModuleWrapper sx={styles.wrapper}>
      <BemHeading
        as="h2"
        variant="h2"
        sx={styles.heading}
        // There is always dark heading on a white background in this module.
        themeName={THEME_NAMES.DARK_TEXT}
      >
        {title}
      </BemHeading>
      <BemMedia
        media={media1[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_6].ratio}
        sx={styles.image1}
        additionalTrackingIdInfo={1}
      />
      <Text as="p" variant="caption.big" sx={styles.text1}>
        {text1}
      </Text>
      <BemThemeWrapper themeName={moduleTheme || theme}>
        <Box
          sx={styles.backgroundBox}
          data-testid="BemM0040-bemBackgroundBox"
        />
        <Text as="p" variant="caption.big" sx={styles.text2}>
          {text2}
        </Text>
        <BemMedia
          media={media2[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_15_8].ratio}
          sx={styles.image2}
          additionalTrackingIdInfo={2}
        />
      </BemThemeWrapper>
    </BemModuleWrapper>
  );
};

export { BemM0040, IPropsBemM0040 };
