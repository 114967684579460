import { defaultLocale, TEvent, TEventTypeId, TLocaleId } from '@bemer/base';
import { ContextFrom, EventFrom, send, sendParent, spawn } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { ActorRefFrom } from 'xstate/lib/types';
import { isEventAfterStartDate, isEventOfEventType } from '../utils/events';
import { searchMachine, TSearchMachineActorRef } from './Search.machine';

const TODAY = new Date().toISOString().split('T')[0];

const filterModel = createModel(
  {
    eventTypeList: [] as { id: TEventTypeId }[],
    today: TODAY,
    events: [] as TEvent[],
    filter: {
      startDate: TODAY,
      eventType: 'ALL' as TEventTypeId,
    },
    searchActorRef: undefined as TSearchMachineActorRef | undefined,
    localeId: defaultLocale.id as TLocaleId,
  },
  {
    events: {
      SET_EVENTS: (events: TEvent[]) => ({ events }),
      CHANGE_VIEWPORT: (coords: { longitude: number; latitude: number }) => ({
        coords,
      }),
      SET_SEARCH_TERM: (value: string) => ({ value }),
      UPDATE_EVENT_TYPE_LIST: (eventTypeList: { id: TEventTypeId }[]) => ({
        eventTypeList,
      }),
      SELECT_START_DATE: (startDate: string) => ({ startDate }),
      SELECT_EVENT_TYPE: (eventType: TEventTypeId) => ({ eventType }),
      RESET_SEARCH_TERM: () => ({}),
    },
  }
);

const filterMachine = filterModel.createMachine(
  {
    initial: 'idle',
    context: filterModel.initialContext,
    id: 'filterMachine',
    states: {
      idle: {
        entry: [
          filterModel.assign({
            searchActorRef: (context) =>
              spawn(
                searchMachine.withContext({
                  ...searchMachine.context,
                  localeId: context.localeId,
                }),
                'searchActor'
              ),
          }),
        ],
        on: {
          CHANGE_VIEWPORT: {
            actions: [sendParent((_context, event) => event)],
          },
          SET_EVENTS: {
            actions: [
              filterModel.assign((_context, event) => ({
                events: event.events,
              })),
              'limitSearchResultsBasedOnFilters',
            ],
          },
          UPDATE_EVENT_TYPE_LIST: {
            actions: [
              'setEventTypeList',
              'updateSelectedFilter',
              'limitSearchResultsBasedOnFilters',
            ],
            cond: 'isEventTypeListDifferent',
          },
          SELECT_START_DATE: {
            actions: [
              'updateDateFilter',
              'updateSelectedFilter',
              'limitSearchResultsBasedOnFilters',
            ],
          },
          SELECT_EVENT_TYPE: {
            actions: [
              'updateEventTypeFilter',
              'updateSelectedFilter',
              'limitSearchResultsBasedOnFilters',
            ],
          },
          RESET_SEARCH_TERM: {
            actions: [
              sendParent((_context, _event) => ({
                type: 'RESET_SEARCH_TERM',
              })),
            ],
          },
        },
      },
    },
  },
  {
    actions: {
      limitSearchResultsBasedOnFilters: send(
        (context: TFilterMachineContext) => {
          if (!context.events) {
            return {
              type: 'HAS_RECEIVED_EVENTS',
              events: [],
            };
          }
          // remove event if its eventType or startDate does not match eventType
          // set by filter
          const possibleEvents = context.events
            .filter((singleEvent) =>
              isEventOfEventType(context.filter.eventType, singleEvent)
            )
            .filter((singleEvent) =>
              isEventAfterStartDate(context.filter.startDate, singleEvent)
            );

          return {
            type: 'HAS_RECEIVED_EVENTS',
            events: possibleEvents,
          };
        },
        { to: 'searchActor' }
      ),
      setEventTypeList: filterModel.assign(
        {
          eventTypeList: (context, event: TFilterMachineEvent) => {
            if (event.type === 'UPDATE_EVENT_TYPE_LIST') {
              return event.eventTypeList;
            }
            return context.eventTypeList;
          },
        },
        'UPDATE_EVENT_TYPE_LIST'
      ),

      updateDateFilter: filterModel.assign(
        (context, event: TFilterMachineEvent) =>
          event.type === 'SELECT_START_DATE'
            ? {
                filter: {
                  ...context.filter,
                  startDate: event.startDate,
                },
              }
            : {}
      ),
      updateEventTypeFilter: filterModel.assign(
        (context, event: TFilterMachineEvent) => ({
          filter: {
            ...context.filter,
            eventType:
              event.type === 'SELECT_EVENT_TYPE' ? event.eventType : 'ALL',
          },
        })
      ),
      updateSelectedFilter: sendParent((context: TFilterMachineContext) => ({
        type: 'UPDATE_FILTER',
        filter: context.filter,
      })),
    },
    guards: {
      isEventTypeListDifferent: (context, event) =>
        event.type === 'UPDATE_EVENT_TYPE_LIST' &&
        JSON.stringify(event.eventTypeList) !==
          JSON.stringify(context.eventTypeList),
    },
  }
);

type TFilterMachineContext = ContextFrom<typeof filterModel>;
type TFilterMachineEvent = EventFrom<typeof filterModel>;
type TFilterMachineActorRef = ActorRefFrom<typeof filterMachine>;

export {
  filterMachine,
  TFilterMachineEvent,
  TFilterMachineContext,
  TFilterMachineActorRef,
};
