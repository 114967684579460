import {
  aspectRatios,
  IGraphqlM0013,
  IGraphqlM0013Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemCloudPdf,
  BemCloudVideo,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP_TO_ZERO,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0013 extends IGraphqlM0013 {}
interface IPropsBemM0013Items {
  items: IGraphqlM0013Item[];
}
interface IPropsBemM0013Item {
  item: IGraphqlM0013Item;
  index: number;
}

const PREFIX = 'M0013';

const styles: IStylesObject = {
  heading: {
    pb: TOP_HEADLINE_V1_PB,
    gridColumn: '2 / -2',
    justifySelf: 'center',
  },
  itemsWrapper: {
    gridTemplateRows: ['auto', '1fr 1fr 1fr', 'auto'],
  },
  itemCardGrid: {
    gridTemplateRows: ['auto 1fr', 'auto', 'auto 1fr'],
    gridTemplateColumns: 'repeat(2, 1fr)',
    height: '100%',
    bg: 'gray.1',
    gridColumn: ['auto', '1 / -1', 'span 4'],
    gap: GRID_ROW_GAP_TO_ZERO,
  },
  itemImage: {
    gridColumn: ['span 2', 'span 1', 'span 2'],

    '&& > *': {
      height: '100%',
    },
  },
  itemDate: {
    color: 'gray.7',
    pt: [6, 8, 8],
    pb: 2,
  },
  itemText: {
    color: 'gray.7',
    pt: [4, 2, 2],
  },
  itemLink: {
    pt: 3,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemTextGrid: (withText: boolean) => ({
    gridTemplateRows: withText
      ? ['auto auto 1fr auto', 'auto auto 1fr auto', 'auto auto 1fr auto']
      : ['auto  1fr auto', 'auto 1fr auto', 'auto  1fr auto'],
    gridColumn: ['span 2', 'span 1', 'span 2'],
    gridGap: defaultGridGap,
    pb: [5, 7, 11],
    px: [5, 5, 5],
  }),
};

const Item = ({ item, index }: IPropsBemM0013Item) => {
  const media =
    item.media[0]._type === 'videoSet'
      ? getLanguageSpecificVideo(item.media[0])
      : item.media[0];

  return (
    <Grid sx={styles.itemCardGrid} key={item._key} data-testid={item._key}>
      {media._type === 'imageWithAlt' ? (
        <BemCloudImage
          image={media}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          sx={styles.itemImage}
          additionalTrackingIdInfo={index + 1}
        />
      ) : null}
      {media._type === 'pdf' ? (
        <BemCloudPdf
          pdf={media}
          hideDownloadLink
          variant="book"
          sx={styles.itemImage}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          additionalTrackingIdInfo={index + 1}
        />
      ) : null}
      {media._type === 'video' ? (
        <BemCloudVideo
          video={media}
          sx={styles.itemImage}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          testId={`${'VIDEO'}${item._key}`}
          additionalTrackingIdInfo={index + 1}
        />
      ) : null}
      <Grid sx={calculatedStyles.itemTextGrid(!!item.text)}>
        <Text as="p" variant="bodyText" sx={styles.itemDate}>
          {item.dateText}
        </Text>
        <Text as="p" variant="bodyText">
          {item.title}
        </Text>
        {item.text ? (
          <Text as="p" variant="small" sx={styles.itemText}>
            {item.text}
          </Text>
        ) : null}
        <BemLink
          to={item.link}
          sx={styles.itemLink}
          variant="links.boldUppercase"
          additionalTrackingIdInfo={index + 1}
        />
      </Grid>
    </Grid>
  );
};

const renderItem = ({ item, index }: IPropsBemM0013Item) => (
  <Item item={item} index={index} />
);

const ItemsOnMobile = ({ items }: IPropsBemM0013Items): JSX.Element | null => (
  <BemTouchSlider
    key="BemM0013_mobile"
    items={items}
    itemRenderer={(data, index) => renderItem({ item: data.item, index })}
    hideShadow
  />
);

const ItemsOnTabletOrLarger = ({
  items,
}: IPropsBemM0013Items): JSX.Element | null => (
  <>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </>
);

const BemM0013 = ({ title, items }: IPropsBemM0013): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2WithSeparatorCentered" sx={styles.heading}>
      {title}
    </BemHeading>
    <Grid variant="contentGrid" sx={styles.itemsWrapper}>
      {useViewportRenderer([
        <ItemsOnMobile key={`${PREFIX}_${VIEWPORTS.MOBILE}`} items={items} />,
        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          items={items}
        />,
        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          items={items}
        />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0013, IPropsBemM0013, IPropsBemM0013Items, IPropsBemM0013Item };
