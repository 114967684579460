import { TAdditionalTrackingIdInfo } from '@bemer/base';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { Box, Button, Grid } from 'theme-ui';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import opacities from '../../gatsby-plugin-theme-ui/opacities';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { ModuleContext } from '../../providers';

type TModalSize = 'full' | 'wide' | 'narrow';

interface IPropsModal {
  isVisible: boolean;
  size?: TModalSize;
  hideBackdrop?: boolean;
  onClose: () => void;
  children?: ReactNode;
  scrollable?: boolean;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;

  /**
   *
   * A optional test id.
   * Default is "BemModal"
   */
  testId?: string;
}
interface IPropsModalBackdrop {
  hideBackdrop?: boolean;
  onClose: () => void;
}
interface IPropsModalHeader {
  onClose: () => void;
}

const MotionBox = motion(Box);
const MotionGrid = motion(Grid);

const MODAL_AND_BACKDROP_OPEN = 'open';
const MODAL_AND_BACKDROP_CLOSED = 'closed';
const MODAL_AND_BACKDROP_EXIT = 'exit';

const styles: IStylesObject = {
  modalHeader: {
    gridColumn: '1 / -1',
    height: [8, 10, 12],
    justifyContent: 'flex-end',
    alignContent: 'center',
    display: 'flex',
    gridRow: 1,
  },
  closeIconWrapper: {
    pr: [2, 3, 4],
    pt: [4, 6, 8],
    display: 'flex',
    alignItems: 'center',
    transform: ['scale(0.9)', 'scale(0.95)', 'scale(1)'],
  },
  modalBackdrop: {
    height: '100%',
    width: '100%',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    bg: 'backdrop',
    opacity: opacities.backdropOpacity,
  },
  modalWrapper: {
    zIndex: 1001,
    gridColumn: '1 / -1',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: ['calc(100vh - 80px)', '80vh', '80vh'],
    height: ['auto', 'auto', 'auto'],
    bg: 'transparent',
    pointerEvents: 'none',
    gridTemplateRows: 'min-content 1fr',
    width: '100%',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  modalContentWrapper: (size: TModalSize) => {
    let gridColumn;
    switch (size) {
      case 'wide':
        gridColumn = ['1 / -1', '2 / -2', '4 / -4'];
        break;
      case 'narrow':
        gridColumn = ['2 / -2', '4 / -4', '5 / -5'];
        break;
      default:
        gridColumn = ['1 / -1', '3 / -3', '3 / -3'];
    }
    return {
      zIndex: 1001,
      borderRadius: ['none', 4, 6],
      boxShadow: 'cardShadow',
      gridTemplateRows: 'min-content 1fr',
      gridColumn,
      gap: defaultGridGap,
      bg: 'background',
      maxHeight: ['100vh', '80vh', '80vh'],
      height: ['100vh', 'auto', 'auto'],
      pointerEvents: 'all',
      overflow: 'hidden',
    };
  },
  modalBodyWrapper: (scrollable: boolean) => ({
    px: [2, 6, 14],
    overflowY: scrollable ? 'auto' : 'hidden',
    gridRow: 2,
    mb: 4,
    pt: 2,
    pb: 6,
  }),
};

const animationVariants = {
  modal: {
    [MODAL_AND_BACKDROP_OPEN]: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: DURATION.SHORT,
      },
    },
    [MODAL_AND_BACKDROP_CLOSED]: {
      opacity: 0,
      scale: 0.5,
    },
    [MODAL_AND_BACKDROP_EXIT]: {
      opacity: 0,
      scale: 0.5,
      transition: {
        duration: DURATION.SHORT,
      },
    },
  },
  backdrop: {
    [MODAL_AND_BACKDROP_OPEN]: {
      opacity: opacities.backdropOpacity,
      transition: {
        duration: DURATION.SHORT,
      },
    },
    [MODAL_AND_BACKDROP_CLOSED]: {
      opacity: 0,
    },
    [MODAL_AND_BACKDROP_EXIT]: {
      opacity: 0,
      transition: {
        duration: DURATION.SHORT,
      },
    },
  },
};

const getScrollbarSize = (doc: Document): number => {
  const scrollDiv = doc.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.overflow = 'scroll';

  doc.body.appendChild(scrollDiv);
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  doc.body.removeChild(scrollDiv);

  return scrollbarSize;
};

const disableBodyScroll = (isVisible: boolean): void => {
  if (isVisible) {
    if (window.innerWidth > document.body.clientWidth) {
      const scrollbarWidth = getScrollbarSize(document);
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = 'inherit';
  }
};

const animationProps = {
  animate: MODAL_AND_BACKDROP_OPEN,
  initial: MODAL_AND_BACKDROP_CLOSED,
  exit: MODAL_AND_BACKDROP_EXIT,
};

const CLOSE_ICON_TEST_ID = 'BemModal-closeIcon';

const ModalHeader = ({ onClose }: IPropsModalHeader) => (
  <Box sx={styles.modalHeader}>
    <Box sx={styles.closeIconWrapper}>
      <Button
        variant="buttons.divWrapper"
        data-testid={CLOSE_ICON_TEST_ID}
        onClick={onClose}
        style={{ height: '2rem', width: '2rem', cursor: 'pointer' }}
      >
        <MdClose size="100%" />
      </Button>
    </Box>
  </Box>
);

const ModalBackdrop = ({ onClose, hideBackdrop }: IPropsModalBackdrop) =>
  !hideBackdrop ? (
    <MotionBox
      onClick={onClose}
      sx={styles.modalBackdrop}
      variants={animationVariants.backdrop}
      {...animationProps}
    />
  ) : null;

const componentName = 'BemModal';
const BemModal = ({
  isVisible = false,
  children,
  size = 'full',
  hideBackdrop = false,
  onClose,
  scrollable = true,
  additionalTrackingIdInfo,
  testId = 'BemModal',
}: IPropsModal): JSX.Element => {
  if (typeof window !== 'undefined') disableBodyScroll(isVisible);

  const { moduleName } = useContext(ModuleContext);
  const trackingId = `${moduleName}-${componentName}${
    additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
  }`;

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <ModalBackdrop onClose={onClose} hideBackdrop={hideBackdrop} />
          <Grid
            variant="fullWidthGrid"
            sx={styles.modalWrapper}
            data-trackingid={trackingId}
            data-testid={testId}
          >
            <MotionGrid
              sx={calculatedStyles.modalContentWrapper(size)}
              variants={animationVariants.modal}
              {...animationProps}
            >
              <ModalHeader onClose={onClose} />
              <Box sx={calculatedStyles.modalBodyWrapper(scrollable)}>
                {children}
              </Box>
            </MotionGrid>
          </Grid>
        </>
      )}
    </AnimatePresence>
  );
};

export { BemModal, IPropsModal };
