import { IGraphqlM0088Document, IGraphqlM0088SectionLevel1 } from '@bemer/base';
import { assign, Machine, StateSchema } from 'xstate';
import { IBemBreadcrumbButton } from '../../components';

interface IDocumentsStates {
  states: {
    init: StateSchema<any>;
    inDocumentListView: StateSchema<any>;
    inDocumentView: StateSchema<any>;
  };
}
interface INavListStates {
  states: {
    navListClosed: StateSchema<any>;
    navListOpen: StateSchema<any>;
  };
}
interface IM0088Schema {
  states: {
    navList: INavListStates;
    documents: IDocumentsStates;
  };
}

interface IM0088Context {
  sections: IGraphqlM0088SectionLevel1[];
  hierarchy: IBemBreadcrumbButton[];
  documentList: IGraphqlM0088Document[];
  selectedDocument?: IGraphqlM0088Document;
  selectedLevel1SectionIndex: number;
  selectedLevel2SectionIndex?: number;
  prevSelectedLevel1SectionIndex: number;
}

type TM0088Events =
  | { type: 'selectDocument'; key: string }
  | { type: 'selectLevel1'; key: string }
  | { type: 'selectLevel2'; key: string }
  | { type: 'updateSections'; sections: IGraphqlM0088SectionLevel1[] }
  | { type: 'toggleNavList'; key?: string };

const m0088Machine = Machine<IM0088Context, IM0088Schema, TM0088Events>(
  {
    id: 'm0088',
    type: 'parallel',
    context: {
      sections: [],
      hierarchy: [],
      documentList: [],
      selectedDocument: undefined,
      selectedLevel1SectionIndex: 0,
      selectedLevel2SectionIndex: undefined,
      prevSelectedLevel1SectionIndex: -1,
    },
    states: {
      navList: {
        id: 'navList',
        initial: 'navListClosed',
        states: {
          navListClosed: {
            on: {
              toggleNavList: {
                target: 'navListOpen',
              },
            },
          },
          navListOpen: {
            on: {
              toggleNavList: {
                target: 'navListClosed',
                actions: ['storePreviousSelectedLevel1SectionIndex'],
              },
            },
          },
        },
      },
      documents: {
        id: 'documents',
        initial: 'init',
        states: {
          init: {
            always: [
              {
                target: 'inDocumentListView',
                actions: ['updateHierarchy', 'setDocumentList'],
                cond: 'isLevel2AlreadySet',
              },
              {
                target: 'inDocumentListView',
                actions: [
                  'autoSelectLevel2Section',
                  'updateHierarchy',
                  'setDocumentList',
                ],
              },
            ],
          },
          inDocumentListView: {
            on: {
              updateSections: {
                target: 'init',
                actions: [
                  'updateSections',
                  'storePreviousSelectedLevel1SectionIndex',
                ],
              },
              selectDocument: {
                target: 'inDocumentView',
                actions: ['selectDocument', 'updateHierarchy'],
              },
              selectLevel1: {
                actions: [
                  'storePreviousSelectedLevel1SectionIndex',
                  'selectLevel1Section',
                  'autoSelectLevel2Section',
                  'updateHierarchy',
                  'setDocumentList',
                ],
              },
              selectLevel2: {
                actions: [
                  'storePreviousSelectedLevel1SectionIndex',
                  'selectLevel2Section',
                  'updateHierarchy',
                  'setDocumentList',
                ],
                cond: 'hasLevel2Sections',
              },
            },
          },
          inDocumentView: {
            on: {
              selectLevel1: {
                target: 'inDocumentListView',
                actions: [
                  'storePreviousSelectedLevel1SectionIndex',
                  'selectLevel1Section',
                  'autoSelectLevel2Section',
                  'removeSelectedDocument',
                  'updateHierarchy',
                  'setDocumentList',
                ],
              },
              selectLevel2: {
                target: 'inDocumentListView',
                actions: [
                  'storePreviousSelectedLevel1SectionIndex',
                  'selectLevel2Section',
                  'removeSelectedDocument',
                  'updateHierarchy',
                  'setDocumentList',
                ],
                cond: 'hasLevel2Sections',
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      /**
       * `updateSections` only exists to make xstate play nice with sanity updates, this will update
       * all sections whenever gatsby received an update using a useEffect hook
       */
      updateSections: assign({
        sections: (_context, event: TM0088Events) => {
          if (event.type === 'updateSections') {
            return event.sections;
          }
          return [];
        },
      }),
      storePreviousSelectedLevel1SectionIndex: assign({
        prevSelectedLevel1SectionIndex: (context: IM0088Context, _event) =>
          context.selectedLevel1SectionIndex,
      }),
      selectLevel1Section: assign({
        selectedLevel1SectionIndex: (context: IM0088Context, event) => {
          let index = -1;
          if (event.type === 'selectLevel1') {
            index = context.sections.findIndex(
              (section) => section._key === event.key
            );
          }

          // if there is no match (-1), return the old result
          return index === -1 ? context.selectedLevel1SectionIndex : index;
        },
        selectedDocument: undefined,
      }),
      autoSelectLevel2Section: assign({
        selectedLevel2SectionIndex: (context, _event) =>
          context.sections[context.selectedLevel1SectionIndex].sections.length
            ? 0
            : undefined,
      }),
      selectLevel2Section: assign({
        selectedLevel2SectionIndex: (context, event: TM0088Events) => {
          if (event.type === 'updateSections') {
            return context.selectedLevel2SectionIndex;
          }

          let index = -1;
          if (event.type === 'selectLevel2') {
            index = context.sections[
              context.selectedLevel1SectionIndex
            ].sections.findIndex((section) => section._key === event.key);
          }
          // if there is no match, return the old result
          return index === -1 ? context.selectedLevel2SectionIndex : index;
        },
      }),
      setDocumentList: assign({
        documentList: (context) => {
          const level1 = context.selectedLevel1SectionIndex;
          const level2 = context.selectedLevel2SectionIndex;

          if (level2 !== undefined) {
            return context.sections[level1].sections[level2].documents.filter(
              Boolean
            );
          }
          return context.sections[level1].documents.filter(Boolean);
        },
      }),
      selectDocument: assign({
        selectedDocument: (context, event: TM0088Events) => {
          if (event.type === 'updateSections') {
            return context.selectedDocument;
          }
          const level1 = context.selectedLevel1SectionIndex;
          const level2 = context.selectedLevel2SectionIndex;
          let documents = [] as IGraphqlM0088Document[];

          if (level2 !== undefined) {
            documents = context.sections[level1].sections[level2].documents;
          } else {
            documents = context.sections[level1].documents;
          }

          const selectedDocument = documents.find(
            (document) => document._key === event.key
          );

          return selectedDocument;
        },
      }),
      removeSelectedDocument: assign({
        selectedDocument: (_context, _event) => undefined,
      }),
      updateHierarchy: assign({
        hierarchy: (context) => {
          const newHierarchy = [];
          const level1 = context.selectedLevel1SectionIndex;
          const level2 = context.selectedLevel2SectionIndex;

          newHierarchy.push({
            label: context.sections[level1].name,
            _key: context.sections[level1]._key,
            _type: 'button',
            onClick: () => {},
          } as IBemBreadcrumbButton);

          // if we are in level2, add the level2 heading to the breadcrumbs
          if (level2 !== undefined) {
            const link: IBemBreadcrumbButton = {
              label: context.sections[level1].sections[level2]?.name,
              _key: context.sections[level1].sections[level2]?._key,
              _type: 'button',
              onClick: () => {},
            };
            newHierarchy.push(link);
          }

          // if a document is shown, add it to the breadcrumbs
          if (context.selectedDocument) {
            const link: IBemBreadcrumbButton = {
              label: context.selectedDocument.name,
              _key: context.selectedDocument._key,
              _type: 'button',
              onClick: () => {},
            };
            newHierarchy.push(link);
          }

          return newHierarchy;
        },
      }),
    },
    guards: {
      hasDocuments: (context) => {
        const level1 = context.selectedLevel1SectionIndex;
        return Boolean(context.sections[level1].documents?.length);
      },
      hasLevel2Sections: (context) => {
        const level1 = context.selectedLevel1SectionIndex;
        return Boolean(context.sections[level1].sections?.length);
      },
      isLevel2AlreadySet: (context) => {
        const level2 = context.selectedLevel2SectionIndex;
        return level2 !== undefined;
      },
    },
  }
);

export { m0088Machine, TM0088Events, IM0088Context };
