import { IImageAsset, IPdfAsset, SANITY_CDN_URL } from '@bemer/base';

const SANITY_DATASET =
  process.env.SANITY_STUDIO_API_DATASET ||
  process.env.STORYBOOK_SANITY_STUDIO_API_DATASET ||
  process.env.GATSBY_SANITY_STUDIO_API_DATASET;

const SANITY_PROJECT_ID =
  process.env.SANITY_STUDIO_API_PROJECT_ID ||
  process.env.STORYBOOK_SANITY_STUDIO_API_PROJECT_ID ||
  process.env.GATSBY_SANITY_STUDIO_API_PROJECT_ID;

const isValidSanityAsset = (asset: IPdfAsset | IImageAsset): boolean => {
  // Check for the case when the image is not fully uploaded in Sanity.
  if (!asset || !asset.url) {
    return false;
  }

  const assetURL = new URL(asset.url);

  if (assetURL.host !== SANITY_CDN_URL) {
    console.warn('the provided asset needs to originate from sanity');
    return false;
  }

  if (
    (SANITY_DATASET && !assetURL.pathname.includes(SANITY_DATASET)) ||
    (SANITY_PROJECT_ID && !assetURL.pathname.includes(SANITY_PROJECT_ID))
  ) {
    console.warn(
      'the provided asset does not match the configured dataset or projectId'
    );
    return false;
  }
  return true;
};

export { isValidSanityAsset };
