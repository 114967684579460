import { aspectRatios, IGraphqlM0064, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  ON_MEDIA_HEADLINE_V1_PB,
  ON_MEDIA_TEXT_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0064 extends IGraphqlM0064 {}

const styles: IStylesObject = {
  imageWrapper: {
    gridColumn: '1 / -1',
    gridRow: 1,
    height: '100%',
    gridTemplateColumns: '1fr',
    mb: [MOBILE_MEDIA_PB, 0, 0],
  },
  textWrapper: {
    gridRow: ['auto', 1, 1],
    zIndex: 1,
    gridColumn: ['2/ -2', '2 / -2', '4 / -4'],
    justifySelf: 'center',
    textAlign: 'center',
    alignSelf: ['auto', 'center', 'auto'],
  },
  heading: {
    pt: [0, 0, 28],
    pb: ON_MEDIA_HEADLINE_V1_PB,
  },
  text: {
    pb: ON_MEDIA_TEXT_V1_PB,
  },
};

const BemM0064 = ({
  title,
  text,
  link,
  media,
}: IPropsBemM0064): JSX.Element => (
  <BemThemeWrapper
    themeName={[
      THEME_NAMES.DARK_TEXT,
      // TODO: refactor quick fixes for having videos, images and video sets
      media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(media[0]).themeName
        : media[0].themeName,
      media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(media[0]).themeName
        : media[0].themeName,
    ]}
  >
    <BemModuleWrapper>
      <Grid sx={styles.imageWrapper}>
        <BemMedia
          media={media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_2_1].ratio}
        />
      </Grid>
      <Box sx={styles.textWrapper} data-testid="M0064-textWrapper">
        <BemHeading
          as="h2"
          variant="h1"
          sx={styles.heading}
          themeName={[
            THEME_NAMES.DARK_TEXT,
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
          ]}
        >
          {title}
        </BemHeading>
        <Text as="p" variant="bodyText" sx={styles.text}>
          {text}
        </Text>
        {link?.length ? (
          <BemLink
            to={link}
            variant="links.buttonPrimary"
            data-testid="BemM0064-link"
          />
        ) : null}
      </Box>
    </BemModuleWrapper>
  </BemThemeWrapper>
);

export { BemM0064, IPropsBemM0064 };
