import { aspectRatios, IGraphqlM0023, RATIOS } from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useEffect } from 'react';
import { Box, Grid, Select } from 'theme-ui';
import {
  BemButton,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_FORM_PB,
  MOBILE_HEADLINE_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { debugXState } from '../../utils/xStateHelper';
import { m0023Machine } from './BemM0023.machine';

interface IPropsBemM0023 extends IGraphqlM0023 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: ['1 / -1', '1 / span 4'],
    textAlign: 'left',
    pt: 2,
    pb: [MOBILE_HEADLINE_PB, 10, 14],

    '&:only-child': {
      gridColumn: ['1 / -1', '1 / -1', '1 / -1'],
    },
  },
  segmentedControls: {
    gridColumn: ['1 / -1', '5 / -1', '5 / -1'],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    pb: MOBILE_FORM_PB,
  },
  cardsWrapper: {
    gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(5, 1fr)'],
  },
  itemCard: {
    gridColumn: 'span 1',
    bg: ['transparent', 'gray.1', 'gray.1'],
  },
  itemImage: {
    p: [0, 4, 4],
  },
  itemHeading: {
    textAlign: ['left', 'center', 'center'],
    pt: [2, 0, 0],
    pb: [2, 4, 4],
    px: [0, 4, 4],
  },
  filterButtons: {
    display: ['none', 'none', 'flex'],
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'flex-end',
  },
  filterSelect: {
    width: '100%',
    pt: [0, 2, 2],
    pb: [2, 0, 0],
    display: ['block', 'block', 'none'],
    svg: {
      ml: -8,
    },
  },
};

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0023 = ({
  title,
  showCategories,
  allItemsButtonLabel,
  product,
}: IPropsBemM0023): JSX.Element => {
  const [current, send] = useMachine(m0023Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      allItems: product.childProducts,
      currentFilter: 'all',
      filteredItems: product.childProducts,
    },
  });

  useEffect(() => {
    send({
      type: 'updateData',
      allItems: product.childProducts ?? [],
    });
  }, [product]);

  return (
    <BemModuleWrapper>
      <Grid variant="contentGrid" data-testid="headWrapper">
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        {showCategories && current.context.allFilters.length ? (
          <Box sx={styles.segmentedControls}>
            <Box sx={styles.filterSelect}>
              <Select
                onChange={(e) =>
                  send({ type: 'setFilter', filterValue: e.target.value })
                }
                value={current.context.currentFilter}
                variant="selectWithoutLabel"
              >
                <option value="all">{allItemsButtonLabel}</option>
                {current.context.allFilters.map((filter) => (
                  <option key={`option_${filter}`} value={filter}>
                    {filter}
                  </option>
                ))}
              </Select>
            </Box>
            <Box sx={styles.filterButtons}>
              <BemButton
                onClick={() => send({ type: 'setFilter', filterValue: 'all' })}
                variant={
                  current.context.currentFilter === 'all'
                    ? 'buttons.primarySmall'
                    : 'buttons.lightSmall'
                }
              >
                {allItemsButtonLabel}
              </BemButton>
              {current.context.allFilters.map((filter) => (
                <BemButton
                  key={`button_${filter}`}
                  onClick={() =>
                    send({ type: 'setFilter', filterValue: filter })
                  }
                  variant={
                    current.context.currentFilter === filter
                      ? 'buttons.primarySmall'
                      : 'buttons.lightSmall'
                  }
                >
                  {filter}
                </BemButton>
              ))}
            </Box>
          </Box>
        ) : null}
      </Grid>
      <Grid
        variant="contentGrid"
        sx={styles.cardsWrapper}
        data-testid="cardsWrapper"
      >
        {current.context.filteredItems.map((item, index) => (
          <Box sx={styles.itemCard} key={item._id}>
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
              withTransparentBackground
              sx={styles.itemImage}
              additionalTrackingIdInfo={index + 1}
            />
            <BemHeading
              as="h3"
              variant="h6"
              sx={styles.itemHeading}
              className="heading"
            >
              {item.name}
            </BemHeading>
          </Box>
        ))}
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0023, IPropsBemM0023 };
