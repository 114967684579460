import {
  IGraphqlM0152,
  IPartnerFinderData,
  IVisitorSendContactRequestPayload,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useContext, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  BemModuleWrapper,
  BemSpinner,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { useScrollToModuleHash } from '../../hooks/useScrollToModuleHash';
import { ApiContext, ThemeHierarchyContext } from '../../providers';
import { setHash } from '../../utils/urlUtils';
import { debugXState } from '../../utils/xStateHelper';
import {
  m0152Machine,
  m0152Model,
  STATES,
  TMachineEvent,
  TMachineState,
} from './BemM0152.machine';
import { ContactForm } from './ContactForm';
import { ContactFormSuccess } from './ContactFormSuccess';
import { PartnerFinder } from './PartnerFinder';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

interface IPropsBemM0152 extends IGraphqlM0152 {
  machineRef?: { current: TMachineState; send: (event: TMachineEvent) => void };
}

const styles: IStylesObject = {
  wrapper: {
    pt: [8, 12, 14],
    pb: [8, 12, 14],
  },
  submittingPartnerFinderData: {
    my: 10,
    mx: 'auto',
    justifyContent: 'center',
    gridColumn: '7 / 9',
  },
  partnerFinderForm: {
    pt: 2,
  },
  contactForm: {
    pt: 2,
  },
};

const MODULE_NAME = 'BemM0152';

/**
 * Module renders partnerFinderForm or contactForm on a page.
 *
 * This module is copied from M0077 which handles the same usecase within a modal.
 */
const BemM0152 = ({
  blocksFormLegalText,
  supportPageLink,
  translations: T,
  machineRef,
  theme: moduleTheme,
}: IPropsBemM0152): JSX.Element | null => {
  const [theme] = useContext(ThemeHierarchyContext);
  const m0152Ref = useRef<HTMLDivElement>(null);
  const [localCurrent, localSend] = useMachine(m0152Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
  });

  const current = machineRef ? machineRef.current : localCurrent;
  const send = machineRef ? machineRef.send : localSend;
  const scrollMarginTop = useScrollToModuleHash(m0152Ref);

  const {
    partner: { useGetPartnerByHandle },
    visitor: {
      getVisitorSettings,
      mutationSendContactRequest,
      mutationVisitorSettings,
    },
  } = useContext(ApiContext);

  const { isFetching: isFetchingGetVisitorSettings, data: visitorSettings } =
    getVisitorSettings();

  const mutationContactRequest = mutationSendContactRequest();
  const mutationVisitorData = mutationVisitorSettings();

  const { data: partnerDataByHandle } = useGetPartnerByHandle(
    visitorSettings?.partnerHandle
  );

  /**
   * Following description of the behaviour from M0077 was changed in this component.
   * Instead of showing a spinner, we show the contact form of a partner.
   *
   * Comment taken from M0077:
   * This function will update the visitorSettings in the backend.
   * If the request is successful, M0152 will go into an final state
   * called STATE.CHANGE_PARTNER_PROCESSING and display an spinner forever.
   *
   * That is intended! In the background the backend will update the partner for the
   * current visitorID and send a new response for the getVisitorSettings() query (react-query
   * will update the data) containing the new partnerHandle.
   *
   * Then the packages/web-app-marketing-site/src/components/VisitorSettings/VisitorSettings.tsx
   * logic will kick in and redirect the user to the partner page. That is a hard redirect and
   * the whole app will be destroyed.
   *
   * @param data
   */
  const handlePartnerFormSubmit = (data: IPartnerFinderData) =>
    new Promise((resolve) => {
      const payload = { partnerHandle: data.partnerData.handle };
      mutationVisitorData.mutate(payload, {
        onSuccess: (result) => {
          send(m0152Model.events.successfullySubmittedPartnerForm());
          resolve(result);
        },
      });
    });

  const handleContactFormSubmit: SubmitHandler<
    IVisitorSendContactRequestPayload
  > = (data) => {
    const resolution = `${window.screen.availHeight} height x ${window.screen.availWidth} width`;
    const {
      title,
      location: { href: url },
    } = document;
    const metaData = {
      ...partnerDataByHandle,
      title,
      url,
      resolution,
      orientation: window.screen.orientation.type,
    };
    const log = true;
    const extendedData = { ...data, metaData, log };
    return new Promise((resolve) => {
      mutationContactRequest.mutate(extendedData, {
        onSuccess: (result) => {
          send(m0152Model.events.successfullySubmittedContactForm(data.email));
          resolve(result);
        },
      });
    });
  };

  const handleChangeDistributor = () => {
    send(m0152Model.events.clickOnChangeDistributor());
  };

  // The visitorSettings are required for this module to display anything.
  if (isFetchingGetVisitorSettings && !visitorSettings) {
    return null;
  }

  // Set the form content depending on the state of the state machine.
  let formContent;
  switch (current.value) {
    case STATES.INITIAL: {
      send(
        m0152Model.events.partnerFinderFinished(visitorSettings?.partnerHandle)
      );
      formContent = <BemSpinner sx={styles.submittingPartnerFinderData} />;
      break;
    }
    case STATES.CHANGE_PARTNER_PROCESSING_MODAL: {
      setHash(MODULE_NAME);
      formContent = <BemSpinner sx={styles.submittingPartnerFinderData} />;
      break;
    }
    case STATES.PARTNER_FINDER_FORM: {
      formContent = (
        <PartnerFinder
          onSubmit={handlePartnerFormSubmit}
          T={T}
          sx={styles.partnerFinderForm}
          theme={moduleTheme || theme}
        />
      );
      break;
    }
    case STATES.CONTACT_FORM: {
      formContent = (
        <ContactForm
          T={T}
          sx={styles.contactForm}
          blocksFormLegalText={blocksFormLegalText}
          onSubmit={handleContactFormSubmit}
          onChangeDistributor={handleChangeDistributor}
          theme={moduleTheme || theme}
        />
      );
      break;
    }
    case STATES.CONTACT_FORM_SUCCESS: {
      formContent = (
        <ContactFormSuccess
          email={current.context.email}
          T={T}
          supportPageLink={supportPageLink[0]}
          theme={moduleTheme || theme}
        />
      );
      break;
    }
    default:
      throw new Error(`State machine is in undefined state ${current.value}`);
  }

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        id={MODULE_NAME}
        sx={styles.wrapper}
        data-testid={MODULE_NAME}
        ref={m0152Ref}
        {...{ style: { scrollMarginTop } }}
      >
        {formContent}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0152, IPropsBemM0152 };
