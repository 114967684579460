import {
  defaultLocale,
  isKeyInObject,
  quotesMap,
  TLocaleId,
} from '@bemer/base';
import React, { ReactNode } from 'react';
import { Box } from 'theme-ui';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemQuoteWrapper {
  children?: ReactNode;
  localeId: TLocaleId;
}

const styles: ICalculatedStylesObject = {
  wrapper: (start: string, end: string) => ({
    '&::before': {
      content: `"${start}"`,
      position: 'absolute',
      display: 'block',
      transform: 'translateX(-100%)',
      zIndex: 1,
    },
    '&::after': {
      content: `"${end}"`,
    },
  }),
};

const BemQuoteWrapper = ({
  children,
  localeId = defaultLocale.id,
}: IPropsBemQuoteWrapper): JSX.Element => {
  // checking if localeId is actually available before accessing the map
  // eg. `de_US` (MacOS with us english system language and region set to germany)
  // is not available and throws and error
  const { start, end } = isKeyInObject(quotesMap, localeId)
    ? quotesMap[localeId]
    : quotesMap[defaultLocale.id];
  return (
    <Box as="span" sx={styles.wrapper(start, end)}>
      {children}
    </Box>
  );
};

export { BemQuoteWrapper, IPropsBemQuoteWrapper };
