import { aspectRatios, IGraphqlM0082, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import {
  BemBreadcrumb,
  BemMedia,
  BemModuleWrapper,
  BemRichtext,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { BREADCRUMB_PY } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { PathNamesContext } from '../../providers';

interface IPropsBemM0082 extends IGraphqlM0082 {}

const styles: IStylesObject = {
  breadCrumb: {
    px: [4, 6, 8],
    py: BREADCRUMB_PY,
  },
  headContentWrapper: {
    gridColumn: '1 / -1',
    boxShadow: 'cardShadow',
    mb: [10, 20, 28],
  },
  richtext: {
    '.richtext h2:first-of-type': {
      textAlign: 'center',
    },
    '.richtext p:first-of-type': {
      textAlign: 'center',
    },
    gridColumn: ['2 / -2', '3 / -3', '4 / -4'],
  },
};

const BemM0082 = ({ media, blog }: IPropsBemM0082): JSX.Element => {
  const pathNames = useContext(PathNamesContext);
  return (
    <BemModuleWrapper>
      <Box sx={styles.headContentWrapper}>
        <BemMedia
          media={media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_3].ratio}
        />
        <BemBreadcrumb links={pathNames} sx={styles.breadCrumb} addPaddingTop />
      </Box>
      <BemRichtext blocks={blog} sx={styles.richtext} />
    </BemModuleWrapper>
  );
};

export { BemM0082, IPropsBemM0082 };
