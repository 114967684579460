import { IGraphqlM0035Item } from '@bemer/base';
import { assign, Machine, StateSchema } from 'xstate';

interface IM0035Schema {
  states: {
    init: StateSchema;
  };
}

interface IM0035Context {
  allItems: IGraphqlM0035Item[];
  currentFilter: string;
  allFilters: string[];
  filteredItems: IGraphqlM0035Item[];
}

type TM0035Events =
  | { type: 'updateData'; allItems: IGraphqlM0035Item[] }
  | { type: 'setFilter'; filterValue: string };

const INITIAL_FILTER = 'all';
const MINIMUM_NUMBER_OF_FILTERS = 2;

const m0035Machine = Machine<IM0035Context, IM0035Schema, TM0035Events>(
  {
    id: 'm0035',
    initial: 'init',
    context: {
      allItems: [],
      allFilters: [],
      currentFilter: INITIAL_FILTER,
      filteredItems: [],
    },
    states: {
      init: {
        entry: ['getAllFilters'],
        on: {
          setFilter: {
            actions: ['setFilter', 'applyFilter'],
          },
          updateData: {
            actions: [
              'updateAllItems',
              'getAllFilters',
              'resetFilter',
              'applyFilter',
            ],
          },
        },
      },
    },
  },
  {
    actions: {
      getAllFilters: assign({
        allFilters: (context, _event) => {
          const filters = context.allItems
            .map((item) => item.product.productSubCategories)
            .flat()
            .map((item) => item.name);
          const uniqueFilters = [...new Set(filters)].sort();
          return uniqueFilters.length >= MINIMUM_NUMBER_OF_FILTERS
            ? uniqueFilters
            : [];
        },
      }),
      setFilter: assign({
        currentFilter: (context, event) =>
          event.type === 'setFilter'
            ? event.filterValue
            : context.currentFilter,
      }),
      resetFilter: assign({
        currentFilter: (_context, _event) => INITIAL_FILTER,
      }),
      applyFilter: assign({
        filteredItems: (context, _event) =>
          context.currentFilter === INITIAL_FILTER
            ? context.allItems
            : context.allItems.filter((item) =>
                item.product.productSubCategories.some(
                  (cat) => cat.name === context.currentFilter
                )
              ),
      }),
      updateAllItems: assign({
        allItems: (context, event) =>
          event.type === 'updateData' ? event.allItems : context.allItems,
      }),
    },
  }
);

export { m0035Machine, TM0035Events };
