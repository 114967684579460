import { determineUsedComponentTheme, TThemeName } from '@bemer/base';
import { HeadingProps } from '@theme-ui/components';
import { useBreakpointIndex } from '@theme-ui/match-media';
import React, { useContext } from 'react';
import { Heading } from 'theme-ui';
import { ModuleContext, ThemeHierarchyContext } from '../../providers';
import { BemThemeWrapper } from '../ThemeWrapper';

interface IPropsBemHeading extends HeadingProps {
  /**
   * Theme variant
   */
  variant?: string;
  themeName?: TThemeName | TThemeName[];
}

const BemHeading = ({
  children,
  variant = 'heading',
  themeName,
  ...props
}: IPropsBemHeading): JSX.Element => {
  const breakpointIndex = useBreakpointIndex();
  const [theme] = useContext(ThemeHierarchyContext);
  const { moduleTheme } = useContext(ModuleContext);

  let themeNameForCurrentViewport: TThemeName | undefined;
  if (typeof themeName === 'string') {
    themeNameForCurrentViewport = themeName;
  }
  if (Array.isArray(themeName) && themeName.length > 0) {
    themeNameForCurrentViewport = themeName[breakpointIndex] || themeName[0];
  }

  const usedThemeName = determineUsedComponentTheme(
    theme,
    moduleTheme,
    themeNameForCurrentViewport
  );

  return (
    <BemThemeWrapper themeName={usedThemeName}>
      <Heading variant={variant} {...props}>
        {children}
      </Heading>
    </BemThemeWrapper>
  );
};

export { BemHeading, IPropsBemHeading };
