import { IGraphqlTranslation } from '@bemer/base';
import { useActor } from '@xstate/react';
import React from 'react';
import Autocomplete from 'react-autocomplete';
import { Box, Grid, Input, Label, Text } from 'theme-ui';
import shadows from '../../../gatsby-plugin-theme-ui/shadows';
import { getTranslation } from '../../../utils/translations';
import { IFeature } from '../utils/mapBox';
import { TSearchMachineActorRef } from './Search.machine';

interface IPropsSearch {
  searchActorRef: TSearchMachineActorRef;
  T: IGraphqlTranslation[];
}

const Search = ({ searchActorRef, T }: IPropsSearch): JSX.Element => {
  const [{ context }, send] = useActor(searchActorRef);

  const handleSearch = (feature: IFeature) => {
    send({
      type: 'SELECT_SUGGESTION',
      coords: {
        longitude: feature.geometry.coordinates[0] as number,
        latitude: feature.geometry.coordinates[1] as number,
      },
    });
    send({
      type: 'SET_SEARCH_TERM',
      value: feature.place_name,
    });
  };

  const hasNoResults = () =>
    !Array.isArray(context.results?.features) ||
    context.results.features.length === 0;

  return (
    <Autocomplete
      getItemValue={(item) => item.id}
      items={context.results?.features || []}
      wrapperStyle={{ display: 'block' }}
      menuStyle={{
        borderRadius: '3px',
        boxShadow: shadows.smallCardShadow,
        zIndex: 4,
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '50%',
      }}
      renderInput={({
        value,
        role,
        autoComplete,
        'aria-autocomplete': ariaAutocomplete,
        'aria-expanded': ariaExpanded,
        ...props
      }: any) => (
        <Grid variant="formInputGrid">
          <Input
            name="searchInput"
            placeholder={getTranslation('EVENT_FILTER_SEARCH_PLACEHOLDER', T)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (hasNoResults()) {
                  return;
                }
                if (value === '') {
                  send({
                    type: 'RESET_SEARCH_TERM',
                  });
                } else {
                  const feature = context.results.features[0];
                  handleSearch(feature);
                }
              }
            }}
            value={value}
            autoComplete={autoComplete}
            role={role}
            aria-autocomplete={ariaAutocomplete}
            aria-expanded={ariaExpanded}
            {...props}
          />
          <Label htmlFor="searchInput">
            {getTranslation('EVENT_FILTER_SEARCH_LABEL', T)}
          </Label>
        </Grid>
      )}
      renderItem={(item, isHighlighted: boolean) => (
        <Box
          sx={{ bg: isHighlighted ? 'gray' : 'white', p: 4 }}
          {...item}
          key={item.id}
        >
          <Text variant="small">{item.place_name || item.name}</Text>
        </Box>
      )}
      value={context.searchTerm}
      onChange={(e) =>
        send({
          type: 'SET_SEARCH_TERM',
          value: e.target.value,
        })
      }
      onSelect={(val) => {
        if (hasNoResults()) {
          return;
        }
        const feature = context.results.features.find(
          (singleFeature) => singleFeature.id === val
        );
        if (feature) {
          handleSearch(feature);
        }
      }}
    />
  );
};

export { Search };
