import { IGraphqlVideo, IGraphqlVideoSet, TLanguageCode } from '@bemer/base';
import { useContext } from 'react';
import { LocalisationContext } from '../providers';

/**
 * returns a language specific video depending on the user language and the given videoSet
 *
 * @param videoSet the videoSet in question
 * @param forcedLanguageCode optional parameter, overrides the user language, use this to force a language to a videoSet - if this language isn't present in the languageSpecificVideo array, the defaultLanguageVideo will still be used
 * @returns the video to the user language if present in the videoSet, otherwise the defaultLanguageVideo
 */
const getLanguageSpecificVideo = (
  videoSet: IGraphqlVideoSet,
  forcedLanguageCode?: TLanguageCode
): IGraphqlVideo => {
  const languageCode =
    forcedLanguageCode ||
    useContext(LocalisationContext).localeIdentifier.languageCode;
  const languageSpecificVideo = videoSet.languageSpecificVideos.filter(
    ({ language }) => language === languageCode
  );
  if (
    languageCode &&
    languageSpecificVideo.length > 0 &&
    languageSpecificVideo[0].video &&
    // keep this for old videoSets, since they have video objects initialized
    languageSpecificVideo[0].video.muxVideo !== null &&
    // this for the newest version of videoSets, which have a muxVideo object set, but no asset
    languageSpecificVideo[0].video.muxVideo?.asset !== null
  ) {
    return languageSpecificVideo[0].video;
  }
  return videoSet.languageDefaultVideo;
};

export { getLanguageSpecificVideo };
