import { aspectRatios, IGraphqlM0002A, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Flex, Grid } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemProductCard,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0002A extends IGraphqlM0002A {}

const styles: IStylesObject = {
  wrapper: {
    bg: 'white',
    mb: 0,
    boxShadow: 'smallCardShadow',
  },
  teaserWrapper: {
    gridColumn: '8 / -1',
    backgroundColor: 'gray.1',
    py: 8,
  },
  linksWrapper: {
    gridColumn: '1 / 7',
    py: 8,
  },
  backdrop: {
    gridColumn: '-2 / -1',
    gridRow: '1',
    bg: 'gray.1',
    height: '100%',
    marginLeft: -8,
  },
};

const BemM0002A = ({
  teaserTitle,
  teaserLink,
  links,
  title,
}: IPropsBemM0002A): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0002A">
    <Box sx={styles.backdrop} />
    <Grid variant="contentGrid" sx={{ gridRow: '1' }}>
      <Box sx={styles.linksWrapper} data-testid="BemM0002A-linkList">
        <BemHeading as="h4" variant="h4" sx={{ pb: 8 }}>
          {title}
        </BemHeading>
        <Grid sx={{ gridTemplateColumns: '1fr 1fr 1fr', gap: 4, rowGap: 8 }}>
          {links.map((link, index) => (
            <BemProductCard link={link} key={link._key} index={index} />
          ))}
        </Grid>
      </Box>
      <Grid
        sx={{
          ...styles.teaserWrapper,
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: 4,
        }}
        data-testid="BemM0002A-teaser"
      >
        <Box
          sx={{
            gridColumn: '2/-1',
          }}
        >
          <BemHeading as="h4" variant="h4" sx={{ pb: 8 }}>
            {teaserTitle}
          </BemHeading>
          <Grid
            sx={{ gridTemplateColumns: '1fr', gridTemplateRows: '1fr', gap: 0 }}
          >
            {teaserLink.image && (
              <BemCloudImage
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
                image={teaserLink.image}
                sx={{ gridRow: 1, gridColumn: 1 }}
              />
            )}

            <Flex
              sx={{
                gridRow: 1,
                gridColumn: 1,
                justifyContent: 'center',
                alignItems: 'flex-end',
                zIndex: 1,
                p: 10,
              }}
            >
              <BemLink to={teaserLink} variant="links.buttonPrimary" />
            </Flex>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </BemModuleWrapper>
);

export { BemM0002A, IPropsBemM0002A };
