import { aspectRatios, IGraphqlM0146, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import { BemHeading, BemMedia, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  INNER_HEADLINE_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0146 extends IGraphqlM0146 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  text: {
    gridColumn: ['2 / -2', '3 / -3', '4 / -4'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  itemWrapper: {
    '&:first-of-type': {
      gridColumn: ['2 / -2', '2 / span 6', '2 / span 6'],
    },
    gridColumn: ['2 / -2', '8 / span 6', '8 / span 6'],
  },
  media: {
    pb: INNER_HEADLINE_PB,
  },
  itemText: {
    color: 'textSecondary',
    pb: [8, 0, 0],
  },
};

const BemM0146 = ({ title, text, items }: IPropsBemM0146): JSX.Element => (
  <BemModuleWrapper>
    {title ? (
      <BemHeading variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
    ) : null}
    {text ? (
      <Text variant="h4" sx={styles.text}>
        {text}
      </Text>
    ) : null}
    {items.map((item) => (
      <Box key={item._key} sx={styles.itemWrapper} data-testid={item._key}>
        <BemMedia
          media={item.media[0]}
          sx={styles.media}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_3].ratio}
          autoplayPreview
        />
        {item.text ? (
          <Text as="p" variant="bodyText" sx={styles.itemText}>
            {item.text}
          </Text>
        ) : null}
      </Box>
    ))}
  </BemModuleWrapper>
);

export { BemM0146, IPropsBemM0146 };
