import { TGraphqlModulePosition } from '@bemer/base';
import { defaultGridGap, GRID_GAP_DESKTOP, GRID_GAP_TABLET } from '../grids';

// Only mobile values
const MOBILE_MODULE_INNER_SECTION_PB = 10;
const MOBILE_MODULE_INNER_SECTION_PT = 10;
const MOBILE_MODULE_TEXT_PB = 8;
const MOBILE_MODULE_CAPTION_BIG_PB = 6;
const MOBILE_HEADLINE_PB = 8;
const MOBILE_TEXT_PB = 6;
const MOBILE_MEDIA_PB = 6;
const MOBILE_FORM_PB = 6;
const MOBILE_CARD_PADDING = 4;
const MOBILE_ASIDE_ACTION_AREA_PT = 8;

// Only tablet values
const TABLET_MODULE_INNER_SECTION_PB = 14;
const TABLET_MEDIA_PB = 6;
const TABLET_HEADLINE_PB = 12;
const TABLET_CARD_PADDING = 6;

// Only Desktop
const DESKTOP_ON_MEDIA_HEADLINE_V1_PB = 12;
const DESKTOP_HEADLINE_PB = 16;

// Modules
const MODULE_MB = [16, 24, 32];
const MODULE_WITH_BACKGROUND_COLOR_PT = [8, 16, 32];
const MODULE_WITH_BACKGROUND_COLOR_PB = [6, 12, 24];

// Headline and Text
const TOP_HEADLINE_V1_PB = [
  MOBILE_HEADLINE_PB,
  TABLET_HEADLINE_PB,
  DESKTOP_HEADLINE_PB,
];
const TOP_HEADLINE_V2_PB = [
  MOBILE_HEADLINE_PB,
  TABLET_HEADLINE_PB,
  DESKTOP_HEADLINE_PB,
];

const TOP_CAPTION_BIG_PB = [MOBILE_MODULE_CAPTION_BIG_PB, 12, 16];

const ASIDE_HEADLINE_PB = [MOBILE_HEADLINE_PB, 8, 10];
const ASIDE_TEXT_PB = [MOBILE_TEXT_PB, 8, 10];
const ASIDE_ACTION_AREA_PT = [MOBILE_ASIDE_ACTION_AREA_PT, 10, 14];

const INNER_HEADLINE_PB = [4, 4, 8];

// Headline and Text on Media
const ON_MEDIA_HEADLINE_V1_PB = [8, 10, DESKTOP_ON_MEDIA_HEADLINE_V1_PB];
const ON_MEDIA_TEXT_V1_PB = ON_MEDIA_HEADLINE_V1_PB;
const ON_MEDIA_HEADLINE_V2_PB = [6, 8, 10];
const ON_MEDIA_TEXT_V2_PB = ON_MEDIA_HEADLINE_V2_PB;

// Images
const ASIDE_MEDIA_PB = [MOBILE_MEDIA_PB, 0, 0];

// Buttons
const MULTIPLE_BUTTONS_BUTTON_MB = defaultGridGap;
const MULTIPLE_BUTTONS_BUTTON_MR = defaultGridGap;
const BUTTON_WIDTH = ['100%', 'auto', 'auto'];

// Grid
const GRID_ROW_GAP = [0, GRID_GAP_TABLET, GRID_GAP_DESKTOP];
const GRID_ROW_GAP_TO_ZERO = [0, 0, 0];

// Cards
const CARD_TEXT_PX = [MOBILE_CARD_PADDING, TABLET_CARD_PADDING, 0];

// Pagination
const PAGINATION_PADDING = 10;
const PAGINATION_PT = [0, PAGINATION_PADDING, PAGINATION_PADDING];
const PAGINATION_PB = PAGINATION_PT;

// Breadcrumb
const BREADCRUMB_PY = [2, 4, 6];

// Other
const FEATURES_LIST_PT = [null, 0, 11];

interface IMarginStyles {
  mt?: number | string[];
  mb?: number | string[];
}

/**
 * Returns the margin bottom or margin top styles depending on modulePosition.
 * If modulePosition is `default`, an empty object is returned.
 *
 * @param modulePosition
 */
const getMarginStylesForModulePosition = (
  modulePosition: TGraphqlModulePosition
): IMarginStyles => {
  let marginStyles;
  switch (modulePosition) {
    case 'aboveHero':
      marginStyles = {
        mb: 0,
      };
      break;

    case 'belowHero':
      marginStyles = {
        mt: MODULE_MB.map((v: number | string) => `-${v}`),
      };
      break;

    case 'default':
    default:
      marginStyles = {};
      break;
  }
  return marginStyles;
};
export {
  ASIDE_ACTION_AREA_PT,
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  ASIDE_TEXT_PB,
  BREADCRUMB_PY,
  BUTTON_WIDTH,
  CARD_TEXT_PX,
  DESKTOP_ON_MEDIA_HEADLINE_V1_PB,
  FEATURES_LIST_PT,
  getMarginStylesForModulePosition,
  GRID_ROW_GAP_TO_ZERO,
  GRID_ROW_GAP,
  INNER_HEADLINE_PB,
  MOBILE_ASIDE_ACTION_AREA_PT,
  MOBILE_CARD_PADDING,
  MOBILE_FORM_PB,
  MOBILE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_CAPTION_BIG_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MOBILE_MODULE_INNER_SECTION_PT,
  MOBILE_MODULE_TEXT_PB,
  MODULE_MB,
  MOBILE_TEXT_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  MULTIPLE_BUTTONS_BUTTON_MB,
  MULTIPLE_BUTTONS_BUTTON_MR,
  ON_MEDIA_HEADLINE_V1_PB,
  ON_MEDIA_HEADLINE_V2_PB,
  ON_MEDIA_TEXT_V1_PB,
  ON_MEDIA_TEXT_V2_PB,
  PAGINATION_PADDING,
  PAGINATION_PB,
  PAGINATION_PT,
  TABLET_CARD_PADDING,
  TABLET_HEADLINE_PB,
  TABLET_MEDIA_PB,
  TABLET_MODULE_INNER_SECTION_PB,
  TOP_CAPTION_BIG_PB,
  TOP_HEADLINE_V1_PB,
  TOP_HEADLINE_V2_PB,
};
