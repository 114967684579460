import {
  aspectRatios,
  IGraphqlM0026,
  IGraphqlM0026Item,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext, useState } from 'react';
import { Box, Grid } from 'theme-ui';
import {
  BemAccordionWithExpander,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  FEATURES_LIST_PT,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0026 extends IGraphqlM0026 {}

const PREFIX = 'M0026';

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  item: {
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    gridTemplateColumns: 'repeat(6,  1fr)',
    gap: defaultGridGap,
    zIndex: 1,
    rowGap: [0, defaultGridGap, defaultGridGap],
  },
  itemHeader: {
    gridRow: 2,
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
  },
  itemImage: {
    gridRow: 1,
    gridColumn: '1 / -1',
  },
  itemTitle: {
    gridRow: 1,
    gridColumn: '2 / -2',
    textAlign: 'center',
    zIndex: 1,
    pt: 20,
  },
  features: {
    pt: FEATURES_LIST_PT,
    gridRow: 3,
    zIndex: 1,
    rowGap: [0, 0, 0],
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
  },
  shadowBox: {
    gridColumn: ['1 / -1', '2 / span 6', '2 / span 6'],
    gridRow: '2 / span 2',
    boxShadow: 'smallCardShadow',
    bg: 'background',
    '&:last-of-type': {
      gridColumn: ['1 / -1', '8 / span 6', '8 / span 6'],
    },
  },

  featureText: {
    py: 10,
  },
  featureDetailsText: {
    pt: 1,
    pb: 6,
  },
  accordion: {
    px: [2, 2, 6],
  },
};

interface IPropsBemM0026Item {
  item: IGraphqlM0026Item;
  index: number;
}
interface IPropsItem {
  item: IGraphqlM0026Item;
  onClick: (index: number | null, isInitialIndex: boolean) => void;
  currentIndex: number | null;
  itemIndex: number;
}

interface IItemsContext {
  currentIndex: null | number;
  onClick: (index: number | null, isInitialIndex: boolean) => void;
  items: IGraphqlM0026Item[];
}
const ItemsContext = React.createContext<IItemsContext>({
  currentIndex: null,
  onClick: () => {},
  items: [],
});

const ItemHeader = ({ item, index }: IPropsBemM0026Item): JSX.Element => (
  <Grid sx={styles.item}>
    <BemMedia
      media={item.media[0]}
      sx={styles.itemImage}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
      additionalTrackingIdInfo={index + 1}
    />
    <BemHeading
      sx={styles.itemTitle}
      as="h3"
      variant="h3"
      // TODO: refactor quick fixes for having videos, images and video sets
      themeName={
        item.media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(item.media[0]).themeName
          : item.media[0].themeName
      }
    >
      {item.title}
    </BemHeading>
  </Grid>
);

const getMobileRenderer = ({
  item,
  onClick,
  currentIndex,
  itemIndex,
}: IPropsItem): JSX.Element => {
  const formattedItems = item.featureItems.map((featureItem) => ({
    ...featureItem,
    title: featureItem.featureText,
    text: featureItem.featureDetails,
  }));
  return (
    <Grid variant="contentGrid" sx={styles.item} data-testid={item._key}>
      <Grid variant="contentGrid" sx={styles.itemHeader}>
        <BemThemeWrapper
          themeName={
            item.media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(item.media[0]).themeName
              : item.media[0].themeName
          }
          key={item._key}
        >
          <ItemHeader item={item} index={itemIndex} />
        </BemThemeWrapper>
      </Grid>
      <Grid variant="contentGrid" sx={styles.features}>
        <BemAccordionWithExpander
          items={formattedItems}
          onClick={(index, isInitialIndex) => onClick(index, isInitialIndex)}
          activeIndex={currentIndex}
          sx={styles.accordion}
          additionalTrackingIdInfo={`accordion${itemIndex + 1}`}
        />
      </Grid>
      <Box sx={styles.shadowBox} />
    </Grid>
  );
};

const ItemsOnTabletOrLarger = (): JSX.Element => {
  const { items, onClick, currentIndex } = useContext(ItemsContext);
  return (
    <>
      <Grid variant="contentGrid" sx={styles.itemHeader}>
        {items.map((item, index) => (
          <BemThemeWrapper
            themeName={
              item.media[0]._type === 'videoSet'
                ? getLanguageSpecificVideo(item.media[0]).themeName
                : item.media[0].themeName
            }
            key={item._key}
          >
            <ItemHeader item={item} index={index} />
          </BemThemeWrapper>
        ))}
      </Grid>
      <Grid variant="contentGrid" sx={styles.features}>
        {items.map((item, accordionIndex) => {
          const newItems = item.featureItems.map((featureItem) => ({
            ...featureItem,
            title: featureItem.featureText,
            text: featureItem.featureDetails,
          }));

          return (
            <BemAccordionWithExpander
              items={newItems}
              onClick={(index, isInitialIndex) =>
                onClick(index, isInitialIndex)
              }
              activeIndex={currentIndex}
              sx={styles.accordion}
              key={item._key}
              additionalTrackingIdInfo={`accordion${accordionIndex + 1}`}
            />
          );
        })}
      </Grid>
      <Box sx={styles.shadowBox} />
      <Box sx={styles.shadowBox} />
    </>
  );
};

const ItemsOnMobile = (): JSX.Element => {
  const { items, onClick, currentIndex } = useContext(ItemsContext);
  return (
    <BemTouchSlider
      items={items}
      itemRenderer={(data, itemIndex) =>
        getMobileRenderer({
          item: data.item,
          onClick,
          currentIndex,
          itemIndex,
        })
      }
    />
  );
};
const Items = (): JSX.Element | null =>
  useViewportRenderer([
    <ItemsOnMobile key={`${PREFIX}_${VIEWPORTS.MOBILE}`} />,
    <ItemsOnTabletOrLarger key={`${PREFIX}_${VIEWPORTS.TABLET}`} />,
    <ItemsOnTabletOrLarger key={`${PREFIX}_${VIEWPORTS.DESKTOP}`} />,
  ]);

const BemM0026 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0026): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  const initialActiveIndex = null;
  const [currentIndex, setCurrentIndex] = useState<number | null>(
    initialActiveIndex
  );

  const onClick = (index: number | null, isInitialIndex = false) => {
    let newIndex = currentIndex && index === currentIndex ? null : index;
    newIndex = isInitialIndex ? index : newIndex;
    setCurrentIndex(newIndex);
  };
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0026-moduleWrapper"
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
          <ItemsContext.Provider value={{ currentIndex, onClick, items }}>
            <Items />
          </ItemsContext.Provider>
        </BemThemeWrapper>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0026, IPropsBemM0026 };
