import { IGraphqlM0145Item } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from 'theme-ui';
import { BemRichtext } from '../../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../../gatsby-plugin-theme-ui/transitions';

const MotionBox = motion(Box);

const styles: IStylesObject = {
  richtext: {
    p: 2,
    '.richtext :is(h1, h2, h3, h4, h5, h6)': {
      pb: 6,
    },
    '.richtext li': {
      my: 1,
    },
  },
  tooltipMobile: {
    gridColumn: '1 / -1',
    p: 5,
    bg: 'gray.2',
    boxShadow: 'bigCardShadow',
    position: 'relative',
    zIndex: 4,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  tooltip: (
    tooltipPositionX: number,
    transformationPercentage: number,
    tooltipPositionY: number
  ) => ({
    position: 'absolute',
    bg: 'gray.2',
    p: 5,
    zIndex: 4,
    cursor: 'default',
    width: ['100vw', '40vw', '25vw'],
    boxShadow: 'bigCardShadow',
    top: [
      `${tooltipPositionY}%`,
      `${tooltipPositionY}%`,
      `${tooltipPositionY}%`,
    ],
    left: [0, `${tooltipPositionX}%`, `${tooltipPositionX}%`],
    transform: [
      'none',
      `translate(-${transformationPercentage}%)`,
      `translate(-${transformationPercentage}%)`,
    ],
  }),
};

interface IPropsTooltipMobile {
  item: IGraphqlM0145Item;
}

interface IPropsTooltip extends IPropsTooltipMobile {
  markerRef: React.RefObject<HTMLDivElement>;
  onHoverStart: () => void;
  onHoverEnd: () => void;
}

const TOOLTIP_VISIBLE = 'visible';
const TOOLTIP_HIDDEN = 'hidden';

const animationVariants = {
  [TOOLTIP_VISIBLE]: {
    opacity: 1,
    transition: {
      duration: DURATION.MEDIUM,
    },
  },
  [TOOLTIP_HIDDEN]: { opacity: 0 },
};
const animationProps = {
  animate: TOOLTIP_VISIBLE,
  initial: TOOLTIP_HIDDEN,
};

const TooltipOnMobile = ({ item }: IPropsTooltipMobile): JSX.Element => (
  <MotionBox
    variants={animationVariants}
    {...animationProps}
    sx={styles.tooltipMobile}
  >
    <BemRichtext sx={styles.richtext} blocks={item.blocks} />
  </MotionBox>
);

const Tooltip = ({
  item,
  markerRef,
  onHoverEnd,
  onHoverStart,
}: IPropsTooltip): JSX.Element => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipPositionX, setTooltipPositionX] = useState(0);
  const [tooltipPositionY, setTooltipPositionY] = useState(0);
  const [transformationPercentage, setTransformationPercentage] = useState(50);

  const calculateTooltipPosition = (
    tooltipViewportInformations: DOMRect,
    markerViewportInformations: DOMRect
  ) => {
    const { clientWidth } = document.documentElement;
    const windowInnerWidth = window.innerWidth;

    const { x: toolTipXPosition, width: tooltipWidth } =
      tooltipViewportInformations;

    const { width: markerWidth, x: markerXPosition } =
      markerViewportInformations;

    setTooltipPositionX(toolTipXPosition);

    const tooltipHalfWidth = tooltipWidth / 2;
    const tooltipEndOfScreenPosition = markerXPosition + tooltipWidth;
    const percentageBuffer = 2;

    const isLeftOverflow =
      tooltipEndOfScreenPosition < clientWidth &&
      tooltipEndOfScreenPosition < windowInnerWidth &&
      toolTipXPosition < 0;
    const isRightOverflow =
      tooltipEndOfScreenPosition > clientWidth &&
      tooltipEndOfScreenPosition > windowInnerWidth;

    if (!isLeftOverflow && !isRightOverflow) {
      return;
    }

    if (isLeftOverflow) {
      const markerToEdgeDistance = markerXPosition;
      if (markerToEdgeDistance > tooltipHalfWidth) return;
      const percentage =
        (100 / tooltipWidth) * (markerToEdgeDistance - markerWidth);
      setTransformationPercentage(percentage + percentageBuffer);
      return;
    }

    if (isRightOverflow) {
      const markerToEdgeDistance = clientWidth - markerXPosition - markerWidth;
      if (markerToEdgeDistance - markerWidth > tooltipHalfWidth) return;
      const percentage = 100 - (100 / tooltipWidth) * markerToEdgeDistance;
      setTransformationPercentage(percentage + percentageBuffer);
    }
  };

  useEffect(() => {
    if (!tooltipRef?.current || !markerRef.current) {
      return;
    }

    const tooltipViewportInformations =
      tooltipRef?.current?.getBoundingClientRect();

    const markerViewportInformations =
      markerRef.current?.getBoundingClientRect();

    calculateTooltipPosition(
      tooltipViewportInformations,
      markerViewportInformations
    );
    const { positionY, positionX } = item;
    const toolTipYBufferValue = 2.5;
    setTooltipPositionY(positionY + toolTipYBufferValue);

    setTooltipPositionX(positionX);
  }, [tooltipRef]);

  return (
    <MotionBox
      variants={animationVariants}
      {...animationProps}
      onHoverEnd={onHoverEnd}
      onHoverStart={onHoverStart}
      ref={tooltipRef}
      sx={calculatedStyles.tooltip(
        tooltipPositionX,
        transformationPercentage,
        tooltipPositionY
      )}
    >
      <BemRichtext sx={styles.richtext} blocks={item.blocks} />
    </MotionBox>
  );
};

export { TooltipOnMobile, Tooltip, IPropsTooltipMobile, IPropsTooltip };
