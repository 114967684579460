import {
  IGraphqlTranslation,
  TEvent,
  TLongCoords,
  TVisibleEvent,
} from '@bemer/base';
import { WebMercatorViewportOptions } from '@math.gl/web-mercator/src/web-mercator-viewport';
import { useBreakpointIndex } from '@theme-ui/match-media';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { AnimatePresence, motion } from 'framer-motion';
import { GeoJsonProperties } from 'geojson';
import debounce from 'lodash.debounce';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdClose } from 'react-icons/md';
import ReactMapGL, {
  FlyToInterpolator,
  InteractiveMapProps,
  MapRef,
  Marker,
  NavigationControl,
  WebMercatorViewport,
} from 'react-map-gl';
import * as Supercluster from 'supercluster';
import { Box, Button, Checkbox, Flex, Label } from 'theme-ui';
import useSupercluster from 'use-supercluster';
import { BemSpinner } from '../../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../../gatsby-plugin-theme-ui/moduleTypes';
import { VIEWPORT_INDEX } from '../../../hooks/useViewportRenderer';
import { LocalisationContext } from '../../../providers';
import { getTranslation } from '../../../utils/translations';
import { TM0068MachineContext, TM0068MachineEvent } from '../BemM0068.machine';
import countryBoundaries from '../utils/countryBoundaries';
import { TEventCardMachineActorRef } from './EventCard/BemEventCard.machine';
import EventCardDetails from './EventCardDetails';
import Pin from './Marker';

const MAP_BOX_ACCESS_TOKEN =
  process.env.STORYBOOK_MAPBOX_ACCESS_TOKEN ||
  process.env.GATSBY_MAPBOX_ACCESS_TOKEN ||
  '';

const COUNTRY_MAP_PADDING = 100;
const DEFAULT_ZOOM = 12;
const MAX_ZOOM = 15;

const navControlStyle = {
  right: 10,
  top: 10,
  zIndex: 3,
};

const MotionBox = motion(Box);

const styles: IStylesObject = {
  wrapper: {
    '.mapboxgl-marker.active, .marker': {
      cursor: 'pointer',
    },
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  loadingOverlay: {
    bg: 'transparent',
    height: '100%',
    width: '100%',
  },
  topbarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    px: [2, 3, 4],
    py: [2, 3, 4],
    width: 'auto',
    zIndex: 2,
    alignItems: 'center',
  },
  loaderWrapper: {
    bg: 'white',
    px: [2, 3, 4],
    py: 2,
    boxShadow: 'mapBoxControlsShadow',
    borderRadius: 1,
  },
  closeButton: {
    bg: 'white',
    display: ['inline-block', 'none', 'none'],
    py: 2,
    px: [1, 2, 2],
    mr: 2,
    color: 'text',
    lineHeight: 0,
    width: 'auto',

    svg: {
      height: '24px',
      width: '24px',
    },

    boxShadow: 'mapBoxControlsShadow',
    borderRadius: 1,
  },
  searchWrapper: {
    pointerEvents: 'initial',
    bg: 'white',
    px: 2,
    py: 2,
    width: 'auto',
    marginRight: 10,
    boxShadow: 'mapBoxControlsShadow',
    borderRadius: 1,
    alignItems: 'center',
  },
  popup: {
    position: 'absolute',
    bottom: 0,
    p: 4,
    width: '100%',
    zIndex: 2,
  },
  eventCardDetails: {
    bg: 'white',
    width: '100%',
    p: 4,
    boxShadow: 'cardShadow',
    cursor: 'pointer',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  cluster: (clusterProportion: number) => ({
    bg: 'primary.7',
    color: 'white',
    borderRadius: 'full',
    p: 4,
    width: `${20 + clusterProportion * 40}px`,
    height: `${20 + clusterProportion * 40}px`,
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

const animationVariants = {
  popup: {
    hidden: {
      transform: 'translateY(100%)',
      transition: {
        animation: 'ease-in-out',
      },
    },
    visible: {
      transform: 'translateY(0%)',
      transition: {
        animation: 'ease-in-out',
      },
    },
  },
};

const getClosestEvent = (location: TLongCoords, events: TEvent[]) => {
  const from = point([location.longitude, location.latitude]);
  const distances = events.map((event) => {
    const to = point([event.venue.lng, event.venue.lat]);

    return distance(from, to);
  });
  const indexOfClosestEvent = distances.indexOf(Math.min(...distances));

  return events[indexOfClosestEvent];
};

interface IMap {
  events: TVisibleEvent<TEventCardMachineActorRef>[];
  coords: TLongCoords;
  // TODO: this should be defined in xstate somewhere, somehow
  current: {
    context: TM0068MachineContext;
    matches: (state: string) => boolean;
  };
  send: (event: TM0068MachineEvent) => void;
  T: IGraphqlTranslation[];
}

const Map = ({ events, current, send, coords, T }: IMap): JSX.Element => {
  const mapRef = useRef<MapRef>(null);
  const context = current.context as TM0068MachineContext;
  const [hasInitializedBoundaries, setHasInitializedBoundaries] =
    useState(false);

  const [viewport, setViewport] = useState<InteractiveMapProps>({
    height: '100%',
    width: '100%',
    zoom: DEFAULT_ZOOM,
    maxZoom: MAX_ZOOM,
  });

  const [popupInfo, setPopupInfo] = useState<TEvent | null>(null);

  const { locale } = useContext(LocalisationContext);

  const initViewport = () => {
    const { countryCode } = locale;
    const boundaries = countryBoundaries[countryCode][1] as number[];

    const { longitude, latitude, zoom } = new WebMercatorViewport(
      viewport as WebMercatorViewportOptions
    ).fitBounds(
      [
        [boundaries[0], boundaries[1]],
        [boundaries[2], boundaries[3]],
      ],
      {
        padding: COUNTRY_MAP_PADDING,
      }
    );

    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom,
    });
  };

  useEffect(() => {
    if (!events || !current.matches('idle')) return;
    if (!hasInitializedBoundaries) {
      initViewport();
      setHasInitializedBoundaries(true);
    }
  }, [current]);

  const updateMapMove = useCallback(
    debounce(() => {
      send({
        type: 'MAP_MOVE',
        bounds: {
          // eslint-disable-next-line no-underscore-dangle
          topLeft: mapRef?.current?.getMap().getBounds()._ne,
          // eslint-disable-next-line no-underscore-dangle
          bottomRight: mapRef?.current?.getMap().getBounds()._sw,
        },
      });
    }, 200),
    []
  );

  useEffect(() => {
    updateMapMove();
  }, [mapRef, viewport]);

  useEffect(() => {
    if (hasInitializedBoundaries) {
      initViewport();
    }
  }, [current.matches('resetMap')]);

  useEffect(() => {
    if (!(coords?.longitude || coords?.latitude)) {
      return;
    }

    const closestEvent = getClosestEvent(coords, current.context.events);
    const distanceBetweenClosestEventAndSearchResult = {
      longitude: Math.abs(
        Math.abs(closestEvent.venue.lng) - Math.abs(coords.longitude)
      ),
      latitude: Math.abs(
        Math.abs(closestEvent.venue.lat) - Math.abs(coords.latitude)
      ),
    };
    const longitudeModifier =
      closestEvent.venue.lng <= coords.longitude ? 1 : -1;
    const latitudeModifier = closestEvent.venue.lat <= coords.latitude ? 1 : -1;
    const oppositePointToSearchResult: [number, number] = [
      coords.longitude +
        distanceBetweenClosestEventAndSearchResult.longitude *
          longitudeModifier,
      coords.latitude +
        distanceBetweenClosestEventAndSearchResult.latitude * latitudeModifier,
    ];
    const { longitude, latitude, zoom } = new WebMercatorViewport(
      viewport as WebMercatorViewportOptions
    ).fitBounds(
      [
        [closestEvent.venue.lng, closestEvent.venue.lat],
        oppositePointToSearchResult,
      ],
      {
        padding: COUNTRY_MAP_PADDING,
      }
    );

    setViewport({
      ...viewport,
      transitionDuration: 'auto',
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      longitude,
      latitude,
      zoom,
    });
  }, [coords]);

  const handleResize = () => {
    setViewport({
      ...viewport,
      zoom: 2,
      width: '100%',
      height: '100%',
    });
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const points: Array<Supercluster.PointFeature<GeoJsonProperties>> =
    events.map(({ event, cardRef }) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [event.venue.lng, event.venue.lat],
      },
      properties: {
        cluster: false,
        event,
        cardRef,
      },
    }));

  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : undefined;

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom || DEFAULT_ZOOM,
    bounds,
    options: {
      radius: 50,
      maxZoom: 20,
    },
  });

  type TCluster = Supercluster.PointFeature<GeoJsonProperties>;

  const handleClusterClick = (cluster: TCluster) => {
    const [longitude, latitude] = cluster.geometry.coordinates;

    const expansionZoom = supercluster
      ? Math.min(
          supercluster.getClusterExpansionZoom(cluster?.properties?.cluster_id),
          10
        )
      : DEFAULT_ZOOM;

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: expansionZoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      transitionDuration: 'auto',
    });
  };

  const onPinClick = (event: TEvent) => {
    setPopupInfo(event.id === popupInfo?.id ? null : event);
  };

  const isMobileView = useBreakpointIndex() < VIEWPORT_INDEX.TABLET;

  return (
    <Box sx={styles.wrapper}>
      {current.matches('loading') ? <Box sx={styles.loadingOverlay} /> : null}
      <Flex sx={styles.topbarWrapper}>
        {!current.matches('idle') && !current.matches('delay') ? (
          <Box sx={styles.loaderWrapper}>
            <BemSpinner size="small" />
          </Box>
        ) : (
          <>
            <Button
              sx={styles.closeButton}
              onClick={() => send({ type: 'HIDE_MAP' })}
            >
              <MdClose />
            </Button>
            <Label sx={styles.searchWrapper} variant="text.small">
              <Checkbox
                checked={context.shouldUpdateEventsOnMapMove}
                onChange={() =>
                  send({ type: 'TOGGLE_SHOULD_UPDATE_EVENTS_ON_MAP_MOVE' })
                }
              />
              {getTranslation('SEARCH_WHEN_MAP_IS_MOVED_LABEL', T)}
            </Label>
          </>
        )}
      </Flex>
      <ReactMapGL
        {...viewport}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        onViewportChange={(nextViewport: InteractiveMapProps) => {
          // TODO: debounce this, state does not tell you wenn the drag has ended
          // debounce(setViewport(nextViewport), 500)
          setViewport(nextViewport);
          setPopupInfo(null);
        }}
        onClick={() => setPopupInfo(null)}
        mapboxApiAccessToken={MAP_BOX_ACCESS_TOKEN}
        ref={mapRef}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const properties = cluster.properties || {};
          const {
            cluster: isCluster,
            point_count: pointCount,
            event,
            cardRef,
          } = properties;

          const clusterProportion = pointCount / points.length;

          if (isCluster) {
            return (
              <Marker
                key={cluster.id}
                longitude={longitude}
                latitude={latitude}
              >
                <Flex
                  sx={calculatedStyles.cluster(clusterProportion)}
                  onClick={() => handleClusterClick(cluster)}
                >
                  {pointCount}
                </Flex>
              </Marker>
            );
          }

          return (
            <Pin
              key={`marker-${event.id}`}
              event={event}
              cardRef={cardRef}
              onClick={onPinClick}
            />
          );
        })}

        <NavigationControl style={navControlStyle} />

        <AnimatePresence>
          {popupInfo && isMobileView ? (
            <MotionBox
              sx={styles.popup}
              initial={animationVariants.popup.hidden}
              animate={animationVariants.popup.visible}
              exit={animationVariants.popup.hidden}
            >
              <EventCardDetails
                event={popupInfo}
                T={T}
                sx={styles.eventCardDetails}
              />
            </MotionBox>
          ) : null}
        </AnimatePresence>
      </ReactMapGL>
    </Box>
  );
};

export { Map };
