import {
  IGraphqlM0025,
  IGraphqlM0025Item,
  TAdditionalTrackingIdInfo,
} from '@bemer/base';
import React from 'react';
import { AspectRatio, Box, Grid } from 'theme-ui';
import { aspectRatios, RATIOS } from '../../../../base';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0025 extends IGraphqlM0025 {}

const PREFIX = 'M0025';

const styles: IStylesObject = {
  heading: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  item: {
    gridColumn: ['1 / -1', 'span 6', 'span 3'],
    border: '2px solid',
    borderColor: 'gray.2',
    height: '100%',
    position: 'relative',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'hidden',

    '&:last-of-type': {
      border: 'none',
    },
  },
  itemImage: {
    px: 4,
    pt: 12,
  },
  lastItemImage: {
    position: 'absolute',
    width: '100%',
    border: 'none',
  },
  itemHeading: {
    gridColumn: '1 / -1',
    py: 10,
    px: 4,
    textAlign: 'center',
    alignSelf: 'center',
    zIndex: 1,
  },
};

interface IPropsBemM0025Items {
  items: IGraphqlM0025Item[];
}
interface IPropsBemM0025Item {
  item: IGraphqlM0025Item;
}

interface IPropsBemM0025ItemTrackingIdInfo extends IPropsBemM0025Item {
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const ItemHeading = ({ item }: IPropsBemM0025Item): JSX.Element => (
  <BemHeading
    as="h3"
    variant="h6"
    sx={styles.itemHeading}
    className="heading"
    // TODO: quick fixes for having videos and video sets
    themeName={
      item.media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(item.media[0]).themeName
        : item.media[0].themeName
    }
  >
    {item.link[0].label}
  </BemHeading>
);

const getLastItemKey = (items: IGraphqlM0025Item[]): string => {
  const numberOfItems = items.length;
  return items[numberOfItems - 1]._key;
};

const LastItem = ({
  item,
  additionalTrackingIdInfo,
}: IPropsBemM0025ItemTrackingIdInfo): JSX.Element => (
  <BemThemeWrapper
    themeName={
      // TODO: refactor quick fixes for having videos, images and video sets
      item.media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(item.media[0]).themeName
        : item.media[0].themeName
    }
  >
    <BemLink
      to={item.link}
      sx={styles.item}
      additionalTrackingIdInfo={additionalTrackingIdInfo}
      data-testid={`BemM0025-${item._key}`}
    >
      <>
        {/* This is an invisible placeholder for the last item to keep all items always the same height */}
        <Box sx={styles.itemImage}>
          <AspectRatio ratio={1} />
        </Box>
        <BemMedia
          media={item.media[0]}
          sx={styles.lastItemImage}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_2_3].ratio}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />
      </>
      <ItemHeading item={item} />
    </BemLink>
  </BemThemeWrapper>
);

const Item = ({
  item,
  additionalTrackingIdInfo,
}: IPropsBemM0025ItemTrackingIdInfo): JSX.Element => (
  <BemLink
    to={item.link}
    sx={styles.item}
    data-testid={`BemM0025-${item._key}`}
    additionalTrackingIdInfo={additionalTrackingIdInfo}
  >
    <BemMedia
      media={item.media[0]}
      sx={styles.itemImage}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      additionalTrackingIdInfo={additionalTrackingIdInfo}
    />
    <ItemHeading item={item} />
  </BemLink>
);

const renderMobileItem = (
  { item }: IPropsBemM0025Item,
  lastItemKey: string,
  index: number
) =>
  item._key === lastItemKey ? (
    <LastItem item={item} additionalTrackingIdInfo={index + 1} />
  ) : (
    <Item item={item} additionalTrackingIdInfo={index + 1} />
  );

const ItemsOnTabletOrLarger = ({
  items,
}: IPropsBemM0025Items): JSX.Element | null => {
  const lastItemKey = getLastItemKey(items);
  return (
    <>
      {items.map((item, index) =>
        lastItemKey === item._key ? (
          <LastItem
            key={item._key}
            item={item}
            additionalTrackingIdInfo={index + 1}
          />
        ) : (
          <Item
            key={item._key}
            item={item}
            additionalTrackingIdInfo={index + 1}
          />
        )
      )}
    </>
  );
};

const BemM0025 = ({ title, items }: IPropsBemM0025): JSX.Element => {
  const lastItemKey = getLastItemKey(items);

  return (
    <BemModuleWrapper>
      <Grid variant="contentGrid">
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        {useViewportRenderer([
          <BemTouchSlider
            items={items}
            itemRenderer={(data, index) =>
              renderMobileItem(data, lastItemKey, index)
            }
            key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          />,
          <ItemsOnTabletOrLarger
            items={items}
            key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          />,
          <ItemsOnTabletOrLarger
            items={items}
            key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          />,
        ])}
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0025, IPropsBemM0025 };
