import { useMachine } from '@xstate/react';
import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { debugXState } from '../../utils/xStateHelper';
import { BemButton } from '../Button';
import { bemPaginationMachine } from './BemPagination.machine';

const ANIMATION_DIRECTION_FORWARD = 'forward';
const ANIMATION_DIRECTION_BACKWARD = 'backward';

type TAnimationDirection =
  | typeof ANIMATION_DIRECTION_FORWARD
  | typeof ANIMATION_DIRECTION_BACKWARD;

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const styles: IStylesObject = {
  button: {
    width: 14,
    height: 14,
    p: [0, 0, 0],
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':disabled': {
      ':hover': {
        cursor: 'default',
      },
    },
  },
  infoText: {
    display: 'inline-block',
    pl: 8,
  },
};

interface IPropsPagination {
  onClick: (direction: TAnimationDirection, activeItemKey: string) => void;
  items: any[];
  visibleItems?: number;
  activeItemKey?: string;
  sx?: ThemeUIStyleObject;
  isCarousel?: boolean;
  withText?: boolean;
}

const BemPagination = ({
  onClick,
  items = [],
  visibleItems = 1,
  activeItemKey,
  sx,
  isCarousel = true,
  withText = true,
}: IPropsPagination): JSX.Element => {
  const [current, send] = useMachine(bemPaginationMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      items,
      activeIndex: items.findIndex(
        (item) => item._key === (activeItemKey || items?.[0]._key)
      ),
      isCarousel,
      visibleItems,
    },
  });

  const totalNumberOfItems = items.length;
  const text = `${current.context.activeIndex + 1} / ${totalNumberOfItems}`;
  const handleButtonClick = (direction: TAnimationDirection) => {
    let currentState;
    if (direction === ANIMATION_DIRECTION_FORWARD) {
      currentState = send({ type: 'forwardButton' });
    } else {
      currentState = send({ type: 'backwardButton' });
    }
    onClick(direction, items[currentState.context.activeIndex]._key);
  };

  return (
    <Box sx={sx}>
      <BemButton
        type="button"
        sx={styles.button}
        onClick={() => handleButtonClick(ANIMATION_DIRECTION_BACKWARD)}
        data-testid="BemPagination-backwardButton"
        additionalTrackingIdInfo="BemPagination-backwardButton"
        disabled={current.context.isBackwardButtonDisabled}
        variant="buttons.pagination"
      >
        <FaAngleLeft />
      </BemButton>
      <BemButton
        type="button"
        sx={styles.button}
        onClick={() => handleButtonClick(ANIMATION_DIRECTION_FORWARD)}
        data-testid="BemPagination-forwardButton"
        additionalTrackingIdInfo="BemPagination-forwardButton"
        disabled={current.context.isForwardButtonDisabled}
        variant="buttons.pagination"
      >
        <FaAngleRight />
      </BemButton>
      {withText && (
        <Box
          as="span"
          sx={styles.infoText}
          data-testid="BemPagination-paginationCounter"
        >
          {text}
        </Box>
      )}
    </Box>
  );
};

export {
  BemPagination,
  TAnimationDirection,
  ANIMATION_DIRECTION_FORWARD,
  ANIMATION_DIRECTION_BACKWARD,
  IPropsPagination,
};
