import {
  BEMER_EVENT_PORTAL_URL,
  IGraphqlTranslation,
  TEvent,
} from '@bemer/base';
import { useActor } from '@xstate/react';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { ICalculatedStylesObject } from '../../../../gatsby-plugin-theme-ui/moduleTypes';
import { useTheme } from '../../../../gatsby-plugin-theme-ui/utils/useTheme';
import { useGetPartnerData } from '../../../../hooks/useGetPartnerData';
import { LocalisationContext } from '../../../../providers';
import { isEventAdvertised } from '../../utils/events';
import EventCardDetails from '../EventCardDetails';
import { TEventCardMachineActorRef } from './BemEventCard.machine';

const MotionBox = motion(Box);

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (isAdvertised) => ({
    bg: isAdvertised ? 'primary.0' : 'white',
    p: [4, 4, 6],
    pt: [2, 2, 4],
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: isAdvertised ? 'primary.0' : 'white',
    boxShadow: 'smallCardShadow',
    label: isAdvertised ? 'advertised' : 'normal',
    cursor: 'pointer',
    outlineColor: 'secondary',
  }),
};

interface IPropsBemEventCard {
  event: TEvent;
  cardRef: TEventCardMachineActorRef;
  T: IGraphqlTranslation[];
  sx?: ThemeUIStyleObject;
}

const BemEventCard = ({
  event,
  cardRef,
  T,
  sx,
}: IPropsBemEventCard): JSX.Element | null => {
  const [current, send] = useActor(cardRef);
  const { id } = event;
  const { locale } = useContext(LocalisationContext);
  const partnerData = useGetPartnerData();

  const {
    theme: { colors },
  } = useTheme();

  const isAdvertised = isEventAdvertised(event, partnerData);

  const animationVariants = {
    card: {
      isActive: {
        transform: 'scale(1.03, 1.03)',
        borderColor: colors.secondary['6'],
      },
      isInactive: {
        transform: 'scale(1, 1)',
      },
    },
  };

  const goToDetailPage = () => {
    const DETAIL_PAGE_URL = `${BEMER_EVENT_PORTAL_URL}/${locale.id}/events/${id}`;
    // eslint-disable-next-line no-restricted-globals
    if (window) {
      window.open(DETAIL_PAGE_URL, '_blank');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      goToDetailPage();
    }
  };

  const sendMouseEnterEvent = () => send({ type: 'MOUSE_ENTER', eventId: id });
  const sendMouseLeaveEvent = () => send({ type: 'MOUSE_LEAVE' });

  return (
    <MotionBox
      id={`eventCard-${id}`}
      role="button"
      tabIndex={0}
      initial="isInactive"
      sx={{ ...calculatedStyles.wrapper(isAdvertised), ...sx }}
      animate={current.context.isActive ? 'isActive' : 'isInactive'}
      variants={animationVariants.card}
      onMouseEnter={sendMouseEnterEvent}
      onMouseLeave={sendMouseLeaveEvent}
      onFocus={sendMouseEnterEvent}
      onBlur={sendMouseLeaveEvent}
      onKeyDown={handleKeyDown}
    >
      <EventCardDetails event={event} T={T} />
    </MotionBox>
  );
};

export { BemEventCard, IPropsBemEventCard };
