import { aspectRatios, IGraphqlM0133, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0133 extends IGraphqlM0133 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  title: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  item: {
    gridColumn: ['span 12', 'span 6', 'span 4'],
    pb: [MOBILE_MEDIA_PB, 10, 12],

    display: ['grid', 'block', 'block'],
    gridTemplateColumns: ['repeat(12, 1fr)', 'none', 'none'],
    gap: defaultGridGap,
    rowGap: 0,
  },

  itemContentWrapper: {
    gridColumn: ['span 7', 'none', 'none'],
  },
  itemTitle: {
    pb: 4,
  },
  itemImage: {
    pb: [0, 6, 8],
    gridColumn: ['span 5', 'none', 'none'],
  },
  itemText: {
    color: 'textSecondary',
  },
};

const BemM0133 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0133): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0133ModuleWrapper">
        <BemHeading as="h2" variant="h2" sx={styles.title}>
          {title}
        </BemHeading>
        <Grid variant="contentGrid">
          {items.map((item, index) => (
            <Box key={item._key} sx={styles.item} data-testid={item._key}>
              <BemMedia
                media={item.media[0]}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
                sx={styles.itemImage}
                additionalTrackingIdInfo={index + 1}
              />
              <Box sx={styles.itemContentWrapper}>
                <BemHeading as="h3" variant="h4" sx={styles.itemTitle}>
                  {item.title}
                </BemHeading>
                <Text as="p" variant="bodyText" sx={styles.itemText}>
                  {item.text}
                </Text>
              </Box>
            </Box>
          ))}
        </Grid>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0133, IPropsBemM0133 };
