import { IGraphqlInternalLink } from '@bemer/base';
import React from 'react';

interface IPropsBemPathNamesProvider {
  children: React.ReactNode;
  contract: IGraphqlInternalLink[];
}

// Initialize the context with a default value.
const defaultValue: IGraphqlInternalLink[] = [];

const PathNamesContext = React.createContext(defaultValue);

const BemPathNamesProvider = ({
  children,
  contract,
}: IPropsBemPathNamesProvider): JSX.Element => (
  <PathNamesContext.Provider value={contract}>
    {children}
  </PathNamesContext.Provider>
);

export { PathNamesContext, BemPathNamesProvider, IPropsBemPathNamesProvider };
