import { TAdditionalTrackingIdInfo } from '@bemer/base';
import { useMachine } from '@xstate/react';
import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Box, Text, ThemeUIStyleObject } from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { ModuleContext } from '../../providers';
import { debugXState } from '../../utils/xStateHelper';
import { BemButton } from '../Button';
import { BemAccordionWithExpanderMachine } from './BemAccordionWithExpander.machine';

interface IPropsBemAccordionWithExpander {
  items: any;
  sx?: ThemeUIStyleObject;
  handleOnClick?: ((itemKey: number) => void) | null;
  onClick?: ((index: number | null, isInitialIndex: boolean) => void) | null;
  activeIndex?: number | null;
  isOpen?: boolean;
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const MotionText = motion(Text);
const MotionBox = motion(Box);

const styles: IStylesObject = {
  contentSection: {
    gridColumn: ['2 / -2', '7 / span 7', '7 / span 7'],
  },
  itemHeading: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    pr: [12, 14, 20],
    gridColumn: 'span 2',
    py: [8, 12, 12],
  },
  textWrapper: {
    gridColumn: 'span 1',
  },
  itemText: {
    pb: [8, 12, 12],
    pr: [16, 14, 20],
    pt: 1,
    cursor: 'default',
  },
};
const TEXT_OPEN = 'open';
const TEXT_COLLAPSED = 'collapsed';
const animationVariants = {
  textWrapper: {
    [TEXT_OPEN]: {
      height: 'auto',
      transition: {
        delayChildren: DURATION.SHORT,
        animation: 'ease-in-out',
        duration: DURATION.SHORT,
      },
    },
    [TEXT_COLLAPSED]: {
      overflow: 'hidden',
      height: 0,
    },
  },
  text: {
    [TEXT_OPEN]: {
      opacity: 1,
    },
    [TEXT_COLLAPSED]: {
      opacity: 0,
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  featureItem: (index, isItemText) => ({
    gridRow: index + 1,
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    borderBottom: '1px solid',
    borderColor: 'horizontalDivider',
    cursor: isItemText ? 'pointer' : 'default',
  }),
};

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const componentName = 'BemAccordionWithExpander';

const BemAccordionWithExpander = ({
  items,
  sx,
  onClick,
  activeIndex,
  additionalTrackingIdInfo = '',
}: IPropsBemAccordionWithExpander): JSX.Element => {
  const [current, send] = useMachine(BemAccordionWithExpanderMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: { activeElement: activeIndex },
  });
  const { moduleName } = useContext(ModuleContext);
  const trackingId = `${moduleName}-${componentName}${
    additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
  }`;
  const handleOnClick = (index: number | null, isInitialIndex = false) => {
    if (onClick) {
      // If the state is being handled externally like in M0026, we use click handler that's
      // passed instead of the xstate handler
      onClick(index, isInitialIndex);
    } else {
      send({ type: 'click', payload: index });
    }
  };
  useEffect(() => {
    if (activeIndex !== undefined) {
      const isInitialIndex = true;
      handleOnClick(activeIndex, isInitialIndex);
    }
  }, []);
  return items.map((item: any, index: number) => {
    const isOpen = onClick
      ? activeIndex === index
      : current.context.activeElement === index && current.matches('open');
    const boxState = isOpen ? 'open' : 'collapsed';
    return (
      <Box
        key={item._key}
        sx={{ ...calculatedStyles.featureItem(index, !!item.text), ...sx }}
      >
        <Box
          sx={styles.itemHeading}
          data-trackingid={`${trackingId}-panel${index + 1}`}
          onClick={() => item.text && handleOnClick(index)}
        >
          <Text as="p" variant="caption.small" data-testid={item._key}>
            {item.title}
          </Text>
          {item.text ? (
            <BemButton variant="buttons.itemExpander">
              <MotionBox animate={{ rotate: isOpen ? -180 : 0 }}>
                <Box style={{ height: '2rem', width: '2rem' }}>
                  <MdExpandMore size="100%" />
                </Box>
              </MotionBox>
            </BemButton>
          ) : null}
        </Box>
        {item.text ? (
          <MotionBox
            key="content"
            variants={animationVariants.textWrapper}
            initial="collapsed"
            animate={boxState}
            sx={styles.textWrapper}
            data-testid={`${item._key}-test`}
          >
            <MotionText
              as="p"
              variant="text.small"
              variants={animationVariants.text}
              sx={styles.itemText}
            >
              {item.text}
            </MotionText>
          </MotionBox>
        ) : null}
      </Box>
    );
  });
};

export { BemAccordionWithExpander, IPropsBemAccordionWithExpander };
