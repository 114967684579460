import { IGraphqlM0008 } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import { BemLink, BemModuleWrapper, BemThemeWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0008 extends IGraphqlM0008 {}

const styles: IStylesObject = {
  wrapper: {
    py: [8, 12, 14],
  },
  textWrapper: {
    gridColumn: ['3 / -3', '3 / span 5', '3 / span 5'],
    pb: [6, 0, 0],
  },
  linkWrapper: {
    display: 'flex',
    gridColumn: ['3 / -3', '10 / -2', '10 / -2'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    pb: 6,
  },
  link: {
    width: ['100%', 'auto', 'auto'],
  },
};

const BemM0008 = ({
  title,
  text,
  link,
  theme: moduleTheme,
}: IPropsBemM0008): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        isFullWidth
        sx={styles.wrapper}
        data-testid="BemM0008-bemModuleWrapper"
      >
        <Box sx={styles.textWrapper} data-testid="BemM0008-textWrapper">
          <Text as="p" variant="caption.big" sx={styles.title}>
            {title}
          </Text>
          <Text as="p" variant="caption.small">
            {text}
          </Text>
        </Box>
        {link?.length ? (
          <Box sx={styles.linkWrapper} data-testid="BemM0008-link">
            <BemLink to={link} sx={styles.link} variant="links.buttonPrimary" />
          </Box>
        ) : null}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0008, IPropsBemM0008 };
