import { XSTATE_INSPECT_URL } from '@bemer/base';
import { inspect } from '@xstate/inspect';

const debugXState = (isEnabled: boolean): void => {
  if (
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    // @ts-ignore
    !window.Cypress &&
    isEnabled
  ) {
    inspect({
      url: XSTATE_INSPECT_URL,
      iframe: false,
    });
  }
};

export { debugXState };
