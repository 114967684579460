import { aspectRatios, IGraphqlM0138, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  ON_MEDIA_HEADLINE_V1_PB,
  ON_MEDIA_TEXT_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0138 extends IGraphqlM0138 {}

const styles: IStylesObject = {
  image: {
    gridColumn: '1 / -1',
    gridRow: '1',
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  textWrapper: {
    gridRow: ['2', '1', '1'],
    gridColumn: ['2 / -2', '2 / -2', '9 / -2'],
    zIndex: '1',
    alignSelf: 'center',
  },
  title: {
    pb: ON_MEDIA_HEADLINE_V1_PB,
  },
  text: {
    pb: ON_MEDIA_TEXT_V1_PB,
  },
};

const BemM0138 = ({
  title,
  text,
  link,
  media,
}: IPropsBemM0138): JSX.Element => (
  <BemModuleWrapper>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_2_1].ratio}
      sx={styles.image}
    />
    <BemThemeWrapper
      themeName={[
        THEME_NAMES.DARK_TEXT,
        // TODO: refactor quick fixes for having videos, images and video sets
        media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(media[0]).themeName
          : media[0].themeName,
        media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(media[0]).themeName
          : media[0].themeName,
      ]}
    >
      <Box sx={styles.textWrapper} data-testid="M0138-textBox">
        <BemHeading
          as="h2"
          variant="h1"
          sx={styles.title}
          themeName={[
            THEME_NAMES.DARK_TEXT,
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
          ]}
        >
          {title}
        </BemHeading>
        <Text as="p" variant="bodyText" sx={styles.text}>
          {text}
        </Text>
        {link?.length ? (
          <BemLink
            to={link}
            variant="links.buttonPrimary"
            data-testid="BemM0138Link"
          />
        ) : null}
      </Box>
    </BemThemeWrapper>
  </BemModuleWrapper>
);

export { BemM0138, IPropsBemM0138 };
