import { aspectRatios, IGraphqlM0022, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  ON_MEDIA_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0022 extends IGraphqlM0022 {}

const styles: IStylesObject = {
  image: {
    gridColumn: '1 / -1',
    gridRow: 1,
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  textWrapper: {
    gridColumn: ['2 / -2', '3 / span 8', '2 / span 5'],
    zIndex: 1,
    gridRow: [2, 1, 1],
    alignSelf: ['center', 'center', 'normal'],
  },
  heading: {
    pt: [0, 0, 32],
    pb: ON_MEDIA_HEADLINE_V2_PB,
  },
};

const BemM0022 = ({ title, media, text }: IPropsBemM0022): JSX.Element => (
  <BemThemeWrapper
    themeName={[
      THEME_NAMES.DARK_TEXT,
      // TODO: refactor quick fixes for having videos, images and video sets
      media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(media[0]).themeName
        : media[0].themeName,
      media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(media[0]).themeName
        : media[0].themeName,
    ]}
  >
    <BemModuleWrapper>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_3].ratio}
        sx={styles.image}
      />
      <Box sx={styles.textWrapper} data-testid="BemM0022-textWrapper">
        <BemHeading
          as="h3"
          variant="h3"
          sx={styles.heading}
          themeName={[
            THEME_NAMES.DARK_TEXT,
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
          ]}
        >
          {title}
        </BemHeading>
        {text ? (
          <Text as="p" variant="caption.small" data-testid="BemM0022Text">
            {text}
          </Text>
        ) : null}
      </Box>
    </BemModuleWrapper>
  </BemThemeWrapper>
);

export { BemM0022, IPropsBemM0022 };
