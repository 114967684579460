import { ContextFrom, EventFrom, State } from 'xstate';
import { createModel } from 'xstate/lib/model';

const EVENTS = {
  PARTNER_FINDER_FINISHED: 'partnerFinderFinished',
  SUCCESSFULLY_SUBMITTED_PARTNER_FORM: 'successfullySubmittedPartnerForm',
  SUCCESSFULLY_SUBMITTED_CONTACT_FORM: 'successfullySubmittedContactForm',
  CLICK_ON_CHANGE_DISTRIBUTOR: 'clickOnChangeDistributor',
} as const;

const STATES = {
  INITIAL: 'initial',
  PARTNER_FINDER_FORM: 'partnerFinderForm',
  CHANGE_PARTNER_PROCESSING: 'changePartnerProcessing',
  CHANGE_PARTNER_PROCESSING_MODAL: 'changePartnerProcessingModal',
  CONTACT_FORM: 'contactForm',
  CONTACT_FORM_SUCCESS: 'contactFormSuccess',
} as const;

const ACTIONS = {
  MUTATE_EMAIL: 'mutateEmail',
} as const;

const m0152Model = createModel(
  {
    email: '',
  },
  {
    events: {
      [EVENTS.PARTNER_FINDER_FINISHED]: (partnerHandle?: string) => ({
        partnerHandle,
      }),
      [EVENTS.SUCCESSFULLY_SUBMITTED_PARTNER_FORM]: () => ({}),
      [EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM]: (email: string) => ({
        email,
      }),
      [EVENTS.CLICK_ON_CHANGE_DISTRIBUTOR]: () => ({}),
    },
  }
);

type TMachineContext = ContextFrom<typeof m0152Model>;
type TMachineEvent = EventFrom<typeof m0152Model>;
type TMachineState = State<TMachineContext, TMachineEvent>;

const m0152Machine = m0152Model.createMachine(
  {
    context: m0152Model.initialContext,
    initial: STATES.INITIAL,
    states: {
      [STATES.INITIAL]: {
        on: {
          [EVENTS.PARTNER_FINDER_FINISHED]: [
            {
              target: STATES.CONTACT_FORM,
              cond: (_, event) => Boolean(event.partnerHandle),
            },
            {
              target: STATES.PARTNER_FINDER_FORM,
            },
          ],
        },
      },
      [STATES.PARTNER_FINDER_FORM]: {
        on: {
          [EVENTS.SUCCESSFULLY_SUBMITTED_PARTNER_FORM]: {
            target: STATES.CHANGE_PARTNER_PROCESSING_MODAL,
          },
        },
      },
      [STATES.CONTACT_FORM]: {
        on: {
          [EVENTS.CLICK_ON_CHANGE_DISTRIBUTOR]: {
            target: STATES.PARTNER_FINDER_FORM,
          },
          [EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM]: {
            actions: ACTIONS.MUTATE_EMAIL,
            target: STATES.CONTACT_FORM_SUCCESS,
          },
        },
      },
      [STATES.CHANGE_PARTNER_PROCESSING_MODAL]: {
        // This is a final state and can not be changed.
      },
      [STATES.CONTACT_FORM_SUCCESS]: {
        on: {},
      },
    },
  },
  {
    actions: {
      [ACTIONS.MUTATE_EMAIL]: m0152Model.assign(
        {
          email: (_, event) => event.email,
        },
        EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM
      ),
    },
  }
);

export {
  m0152Model,
  m0152Machine,
  TMachineContext,
  TMachineEvent,
  TMachineState,
  STATES,
};
