import { aspectRatios, IGraphqlM0124, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0124 extends IGraphqlM0124 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
    label: 'heroStartsWithText',
  },
  text: {
    gridColumn: '4 / -4',
    pb: TOP_HEADLINE_V1_PB,
    textAlign: 'center',
  },
  subtitle: {
    gridColumn: '2 / -2',
    pb: [8, 10, 12],
  },
  item: {
    gridColumn: ['2 / -2', 'span 6', 'span 6'],
    '&:first-of-type': {
      gridColumn: ['2 / -2', '2 / span 6', '2 / span 6'],
    },
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
  },
  image: {
    pb: [MOBILE_MEDIA_PB, 8, 8],
  },
  itemTitle: {
    pb: 6,
    color: 'textMuted',
  },
};

const BemM0124 = ({
  title,
  text,
  subtitle,
  items,
}: IPropsBemM0124): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h1" sx={styles.heading}>
      {title}
    </BemHeading>
    {text ? (
      <Text as="p" sx={styles.text}>
        {text}
      </Text>
    ) : null}
    <BemHeading as="h2" variant="h3" sx={styles.subtitle}>
      {subtitle}
    </BemHeading>
    {items.map((item, index) => (
      <BemLink
        to={item.link}
        key={item._key}
        sx={styles.item}
        data-testid={item._key}
        additionalTrackingIdInfo={index + 1}
      >
        <Box>
          <BemMedia
            media={item.media[0]}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
            sx={styles.image}
            additionalTrackingIdInfo={index + 1}
          />
          <Text variant="bodyText" as="p" sx={styles.itemTitle}>
            {item.title}
          </Text>
          <Text variant="bodyText" as="p">
            {item.text}
          </Text>
        </Box>
      </BemLink>
    ))}
  </BemModuleWrapper>
);

export { BemM0124, IPropsBemM0124 };
