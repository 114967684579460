import { aspectRatios, IGraphqlM0071, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { GRID_GAP_MOBILE } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0071 extends IGraphqlM0071 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  video1: {
    gridColumn: ['2 / -2', '3 / span 7', '3 / span 7'],
    pb: [GRID_GAP_MOBILE, 0, 0],
    '&& > *': {
      height: '100%',
    },
  },
  image1: {
    gridColumn: ['2 / -2', 'span 3', 'span 3'],
    pb: [GRID_GAP_MOBILE, 0, 0],
    '&& > *': {
      height: '100%',
    },
  },
  image2: {
    gridColumn: ['2 / -2', '3 / span 5', '3 / span 5'],
    pb: [GRID_GAP_MOBILE, 0, 0],
    '&& > *': {
      height: '100%',
    },
  },
  video2: {
    gridColumn: ['2 / -2', 'span 5', 'span 5'],
    pb: [GRID_GAP_MOBILE, 0, 0],
    '&& > *': {
      height: '100%',
    },
  },
};

const BemM0071 = ({
  title,
  media3,
  media1,
  media4,
  media2,
  theme: moduleTheme,
}: IPropsBemM0071): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0071-bemModuleWrapper"
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        <BemMedia
          media={media3[0]}
          testId="BemCloudVideo1"
          sx={styles.video1}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          additionalTrackingIdInfo={1}
        />
        <BemMedia
          sx={styles.image1}
          media={media1[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_2_3].ratio}
          additionalTrackingIdInfo={2}
          testId="BemCloudImage1"
        />
        <BemMedia
          sx={styles.image2}
          media={media2[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
          additionalTrackingIdInfo={3}
          testId="BemCloudImage2"
        />
        <BemMedia
          media={media4[0]}
          testId="BemCloudVideo2"
          sx={styles.video2}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
          additionalTrackingIdInfo={4}
        />
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0071, IPropsBemM0071 };
