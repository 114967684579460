import { IGraphqlM0123 } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import { aspectRatios, RATIOS } from '../../../../base';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_TEXT_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0123 extends IGraphqlM0123 {}

const styles: IStylesObject = {
  headingWrapper: {
    gridColumn: ['2 / -2', '2 / span 4', '2 / span 3'],
    alignSelf: 'center',
    gridRow: [3, 'auto', 'auto'],
  },
  video: {
    pb: [MOBILE_MEDIA_PB, 10, 10],
    gridColumn: ['2 / -2', '6 / -2', '6 / -2'],
    gridRow: [1, 'auto', 'auto'],
  },
  text: {
    color: 'textMuted',
    gridColumn: ['2 / -2', '6 / -2', '6 / -2'],
    gridRow: [2, 'auto', 'auto'],
    pb: [MOBILE_MODULE_TEXT_PB, 0, 0],
  },
};

const BemM0123 = ({
  title,
  link,
  text,
  media,
}: IPropsBemM0123): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.headingWrapper}>
      <BemHeading
        as="h2"
        variant="h2WithSeparatorForHeadlineWithoutPaddingBottom"
      >
        {title}
      </BemHeading>
      {link?.length ? (
        <BemActionArea data-testid="BemM0123Link">
          <BemLink to={link} variant="links.buttonPrimary" />
        </BemActionArea>
      ) : null}
    </Box>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_3].ratio}
      sx={styles.video}
      autoplayPreview
    />
    <Text as="p" variant="tiny" sx={styles.text}>
      {text}
    </Text>
  </BemModuleWrapper>
);

export { BemM0123, IPropsBemM0123 };
