import { IGraphqlVideo, TAdditionalTrackingIdInfo } from '@bemer/base';
import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { Box, Text } from 'theme-ui';
import { BemCloudVideo } from '../../components';
import { BemButton } from '../../components/Button';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsVideoItem {
  video: IGraphqlVideo;
  title: string;
  current: any;
  additionalTrackingIdInfo: TAdditionalTrackingIdInfo;
}

const styles: IStylesObject = {
  panelContentVideoWrapper: {
    gridColumn: [null, 'span 6', 'span 3'],
  },
};

const VideoItem = ({
  video,
  title,
  current,
  additionalTrackingIdInfo,
}: IPropsVideoItem): JSX.Element => {
  const [playing, setPlaying] = useState(false);

  if (current.value?.productTypeSelected?.videoPanel === 'closed' && playing) {
    setPlaying(false);
  }

  const playButton = (
    <BemButton
      onClick={() => setPlaying(true)}
      variant="buttons.play"
      additionalTrackingIdInfo={additionalTrackingIdInfo}
    >
      <FaPlay />
    </BemButton>
  );

  return (
    <Box key={`${video._key}`} sx={styles.panelContentVideoWrapper}>
      <BemCloudVideo
        playButtonPosition="bottomRight"
        video={video}
        playing={playing}
        playButton={playButton}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
        additionalTrackingIdInfo={additionalTrackingIdInfo}
      />
      <Text as="div" variant="body" sx={{ pt: 5 }}>
        {title}
      </Text>
    </Box>
  );
};

export { VideoItem };
