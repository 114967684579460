import { IGraphqlM0148 } from '@bemer/base';
import React from 'react';
import { Link } from 'theme-ui';
import { BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0148 extends IGraphqlM0148 {}

const styles: IStylesObject = {
  wrapper: {
    mb: 0,
    position: 'relative',
  },
  anchorLink: {
    position: 'absolute',
    top: -24,
  },
};

const BemM0148 = ({ anchor, anchorSuffix }: IPropsBemM0148): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper}>
    <Link
      href="/"
      sx={styles.anchorLink}
      id={`${anchor}${anchorSuffix ? `-${anchorSuffix}` : ''}`}
    />
  </BemModuleWrapper>
);

export { BemM0148, IPropsBemM0148 };
