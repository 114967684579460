import { aspectRatios, IGraphqlM0078, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MOBILE_MODULE_INNER_SECTION_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0078 extends IGraphqlM0078 {}

const styles: IStylesObject = {
  title: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  text: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    textAlign: 'center',
    pb: [MOBILE_HEADLINE_PB, 20, 20],
  },
  item: {
    borderBottom: '1px solid',
    borderBottomColor: 'gray.2',
    '&:first-of-type': {
      pt: 0,
    },
    '&:last-of-type': {
      borderBottom: 'none',
      pb: 0,
    },
    pt: [MOBILE_MODULE_INNER_SECTION_PT, 8, 8],
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 12, 12],
  },
  itemYear: {
    gridColumn: ['1 / span 3', '1 / span 2', '1 / span 2'],
  },
  itemTitleTextWrapper: {
    gridColumn: ['4 / -1', '3 / span 4', '3 / span 4'],
  },
  itemTitleTextWrapperFullWidth: {
    gridColumn: ['4 / -1', '3 / -1', '3 / -1'],
  },
  itemText: {
    pt: 8,
  },
  itemTextLinkWrapper: {
    pt: 8,
    width: ['100%', 'auto', 'auto'],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  image: (isFullWidthImage: boolean, numberOfImages: number) => {
    const gridRow = isMobile() ? '1' : 'auto';
    const spanWidthSingleImage = isMobile() ? 12 : 6;
    const spanWidthImageOfMany = isMobile() ? 6 : 3;
    const spanWidth =
      numberOfImages > 1 ? spanWidthImageOfMany : spanWidthSingleImage;
    const pb = [MOBILE_MEDIA_PB, 0, 0];

    if (isFullWidthImage) {
      return {
        gridColumn: '1 / -1',
        pt: [0, 4, 4],
        gridRow,
        pb,
      };
    }

    return {
      gridColumn: `span ${spanWidth}`,
      gridRow,
      pb,
    };
  },
};

const BemM0078 = ({ title, text, items }: IPropsBemM0078): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h1" sx={styles.title}>
      {title}
    </BemHeading>
    <Text sx={styles.text} variant="caption.small">
      {text}
    </Text>

    {items.map((item, yearIndex) => (
      <Grid
        variant="contentGrid"
        sx={styles.item}
        key={item._key}
        data-testid={item._key}
      >
        <BemHeading as="h2" variant="h2" sx={styles.itemYear}>
          {item.year}
        </BemHeading>
        <Box
          sx={
            item.isFullWidthImage
              ? styles.itemTitleTextWrapperFullWidth
              : styles.itemTitleTextWrapper
          }
          data-testid={`${item._key}-item-title-text-wrapper`}
        >
          <Text as="h3" variant="h4">
            {item.title}
          </Text>
          {item.text ? (
            <Text as="p" variant="bodyText" sx={styles.itemText}>
              {item.text}
            </Text>
          ) : null}
          {item.link?.length ? (
            <Box sx={styles.itemTextLinkWrapper}>
              <BemLink
                to={item.link}
                variant="links.buttonSecondary"
                additionalTrackingIdInfo={`year${yearIndex + 1}`}
                data-testid={`link-${item._key}`}
              />
            </Box>
          ) : null}
        </Box>
        {item.images.map((media, mediaIndex) => (
          <BemMedia
            sx={calculatedStyles.image(
              item.isFullWidthImage,
              item.images.length
            )}
            media={media}
            forcedAspectRatio={
              item.isFullWidthImage
                ? aspectRatios[RATIOS.RATIO_7_3].ratio
                : aspectRatios[RATIOS.RATIO_3_2].ratio
            }
            key={media._key}
            additionalTrackingIdInfo={`year${yearIndex + 1}-media${
              mediaIndex + 1
            }`}
          />
        ))}
      </Grid>
    ))}
  </BemModuleWrapper>
);

export { BemM0078, IPropsBemM0078 };
