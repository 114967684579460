import {
  ICoach,
  IGraphqlTranslation,
  ILocale,
  IPartnerFinderData,
  ISpeaker,
  TEvent,
  TEventTypeFilter,
  TVisibleEvent,
} from '@bemer/base';
import { useContext } from 'react';
import { LocalisationContext } from '../../../providers';
import { getLocalizedAmount } from '../../../utils/localisation';
import { getTranslation } from '../../../utils/translations';
import { TEventCardMachineActorRef } from '../components/EventCard/BemEventCard.machine';

const getIsPartnerEvent = (
  event: TEvent,
  partnerData: IPartnerFinderData | null | undefined
): boolean => {
  const partnerId = partnerData?.partnerData?.customerNo;
  const { coaches, speakers, creator } = event;

  const isPartnerCoach = !!coaches?.find(
    (coach: ICoach) => coach.navisionCustomerId === partnerId
  );
  const isPartnerSpeaker = !!speakers?.find(
    (speaker: ISpeaker) => speaker.navisionCustomerId === partnerId
  );
  const isPartnerCreator = creator?.navisionCustomerId === partnerId;

  return isPartnerCoach || isPartnerSpeaker || isPartnerCreator;
};

const sortByDate = (
  eventList: TVisibleEvent<TEventCardMachineActorRef>[]
): TVisibleEvent<TEventCardMachineActorRef>[] =>
  eventList.sort(
    (
      a: TVisibleEvent<TEventCardMachineActorRef>,
      b: TVisibleEvent<TEventCardMachineActorRef>
    ) =>
      new Date(a.event.dateStartISO8601).getTime() -
      new Date(b.event.dateStartISO8601).getTime()
  );

const isBetween = (min: number, max: number, input: number): boolean =>
  input > min && input < max;

const isEventAfterStartDate = (startDate: string, event: TEvent): boolean => {
  if (startDate === undefined) {
    return true;
  }
  const startDateTimestamp = new Date(startDate).getTime();
  const eventDateTimestamp = new Date(event.dateStartISO8601).getTime();

  return eventDateTimestamp >= startDateTimestamp;
};

const isEventOfEventType = (
  eventType: TEventTypeFilter,
  event: TEvent
): boolean => {
  if (eventType === 'ALL' || eventType === undefined) {
    return true;
  }

  return event.eventType === eventType;
};

const getSortedEvents = (
  items: TVisibleEvent<TEventCardMachineActorRef>[],
  partnerData: IPartnerFinderData | null | undefined
): TVisibleEvent<TEventCardMachineActorRef>[] => {
  const isBemerEvent = (item: TVisibleEvent<TEventCardMachineActorRef>) =>
    item.event.isBemerEvent;
  const isPartnerEvent = (item: TVisibleEvent<TEventCardMachineActorRef>) =>
    getIsPartnerEvent(item.event, partnerData) && !isBemerEvent(item);
  const isNormalEvent = (item: TVisibleEvent<TEventCardMachineActorRef>) =>
    !isPartnerEvent(item) && !isBemerEvent(item);

  const bemerEvents = items.filter(
    (item: TVisibleEvent<TEventCardMachineActorRef>) => isBemerEvent(item)
  );

  const partnerEvents = items.filter(
    (item: TVisibleEvent<TEventCardMachineActorRef>) => isPartnerEvent(item)
  );

  const nonBemerEvents = items.filter(
    (item: TVisibleEvent<TEventCardMachineActorRef>) => isNormalEvent(item)
  );

  return [...bemerEvents, ...partnerEvents, ...nonBemerEvents];
};

const getLocalDateFormatted = (isoDate1: string, isoDate2: string): string => {
  const { locale } = useContext(LocalisationContext);
  const date1 = new Date(isoDate1);
  const date2 = new Date(isoDate2);

  const fmt = new Intl.DateTimeFormat(locale.bcp47Id, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  // @ts-ignore this is odd, formatRange definitly exists
  // It's not part of the latest es5 types:
  // https://github.com/microsoft/TypeScript/blob/v4.4.4/src/lib/es5.d.ts#L4365
  // A guess would be the missing support in IE: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
  return fmt.formatRange(date1, date2);
};

const formatLocation = ({
  country,
  city,
}: {
  country: string;
  city: string;
}): string | null => {
  if (!country && !city) return null;
  if (country) {
    return city ? `${country} \u00B7 ${city}` : country;
  }
  return city || null;
};

const formatPrice = ({
  priceString,
  locale,
  T,
}: {
  priceString: string | null;
  locale: ILocale;
  T: IGraphqlTranslation[];
}): string => {
  const price =
    typeof priceString === 'string' ? priceString.split(' ')[1] : '0.0';

  const labelFree = getTranslation('FREE_EVENT', T);

  return parseFloat(price) === 0.0
    ? labelFree
    : `${getLocalizedAmount(locale, parseFloat(price))}`;
};

const isEventAdvertised = (
  event: TEvent,
  partnerData: IPartnerFinderData | null | undefined
): boolean => {
  const isPartnerEvent = getIsPartnerEvent(event, partnerData);
  return event.isBemerEvent || isPartnerEvent;
};

export {
  getIsPartnerEvent,
  sortByDate,
  getLocalDateFormatted,
  formatLocation,
  formatPrice,
  isEventOfEventType,
  isEventAfterStartDate,
  isBetween,
  getSortedEvents,
  isEventAdvertised,
};
