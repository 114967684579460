import { aspectRatios, IGraphqlM0145, RATIOS } from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useRef } from 'react';
import { Box, Grid } from 'theme-ui';
import { BemBackdrop, BemCloudImage, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { isMobile } from '../../utils/breakpointIndices';
import { debugXState } from '../../utils/xStateHelper';
import { m0145Machine } from './BemM0145.machine';
import { Marker } from './components/BemMarker';
import { Tooltip, TooltipOnMobile } from './components/BemTooltip';

interface IPropsBemM0145 extends IGraphqlM0145 {}

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const styles: IStylesObject = {
  wrapper: {
    gridColumn: '2 / -2',
    position: 'relative',
    gridRow: 1,
  },
  hiddenWrapper: {
    overflow: 'hidden',
    position: 'relative',
    gridColumn: '1 / -1',
    gridRow: 1,
  },
  backdrop: {
    bg: 'transparent',
    zIndex: 2,
  },
  image: {
    gridColumn: '1 / -1',
    position: 'relative',
    gridRow: 1,
  },
};

const BemM0145 = ({ image, items }: IPropsBemM0145): JSX.Element => {
  const [current, send] = useMachine(m0145Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
  });
  const { activeMarker, backdropVisibility } = current.context;
  const onMobile = isMobile();

  const markerAndTooltips = items.map((item, index) => {
    const markerRef = useRef<HTMLDivElement>(null);
    const openTooltipOnHover = () => {
      send({ type: 'mouseEnter', data: { activeMarker: index } });
    };
    const openTooltipOnClick = () => {
      send({ type: 'mouseClickOpen', data: { activeMarker: index } });
    };
    const declineCloseEvent = () => {
      send({ type: 'decline' });
    };
    const closeTooltip = () => {
      send({ type: 'mouseLeave' });
    };

    const tooltip = onMobile ? (
      <TooltipOnMobile item={item} />
    ) : (
      <Tooltip
        onHoverEnd={closeTooltip}
        onHoverStart={declineCloseEvent}
        markerRef={markerRef}
        item={item}
      />
    );
    return {
      marker: (
        <Marker
          markerRef={markerRef}
          onHoverEnd={closeTooltip}
          onHoverStart={openTooltipOnHover}
          onClick={openTooltipOnClick}
          key={`${item._key}-marker`}
          item={item}
        />
      ),
      tooltip: activeMarker === index ? tooltip : null,
    };
  });

  return (
    <>
      <BemModuleWrapper>
        <Grid sx={styles.wrapper}>
          <BemCloudImage
            image={image}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
            sx={styles.image}
          />
          <Box sx={styles.hiddenWrapper}>
            {markerAndTooltips.map(
              (markerAndTooltip) => markerAndTooltip.marker
            )}
          </Box>
          {markerAndTooltips.map(
            (markerAndTooltip) => markerAndTooltip.tooltip
          )}
        </Grid>
      </BemModuleWrapper>
      <BemBackdrop
        state={backdropVisibility}
        sx={styles.backdrop}
        handleClose={() => {
          send({ type: 'mouseClickClose' });
        }}
      />
    </>
  );
};

export { BemM0145, IPropsBemM0145 };
