import { aspectRatios, IGraphqlM0041, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0041 extends IGraphqlM0041 {}

const ITEM_PADDING_BOTTOM_MOBILE = 4;

const styles: IStylesObject = {
  leftBox: {
    gridColumn: ['2 / -2', '2 / span 6', '2 / span 6'],
    gridTemplateColumns: [
      'repeat(12, 1fr)',
      'repeat(6, 1fr)',
      'repeat(6, 1fr)',
    ],
    gap: defaultGridGap,
  },
  rightBox: {
    gridColumn: ['2 / -2', '9 / span 5', '9 / span 5'],
  },
  heading: {
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    pb: [10, 20, 40],
  },
  item: {
    gridColumn: ['1 / -1', 'span 3', 'span 3'],
    gridTemplateColumns: [
      'repeat(12, 1fr)',
      'repeat(3, 1fr)',
      'repeat(3, 1fr)',
    ],
    gap: defaultGridGap,
    pb: [ITEM_PADDING_BOTTOM_MOBILE, 16, 16],
    '&:nth-last-of-type(-n+2)': {
      pb: [ITEM_PADDING_BOTTOM_MOBILE, 0, 0],
    },
    '&:last-of-type': {
      pb: [8, 0, 0],
    },

    gridTemplateRows: 'auto 1fr',
  },
  itemImage: {
    borderRadius: 'full',
    overflow: 'hidden',
    gridColumn: ['span 2', 'span 1', 'span 1'],
    alignSelf: 'flex-start',
  },
  itemText: {
    gridColumn: ['4 / -1', '1 / span 3', '1 / span 3'],
    pt: [0, 4, 8],
  },
};

const BemM0041 = ({ title, media, items }: IPropsBemM0041): JSX.Element => (
  <BemModuleWrapper>
    <Grid sx={styles.leftBox} data-testid="BemM0041-leftBox">
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      {items.map((item) => (
        <Grid sx={styles.item} key={item._key}>
          <BemCloudImage
            image={item.image}
            sx={styles.itemImage}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
          />
          <Text as="p" variant="bodyText" sx={styles.itemText}>
            {item.text}
          </Text>
        </Grid>
      ))}
    </Grid>
    <Box sx={styles.rightBox} data-testid="BemM0041-rightBox">
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_9].ratio}
      />
    </Box>
  </BemModuleWrapper>
);

export { BemM0041, IPropsBemM0041 };
