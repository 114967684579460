import { aspectRatios, IGraphqlM0118, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0118 extends IGraphqlM0118 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: ['2 / -2', '3 / -3', '4 / -4'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  contentItem: {
    alignItems: 'center',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 24, 24],

    '&:last-of-type': {
      pb: 0,
    },
  },
  itemHeading: {
    pb: ASIDE_HEADLINE_PB,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  image: (isEven: boolean) => ({
    gridRow: 1,
    gridColumn: isEven
      ? ['1 / -1', '7 / span 6', '7 / span 6']
      : ['1 / -1', '1 / span 6', '1 / span 6'],
    pb: ASIDE_MEDIA_PB,
  }),
  text: (isEven: boolean) => ({
    gridRow: [2, 1, 1],
    gridColumn: isEven
      ? ['1 / -1', '2 / span 4', '2 / span 4']
      : ['1 / -1', '8 / span 4', '8 / span 4'],
  }),
};

const BemM0118 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0118): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        data-testid="BemM0118-bemModuleWrapper"
        sx={styles.wrapper}
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        {items.map((item, index) => (
          <Grid variant="contentGrid" sx={styles.contentItem} key={item._key}>
            <Box sx={calculatedStyles.text(index % 2)} data-testid={item._key}>
              <BemHeading as="h3" variant="h2" sx={styles.itemHeading}>
                {item.title}
              </BemHeading>
              <Text as="p">{item.text}</Text>
              {item.link && (
                <BemActionArea>
                  <BemLink
                    to={item.link}
                    variant="links.buttonPrimary"
                    additionalTrackingIdInfo={index + 1}
                  />
                </BemActionArea>
              )}
            </Box>
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_6_5].ratio}
              sx={calculatedStyles.image(index % 2)}
              additionalTrackingIdInfo={index + 1}
            />
          </Grid>
        ))}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0118, IPropsBemM0118 };
