import { aspectRatios, IGraphqlM0132, RATIOS } from '@bemer/base';
import React from 'react';
import { BemHeading, BemMedia, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0132 extends IGraphqlM0132 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  video: {
    gridColumn: '2 / -2',
  },
};

const BemM0132 = ({ title, media }: IPropsBemM0132): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h1" sx={styles.heading}>
      {title}
    </BemHeading>
    <BemMedia
      media={media[0]}
      sx={styles.video}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_17_8].ratio}
      autoplayPreview
    />
  </BemModuleWrapper>
);

export { BemM0132, IPropsBemM0132 };
