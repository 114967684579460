import {
  aspectRatios,
  IGraphqlM0055,
  IGraphqlM0055Item,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemRichtext,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  CARD_TEXT_PX,
  INNER_HEADLINE_PB,
  MOBILE_CARD_PADDING,
  MOBILE_FORM_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_TEXT_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TABLET_CARD_PADDING,
  TABLET_MEDIA_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0055 extends IGraphqlM0055 {}

const PREFIX = 'M0055';

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  text: {
    pb: [MOBILE_MODULE_TEXT_PB, 0, 0],
  },
  link: {
    mb: [MOBILE_FORM_PB, 0, 0],
  },
  contentWrapper: {
    gridColumn: ['2 / -2', '2 / span 4', '2 / span 4'],
  },
  item: {
    gridColumn: '7 / -2',
    pb: [MOBILE_CARD_PADDING, TABLET_CARD_PADDING, 16],
    bg: ['background', 'background', 'transparent'],
    '&:last-of-type': {
      pb: [MOBILE_CARD_PADDING, TABLET_CARD_PADDING, 0],
    },
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    height: '100%',
  },
  itemImage: {
    pb: [MOBILE_MEDIA_PB, TABLET_MEDIA_PB, 12],
  },
  itemTitle: {
    pb: INNER_HEADLINE_PB,
    px: CARD_TEXT_PX,
    color: ['bluishGray.8', 'bluishGray.8', 'text'],
  },
  itemText: {
    px: CARD_TEXT_PX,
    color: ['bluishGray.8', 'bluishGray.8', 'text'],
  },
  itemActionArea: {
    px: CARD_TEXT_PX,
  },
  sliderWrapper: { gridColumn: ['2 / -2', '7 / -2', null] },
};

const calculatedStyles: ICalculatedStylesObject = {
  title: (hasText: boolean) => ({
    pb: hasText ? ASIDE_HEADLINE_PB : 0,
  }),
};

interface IPropsItemsOnTabletOrSmaller {
  items: IGraphqlM0055Item[];
}
interface IPropsItem {
  item: IGraphqlM0055Item;
  index: number;
}

const Item = ({ item, index }: IPropsItem) => (
  <Box key={item._key} sx={styles.item} data-testid={item._key}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_2].ratio}
      sx={styles.itemImage}
      additionalTrackingIdInfo={index + 1}
    />
    <BemHeading as="h3" variant="h3" sx={styles.itemTitle}>
      {item.title}
    </BemHeading>
    <BemRichtext blocks={item.blocks} sx={styles.itemText} />
    {item.link?.length ? (
      <BemActionArea sx={styles.itemActionArea}>
        <BemLink
          to={item.link}
          variant="links.buttonPrimary"
          data-testid={`${item._key}_${item.link[0]._type}`}
          additionalTrackingIdInfo={index + 1}
        />
      </BemActionArea>
    ) : null}
  </Box>
);

const ItemsOnDesktop = ({ items }: IPropsItemsOnTabletOrSmaller) => (
  <>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </>
);
const ItemsOnTabletOrSmaller = ({ items }: IPropsItemsOnTabletOrSmaller) => (
  <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
    <Box sx={styles.sliderWrapper} data-testid="BemM0055-sliderWrapper">
      <BemTouchSlider
        items={items}
        itemRenderer={({ item }, index) => <Item item={item} index={index} />}
      />
    </Box>
  </BemThemeWrapper>
);

const BemM0055 = ({
  title,
  text,
  link,
  items,
  theme: moduleTheme,
}: IPropsBemM0055): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0055-moduleWrapper"
      >
        <Box sx={styles.contentWrapper} data-testid="BemM0055-contentWrapper">
          <BemHeading
            as="h2"
            variant={
              text
                ? 'h2WithSeparator'
                : 'h2WithSeparatorForHeadlineWithoutPaddingBottom'
            }
            sx={calculatedStyles.title(!!text)}
          >
            {title}
          </BemHeading>
          {text ? (
            <Text
              as="p"
              variant="caption.small"
              data-testid="BemM0055Text"
              sx={styles.text}
            >
              {text}
            </Text>
          ) : null}
          {link?.length ? (
            <BemActionArea>
              <BemLink
                to={link}
                variant="links.buttonPrimary"
                data-testid="BemM0055Link"
                sx={styles.link}
              />
            </BemActionArea>
          ) : null}
        </Box>
        {useViewportRenderer([
          <ItemsOnTabletOrSmaller
            items={items}
            key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          />,
          <ItemsOnTabletOrSmaller
            items={items}
            key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          />,
          <ItemsOnDesktop
            items={items}
            key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          />,
        ])}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0055, IPropsBemM0055 };
