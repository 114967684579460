import React, { ReactNode } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ASIDE_ACTION_AREA_PT } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemActionArea {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
}

const styles: IStylesObject = {
  wrapper: {
    pt: ASIDE_ACTION_AREA_PT,
  },
};

const BemActionArea = ({ sx, children }: IPropsBemActionArea): JSX.Element => (
  <Box sx={{ ...styles.wrapper, ...sx }}>{children}</Box>
);

export { BemActionArea, IPropsBemActionArea };
