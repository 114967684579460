import { IGraphqlModule, isKeyInObject, TAllGraphqlModule } from '@bemer/base';
// Plop: Append module imports here
import {
  BemM0001,
  BemM0002,
  BemM0002A,
  BemM0002B,
  BemM0002C,
  BemM0003,
  BemM0004,
  BemM0005,
  BemM0006,
  BemM0007,
  BemM0008,
  BemM0009,
  BemM0010,
  BemM0011,
  BemM0012,
  BemM0013,
  BemM0014,
  BemM0015,
  BemM0016,
  BemM0018,
  BemM0019,
  BemM0020,
  BemM0021,
  BemM0022,
  BemM0023,
  BemM0024,
  BemM0025,
  BemM0026,
  BemM0027,
  BemM0031,
  BemM0032,
  BemM0033,
  BemM0034,
  BemM0035,
  BemM0037,
  BemM0038,
  BemM0039,
  BemM0040,
  BemM0041,
  BemM0042,
  BemM0043,
  BemM0044,
  BemM0045,
  BemM0046,
  BemM0047,
  BemM0048,
  BemM0049,
  BemM0050,
  BemM0051,
  BemM0052,
  BemM0053,
  BemM0054,
  BemM0055,
  BemM0056,
  BemM0057,
  BemM0058,
  BemM0062,
  BemM0063,
  BemM0064,
  BemM0065,
  BemM0066,
  BemM0067,
  BemM0068,
  BemM0069,
  BemM0071,
  BemM0072,
  BemM0074,
  BemM0075,
  BemM0076,
  BemM0077,
  BemM0078,
  BemM0079,
  BemM0080,
  BemM0082,
  BemM0087,
  BemM0088,
  BemM0090,
  BemM0092,
  BemM0094,
  BemM0095,
  BemM0106,
  BemM0107,
  BemM0114,
  BemM0117,
  BemM0118,
  BemM0119,
  BemM0120,
  BemM0121,
  BemM0122,
  BemM0123,
  BemM0124,
  BemM0127,
  BemM0131,
  BemM0132,
  BemM0133,
  BemM0134,
  BemM0135,
  BemM0137,
  BemM0138,
  BemM0139,
  BemM0140,
  BemM0141,
  BemM0143,
  BemM0144,
  BemM0145,
  BemM0146,
  BemM0148,
  BemM0149,
  BemM0150,
  BemM0151,
  BemM0151B,
  BemM0152,
  BemModuleProvider,
  IPropsBemM0001,
  IPropsBemM0002,
  IPropsBemM0002A,
  IPropsBemM0002B,
  IPropsBemM0002C,
  IPropsBemM0003,
  IPropsBemM0004,
  IPropsBemM0005,
  IPropsBemM0006,
  IPropsBemM0007,
  IPropsBemM0008,
  IPropsBemM0009,
  IPropsBemM0010,
  IPropsBemM0011,
  IPropsBemM0012,
  IPropsBemM0013,
  IPropsBemM0014,
  IPropsBemM0015,
  IPropsBemM0016,
  IPropsBemM0018,
  IPropsBemM0019,
  IPropsBemM0020,
  IPropsBemM0021,
  IPropsBemM0022,
  IPropsBemM0023,
  IPropsBemM0024,
  IPropsBemM0025,
  IPropsBemM0026,
  IPropsBemM0027,
  IPropsBemM0031,
  IPropsBemM0032,
  IPropsBemM0033,
  IPropsBemM0034,
  IPropsBemM0035,
  IPropsBemM0037,
  IPropsBemM0038,
  IPropsBemM0039,
  IPropsBemM0040,
  IPropsBemM0041,
  IPropsBemM0042,
  IPropsBemM0043,
  IPropsBemM0044,
  IPropsBemM0045,
  IPropsBemM0046,
  IPropsBemM0047,
  IPropsBemM0048,
  IPropsBemM0049,
  IPropsBemM0050,
  IPropsBemM0051,
  IPropsBemM0052,
  IPropsBemM0053,
  IPropsBemM0054,
  IPropsBemM0055,
  IPropsBemM0056,
  IPropsBemM0057,
  IPropsBemM0058,
  IPropsBemM0062,
  IPropsBemM0063,
  IPropsBemM0064,
  IPropsBemM0065,
  IPropsBemM0066,
  IPropsBemM0067,
  IPropsBemM0068,
  IPropsBemM0069,
  IPropsBemM0071,
  IPropsBemM0072,
  IPropsBemM0074,
  IPropsBemM0075,
  IPropsBemM0076,
  IPropsBemM0077,
  IPropsBemM0078,
  IPropsBemM0079,
  IPropsBemM0080,
  IPropsBemM0082,
  IPropsBemM0087,
  IPropsBemM0088,
  IPropsBemM0090,
  IPropsBemM0092,
  IPropsBemM0094,
  IPropsBemM0095,
  IPropsBemM0106,
  IPropsBemM0107,
  IPropsBemM0114,
  IPropsBemM0117,
  IPropsBemM0118,
  IPropsBemM0119,
  IPropsBemM0120,
  IPropsBemM0121,
  IPropsBemM0122,
  IPropsBemM0123,
  IPropsBemM0124,
  IPropsBemM0127,
  IPropsBemM0131,
  IPropsBemM0132,
  IPropsBemM0133,
  IPropsBemM0134,
  IPropsBemM0135,
  IPropsBemM0137,
  IPropsBemM0138,
  IPropsBemM0139,
  IPropsBemM0140,
  IPropsBemM0141,
  IPropsBemM0143,
  IPropsBemM0144,
  IPropsBemM0145,
  IPropsBemM0146,
  IPropsBemM0148,
  IPropsBemM0149,
  IPropsBemM0150,
  IPropsBemM0151,
  IPropsBemM0151B,
  IPropsBemM0152,
} from '@bemer/ui-library';
import React from 'react';

interface IAppModule {
  module: IGraphqlModule;
  modulePosition?: number;
}

const AppModule = ({
  module,
  modulePosition = 0,
}: IAppModule): JSX.Element | null => {
  if (
    !module ||
    !module.module ||
    !module.module[0] ||
    !module.module[0]._type
  ) {
    return null;
  }
  const getModule = (moduleData: TAllGraphqlModule) => {
    switch (moduleData._type) {
      // Plop: Append module usage here [START]
      case 'm0001': {
        return (
          <BemM0001 key={moduleData._key} {...(moduleData as IPropsBemM0001)} />
        );
      }
      case 'm0002': {
        return (
          <BemM0002 key={moduleData._key} {...(moduleData as IPropsBemM0002)} />
        );
      }
      case 'm0002A': {
        return (
          <BemM0002A
            key={moduleData._key}
            {...(moduleData as IPropsBemM0002A)}
          />
        );
      }
      case 'm0002B': {
        return (
          <BemM0002B
            key={moduleData._key}
            {...(moduleData as IPropsBemM0002B)}
          />
        );
      }
      case 'm0002C': {
        return (
          <BemM0002C
            key={moduleData._key}
            {...(moduleData as IPropsBemM0002C)}
          />
        );
      }
      case 'm0003': {
        return (
          <BemM0003 key={moduleData._key} {...(moduleData as IPropsBemM0003)} />
        );
      }
      case 'm0004': {
        return (
          <BemM0004 key={moduleData._key} {...(moduleData as IPropsBemM0004)} />
        );
      }
      case 'm0005': {
        return (
          <BemM0005 key={moduleData._key} {...(moduleData as IPropsBemM0005)} />
        );
      }
      case 'm0006': {
        return (
          <BemM0006 key={moduleData._key} {...(moduleData as IPropsBemM0006)} />
        );
      }
      case 'm0007': {
        return (
          <BemM0007 key={moduleData._key} {...(moduleData as IPropsBemM0007)} />
        );
      }
      case 'm0008': {
        return (
          <BemM0008 key={moduleData._key} {...(moduleData as IPropsBemM0008)} />
        );
      }
      case 'm0009': {
        return (
          <BemM0009 key={moduleData._key} {...(moduleData as IPropsBemM0009)} />
        );
      }
      case 'm0010': {
        return (
          <BemM0010 key={moduleData._key} {...(moduleData as IPropsBemM0010)} />
        );
      }
      case 'm0011': {
        return (
          <BemM0011 key={moduleData._key} {...(moduleData as IPropsBemM0011)} />
        );
      }
      case 'm0012': {
        return (
          <BemM0012 key={moduleData._key} {...(moduleData as IPropsBemM0012)} />
        );
      }
      case 'm0013': {
        return (
          <BemM0013 key={moduleData._key} {...(moduleData as IPropsBemM0013)} />
        );
      }
      case 'm0014': {
        return (
          <BemM0014 key={moduleData._key} {...(moduleData as IPropsBemM0014)} />
        );
      }
      case 'm0015': {
        return (
          <BemM0015 key={moduleData._key} {...(moduleData as IPropsBemM0015)} />
        );
      }
      case 'm0016': {
        return (
          <BemM0016 key={moduleData._key} {...(moduleData as IPropsBemM0016)} />
        );
      }
      case 'm0018': {
        return (
          <BemM0018 key={moduleData._key} {...(moduleData as IPropsBemM0018)} />
        );
      }
      case 'm0019': {
        return (
          <BemM0019 key={moduleData._key} {...(moduleData as IPropsBemM0019)} />
        );
      }
      case 'm0020': {
        return (
          <BemM0020 key={moduleData._key} {...(moduleData as IPropsBemM0020)} />
        );
      }
      case 'm0021': {
        return (
          <BemM0021 key={moduleData._key} {...(moduleData as IPropsBemM0021)} />
        );
      }
      case 'm0022': {
        return (
          <BemM0022 key={moduleData._key} {...(moduleData as IPropsBemM0022)} />
        );
      }
      case 'm0023': {
        return (
          <BemM0023 key={moduleData._key} {...(moduleData as IPropsBemM0023)} />
        );
      }
      case 'm0024': {
        return (
          <BemM0024 key={moduleData._key} {...(moduleData as IPropsBemM0024)} />
        );
      }
      case 'm0025': {
        return (
          <BemM0025 key={moduleData._key} {...(moduleData as IPropsBemM0025)} />
        );
      }
      case 'm0026': {
        return (
          <BemM0026 key={moduleData._key} {...(moduleData as IPropsBemM0026)} />
        );
      }
      case 'm0027': {
        return (
          <BemM0027 key={moduleData._key} {...(moduleData as IPropsBemM0027)} />
        );
      }
      case 'm0031': {
        return (
          <BemM0031 key={moduleData._key} {...(moduleData as IPropsBemM0031)} />
        );
      }
      case 'm0032': {
        return (
          <BemM0032 key={moduleData._key} {...(moduleData as IPropsBemM0032)} />
        );
      }
      case 'm0033': {
        return (
          <BemM0033 key={moduleData._key} {...(moduleData as IPropsBemM0033)} />
        );
      }
      case 'm0034': {
        return (
          <BemM0034 key={moduleData._key} {...(moduleData as IPropsBemM0034)} />
        );
      }
      case 'm0035': {
        return (
          <BemM0035 key={moduleData._key} {...(moduleData as IPropsBemM0035)} />
        );
      }
      case 'm0037': {
        return (
          <BemM0037 key={moduleData._key} {...(moduleData as IPropsBemM0037)} />
        );
      }
      case 'm0038': {
        return (
          <BemM0038 key={moduleData._key} {...(moduleData as IPropsBemM0038)} />
        );
      }
      case 'm0039': {
        return (
          <BemM0039 key={moduleData._key} {...(moduleData as IPropsBemM0039)} />
        );
      }
      case 'm0040': {
        return (
          <BemM0040 key={moduleData._key} {...(moduleData as IPropsBemM0040)} />
        );
      }
      case 'm0041': {
        return (
          <BemM0041 key={moduleData._key} {...(moduleData as IPropsBemM0041)} />
        );
      }
      case 'm0042': {
        return (
          <BemM0042 key={moduleData._key} {...(moduleData as IPropsBemM0042)} />
        );
      }
      case 'm0043': {
        return (
          <BemM0043 key={moduleData._key} {...(moduleData as IPropsBemM0043)} />
        );
      }
      case 'm0044': {
        return (
          <BemM0044 key={moduleData._key} {...(moduleData as IPropsBemM0044)} />
        );
      }
      case 'm0045': {
        return (
          <BemM0045 key={moduleData._key} {...(moduleData as IPropsBemM0045)} />
        );
      }
      case 'm0046': {
        return (
          <BemM0046 key={moduleData._key} {...(moduleData as IPropsBemM0046)} />
        );
      }
      case 'm0047': {
        return (
          <BemM0047 key={moduleData._key} {...(moduleData as IPropsBemM0047)} />
        );
      }
      case 'm0048': {
        return (
          <BemM0048 key={moduleData._key} {...(moduleData as IPropsBemM0048)} />
        );
      }
      case 'm0049': {
        return (
          <BemM0049 key={moduleData._key} {...(moduleData as IPropsBemM0049)} />
        );
      }
      case 'm0050': {
        return (
          <BemM0050 key={moduleData._key} {...(moduleData as IPropsBemM0050)} />
        );
      }
      case 'm0051': {
        return (
          <BemM0051 key={moduleData._key} {...(moduleData as IPropsBemM0051)} />
        );
      }
      case 'm0052': {
        return (
          <BemM0052 key={moduleData._key} {...(moduleData as IPropsBemM0052)} />
        );
      }
      case 'm0053': {
        return (
          <BemM0053 key={moduleData._key} {...(moduleData as IPropsBemM0053)} />
        );
      }
      case 'm0054': {
        return (
          <BemM0054 key={moduleData._key} {...(moduleData as IPropsBemM0054)} />
        );
      }
      case 'm0055': {
        return (
          <BemM0055 key={moduleData._key} {...(moduleData as IPropsBemM0055)} />
        );
      }
      case 'm0056': {
        return (
          <BemM0056 key={moduleData._key} {...(moduleData as IPropsBemM0056)} />
        );
      }
      case 'm0057': {
        return (
          <BemM0057 key={moduleData._key} {...(moduleData as IPropsBemM0057)} />
        );
      }
      case 'm0058': {
        return (
          <BemM0058 key={moduleData._key} {...(moduleData as IPropsBemM0058)} />
        );
      }
      case 'm0062': {
        return (
          <BemM0062 key={moduleData._key} {...(moduleData as IPropsBemM0062)} />
        );
      }
      case 'm0063': {
        return (
          <BemM0063 key={moduleData._key} {...(moduleData as IPropsBemM0063)} />
        );
      }
      case 'm0064': {
        return (
          <BemM0064 key={moduleData._key} {...(moduleData as IPropsBemM0064)} />
        );
      }
      case 'm0065': {
        return (
          <BemM0065 key={moduleData._key} {...(moduleData as IPropsBemM0065)} />
        );
      }
      case 'm0066': {
        return (
          <BemM0066 key={moduleData._key} {...(moduleData as IPropsBemM0066)} />
        );
      }
      case 'm0067': {
        return (
          <BemM0067 key={moduleData._key} {...(moduleData as IPropsBemM0067)} />
        );
      }
      case 'm0068': {
        return (
          <BemM0068 key={moduleData._key} {...(moduleData as IPropsBemM0068)} />
        );
      }
      case 'm0069': {
        return (
          <BemM0069 key={moduleData._key} {...(moduleData as IPropsBemM0069)} />
        );
      }
      case 'm0071': {
        return (
          <BemM0071 key={moduleData._key} {...(moduleData as IPropsBemM0071)} />
        );
      }
      case 'm0072': {
        return (
          <BemM0072 key={moduleData._key} {...(moduleData as IPropsBemM0072)} />
        );
      }
      case 'm0074': {
        return (
          <BemM0074 key={moduleData._key} {...(moduleData as IPropsBemM0074)} />
        );
      }
      case 'm0075': {
        return (
          <BemM0075 key={moduleData._key} {...(moduleData as IPropsBemM0075)} />
        );
      }
      case 'm0076': {
        return (
          <BemM0076 key={moduleData._key} {...(moduleData as IPropsBemM0076)} />
        );
      }
      case 'm0077': {
        return (
          <BemM0077 key={moduleData._key} {...(moduleData as IPropsBemM0077)} />
        );
      }
      case 'm0078': {
        return (
          <BemM0078 key={moduleData._key} {...(moduleData as IPropsBemM0078)} />
        );
      }
      case 'm0079': {
        return (
          <BemM0079 key={moduleData._key} {...(moduleData as IPropsBemM0079)} />
        );
      }
      case 'm0080': {
        return (
          <BemM0080 key={moduleData._key} {...(moduleData as IPropsBemM0080)} />
        );
      }
      case 'm0082': {
        return (
          <BemM0082 key={moduleData._key} {...(moduleData as IPropsBemM0082)} />
        );
      }
      case 'm0087': {
        return (
          <BemM0087 key={moduleData._key} {...(moduleData as IPropsBemM0087)} />
        );
      }
      case 'm0088': {
        return (
          <BemM0088 key={moduleData._key} {...(moduleData as IPropsBemM0088)} />
        );
      }
      case 'm0090': {
        return (
          <BemM0090 key={moduleData._key} {...(moduleData as IPropsBemM0090)} />
        );
      }
      case 'm0092': {
        return (
          <BemM0092 key={moduleData._key} {...(moduleData as IPropsBemM0092)} />
        );
      }
      case 'm0094': {
        return (
          <BemM0094 key={moduleData._key} {...(moduleData as IPropsBemM0094)} />
        );
      }
      case 'm0095': {
        return (
          <BemM0095 key={moduleData._key} {...(moduleData as IPropsBemM0095)} />
        );
      }
      case 'm0106': {
        return (
          <BemM0106 key={moduleData._key} {...(moduleData as IPropsBemM0106)} />
        );
      }
      case 'm0107': {
        return (
          <BemM0107 key={moduleData._key} {...(moduleData as IPropsBemM0107)} />
        );
      }
      case 'm0114': {
        return (
          <BemM0114 key={moduleData._key} {...(moduleData as IPropsBemM0114)} />
        );
      }
      case 'm0117': {
        return (
          <BemM0117 key={moduleData._key} {...(moduleData as IPropsBemM0117)} />
        );
      }
      case 'm0118': {
        return (
          <BemM0118 key={moduleData._key} {...(moduleData as IPropsBemM0118)} />
        );
      }
      case 'm0119': {
        return (
          <BemM0119 key={moduleData._key} {...(moduleData as IPropsBemM0119)} />
        );
      }
      case 'm0120': {
        return (
          <BemM0120 key={moduleData._key} {...(moduleData as IPropsBemM0120)} />
        );
      }
      case 'm0121': {
        return (
          <BemM0121 key={moduleData._key} {...(moduleData as IPropsBemM0121)} />
        );
      }
      case 'm0122': {
        return (
          <BemM0122 key={moduleData._key} {...(moduleData as IPropsBemM0122)} />
        );
      }
      case 'm0123': {
        return (
          <BemM0123 key={moduleData._key} {...(moduleData as IPropsBemM0123)} />
        );
      }
      case 'm0124': {
        return (
          <BemM0124 key={moduleData._key} {...(moduleData as IPropsBemM0124)} />
        );
      }
      case 'm0127': {
        return (
          <BemM0127 key={moduleData._key} {...(moduleData as IPropsBemM0127)} />
        );
      }
      case 'm0131': {
        return (
          <BemM0131 key={moduleData._key} {...(moduleData as IPropsBemM0131)} />
        );
      }
      case 'm0132': {
        return (
          <BemM0132 key={moduleData._key} {...(moduleData as IPropsBemM0132)} />
        );
      }
      case 'm0133': {
        return (
          <BemM0133 key={moduleData._key} {...(moduleData as IPropsBemM0133)} />
        );
      }
      case 'm0134': {
        return (
          <BemM0134 key={moduleData._key} {...(moduleData as IPropsBemM0134)} />
        );
      }
      case 'm0135': {
        return (
          <BemM0135 key={moduleData._key} {...(moduleData as IPropsBemM0135)} />
        );
      }
      case 'm0137': {
        return (
          <BemM0137 key={moduleData._key} {...(moduleData as IPropsBemM0137)} />
        );
      }
      case 'm0138': {
        return (
          <BemM0138 key={moduleData._key} {...(moduleData as IPropsBemM0138)} />
        );
      }
      case 'm0139': {
        return (
          <BemM0139 key={moduleData._key} {...(moduleData as IPropsBemM0139)} />
        );
      }
      case 'm0140': {
        return (
          <BemM0140 key={moduleData._key} {...(moduleData as IPropsBemM0140)} />
        );
      }
      case 'm0141': {
        return (
          <BemM0141 key={moduleData._key} {...(moduleData as IPropsBemM0141)} />
        );
      }
      case 'm0143': {
        return (
          <BemM0143 key={moduleData._key} {...(moduleData as IPropsBemM0143)} />
        );
      }
      case 'm0144': {
        return (
          <BemM0144 key={moduleData._key} {...(moduleData as IPropsBemM0144)} />
        );
      }
      case 'm0145': {
        return (
          <BemM0145 key={moduleData._key} {...(moduleData as IPropsBemM0145)} />
        );
      }
      case 'm0146': {
        return (
          <BemM0146 key={moduleData._key} {...(moduleData as IPropsBemM0146)} />
        );
      }
      case 'm0148': {
        return (
          <BemM0148 key={moduleData._key} {...(moduleData as IPropsBemM0148)} />
        );
      }
      case 'm0149': {
        return (
          <BemM0149 key={moduleData._key} {...(moduleData as IPropsBemM0149)} />
        );
      }
      case 'm0150': {
        return (
          <BemM0150 key={moduleData._key} {...(moduleData as IPropsBemM0150)} />
        );
      }
      case 'm0151': {
        return (
          <BemM0151 key={moduleData._key} {...(moduleData as IPropsBemM0151)} />
        );
      }
      case 'm0151B': {
        return (
          <BemM0151B
            key={moduleData._key}
            {...(moduleData as IPropsBemM0151B)}
          />
        );
      }
      case 'm0152': {
        return (
          <BemM0152 key={moduleData._key} {...(moduleData as IPropsBemM0152)} />
        );
      }
      // Plop: Append module usage here [END]
      default: {
        return (
          <div key={module._key}>
            <h2>{module._type}</h2>
            <pre>{JSON.stringify(module, null, 2)}</pre>
          </div>
        );
      }
    }
  };

  const moduleTheme = isKeyInObject({ ...module.module[0] }, 'theme')
    ? // @ts-ignore - not all modules have a theme field.
      module.module[0].theme
    : undefined;

  const moduleContract = {
    moduleName: `modulePosition_${modulePosition}-Bem${module.module[0]._type.toUpperCase()}`,
    moduleTheme,
  };

  return (
    <BemModuleProvider contract={moduleContract}>
      {getModule(module.module[0])}
    </BemModuleProvider>
  );
};

export { AppModule, IAppModule };
