import { IGraphqlTranslation, TEventTypeId } from '@bemer/base';
import { useActor } from '@xstate/react';
import React, { useContext, useEffect } from 'react';
import { Box, Grid, Input, Label, Select } from 'theme-ui';
import { IStylesObject } from '../../../gatsby-plugin-theme-ui/moduleTypes';
import { ApiContext } from '../../../providers';
import { getTranslation } from '../../../utils/translations';
import { TFilterMachineActorRef } from './Filter.machine';
import { Search } from './Search';

const styles: IStylesObject = {
  searchBar: {
    pt: [0, 2, 2],
    textAlign: 'center',
  },
  textWrapper: {
    gridColumn: '2 / -2',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchWrapper: {
    gridColumn: ['2 / -2', '2 / span 5', '2 / span 5'],
    pb: [2, 0, 0],
  },
  datePickerWrapper: {
    gridColumn: ['2 / span 6', '7 / span 4', '7 / span 3'],
    pb: [2, 0, 0],
  },
  filterSelect: {
    gridColumn: ['8 / span 6', '11 / span 3', '10 / span 3'],
    pb: [2, 0, 0],
  },
  viewSwitcher: {
    gridColumn: ['2 / span 12', '2 / span 12', '12 / span 2'],
    textAlign: 'right',
  },
};

interface IPropsFilter {
  filterActorRef: TFilterMachineActorRef;
  T: IGraphqlTranslation[];
}

const Filter = ({ filterActorRef, T }: IPropsFilter): JSX.Element => {
  const [{ context }, send] = useActor(filterActorRef);

  const {
    events: { getEventTypes },
  } = useContext(ApiContext);
  const { data: eventTypeList, isFetching: isFetchingEventTypes } =
    getEventTypes();

  useEffect(() => {
    if (eventTypeList) {
      send({ type: 'UPDATE_EVENT_TYPE_LIST', eventTypeList });
    }
  }, [isFetchingEventTypes, eventTypeList]);

  return (
    <>
      <Box sx={styles.searchWrapper}>
        {context.searchActorRef ? (
          <Search searchActorRef={context.searchActorRef} T={T} />
        ) : null}
      </Box>
      <Box sx={styles.datePickerWrapper}>
        <Box sx={{ display: 'block' }}>
          <Grid variant="formInputGrid">
            <Input
              name="eventStartDate"
              onChange={(e) =>
                send({
                  type: 'SELECT_START_DATE',
                  startDate: e.target.value,
                })
              }
              min={context.today}
              // max should be max date of all events
              placeholder={getTranslation(
                'EVENT_FILTER_STARTDATE_PLACEHOLDER',
                T
              )}
              type="date"
              value={context.filter.startDate}
              sx={{
                display: 'flex',

                '::-webkit-date-and-time-value': {
                  textAlign: 'left',
                },
              }}
            />
            <Label htmlFor="eventStartDate">
              {getTranslation('EVENT_FILTER_STARTDATE_LABEL', T)}
            </Label>
          </Grid>
        </Box>
      </Box>
      <Grid variant="formInputGrid" sx={styles.filterSelect}>
        <Select
          name="eventEventType"
          disabled={isFetchingEventTypes}
          onChange={(e) =>
            send({
              type: 'SELECT_EVENT_TYPE',
              eventType: e.target.value as TEventTypeId,
            })
          }
          defaultValue={context.filter.eventType || 'EVENT_TYPE_ALL'}
        >
          <option value="ALL">{getTranslation('EVENT_TYPE_ALL', T)}</option>
          {context.eventTypeList.map((eventType) => (
            <option key={eventType.id} value={eventType.id}>
              {getTranslation(`EVENT_TYPE_${eventType.id}`, T)}
            </option>
          ))}
        </Select>
        <Label htmlFor="eventEventType">
          {getTranslation('EVENT_FILTER_EVENT_TYPE_LABEL', T)}
        </Label>
      </Grid>

      {/* <Box sx={styles.viewSwitcher}>
        <Button type="button" variant="pagination">
          <FaAngleLeft />
        </Button>
        <Button variant="pagination">
          <FaAngleRight />
        </Button>
      </Box> */}
    </>
  );
};

export { Filter };
