import { TThemeName } from '@bemer/base';
import React from 'react';

interface IPropsBemThemeHierarchyProvider {
  children: React.ReactNode;
  contract: TThemeName[];
}

// Initialize the context with a default value.
const defaultValue: TThemeName[] = [];

const ThemeHierarchyContext = React.createContext(defaultValue);

const BemThemeHierarchyProvider = ({
  children,
  contract,
}: IPropsBemThemeHierarchyProvider): JSX.Element => (
  <ThemeHierarchyContext.Provider value={contract}>
    {children}
  </ThemeHierarchyContext.Provider>
);

export {
  ThemeHierarchyContext,
  BemThemeHierarchyProvider,
  IPropsBemThemeHierarchyProvider,
};
