import { aspectRatios, IGraphqlM0090, RATIOS } from '@bemer/base';
import React, { useContext, useState } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemButton,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemRichtext,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP_TO_ZERO,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0090 extends IGraphqlM0090 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  text: {
    gridColumn: ['2 / -2', '4 / -4', '4 / -4'],
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  image: {
    gridColumn: ['2 / -2', '2 / 7', '2 / 8'],
    pb: [6, 0, 0],
  },
  itemsGrid: {
    gridColumn: ['2 / -2', '7 / -2', '9 / -2'],
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr 1fr',
    gap: GRID_ROW_GAP_TO_ZERO,
  },
  button: {
    gridColumn: ['span 1', 'span 1', 'span 1'],
  },
  textWrapper: {
    gridColumn: 'span 2',
  },
  firstParagraph: {
    pt: [8, 10, 14],
    pb: [6, 8, 10],
  },
};

const BemM0090 = ({
  title,
  text,
  items,
  theme: moduleTheme,
}: IPropsBemM0090): JSX.Element => {
  const [activeElement, setActiveElement] = useState(items[0]._key);
  const [theme] = useContext(ThemeHierarchyContext);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0090Wrapper">
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        <Text as="p" sx={styles.text}>
          {text}
        </Text>
        {items.map((item, index) =>
          activeElement === item._key ? (
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
              sx={styles.image}
              key={item._key}
              additionalTrackingIdInfo={index + 1}
            />
          ) : null
        )}
        <Grid
          variant="contentGrid"
          sx={styles.itemsGrid}
          data-testid="BemM0090ButtonAndTextWrapper"
        >
          {items.map((item) => (
            <BemButton
              key={item._key}
              variant={
                activeElement === item._key
                  ? 'buttons.primary'
                  : 'buttons.secondary'
              }
              onClick={() => setActiveElement(item._key)}
              sx={styles.button}
            >
              {item.title}
            </BemButton>
          ))}
          {items.map((item) =>
            activeElement === item._key ? (
              <Box sx={styles.textWrapper} key={item._key}>
                <BemRichtext blocks={item.block} sx={styles.firstParagraph} />
              </Box>
            ) : null
          )}
        </Grid>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0090, IPropsBemM0090 };
