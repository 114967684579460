/**
 * compares two urls and checks if they are parts of each other, to determine
 * if they have some kind of parent child relationship
 * @param url1 input url to compare
 * @param url2 second url to compare
 * @returns boolean
 */
const isPartialMatch = (url1: string, url2: string): boolean =>
  url2.startsWith(url1);

// eslint-disable-next-line import/prefer-default-export
export { isPartialMatch };
