import React, { ReactNode } from 'react';
import { Badge, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemPill {
  children: ReactNode;
  backgroundColor?: string;
  color?: string;
  sx?: ThemeUIStyleObject;
  size?: 'tiny' | 'small';
}

const styles: IStylesObject = {
  badge: {
    display: 'inline-flex',
    px: 3,
    py: 1,
    borderRadius: 5,
  },
};

const BemPill = ({
  sx,
  backgroundColor = 'black',
  color = 'white',
  children,
  size = 'tiny',
}: IPropsBemPill): JSX.Element => (
  <Badge
    sx={{ ...styles.badge, backgroundColor, color, ...sx }}
    variant={size === 'tiny' ? 'text.tiny' : 'text.small'}
  >
    {children}
  </Badge>
);

export { BemPill, IPropsBemPill };
