import {
  defaultLocale,
  TBounds,
  TEvent,
  TFilter,
  TLocaleId,
  TLongCoords,
  TVisibleEvent,
} from '@bemer/base';
import { ContextFrom, EventFrom, send, spawn } from 'xstate';
import { createModel } from 'xstate/lib/model';
import {
  eventCardMachine,
  TEventCardMachineActorRef,
} from './components/EventCard/BemEventCard.machine';
import {
  filterMachine,
  TFilterMachineActorRef,
} from './components/Filter.machine';
import {
  isBetween,
  isEventAfterStartDate,
  isEventOfEventType,
} from './utils/events';

const TODAY = new Date().toISOString().split('T')[0];

const m0068Model = createModel(
  {
    bounds: {
      topLeft: {
        lng: -170,
        lat: 80,
      },
      bottomRight: {
        lng: 170,
        lat: -80,
      },
    },
    changedViewport: { latitude: 0, longitude: 0 } as TLongCoords,

    events: [] as TEvent[],
    filter: {
      startDate: TODAY,
      eventType: 'ALL',
      searchTerm: '',
    } as TFilter,
    filterActorRef: undefined as TFilterMachineActorRef | undefined,
    shouldUpdateEventsOnMapMove: true,
    visibleEvents: [] as TVisibleEvent<TEventCardMachineActorRef>[],
    isMapVisible: true,
    localeId: defaultLocale.id as TLocaleId,
  },
  {
    events: {
      SET_EVENTS: (events: TEvent[]) => ({ events }),
      TOGGLE_SHOULD_UPDATE_EVENTS_ON_MAP_MOVE: () => ({}),
      SHOW_MAP: () => ({}),
      HIDE_MAP: () => ({}),
      MAP_MOVE: (bounds: TBounds) => ({ bounds }),
      UPDATE_FILTER: (filter: TFilter) => ({ filter }),
      CHANGE_VIEWPORT: (coords: TLongCoords) => ({ coords }),
      MOUSE_ENTER_MARKER: (id: string) => ({ id }),
      RESET_SEARCH_TERM: () => ({}),
    },
  }
);

const m0068Machine = m0068Model.createMachine(
  {
    id: 'm0068',
    initial: 'loading',
    context: m0068Model.initialContext,
    states: {
      loading: {
        entry: [
          m0068Model.assign({
            filterActorRef: (context) =>
              spawn(
                filterMachine.withContext({
                  ...filterMachine.context,
                  localeId: context.localeId,
                }),
                'filterActor'
              ),
          }),
        ],
        on: {
          SET_EVENTS: {
            target: 'idle',
            actions: [
              'setEvents',
              'setVisibleEvents',
              'updateEventsForSearchActor',
            ],
          },
        },
      },
      idle: {
        on: {
          MAP_MOVE: [
            {
              target: 'updateEvents',
              actions: ['updateBounds'],
              cond: (context, _event) => context.shouldUpdateEventsOnMapMove,
            },
          ],
          TOGGLE_SHOULD_UPDATE_EVENTS_ON_MAP_MOVE: {
            actions: ['toggleShouldUpdateEventsOnMapMove'],
          },
          UPDATE_FILTER: {
            actions: ['setFilter', 'setVisibleEvents'],
          },
          CHANGE_VIEWPORT: {
            actions: ['setNewViewPort'],
          },
          MOUSE_ENTER_MARKER: {
            actions: ['scrollTo'],
          },
          HIDE_MAP: {
            actions: ['hideMap'],
          },
          SHOW_MAP: {
            actions: ['showMap'],
          },
          RESET_SEARCH_TERM: [
            {
              target: ['resetMap'],
            },
          ],
        },
      },
      resetMap: {
        after: {
          100: {
            target: 'idle',
          },
        },
      },
      updateEvents: {
        on: {
          MAP_MOVE: {
            actions: ['updateBounds'],
            target: 'updateEvents',
          },
        },
        after: {
          100: {
            target: 'idle',
            actions: ['setVisibleEvents'],
          },
        },
      },
    },
  },
  {
    actions: {
      showMap: m0068Model.assign({
        isMapVisible: true,
      }),
      hideMap: m0068Model.assign({
        isMapVisible: false,
      }),

      /**
       * this will update the current bounding box eg. when the map is used
       */
      updateBounds: m0068Model.assign(
        (_context, event) => ({
          bounds: event.bounds,
        }),
        'MAP_MOVE'
      ),

      /**
       * this controls toggles the control to update the bounding box whenever
       * the map is moved
       */
      toggleShouldUpdateEventsOnMapMove: m0068Model.assign(
        (context, _event) => ({
          shouldUpdateEventsOnMapMove: !context.shouldUpdateEventsOnMapMove,
        })
      ),

      /**
       * this will update the filter from an external machine in this machine
       */
      setFilter: m0068Model.assign(
        (_context, event) => ({
          filter: event.filter,
        }),
        'UPDATE_FILTER'
      ),

      /**
       * This action will
       * 1. stop all card actors of the currently visible events
       * 2. filter all events by boundingBox, eventType and startDate
       * 3. spawn new actors for the new visible events
       */
      setVisibleEvents: m0068Model.assign((context, _event) => {
        const { topLeft, bottomRight } = context.bounds;

        // Stop all cardRefs for all visible events.
        context.visibleEvents.forEach((event) => {
          event.cardRef.stop?.();
        });

        // set new visible events.
        return {
          visibleEvents: context.events
            // remove event if it is not within current longitude or
            // latitude range
            .filter(
              (event) =>
                isBetween(
                  Math.min(topLeft.lng, bottomRight.lng),
                  Math.max(topLeft.lng, bottomRight.lng),
                  event.venue.lng
                ) &&
                isBetween(
                  Math.min(topLeft.lat, bottomRight.lat),
                  Math.max(topLeft.lat, bottomRight.lat),
                  event.venue.lat
                )
            )

            // remove event if its eventType does not match eventType set
            // by filter
            .filter((event) =>
              isEventOfEventType(context.filter.eventType, event)
            )

            // remove event if its startDate is before date set by filter
            .filter((event) =>
              isEventAfterStartDate(context.filter.startDate, event)
            )

            .map((event) => ({
              event,
              // add a new eventCardMachine actor with a unique name
              cardRef: spawn(eventCardMachine, `card-${event.id}`),
            })),
        };
      }),

      setNewViewPort: m0068Model.assign(
        (_context, event) => ({
          changedViewport: event.coords,
        }),
        'CHANGE_VIEWPORT'
      ),

      setEvents: m0068Model.assign(
        (_context, event) => ({
          events: event.events,
        }),
        'SET_EVENTS'
      ),
      updateEventsForSearchActor: send(
        (context: TM0068MachineContext) => ({
          type: 'SET_EVENTS',
          events: context.events,
        }),
        {
          to: 'filterActor',
        }
      ),
    },
    guards: {},
  }
);

type TM0068MachineContext = ContextFrom<typeof m0068Model>;
type TM0068MachineEvent = EventFrom<typeof m0068Model>;

export { m0068Machine, TM0068MachineContext, TM0068MachineEvent };
