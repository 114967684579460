import {
  aspectRatios,
  IGraphqlInternalNavigationImageLink,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { AspectRatio, Box, Flex, Grid, Text } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { BemLink } from '../Link';
import { BemMedia } from '../Media/BemMedia';

interface IPropsBemProductCard {
  link: IGraphqlInternalNavigationImageLink;
  index: number;
}

const styles: IStylesObject = {
  link: {
    '&, &:link, &:active, &:visited': {
      color: 'text',
    },
  },
  label: { pt: 4, fontWeight: 'bold' },
  textOnlyTile: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gap: 0,
    height: '100%',
  },
  textOnlyTileLabel: {
    variant: 'text.small',
    gridRow: 1,
    gridColumn: 1,
    fontWeight: 'bold',
    zIndex: 1,
  },
  textOnlyTileIcon: {
    gridRow: 1,
    gridColumn: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 0,
  },
};

// this is only the small version
const BemProductCard = ({ link, index }: IPropsBemProductCard): JSX.Element => (
  <BemLink to={link} sx={styles.link} additionalTrackingIdInfo={index + 1}>
    {link.media?.length ? (
      <Box>
        <BemMedia
          media={link.media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        />
        <Text as="div" variant="text.small" sx={styles.label}>
          {link.label}
        </Text>
      </Box>
    ) : (
      <AspectRatio
        ratio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        sx={{ bg: 'productCardBackground', p: 6 }}
      >
        <Grid sx={styles.textOnlyTile}>
          <Text as="div" sx={styles.textOnlyTileLabel}>
            {link.label}
          </Text>
          <Flex sx={styles.textOnlyTileIcon}>
            <Box style={{ height: '2rem', width: '2rem' }}>
              <FiArrowRight size="100%" />
            </Box>
          </Flex>
        </Grid>
      </AspectRatio>
    )}
  </BemLink>
);

export { BemProductCard, IPropsBemProductCard };
