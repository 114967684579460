import {
  IGraphqlM0088,
  IGraphqlM0088Document,
  IGraphqlM0088SectionLevel1,
  IGraphqlM0088SectionLevel2,
  TAdditionalTrackingIdInfo,
  TGraphqlLink,
} from '@bemer/base';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useMachine } from '@xstate/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { FaAlignLeft, FaTimes } from 'react-icons/fa';
import { MdExpandMore } from 'react-icons/md';
import { Box, Flex, Grid, ThemeUIStyleObject } from 'theme-ui';
import {
  BemBreadcrumb,
  BemButton,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemRichtext,
} from '../../components';
import {
  defaultGridGap,
  GRID_GAP_MOBILE,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import opacities from '../../gatsby-plugin-theme-ui/opacities';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { useTheme } from '../../gatsby-plugin-theme-ui/utils/useTheme';
import { VIEWPORT_INDEX } from '../../hooks/useViewportRenderer/index';
import { ModuleContext } from '../../providers/ModuleProvider/BemModuleProvider';
import { debugXState } from '../../utils/xStateHelper';
import { IM0088Context, m0088Machine, TM0088Events } from './BemM0088.machine';

const NAV_LIST_PADDING_X = 6;
const NAV_LIST_ICON_PADDING_TOP = 2;
const NAV_LIST_PADDING_TOP = 9;

const componentName = 'BemM0088';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

interface IPropsBemM0088 extends IGraphqlM0088 {
  sx?: ThemeUIStyleObject;
}

const MotionBox = motion(Box);
const BACKDROP_OPEN = 'open';
const BACKDROP_CLOSED = 'closed';
const BACKDROP_EXIT = 'exit';
const TOGGLE_OPEN = 'open';
const TOGGLE_CLOSE = 'close';

const animationVariants = {
  navListToggle: {
    [TOGGLE_OPEN]: {
      left: 0,
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
    [TOGGLE_CLOSE]: {
      left: '-100vw',
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
  },
  sectionToggle: {
    [TOGGLE_OPEN]: {
      rotate: 180,
    },
    [TOGGLE_CLOSE]: {
      rotate: 0,
    },
  },
  sections: {
    [TOGGLE_OPEN]: {
      height: 'auto',
      opacity: 1,
      visibility: 'visible',
    },
    [TOGGLE_CLOSE]: {
      height: 0,
      opacity: 0,
      transition: {
        staggerChildren: DURATION.MEDIUM,
      },
      transitionEnd: { visibility: 'hidden' },
    },
  },
  backdrop: {
    [BACKDROP_OPEN]: {
      opacity: opacities.backdropOpacity,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
    [BACKDROP_CLOSED]: {
      opacity: 0,
    },
    [BACKDROP_EXIT]: {
      opacity: 0,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
  },
} as const;

const calculatedStyles: ICalculatedStylesObject = {
  listItemTextColor: (active: boolean) => ({
    color: active ? 'text' : 'gray.7',
    fontWeight: active ? 'bold' : 'normal',
  }),
};

const styles: IStylesObject = {
  tableOfContentsWrapper: {
    gridColumn: ['2 / span 3', '2 / span 4', '2 / span 3'],
    backgroundColor: 'gray.2',
    pt: [0, NAV_LIST_PADDING_TOP, NAV_LIST_PADDING_TOP],
    pb: 6,
    alignSelf: 'flex-start',
    position: ['absolute', 'static', 'static'],
    zIndex: 99,
  },
  navListIcon: {
    gridColumn: ['2 / span 1', null, null],
    cursor: 'pointer',
    alignSelf: 'flex-start',
    display: ['inline-block', 'none', 'none'],
    pt: NAV_LIST_ICON_PADDING_TOP,
  },
  navListIconClosed: {
    px: NAV_LIST_PADDING_X - GRID_GAP_MOBILE,
  },
  navListIconOpen: {
    pb: NAV_LIST_PADDING_TOP,
  },
  navList: {
    px: NAV_LIST_PADDING_X,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 6,
    height: 3,
    flexShrink: 0,
    flexGrow: 0,
  },
  headerWrapper: {
    gridTemplateColumns: ['none', 'auto auto', 'auto auto'],
    pb: 12,
    gridGap: defaultGridGap,
  },
  breadCrumb: {
    pt: 2,
    alignSelf: 'start',
  },
  downloadLink: {
    alignSelf: 'start',
  },
  contentWrapper: {
    gridColumn: ['4 / -2', '7 /-2', '6 / -2'],
    color: 'gray.7',
  },
  modalBackdrop: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: 'gray.6',
    zIndex: 98,
  },
  sectionList: {
    pl: 4,
    overflow: 'hidden',
    '& li:first-of-type': {
      pt: 0,
    },
    '& li:last-child': {
      pb: 0,
    },
  },
  documentListItem: {
    pb: 8,
  },
  documentList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  documentLink: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: 'inherit',
    textAlign: 'left',
    px: 0,
    cursor: 'pointer',
  },
  headlineWithLink: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 12,
  },
  listItem: {
    backgroundColor: 'transparent',
    px: 0,
    pt: 2,
    pb: 7,
    position: 'relative',
    borderWidth: 0,
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& div:first-of-type': {
      flexGrow: 1,
    },
    '&:hover': {
      color: 'text',
    },
    '& ~ ul &': {
      pt: 0,
    },
  },
  contentHeading: {
    pb: 14,
  },
};

interface IPropsDocumentList {
  documents: IGraphqlM0088Document[];
  send: (event: TM0088Events) => void;
  current: { value: any; context: IM0088Context };
  scrollToTop: () => void;
}

const backdropAnimationProps = {
  animate: BACKDROP_OPEN,
  initial: BACKDROP_CLOSED,
  exit: BACKDROP_EXIT,
};

interface IPropsModalBackdrop {
  hideBackdrop?: boolean;
  onClose: () => void;
}

const ModalBackdrop = ({ onClose, hideBackdrop }: IPropsModalBackdrop) => (
  <AnimatePresence>
    {!hideBackdrop ? (
      <MotionBox
        onClick={onClose}
        sx={styles.modalBackdrop}
        variants={animationVariants.backdrop}
        {...backdropAnimationProps}
      />
    ) : null}
  </AnimatePresence>
);

const DocumentList = ({
  documents,
  send,
  current,
  scrollToTop,
}: IPropsDocumentList) => {
  const additionalTrackingIdInfoCategory = `category-${
    current.context.selectedLevel1SectionIndex + 1
  }`;
  const additionalTrackingIdInfoSubCategory =
    current.context.selectedLevel2SectionIndex !== undefined
      ? `-subCategory-${current.context.selectedLevel2SectionIndex + 1}`
      : '';

  const onButtonClick = (documentKey: string) => {
    send({
      type: 'selectDocument',
      key: documentKey,
    });
    scrollToTop();
  };

  return (
    <Box as="ul" sx={styles.documentList}>
      {documents.map((document, index) => (
        <Box
          as="li"
          sx={styles.documentListItem}
          key={`documentList_${document._key}`}
          variant="text.bodyText"
        >
          <BemButton
            sx={styles.documentLink}
            onClick={() => onButtonClick(document._key)}
            variant="buttons.linkDefault"
            additionalTrackingIdInfo={`${additionalTrackingIdInfoCategory}${additionalTrackingIdInfoSubCategory}-link-${
              index + 1
            }`}
          >
            {document.name}
          </BemButton>
        </Box>
      ))}
    </Box>
  );
};

interface IPropsNestedList {
  isActive: boolean;
  wasActive: boolean;
  level2: number | undefined;
  sections: IGraphqlM0088SectionLevel2[];
  send: (event: TM0088Events) => void;
  isNavOpen: boolean;
  additionalTrackingIdInfo: TAdditionalTrackingIdInfo;
  scrollToTop: () => void;
}

const NestedList = ({
  isActive,
  wasActive,
  level2,
  sections,
  send,
  isNavOpen,
  additionalTrackingIdInfo,
  scrollToTop,
}: IPropsNestedList) => {
  const { moduleName } = useContext(ModuleContext);
  const trackingIdBase = `${moduleName}-${componentName}`;

  const onButtonClick = (subSectionKey: string) => {
    send({
      type: 'selectLevel2',
      key: subSectionKey,
    });

    if (isNavOpen) {
      send({ type: 'toggleNavList' });
    }

    scrollToTop();
  };

  return (
    <AnimatePresence>
      <MotionBox
        initial={wasActive ? TOGGLE_OPEN : TOGGLE_CLOSE}
        animate={isActive ? TOGGLE_OPEN : TOGGLE_CLOSE}
        variants={animationVariants.sections}
        as="ul"
        sx={styles.sectionList}
      >
        {sections.map((subSection, index) => (
          <Box as="li" sx={styles.documentList} key={subSection._key}>
            <Box
              as="button"
              sx={styles.listItem}
              onClick={() => onButtonClick(subSection._key)}
              data-trackingid={`${trackingIdBase}-${additionalTrackingIdInfo}-subCategory-${
                index + 1
              }`}
            >
              <Box
                variant="text.nav"
                sx={calculatedStyles.listItemTextColor(level2 === index)}
              >
                {subSection.name}
              </Box>
            </Box>
          </Box>
        ))}
      </MotionBox>
    </AnimatePresence>
  );
};

interface IPropsM0088HeadlineWithLink {
  node: {
    title: string;
    link: TGraphqlLink;
  };
}

const M0088HeadlineWithLink = ({
  node: { title, link },
}: IPropsM0088HeadlineWithLink) => (
  <Flex sx={styles.headlineWithLink}>
    <BemHeading variant="h4">{title}</BemHeading>
    <BemLink to={link} variant="links.boldUppercase" />
  </Flex>
);

interface IPropsNavListIcon {
  sx: any;
  isNavOpen: boolean;
  send: (event: TM0088Events) => void;
  isInNav: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const NavListIcon = ({ sx, isNavOpen, send, isInNav }: IPropsNavListIcon) => (
  <Box
    sx={{ ...styles.navListIcon, ...sx }}
    onClick={() => {
      send({
        type: 'toggleNavList',
      });
    }}
  >
    {isNavOpen ? (
      <FaTimes
        data-testid={
          isInNav ? 'BemM0088MobileNavCloseIcon' : 'BemM0088MobileCloseIcon'
        }
      />
    ) : (
      <FaAlignLeft
        data-testid={
          isInNav ? 'BemM0088MobileNavOpenIcon' : 'BemM0088MobileOpenIcon'
        }
      />
    )}
  </Box>
);

interface IPropsNavListLvl1Section {
  section: IGraphqlM0088SectionLevel1;
  isActive: boolean;
  hasNestedSection: boolean;
  send: (event: TM0088Events) => void;
  scrollToTop: () => void;
}

const onNavListLvl1ItemClick = ({
  section,
  isActive,
  hasNestedSection,
  send,
  scrollToTop,
}: IPropsNavListLvl1Section) => {
  if (isActive || !hasNestedSection) {
    send({
      type: 'toggleNavList',
    });
  }
  if (!isActive) {
    send({
      type: 'selectLevel1',
      key: section._key,
    });
  }
  scrollToTop();
};

const getPxByRem = (remValue: string) => {
  const yOffsetInt = parseInt(remValue, 10);
  return (
    yOffsetInt * parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
};

const BemM0088 = ({ sections, sx, ...props }: IPropsBemM0088): JSX.Element => {
  const [current, send] = useMachine(m0088Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      sections: sections.filter(Boolean),
    },
  });

  const { theme } = useTheme();
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (window && contentWrapperRef?.current) {
      const navigationNormalHeight = theme.space['24'];
      const navigationMinimizedHeight = theme.space['16'];

      const yOffsetPx = getPxByRem(navigationNormalHeight);
      const yOffset = -yOffsetPx - 1;
      const elementToScroll = contentWrapperRef.current.getBoundingClientRect();
      const y = elementToScroll.top + window.scrollY + yOffset;

      const yOffsetPreventingScrolling = getPxByRem(navigationMinimizedHeight);

      // Only scroll if top position of module is out of sight
      if (elementToScroll.top < yOffsetPreventingScrolling) {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const {
    selectedLevel1SectionIndex,
    selectedLevel2SectionIndex,
    prevSelectedLevel1SectionIndex,
  } = current.context;

  const showBreadcrumbs = current.context.hierarchy.length >= 2;
  const hasSelectedLevel2SectionIndex =
    selectedLevel2SectionIndex !== undefined && selectedLevel2SectionIndex > -1;

  let headline = current.context?.selectedDocument?.name;
  const downloadLink = current.context?.selectedDocument?.downloadLink;

  const isNavOpen = current.matches('navList.navListOpen');

  const { moduleName } = useContext(ModuleContext);
  const trackingIdBase = `${moduleName}-${componentName}`;

  if (current.matches('documents.inDocumentListView')) {
    if (
      hasSelectedLevel2SectionIndex &&
      typeof selectedLevel2SectionIndex !== 'undefined' &&
      [
        ...current.context.sections[selectedLevel1SectionIndex].sections.keys(),
      ].includes(selectedLevel2SectionIndex)
    ) {
      headline =
        current.context.sections[selectedLevel1SectionIndex].sections[
          selectedLevel2SectionIndex
        ]?.name;
    } else {
      headline = current.context.sections[selectedLevel1SectionIndex].name;
    }
  }

  useEffect(() => {
    send({
      type: 'updateSections',
      sections,
    });
  }, sections);

  if (useBreakpointIndex() > VIEWPORT_INDEX.MOBILE) {
    if (isNavOpen) {
      send({ type: 'toggleNavList' });
    }
  }

  return (
    <BemModuleWrapper sx={sx} {...props} data-testid="BemM0088">
      <NavListIcon
        sx={styles.navListIconClosed}
        isNavOpen={isNavOpen}
        send={send}
        isInNav={false}
      />
      <AnimatePresence>
        <MotionBox
          key={`NavBox${isNavOpen}`}
          sx={styles.tableOfContentsWrapper}
          as="nav"
          variants={animationVariants.navListToggle}
          initial={isNavOpen ? TOGGLE_CLOSE : TOGGLE_OPEN}
          animate={isNavOpen ? TOGGLE_OPEN : TOGGLE_CLOSE}
          data-testid="BemM0088NavBox"
        >
          <Box as="ul" sx={styles.navList}>
            <NavListIcon
              sx={styles.navListIconOpen}
              isNavOpen={isNavOpen}
              send={send}
              isInNav
            />

            {current.context.sections.map((section, index) => {
              const hasNestedSection = Boolean(section.sections.length);
              const isActive =
                hasSelectedLevel2SectionIndex &&
                selectedLevel1SectionIndex === index;
              return (
                <React.Fragment key={section._key}>
                  <Box as="li" sx={styles.documentList}>
                    <Box
                      as="button"
                      sx={styles.listItem}
                      onClick={() =>
                        onNavListLvl1ItemClick({
                          section,
                          isActive,
                          hasNestedSection,
                          send,
                          scrollToTop,
                        })
                      }
                      data-trackingid={`${trackingIdBase}-category-${
                        index + 1
                      }`}
                    >
                      <Box
                        variant="text.nav"
                        sx={calculatedStyles.listItemTextColor(
                          selectedLevel1SectionIndex === index
                        )}
                      >
                        {section.name}
                      </Box>
                      {hasNestedSection ? (
                        <MotionBox
                          sx={{
                            ...styles.iconWrapper,
                            ...calculatedStyles.listItemTextColor(
                              selectedLevel1SectionIndex === index
                            ),
                          }}
                          animate={
                            hasSelectedLevel2SectionIndex &&
                            selectedLevel1SectionIndex === index
                              ? TOGGLE_OPEN
                              : TOGGLE_CLOSE
                          }
                          variants={animationVariants.sectionToggle}
                        >
                          <Box style={{ height: '1.5rem', width: '1.5rem' }}>
                            <MdExpandMore size="100%" />
                          </Box>
                        </MotionBox>
                      ) : null}
                    </Box>
                    {hasNestedSection ? (
                      <NestedList
                        isActive={
                          hasSelectedLevel2SectionIndex &&
                          selectedLevel1SectionIndex === index
                        }
                        wasActive={prevSelectedLevel1SectionIndex === index}
                        level2={selectedLevel2SectionIndex}
                        sections={section.sections}
                        send={send}
                        isNavOpen={isNavOpen}
                        additionalTrackingIdInfo={`category-${index + 1}`}
                        scrollToTop={scrollToTop}
                      />
                    ) : null}
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </MotionBox>
      </AnimatePresence>
      <ModalBackdrop
        onClose={() => send({ type: 'toggleNavList' })}
        hideBackdrop={current.matches('navList.navListClosed')}
      />

      <Box sx={styles.contentWrapper} ref={contentWrapperRef}>
        <Grid sx={styles.headerWrapper}>
          {showBreadcrumbs ? (
            <BemBreadcrumb
              links={current.context.hierarchy.map((link, index) => ({
                ...link,
                onClick: () =>
                  send({
                    type: index === 0 ? 'selectLevel1' : 'selectLevel2',
                    key: link._key,
                  }),
              }))}
              sx={styles.breadCrumb}
            />
          ) : null}
          {downloadLink ? (
            <BemLink
              to={downloadLink}
              variant="links.buttonPrimary"
              sx={styles.downloadLink}
            />
          ) : null}
        </Grid>

        <BemHeading variant="h3" sx={styles.contentHeading}>
          {headline}
        </BemHeading>

        {current.matches('documents.inDocumentListView') ? (
          <DocumentList
            documents={current.context.documentList}
            send={send}
            current={current}
            scrollToTop={scrollToTop}
          />
        ) : null}

        {current.matches('documents.inDocumentView') &&
        current.context.selectedDocument ? (
          <BemRichtext
            blocks={current.context.selectedDocument.blocks}
            additionalTypes={{ m0088HeadlineWithLink: M0088HeadlineWithLink }}
          />
        ) : null}
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0088, IPropsBemM0088 };
