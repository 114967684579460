import { IGraphqlM0056Item } from '@bemer/base';
import { assign, Machine, StateSchema } from 'xstate';

interface IM0056Context {
  currentActiveIndex: number;
  items: IGraphqlM0056Item[];
}

const items: IGraphqlM0056Item[] = [];
interface IM0056Schema {
  states: {
    idle: StateSchema;
  };
}

type TM0056Events = { type: 'forward' } | { type: 'backward' };

const m0056Machine = Machine<IM0056Context, IM0056Schema, TM0056Events>(
  {
    id: 'items',
    initial: 'idle',
    context: {
      currentActiveIndex: 0,
      items,
    },
    states: {
      idle: {
        on: {
          forward: {
            actions: ['setNextCurrentActiveIndex', 'scrollTo'],
          },
          backward: {
            actions: ['setPreviousCurrentActiveIndex', 'scrollTo'],
          },
        },
      },
    },
  },
  {
    actions: {
      setNextCurrentActiveIndex: assign((context, _event) => ({
        currentActiveIndex:
          context.currentActiveIndex < context.items.length - 1
            ? context.currentActiveIndex + 1
            : 0,
      })),
      setPreviousCurrentActiveIndex: assign((context, _event) => ({
        currentActiveIndex:
          context.currentActiveIndex === 0
            ? context.items.length - context.currentActiveIndex - 1
            : context.currentActiveIndex - 1,
      })),
    },
  }
);
export { m0056Machine, TM0056Events, IM0056Context };
