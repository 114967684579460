import { useBreakpointIndex } from '@theme-ui/match-media';

const getViewportAspectRatio = (
  aspectRatio: number,
  forcedAspectRatio?: number | number[]
): number => {
  const breakpointIndex = useBreakpointIndex();
  let aspectRatioForCurrentViewport = aspectRatio;
  if (forcedAspectRatio) {
    if (typeof forcedAspectRatio === 'number') {
      aspectRatioForCurrentViewport = forcedAspectRatio;
    }
    if (Array.isArray(forcedAspectRatio) && forcedAspectRatio.length > 0) {
      aspectRatioForCurrentViewport =
        forcedAspectRatio[breakpointIndex] || forcedAspectRatio[0];
    }
  }
  return aspectRatioForCurrentViewport;
};

const isMobile = (): boolean => useBreakpointIndex() === 0;
const isTablet = (): boolean => useBreakpointIndex() === 1;
const isDesktop = (): boolean => useBreakpointIndex() === 2;

export { getViewportAspectRatio, isMobile, isTablet, isDesktop };
