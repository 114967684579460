import { aspectRatios, IGraphqlM0058, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_TEXT_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0058 extends IGraphqlM0058 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: '3 / -3',
    gridRow: '1',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  title: {
    pb: ASIDE_HEADLINE_PB,
  },
  text2: {
    pb: ASIDE_TEXT_PB,
  },
  link: {
    mb: [8, 0, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  image: ({ isEven, index, hasTitle }) => ({
    gridRow: hasTitle
      ? ['auto', index + 2, index + 2]
      : ['auto', index + 1, index + 1],
    height: '100%',
    gridColumn: isEven
      ? ['1 / -1', '1 / 8', '1 / 8']
      : ['1 / -1', '8 / -1', '8 / -1'],
    pt: index === 0 ? 0 : [0, 16, 32],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  }),

  grid: ({ index, hasTitle }) => ({
    gridRow: hasTitle
      ? ['auto', index + 2, index + 2]
      : ['auto', index + 1, index + 1],
    pt: index === 0 ? 0 : [0, 16, 32],
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
    alignSelf: 'center',
    '&:last-of-type': {
      pb: [0, 0, 0],
    },
  }),

  textContentBox: ({ isEven }) => ({
    gridColumn: isEven
      ? ['1 / -1', '8 / span 4', '8 / span 4']
      : ['1 / -1', '1 / span 4', '1 / span 4'],
  }),

  text1: (isSecondParagraph) => ({
    pb: isSecondParagraph ? ASIDE_TEXT_PB : 0,
  }),
};

const BemM0058 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0058): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0058-moduleWrapper"
      >
        {title ? (
          <BemHeading
            as="h2"
            variant="h1"
            sx={styles.heading}
            data-testid="BemM0058Title"
          >
            {title}
          </BemHeading>
        ) : null}

        {items.map((item, index) => {
          const calculationStyleParams = {
            isEven: index % 2,
            index,
            hasTitle: !!title,
          };
          const isSecondParagraph = !!item.text2;
          const additionalTrackingIdInfo = index + 1;

          return (
            <React.Fragment key={item._key}>
              <BemMedia
                media={item.media[0]}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_10_9].ratio}
                sx={calculatedStyles.image(calculationStyleParams)}
                additionalTrackingIdInfo={additionalTrackingIdInfo}
              />
              <Grid
                variant="contentGrid"
                data-testid={item._key}
                sx={calculatedStyles.grid(calculationStyleParams)}
              >
                <Box
                  sx={calculatedStyles.textContentBox(calculationStyleParams)}
                  data-testid={`${item._key}-textContentBox`}
                >
                  <BemHeading as="h3" variant="h2" sx={styles.title}>
                    {item.title}
                  </BemHeading>
                  <Text
                    as="p"
                    variant="bodyText"
                    sx={calculatedStyles.text1(isSecondParagraph)}
                  >
                    {item.text1}
                  </Text>
                  {item.text2 ? (
                    <Text as="p" variant="bodyText" sx={styles.text2}>
                      {item.text2}
                    </Text>
                  ) : null}
                  {item.link?.length ? (
                    <BemActionArea>
                      <BemLink
                        to={item.link}
                        sx={styles.link}
                        variant={
                          item.linkIsSecondary
                            ? 'links.buttonSecondary'
                            : 'links.buttonPrimary'
                        }
                        additionalTrackingIdInfo={additionalTrackingIdInfo}
                      />
                    </BemActionArea>
                  ) : null}
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0058, IPropsBemM0058 };
