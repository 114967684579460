import { TAdditionalTrackingIdInfo, TGraphqlMediaItem } from '@bemer/base';
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';
import { BemCloudImage } from '../CloudImage';
import { BemCloudVideo, TPlayButtonPosition } from '../CloudVideo';

interface IPropsBemMedia {
  media: TGraphqlMediaItem;
  forcedAspectRatio?: number | number[];

  coverAvailableSpace?: boolean;
  withTransparentBackground?: boolean;

  playButtonPosition?: TPlayButtonPosition;
  playButton?: JSX.Element;
  playing?: boolean;
  autoplayPreview?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onError?: (error: Event) => void;

  className?: string;
  sx?: ThemeUIStyleObject;
  playButtonWrapperSx?: ThemeUIStyleObject;
  testId?: string;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const BemMedia = ({
  media,
  forcedAspectRatio,

  coverAvailableSpace,
  withTransparentBackground,

  playButtonPosition,
  playButton,
  playing,
  autoplayPreview,
  onPlay,
  onPause,
  onEnded,
  onError,

  className,
  sx,
  playButtonWrapperSx,
  testId,

  additionalTrackingIdInfo = '',
}: IPropsBemMedia): JSX.Element | null => {
  if (!media) {
    return null;
  }

  const mediaObject =
    media._type === 'videoSet' ? getLanguageSpecificVideo(media) : media;

  return mediaObject._type === 'imageWithAlt' ? (
    <BemCloudImage
      image={mediaObject}
      forcedAspectRatio={forcedAspectRatio}
      coverAvailableSpace={coverAvailableSpace}
      withTransparentBackground={withTransparentBackground}
      className={className}
      sx={sx}
      testId={testId}
      additionalTrackingIdInfo={additionalTrackingIdInfo}
    />
  ) : (
    <BemCloudVideo
      video={mediaObject}
      forcedAspectRatio={forcedAspectRatio}
      playButtonPosition={playButtonPosition}
      playButton={playButton}
      playing={playing}
      autoplayPreview={autoplayPreview}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnded}
      onError={onError}
      sx={sx}
      playButtonWrapperSx={playButtonWrapperSx}
      testId={testId}
      additionalTrackingIdInfo={additionalTrackingIdInfo}
    />
  );
};

export { BemMedia, IPropsBemMedia };
