import { aspectRatios, IGraphqlM0033, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0033 extends IGraphqlM0033 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  contentItem: {
    alignItems: 'center',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 32, 32],

    '&:last-of-type': {
      pb: 0,
    },
  },
  heading: {
    pb: ASIDE_HEADLINE_PB,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  image: (isEven: boolean) => ({
    gridRow: 1,
    gridColumn: isEven
      ? ['1 / -1', '8 / -1', '8 / -1']
      : ['1 / -1', '1 / span 6', '1 / span 6'],
    pb: ASIDE_MEDIA_PB,
  }),
  text: (isEven: boolean) => ({
    gridRow: [2, 1, 1],
    gridColumn: isEven
      ? ['1 / -1', '2 / span 5', '2 / span 5']
      : ['1 / -1', '8 / -1', '8 / -1'],
  }),
};

const BemM0033 = ({
  items,
  theme: moduleTheme,
}: IPropsBemM0033): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0033-bemModuleWrapper"
      >
        {items.map((item, index) => (
          <Grid variant="contentGrid" sx={styles.contentItem} key={item._key}>
            <Box sx={calculatedStyles.text(index % 2)}>
              <BemHeading as="h2" variant="h2" sx={styles.heading}>
                {item.title}
              </BemHeading>
              <Text as="p" variant="caption.small">
                {item.text}
              </Text>
            </Box>
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
              sx={calculatedStyles.image(index % 2)}
              additionalTrackingIdInfo={index + 1}
            />
          </Grid>
        ))}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0033, IPropsBemM0033 };
