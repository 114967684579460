import {
  IGraphqlTranslation,
  IVisitorSendContactRequestPayload,
  TGraphqlRichTextBlocks,
} from '@bemer/base';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Box, Flex, Grid, Input, Label, Text, Textarea } from 'theme-ui';
import {
  BemAvatar,
  BemButton,
  BemHeading,
  BemLink,
  BemRichtext,
} from '../../components';
import { GRID_GAP_DESKTOP } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { tinyTextCapsize } from '../../gatsby-plugin-theme-ui/typography';
import { useGetPartnerData } from '../../hooks/useGetPartnerData';
import { getTranslation } from '../../utils/translations';

const styles: IStylesObject = {
  distributorWrapper: {
    gridColumn: ['span 12', 'span 12', 'span 4'],
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(4, min-content) 1fr',
    alignContent: 'start',
  },
  distributorDescription: {
    pb: [6, 4, 4],
  },
  distributorDetails: {
    gridTemplateColumns: ['auto 1fr', 'auto 1fr', '1fr'],
    alignItems: 'start',
    pb: 4,
  },
  distributorImage: {
    width: [14, 20, 24],
    height: [14, 20, 24],
    borderRadius: 'full',
    overflow: 'hidden',
    mb: [0, 0, 4],
  },
  distributorChangeLink: {
    alignSelf: 'end',
    mb: [6, 4, 0],
  },
  formWrapper: {
    gridColumn: ['span 12', 'span 12', 'span 8'],
    gridTemplateColumns: '1fr',
    borderLeftStyle: 'solid',
    borderLeftColor: 'textMuted',
    borderLeftWidth: [0, 0, 'px'],
    pl: [0, 0, GRID_GAP_DESKTOP],
  },
  formActionArea: {
    gridColumn: '1 / -1',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 4,
  },
  heading: {
    pb: 6,
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
  },
  changeDistributorButton: {
    cursor: 'pointer',
    pl: 0,
    pr: 0,
    pt: 8,
    lineHeight: '22px',
    backgroundColor: 'transparent',
    fontFamily: 'body',
    borderRadius: 0,
    ...tinyTextCapsize,
  },
};

interface IPropsModalContactForm {
  onSubmit: (data: IVisitorSendContactRequestPayload) => void;
  onCancel: () => void;
  onChangeDistributor: () => void;
  blocksFormLegalText: TGraphqlRichTextBlocks;
  T: IGraphqlTranslation[];
}
const ModalContactForm = ({
  onSubmit,
  onCancel,
  onChangeDistributor,
  blocksFormLegalText,
  T,
}: IPropsModalContactForm): JSX.Element | null => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<IVisitorSendContactRequestPayload>({ mode: 'onChange' });

  const partnerData = useGetPartnerData();
  if (!partnerData) {
    return null;
  }

  return (
    <Grid variant="contentGrid">
      <Grid
        variant="contentGrid"
        sx={styles.distributorWrapper}
        data-testid="ModalContactForm-distributorWrapper"
      >
        <BemHeading as="h3" variant="h3" sx={styles.heading}>
          {getTranslation('YOUR_DISTRIBUTOR_HEADLINE', T)}
        </BemHeading>
        <Text as="span" sx={styles.distributorDescription}>
          {getTranslation('YOUR_DISTRIBUTOR_COPY_TEXT', T)}
        </Text>
        <Grid variant="contentGrid" sx={styles.distributorDetails}>
          <BemAvatar sx={styles.distributorImage} />
          <Text as="span">
            <strong>{partnerData?.finderData.partnerFinderName}</strong>
            <br />
            {partnerData?.finderData.shortAddress
              ? partnerData?.finderData.shortAddress
              : null}
            <br />
            {partnerData?.finderData.partnerFinderCompany
              ? partnerData?.finderData.partnerFinderCompany
              : null}
            <br />
            <BemLink
              to={{
                _key: 'key-externalLink-url',
                _type: 'externalLink',
                label: `${partnerData?.partnerData.handle}.bemergroup.com`,
                target: '_blank',
                externalLink: `https://${partnerData?.partnerData.handle}.bemergroup.com`,
              }}
            />
            <br />
            {partnerData?.finderData.partnerFinderPhone ? (
              <BemLink
                to={{
                  _key: 'key-externalLink-tel',
                  _type: 'externalLink',
                  label: partnerData?.finderData.partnerFinderPhone || '',
                  target: '',
                  externalLink: `tel:${partnerData?.finderData.partnerFinderPhone.replace(
                    ' ',
                    ''
                  )}`,
                }}
              />
            ) : null}
          </Text>
        </Grid>
        <Box sx={styles.distributorChangeLink}>
          <BemButton
            variant="buttons.linkDefault"
            type="button"
            onClick={onChangeDistributor}
            sx={styles.changeDistributorButton}
            additionalTrackingIdInfo="changeDistributor"
            data-testid="ModalContactForm-changeDistributorButton"
          >
            {getTranslation('YOUR_DISTRIBUTOR_ACTION_CHANGE_DISTRIBUTOR', T)}
          </BemButton>
        </Box>
      </Grid>

      <Grid
        variant="contentGrid"
        sx={styles.formWrapper}
        data-testid="ModalContactForm-formWrapper"
      >
        <BemHeading as="h3" variant="h3" sx={styles.heading}>
          {getTranslation('FORM_HEADLINE', T)}
        </BemHeading>

        <Grid as="form" variant="formGrid" onSubmit={handleSubmit(onSubmit)}>
          <Grid variant="formInputGrid">
            <Input
              data-testid="ModalContactForm-name"
              placeholder={getTranslation('FORM_FIELD_NAME_LABEL', T)}
              {...register('name', { required: true })}
            />
            <Label htmlFor="name">
              {getTranslation('FORM_FIELD_NAME_LABEL', T)}
            </Label>
            {errors.name && (
              <Text as="em" variant="formInputError">
                {getTranslation('FORM_FIELD_NAME_ERROR_GENERIC', T)}
              </Text>
            )}
          </Grid>

          <Grid variant="formInputGrid">
            <Input
              data-testid="ModalContactForm-mail"
              placeholder={getTranslation('FORM_FIELD_EMAIL_LABEL', T)}
              {...register('email', { required: true, pattern: /.+@.+/ })}
            />
            <Label htmlFor="email">
              {getTranslation('FORM_FIELD_EMAIL_LABEL', T)}
            </Label>
            {errors.email && (
              <Text as="em" variant="formInputError">
                {getTranslation('FORM_FIELD_EMAIL_ERROR_GENERIC', T)}
              </Text>
            )}
          </Grid>

          <Grid variant="formInputGrid">
            <Textarea
              data-testid="ModalContactForm-textarea"
              rows={6}
              placeholder={getTranslation('FORM_FIELD_QUESTION_LABEL', T)}
              {...register('message', { required: true })}
            />
            <Label htmlFor="message">
              {getTranslation('FORM_FIELD_QUESTION_LABEL', T)}
            </Label>
            {errors.message && (
              <Text as="em" variant="formInputError">
                {getTranslation('FORM_FIELD_QUESTION_ERROR_GENERIC', T)}
              </Text>
            )}
          </Grid>

          <Grid variant="formInputGrid">
            <BemRichtext blocks={blocksFormLegalText} />
          </Grid>

          <Flex sx={styles.formActionArea}>
            <BemButton
              variant="buttons.tertiarySmall"
              type="button"
              onClick={onCancel}
              sx={styles.backButton}
              additionalTrackingIdInfo="back"
              data-testid="ModalContactForm-backButton"
            >
              <Box style={{ height: '2rem', width: '2rem' }}>
                <BsArrowLeftShort size="100%" />
              </Box>
              {getTranslation('FORM_ACTION_BACK', T)}
            </BemButton>
            <BemButton
              data-testid="ModalContactForm-submitButton"
              disabled={isSubmitting || !isValid}
              additionalTrackingIdInfo="submit"
            >
              {getTranslation('FORM_ACTION_SUBMIT', T)}
            </BemButton>
          </Flex>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ModalContactForm };
