import { aspectRatios, IGraphqlM0037, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemGallery,
  BemHeading,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  MOBILE_MODULE_INNER_SECTION_PT,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0037 extends IGraphqlM0037 {}

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    pb: ASIDE_HEADLINE_PB,
  },
  gallery: {
    gridColumn: ['1 / -1', '2 / span 7', '2 / span 7'],
    overflow: 'hidden',
  },
  textWrapper: {
    pt: [MOBILE_MODULE_INNER_SECTION_PT, 12, 28],
    gridColumn: ['2 / -2', '9 / span 4', '10 / span 4'],
  },
};

const BemM0037 = ({
  title,
  text,
  items,
  theme: moduleTheme,
}: IPropsBemM0037): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0037-bemModuleWrapper"
      >
        <BemGallery
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_4_3].ratio}
          items={items}
          navigationButtonsStyle="outside"
          previewItemForcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
          showCarousel={!isMobile()}
          sx={styles.gallery}
          theme={moduleTheme || theme}
        />
        <Box sx={styles.textWrapper}>
          <BemHeading as="h2" variant="h2" sx={styles.heading}>
            {title}
          </BemHeading>
          <Text as="p" variant="bodyText">
            {text}
          </Text>
        </Box>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0037, IPropsBemM0037 };
