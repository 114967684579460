import {
  buildInternalRoute,
  determineUsedComponentTheme,
  TAdditionalTrackingIdInfo,
  TGraphqlLink,
  TGraphqlLinkItem,
} from '@bemer/base';
import React, { ReactNode, useContext } from 'react';
import { FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';
import { Box, Link as ThemeUiLink, ThemeUIStyleObject } from 'theme-ui';
import { TLinkVariant } from '../../gatsby-plugin-theme-ui/links';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  LocalisationContext,
  ModuleContext,
  ThemeHierarchyContext,
} from '../../providers';
import { BemThemeWrapper } from '../ThemeWrapper';
import InternalLink from './InternalLink';

interface IPropsBemLink {
  /**
   * Can be an object of type `TGraphqlLink` or `TGraphqlLinkItem`
   * which will contain either an array of internal and external links
   * or a single external or internal link with `children` (label) and `target`.
   */
  to: TGraphqlLink | TGraphqlLinkItem;
  /**
   * If children is not undefined, it will be used as rendered content and overwrites the content in
   * to.label if `IGraphqlExternalLink` or `IGraphqlInternalLink` is given as `to`.
   * @default undefined
   */
  children?: ReactNode;

  /**
   * Only used for internal links. It sets the link into active state if only a part of the URL
   * matches it's to prop.
   * @see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#show-active-styles-for-partially-matched-and-parent-links
   * @default false
   */
  partiallyActive?: boolean;

  /**
   * Used to change the variant of the link.
   * @default 'links.default'
   */
  variant?: TLinkVariant;

  /**
   * The sx overwrites used in the link component itself. It is applied to external links (ThemeUiLink)
   * and internal links (GatsbyLink)
   *
   *
   * @default undefined
   */
  sx?: ThemeUIStyleObject;

  /**
   * icons can be set before and after the label, an `iconBefore` will replace the links for link type tel: and mailto:
   */
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const styles: IStylesObject = {
  gatsby: {
    variant: 'links.default',
  },
  icon: {
    display: 'inline',
    px: 1,
    verticalAlign: 'middle',
  },
};

/**
 * additional props are spread here, because otherwise BemLink would not accept
 * role or aria-label props without destructuring them explicitly
 */

const componentName = 'BemLink';
const BemLink = ({
  to,
  sx,
  children,
  partiallyActive = false,
  variant = 'links.default',
  iconBefore,
  iconAfter,
  additionalTrackingIdInfo = '',
  ...props
}: IPropsBemLink): JSX.Element | null => {
  // Check for sanity live preview.
  if (!to) {
    return null;
  }

  const { moduleName } = useContext(ModuleContext);
  const { localeIdentifier } = useContext(LocalisationContext);

  const linkTo = Array.isArray(to) ? to[0] : to;
  let resolvedTo = '';

  if (!linkTo) {
    return null;
  }

  // IGraphqlInternalLink
  if (linkTo.to) {
    resolvedTo = buildInternalRoute(linkTo.to, localeIdentifier);
    if (linkTo.anchor) {
      resolvedTo = `${resolvedTo}#${linkTo.anchor}`;
    }
  }
  // IGraphqlExternalLink
  else if (linkTo.externalLink) {
    resolvedTo = linkTo.externalLink;
  }

  const label: ReactNode | string = children || linkTo.label;

  let icon;
  switch (true) {
    case resolvedTo?.includes('tel:'):
      icon = <FaPhoneAlt />;
      break;

    case resolvedTo?.includes('mailto:'):
      icon = <FaRegEnvelope />;
      break;

    default:
      break;
  }
  const [theme] = useContext(ThemeHierarchyContext);
  const { moduleTheme } = useContext(ModuleContext);

  const usedThemeName = determineUsedComponentTheme(theme, moduleTheme);

  const trackingId = `${moduleName}-${componentName}-${variant?.replace(
    '.',
    '_'
  )}${additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''}`;

  const checkButtonStyle = () => {
    if (variant.includes('button')) {
      return variant;
    }
    return null;
  };

  if (linkTo._type === 'externalLink' || linkTo._type === 'externalImageLink') {
    const linkTargetFallback = resolvedTo.includes('://') ? '_blank' : '';
    const componentThemeUiLink = (
      <ThemeUiLink
        sx={sx}
        variant={variant}
        href={resolvedTo}
        rel="noreferrer"
        target={linkTo.target || linkTargetFallback}
        data-trackingid={trackingId}
        {...props}
      >
        {iconBefore || icon ? (
          <Box sx={styles.icon}>{iconBefore || icon}</Box>
        ) : null}
        {label}
        {iconAfter ? <Box sx={styles.icon}>{iconAfter}</Box> : null}
      </ThemeUiLink>
    );

    if (checkButtonStyle()) {
      return (
        <BemThemeWrapper themeName={usedThemeName}>
          {componentThemeUiLink}
        </BemThemeWrapper>
      );
    }
    return componentThemeUiLink;
  }

  // Internal links use the Gatsby Link.
  const componentInternalLink = (
    <InternalLink
      sx={sx}
      activeClassName="active"
      partiallyActive={partiallyActive}
      variant={variant}
      to={resolvedTo}
      data-trackingid={trackingId}
      {...props}
    >
      {iconBefore ? <Box sx={styles.icon}>{iconBefore}</Box> : null}
      {label}
      {iconAfter ? <Box sx={styles.icon}>{iconAfter}</Box> : null}
    </InternalLink>
  );
  if (checkButtonStyle()) {
    return (
      <BemThemeWrapper themeName={usedThemeName}>
        {componentInternalLink}
      </BemThemeWrapper>
    );
  }
  return componentInternalLink;
};

export { BemLink, IPropsBemLink };
