import { TEvent } from '@bemer/base';
import { useActor } from '@xstate/react';
import React from 'react';
import { Marker } from 'react-map-gl';
import { Box } from 'theme-ui';
import { BemAvatar } from '../../../components';
import { ICalculatedStylesObject } from '../../../gatsby-plugin-theme-ui/moduleTypes';
import { useGetPartnerData } from '../../../hooks/useGetPartnerData';
import { isEventAdvertised } from '../utils/events';
import { TEventCardMachineActorRef } from './EventCard/BemEventCard.machine';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 35;

const calculatedStyles: ICalculatedStylesObject = {
  avatar: () => ({
    borderRadius: 'full',
    position: 'absolute',
    transform: 'translate(-50%, -33px)',
    zIndex: 1,
  }),
  svg: (size, isActive) => ({
    height: `${size}px`,
    cursor: 'pointer',
    fill: isActive ? 'secondary' : 'primary.6',
    stroke: 'none',
  }),
};

// Important for perf: the markers never change, avoid rerender when the map viewport changes
interface IPin {
  event: TEvent;
  cardRef: TEventCardMachineActorRef;
  onClick: (event: TEvent) => void;
}
const Pin = ({ event, cardRef, onClick }: IPin) => {
  const [{ context }, send] = useActor(cardRef);
  const partnerData = useGetPartnerData();

  const isAdvertised = isEventAdvertised(event, partnerData);
  const { isBemerEvent, creator } = event;
  const partnerId = isBemerEvent ? creator.navisionCustomerId : undefined;

  return (
    <Marker
      className={context.isActive ? 'active' : ''}
      longitude={event.venue.lng}
      latitude={event.venue.lat}
    >
      <Box
        onMouseEnter={() => send({ type: 'MOUSE_ENTER_MARKER', id: event.id })}
        onMouseLeave={() => send({ type: 'MOUSE_LEAVE_MARKER' })}
        onClick={() => onClick(event)}
        sx={{ transform: `translate(${(SIZE * -1) / 2}px)` }}
      >
        {isAdvertised ? (
          <BemAvatar
            size="tiny"
            sx={calculatedStyles.avatar()}
            forcePartnerId={partnerId}
          />
        ) : null}
        <Box
          as="svg"
          // @ts-ignore svg has a viewbox
          viewBox="0 0 24 24"
          sx={calculatedStyles.svg(SIZE, context.isActive)}
        >
          <path d={ICON} />
        </Box>
      </Box>
    </Marker>
  );
};

const MemoizedPin = React.memo(Pin);

export { MemoizedPin as default };
