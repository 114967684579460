import { IGraphqlM0009 } from '@bemer/base';
import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { BemHeading, BemModuleWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MOBILE_HEADLINE_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0009 extends IGraphqlM0009 {}

const styles: IStylesObject = {
  title: {
    gridColumn: ['2 / span 12', '3 / 6', '3 / 6'],
    pb: [MOBILE_HEADLINE_PB, 0, 0],
  },
  description: {
    gridColumn: ['2 / span 12', '3 / 7', '3 / 7'],
    pb: [MOBILE_HEADLINE_PB, 0, 0],
    fontWeight: 'bold',
  },
  item: {
    pt: [4, 0, 0],
    pb: [4, 12, 16],
    alignItems: ['center', 'start', 'start'],
    justifyContent: 'space-between',
    flexDirection: ['row', 'column', 'column'],
    borderBottomWidth: ['px', 'none', 'none'],
    borderBottomStyle: ['solid', 'none', 'none'],
    borderBottomColor: ['gray', 'none', 'none'],

    '&:last-of-type': {
      pb: 0,
      borderBottomWidth: 'none',
      borderBottomStyle: 'none',
      borderBottomColor: 'none',
    },
  },
  heading: {
    pb: [0, 4, 4],
    fontWeight: 'body',
    flexShrink: 0,
    width: ['50%', 'auto', 'auto'],
    textAlign: 'start',
    pr: [1, 0, 0],
  },
  text: {
    width: ['50%', 'auto', 'auto'],
    textAlign: ['end', 'start', 'start'],
    pl: [1, 0, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemsWrapper: (withTitle: boolean) => ({
    gridColumn: ['2 / -2', '9 / -2', '9 / -2'],
    pt: withTitle ? [0, 2, 2] : [0, 0, 0],
  }),
};
const BemM0009 = ({
  title,
  items,
  description,
}: IPropsBemM0009): JSX.Element | null => {
  if (!items) {
    return null;
  }
  return (
    <BemModuleWrapper>
      {title ? (
        <BemHeading as="h2" variant="h2" sx={styles.title}>
          {title}
        </BemHeading>
      ) : (
        <Text as="h4" variant="h4" sx={styles.description}>
          {description}
        </Text>
      )}
      <Box sx={calculatedStyles.itemsWrapper(!!title)}>
        {items.map((item) => (
          <Flex key={item._key} sx={styles.item} data-testid={item._key}>
            <BemHeading as="h3" variant="h2" sx={styles.heading}>
              {item.title}
            </BemHeading>
            <Text as="p" sx={styles.text}>
              {item.text}
            </Text>
          </Flex>
        ))}
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0009, IPropsBemM0009 };
