import { THEME_NAMES, TThemeName } from '@bemer/base';
import React, { ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from 'theme-ui';
import colors from '../../gatsby-plugin-theme-ui/colors';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MODULE_MB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemPage {
  children: ReactNode;
}

const styles: IStylesObject = {
  body: {
    // "Removes" the margin bottom of the last module.
    mb: MODULE_MB.map((v: number | string) => `-${v}`),
    bg: 'bodyBackground',
  },
  content: {
    minHeight: '100vh',
    mx: 'auto',
    bg: 'pageBackground',
  },
};

type TFavicons = {
  [k in TThemeName]?: { icon: string; color: string };
};

const BemPage = ({ children }: IPropsBemPage): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  const favicons = {
    [THEME_NAMES.HUMAN_LINE]: {
      icon: '/assets/Favicon-HumanLine.svg',
      // eslint-disable-next-line no-underscore-dangle
      color: colors.secondary.__default,
    },
    [THEME_NAMES.VETERINARY_LINE]: {
      icon: '/assets/Favicon-AnimalLine.svg',
      // eslint-disable-next-line no-underscore-dangle
      color: colors.darkPetrol.__default,
    },
  } as TFavicons;

  const faviconSelector = favicons[theme] || favicons[THEME_NAMES.HUMAN_LINE];

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={faviconSelector?.icon}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="16x16"
          href={faviconSelector?.icon}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="32x32"
          href={faviconSelector?.icon}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="96x96"
          href={faviconSelector?.icon}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="128x128"
          href={faviconSelector?.icon}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          sizes="196x196"
          href={faviconSelector?.icon}
        />
        <link
          rel="mask-icon"
          href="/assets/Pinned-Tab-Icon.svg"
          color={faviconSelector?.color}
        />

        <link rel="shortcut icon" href={faviconSelector?.icon} />
        <meta name="msapplication-TileColor" content={faviconSelector?.color} />
        <meta name="msapplication-TileImage" content={faviconSelector?.icon} />
        <meta
          name="msapplication-square70x70logo"
          content={faviconSelector?.icon}
        />
        <meta
          name="msapplication-square150x150logo"
          content={faviconSelector?.icon}
        />
        <meta
          name="msapplication-square310x310logo"
          content={faviconSelector?.icon}
        />
        <meta name="theme-color" content={faviconSelector?.color} />
      </Helmet>
      <Box sx={styles.body}>
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </>
  );
};

export { BemPage, IPropsBemPage };
