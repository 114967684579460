import { aspectRatios, IGraphqlImage, RATIOS } from '@bemer/base';
import { alpha } from '@theme-ui/color';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Box, Flex } from 'theme-ui';
import { BemAvatar, BemCloudImage, BemPill } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { ApiContext, ModuleContext } from '../../providers';

const FLOATING_AVATAR_BUTTON_VISIBLE = 'visible';
const FLOATING_AVATAR_BUTTON_HIDDEN = 'hidden';

const styles: IStylesObject = {
  imageButtonFlexBox: {
    height: 0, // Makes the main content of the parent element not visible, so it can not cover click areas of other modules.
    gridColumn: '2 / -2',
    justifyContent: 'flex-end',
  },
  imageButtonWrapper: {
    boxSizing: 'content-box',
    width: [14, 20, 24],
    height: [14, 20, 24],
    position: 'fixed',
    bottom: 6,
    mb: 0,
    zIndex: 6,
    borderRadius: 'full',
    borderWidth: [2, 3, 3],
    borderStyle: 'solid',
    borderColor: alpha('accentBackgroundHumanLine', 0.25),
    boxShadow: 'smallCardShadow',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'bigCardShadow',
    },
  },
  imageButton: {
    borderRadius: 'full',
    overflow: 'hidden',
  },
  pill: {
    position: 'absolute',
    zIndex: 1,
    top: -1,
    right: -1,
    width: [5, 6, 6],
    height: [5, 6, 6],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const animationVariants = {
  [FLOATING_AVATAR_BUTTON_VISIBLE]: {
    opacity: 1,
    scale: [0.2, 1.5, 1],
    rotate: [45, -45, 0],
    transition: {
      delay: 2,
      duration: DURATION.MEDIUM,
    },
  },
  [FLOATING_AVATAR_BUTTON_HIDDEN]: { opacity: 0, scale: 0.2 },
};
const animationProps = {
  animate: FLOATING_AVATAR_BUTTON_VISIBLE,
  initial: FLOATING_AVATAR_BUTTON_HIDDEN,
};
const MotionBox = motion(Box);

interface IPropsFloatingAvatarButton {
  image: IGraphqlImage;
  onClick: () => void;
}

const componentName = 'FloatingAvatarButton';
const FloatingAvatarButton = ({
  image,
  onClick,
}: IPropsFloatingAvatarButton): JSX.Element => {
  const {
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);

  const { data: visitorSettings } = getVisitorSettings();

  const { moduleName } = useContext(ModuleContext);
  const trackingId = `${moduleName}-${componentName}`;

  return (
    <Flex sx={styles.imageButtonFlexBox} data-trackingid={trackingId}>
      <MotionBox
        as="button"
        variant="buttons.avatarWrapper"
        data-testid="FloatingAvatarButton-motionBox"
        sx={styles.imageButtonWrapper}
        onClick={onClick}
        variants={animationVariants}
        {...animationProps}
      >
        <BemPill
          backgroundColor="accentBackgroundHumanLine"
          color="white"
          size="small"
          sx={styles.pill}
        >
          1
        </BemPill>

        {visitorSettings?.partnerHandle ? (
          <BemAvatar sx={styles.imageButton} />
        ) : (
          <BemCloudImage
            image={image}
            sx={styles.imageButton}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
          />
        )}
      </MotionBox>
    </Flex>
  );
};

export { FloatingAvatarButton };
