import {
  aspectRatios,
  IGraphqlM0062,
  IGraphqlM0062Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0062 extends IGraphqlM0062 {}

interface IPropsBemM0062Item {
  item: IGraphqlM0062Item;
  index: number;
}
interface IPropsBemM0062Items {
  items: IGraphqlM0062Item[];
}

const PREFIX = 'M0062';

const styles: IStylesObject = {
  heading: {
    gridColumn: ['2 / -2', '3 / -3', '4 / -4'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
    pt: 1,
  },
  itemWrapper: {
    gridColumn: 'span 4',
    '&:first-of-type': {
      gridColumn: '2 / span 4',
    },
  },
  textContentWrapper: {
    px: [6, 0, 0],
    pb: [6, 0, 0],
  },
  itemTitle: {
    pt: [6, 7, 9],
    pb: [4, 5, 7],
  },
};

const Item = ({ item, index }: IPropsBemM0062Item) => (
  <Box key={item._key} sx={styles.itemWrapper} data-testid={item._key}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_4_3].ratio}
      additionalTrackingIdInfo={index + 1}
    />
    <Box sx={styles.textContentWrapper}>
      <BemHeading as="h4" variant="h4" sx={styles.itemTitle}>
        {item.title}
      </BemHeading>
      <Text as="p" variant="bodyText">
        {item.text}
      </Text>
    </Box>
  </Box>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0062Items) => (
  <>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </>
);

const renderItem = ({ item, index }: IPropsBemM0062Item) => (
  <Item item={item} index={index} />
);

const BemM0062 = ({ title, items }: IPropsBemM0062): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    {useViewportRenderer([
      <BemTouchSlider
        key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        items={items}
        itemRenderer={(data, index) => renderItem({ item: data.item, index })}
      />,
      <ItemsOnTabletOrLarger
        items={items}
        key={`${PREFIX}_${VIEWPORTS.TABLET}`}
      />,
      <ItemsOnTabletOrLarger
        items={items}
        key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
      />,
    ])}
  </BemModuleWrapper>
);

export { BemM0062, IPropsBemM0062, IPropsBemM0062Item, IPropsBemM0062Items };
