import { aspectRatios, IGraphqlM0011, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import { BemMedia, BemModuleWrapper, BemQuoteWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ASIDE_MEDIA_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { LocalisationContext } from '../../providers';

interface IPropsBemM0011 extends IGraphqlM0011 {}

const styles: IStylesObject = {
  image: {
    gridColumn: ['1 / -1', '1 / 8', '1 / 8'],
    pb: ASIDE_MEDIA_PB,
  },
  contentWrapper: {
    gridColumn: ['3 / -3', '9 / -2', '9 / -2'],
    alignSelf: 'center',
  },
  text: {
    pb: 10,
  },
  testimonial: {
    color: 'textMuted',
    fontWeight: 'bold',
  },
};

const BemM0011 = ({
  name,
  jobTitle,
  text,
  media,
}: IPropsBemM0011): JSX.Element => {
  const { locale } = useContext(LocalisationContext);
  return (
    <BemModuleWrapper>
      <BemMedia
        media={media[0]}
        sx={styles.image}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_6_5].ratio}
      />
      <Box sx={styles.contentWrapper} data-testid="BemM0011-contentWrapper">
        <Text as="p" variant="caption.big" sx={styles.text}>
          <BemQuoteWrapper localeId={locale.id}>{text}</BemQuoteWrapper>
        </Text>
        <Text as="p" sx={styles.testimonial}>
          {name}, <br />
          {jobTitle}
        </Text>
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0011, IPropsBemM0011 };
