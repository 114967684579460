/* eslint-disable no-underscore-dangle */
import {
  API_ENDPOINTS,
  buildInternalRoute,
  IGraphqlM0149,
  TGraphqlLinkItem,
} from '@bemer/base';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import { FaAlignLeft, FaTimes } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Box, Spinner, ThemeUIStyleObject } from 'theme-ui';
import { BemLink, BemModuleWrapper, BemRichtext } from '../../components';
import {
  defaultGridGap,
  GRID_GAP_MOBILE,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import opacities from '../../gatsby-plugin-theme-ui/opacities';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { LocalisationContext } from '../../providers';
import { isPartialMatch } from '../../utils/isPartialMatch';

interface IPropsBemM0149 extends IGraphqlM0149 {
  sx?: ThemeUIStyleObject;
}

const MotionBox = motion(Box);
const BACKDROP_OPEN = 'open';
const BACKDROP_CLOSED = 'closed';
const BACKDROP_EXIT = 'exit';
const TOGGLE_OPEN = 'open';
const TOGGLE_CLOSE = 'close';

const NAV_LIST_PADDING_X = 4;
const NAV_LIST_ICON_PADDING_TOP = 2;
const NAV_LIST_PADDING_TOP = 9;

const animationVariants = {
  navListToggle: {
    [TOGGLE_OPEN]: {
      left: 0,
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
    [TOGGLE_CLOSE]: {
      left: '-100vw',
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
  },
  sectionToggle: {
    [TOGGLE_OPEN]: {
      rotate: 180,
    },
    [TOGGLE_CLOSE]: {
      rotate: 0,
    },
  },
  sections: {
    [TOGGLE_OPEN]: {
      height: 'auto',
      opacity: 1,
      visibility: 'visible',
    },
    [TOGGLE_CLOSE]: {
      height: 0,
      opacity: 0,
      transition: {
        staggerChildren: DURATION.MEDIUM,
      },
      transitionEnd: { visibility: 'hidden' },
    },
  },
  backdrop: {
    [BACKDROP_OPEN]: {
      opacity: opacities.backdropOpacity,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
    [BACKDROP_CLOSED]: {
      opacity: 0,
    },
    [BACKDROP_EXIT]: {
      opacity: 0,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
  },
} as const;

const componentName = 'BemM0149';

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: [8, 12, 16],
  },
  textWrapper: {
    gridColumn: '2 / -2',
  },

  tableOfContentsWrapper: {
    gridColumn: ['2 / span 3', '2 / span 4', '2 / span 3'],
    backgroundColor: 'gray.2',
    pt: [0, NAV_LIST_PADDING_TOP, NAV_LIST_PADDING_TOP],
    pb: 6,
    alignSelf: 'flex-start',
    position: ['absolute', 'static', 'static'],
    zIndex: 99,
  },
  navListIcon: {
    gridColumn: ['2 / span 1', null, null],
    cursor: 'pointer',
    alignSelf: 'flex-start',
    display: ['inline-block', 'none', 'none'],
    pt: NAV_LIST_ICON_PADDING_TOP,
  },
  navListIconClosed: {
    px: NAV_LIST_PADDING_X - GRID_GAP_MOBILE,
  },
  navListIconOpen: {
    pb: NAV_LIST_PADDING_TOP,
  },
  navList: {
    px: NAV_LIST_PADDING_X,
  },
  headerWrapper: {
    gridTemplateColumns: ['none', 'auto auto', 'auto auto'],
    pb: 12,
    gridGap: defaultGridGap,
  },
  contentWrapper: {
    gridColumn: ['4 / -2', '7 /-2', '6 / -2'],
    color: 'gray.7',
  },
  modalBackdrop: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bg: 'gray.6',
    zIndex: 98,
  },
  documentList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    backgroundColor: 'transparent',
    px: 0,
    pt: 2,
    pb: 7,
    position: 'relative',
    borderWidth: 0,
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& div:first-of-type': {
      flexGrow: 1,
    },
    '&:hover': {
      color: 'text',
    },
    '& ~ ul &': {
      pt: 0,
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  link: (isActive: boolean) => ({
    color: 'text',
    display: 'inline-block',
    width: '100%',
    fontWeight: isActive ? 700 : 400,
    '&.active': {
      color: 'text',
      fontWeight: 700,
    },
  }),
};

const backdropAnimationProps = {
  animate: BACKDROP_OPEN,
  initial: BACKDROP_CLOSED,
  exit: BACKDROP_EXIT,
};

interface IPropsModalBackdrop {
  hideBackdrop?: boolean;
  onClose: () => void;
}

const ModalBackdrop = ({ onClose, hideBackdrop }: IPropsModalBackdrop) => (
  <AnimatePresence>
    {!hideBackdrop ? (
      <MotionBox
        onClick={onClose}
        sx={styles.modalBackdrop}
        variants={animationVariants.backdrop}
        {...backdropAnimationProps}
      />
    ) : null}
  </AnimatePresence>
);
interface IPropsNavListIcon {
  sx: any;
  isNavOpen: boolean;
  toggleNavList: (isNavOpen: boolean) => void;
  isInNav: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const NavListIcon = ({
  sx,
  isNavOpen,
  toggleNavList,
  isInNav,
}: IPropsNavListIcon) => (
  <Box
    sx={{ ...styles.navListIcon, ...sx }}
    onClick={() => toggleNavList(!isNavOpen)}
  >
    {isNavOpen ? (
      <FaTimes
        data-testid={
          isInNav ? 'BemM0149MobileNavCloseIcon' : 'BemM0149MobileCloseIcon'
        }
      />
    ) : (
      <FaAlignLeft
        data-testid={
          isInNav ? 'BemM0149MobileNavOpenIcon' : 'BemM0149MobileOpenIcon'
        }
      />
    )}
  </Box>
);

const BemM0149 = ({
  legalTexts,
  rootPage,
  sx,
  ...props
}: IPropsBemM0149): JSX.Element => {
  const { locale, localeIdentifier } = useContext(LocalisationContext);

  const windowPathName =
    typeof window !== 'undefined' ? window.location.pathname : '';

  const currentLocaleId = locale.id;
  const {
    country: {
      region: { regionCode },
    },
    languageCode,
  } = localeIdentifier;
  const currentLegalDocumentId = legalTexts.find(
    (text) => text.localeId === currentLocaleId
  )?.resolvedLegalDocument;

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  if (!rootPage) return <Spinner />;

  // pageId is the id of the root page of the whole page tree.
  // The reference to the root page is defined in M0149 (above the legal texts) and must be the same on every
  const pageId = rootPage?._id; // should be always the same on every M0149 module

  const regionMapping = Object.freeze({
    EUROPE: 'DE',
    USA: 'US',
    ITALY: 'IT',
    WORLD: 'ZA',
    CANADA: 'CA',
  });

  const regionCodeShort = regionMapping[regionCode];
  const regionLocaleId = `${languageCode}_${regionCodeShort}`;

  const url = `${API_ENDPOINTS.BEMER_MARKETING}/functions/pageTree?localeId=${regionLocaleId}&pageId=${pageId}`;

  const { isLoading, data: pageTree } = useQuery(
    'legalTextsPageTree',
    async () => {
      if (window) {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      }
      return Promise.resolve({});
    }
  );

  const [isNavOpen, setIsNavOpen] = useState(false);

  if (isLoading) return <Spinner />;
  if (!pageTree) return <div>No PageTree</div>;

  const mainLevelActiveNavigationLink = pageTree.children.find(
    (navItem: TGraphqlLinkItem) =>
      isPartialMatch(
        buildInternalRoute(navItem.to, localeIdentifier),
        windowPathName
      )
  );

  const buildTree = (treeNode: any) => {
    if (treeNode.children.length === 0) return null;

    return (
      <Box as="ul" sx={styles.navList}>
        {treeNode.children.map((page: any) => {
          const isActiveLink = mainLevelActiveNavigationLink?._id === page._id;

          return (
            <React.Fragment key={page._id}>
              <Box as="li" sx={styles.documentList}>
                <Box as="button" sx={styles.listItem}>
                  <Box variant="text.nav">
                    <BemLink
                      to={page}
                      sx={calculatedStyles.link(isActiveLink)}
                    />
                  </Box>
                </Box>
                {page?.children.length > 0 ? buildTree(page) : null}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <BemModuleWrapper sx={sx} {...props} data-testid={`${componentName}`}>
      <NavListIcon
        sx={styles.navListIconClosed}
        isNavOpen={isNavOpen}
        toggleNavList={() => setIsNavOpen(!isNavOpen)}
        isInNav={false}
      />
      <AnimatePresence>
        <MotionBox
          key={`NavBox${isNavOpen}`}
          sx={styles.tableOfContentsWrapper}
          as="nav"
          variants={animationVariants.navListToggle}
          initial={isNavOpen ? TOGGLE_CLOSE : TOGGLE_OPEN}
          animate={isNavOpen ? TOGGLE_OPEN : TOGGLE_CLOSE}
          data-testid={`${componentName}NavBox`}
        >
          <Box sx={styles.navList}>
            <NavListIcon
              sx={styles.navListIconOpen}
              isNavOpen={isNavOpen}
              toggleNavList={() => setIsNavOpen(!isNavOpen)}
              isInNav
            />
            {buildTree(pageTree)}
          </Box>
        </MotionBox>
      </AnimatePresence>
      <ModalBackdrop
        onClose={() => setIsNavOpen(!isNavOpen)}
        hideBackdrop={!isNavOpen}
      />
      <Box sx={styles.contentWrapper} ref={contentWrapperRef}>
        <BemRichtext
          blocks={JSON.parse(currentLegalDocumentId?._rawRichtext || '{}')}
        />
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0149, IPropsBemM0149 };
