import {
  BEMER_PARTNER_LEVELS,
  IAddressSuggestionData,
  IGraphqlTranslation,
  IPartnerFinderData,
  TThemeName,
} from '@bemer/base';
import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  Grid,
  Input,
  Label,
  Text,
  ThemeUIStyleObject,
} from 'theme-ui';
import {
  BemAvatar,
  BemButton,
  BemHeading,
  BemSpinner,
  BemThemeWrapper,
} from '../../components';
import { GRID_GAP_DESKTOP } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import shadows from '../../gatsby-plugin-theme-ui/shadows';
import { ApiContext, ThemeHierarchyContext } from '../../providers';
import { getTranslation } from '../../utils/translations';
import { PartnerCard } from './PartnerCard';

interface IPropsPartnerFinder {
  onSubmit: (data: IPartnerFinderData) => void;
  T: IGraphqlTranslation[];
  sx?: ThemeUIStyleObject;
  theme?: TThemeName;
}

type TPartnerSuggestion = 'partnerSuggestion';
type TAddressSuggestion = 'addressSuggestion';
interface ISuggestionItem {
  type: TPartnerSuggestion | TAddressSuggestion;
  label: string;
  key: string;
  afterLabel: string;
  subLabel?: string;
  isFirstPartnerSuggestion: boolean;
  isFirstAddressSuggestion: boolean;
  data: IPartnerFinderData | IAddressSuggestionData;
}

const MAXIMUM_DISPLAYED_SUGGESTIONS = 4;

const styles: IStylesObject = {
  distributorWrapper: {
    gridColumn: ['span 12', 'span 12', 'span 4'],
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(4, min-content) 1fr',
    alignContent: 'start',
  },

  description: {
    pb: [6, 4, 4],
  },

  distributorDetails: {
    gridTemplateColumns: ['auto 1fr', 'auto 1fr', '1fr'],
    alignItems: 'start',
    pb: 4,
  },

  distributorImage: {
    width: [14, 20, 24],
    height: [14, 20, 24],
    borderRadius: 'full',
    overflow: 'hidden',
    mb: [0, 0, 4],
  },

  distributorChangeLink: {
    alignSelf: 'end',
    justifySelf: 'center',
    mb: [6, 4, 0],
  },

  formWrapper: {
    gridColumn: ['span 12', 'span 12', 'span 8'],
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'max-content max-content max-content 1fr',
    // Using a pixel value here, to prevent scrolling inside the auto suggestion dropdown.
    // (The auto suggestion dropdown can not be rendered outside the form)
    alignContent: 'start',
    borderLeftStyle: 'solid',
    borderLeftColor: 'textMuted',
    borderLeftWidth: [0, 0, 'px'],
    pl: [0, 0, GRID_GAP_DESKTOP],
  },

  heading: {
    pb: 6,
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',
  },

  autoSuggestionItemWrapper: {
    borderBottomWidth: 'px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.2',

    '&:last-of-type': {
      borderBottomColor: 'transparent',
    },
  },

  autoSuggestionHeadline: {
    // We use this theme ui concept for quick access
    // to the current theme color of text https://theme-ui.com/color-modes#colors-object
    color: 'var(--theme-ui-colors-text)',
    textAlign: 'center',
    bg: 'white',
    px: 2,
    py: 5,
  },

  autoSuggestionPartnerAvatarWrapper: {
    width: 8,
    alignItems: 'center',
  },

  autoSuggestionItemLabelWrapper: {
    flexDirection: 'column' as const,
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  autoSuggestionItemLabel: {
    color: 'var(--theme-ui-colors-text)',
    p: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  autoSuggestionItemSubLabel: {
    color: 'var(--theme-ui-colors-text)',
    pt: 2,
    px: 1,
    pb: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  partnerCardWrapper: {
    gridColumn: '2 / -2',
    pt: 5,
    justifyContent: 'space-around',
  },
  formActionArea: {
    alignSelf: 'end',
    gridColumn: '1 / -1',
    justifyContent: 'flex-end',
    alignItems: 'center',
    mt: 4,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  autoSuggestionItem: (isHighlighted: boolean) => ({
    cursor: 'pointer',
    bg: isHighlighted ? 'gray' : 'white',
    px: 4,
    height: 12,
  }),
};

const PartnerFinder = ({
  onSubmit,
  T,
  sx,
  theme: moduleTheme,
}: IPropsPartnerFinder): JSX.Element | null => {
  const [theme] = useContext(ThemeHierarchyContext);
  const [searchString, setSearchString] = useState('');
  const [selectedPartner, setSelectedPartner] = useState<
    IPartnerFinderData | undefined
  >(undefined);
  const [addressData, setAddressData] = useState<
    IAddressSuggestionData | undefined
  >(undefined);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IPartnerFinderData>();

  const {
    partner: { getPartnerSuggestions, getPartnersByLocation },
    address: { getAddressSuggestions },
  } = useContext(ApiContext);

  const { data: partnerSuggestions } = getPartnerSuggestions(
    searchString,
    BEMER_PARTNER_LEVELS.IBDT,
    {
      enabled: !!searchString,
    }
  );

  const { isFetching: isFetchingPartnerByLocation, data: partnerByLocation } =
    getPartnersByLocation(
      addressData?.geoLatitude,
      addressData?.geoLongitude,
      BEMER_PARTNER_LEVELS.BPPLUS,
      {
        enabled: !!addressData?.geoLatitude && !!addressData?.geoLongitude,
      }
    );
  const { data: addressSuggestions } = getAddressSuggestions(searchString, {
    enabled: !!searchString,
  });

  useEffect(() => {
    if (!partnerByLocation) {
      return;
    }
    setSelectedPartner(partnerByLocation[0]);
    setAddressData(undefined);
  }, [partnerByLocation]);

  const mappedPartnerSuggestions: ISuggestionItem[] =
    partnerSuggestions?.map((data, index) => ({
      type: 'partnerSuggestion',
      label: data.finderData.partnerFinderName,
      key: data.partnerData.customerNo,
      afterLabel: data.finderData.partnerFinderCompany
        ? ` - ${data.finderData.partnerFinderCompany}`
        : '',
      subLabel: data.finderData.shortAddress,
      isFirstPartnerSuggestion: !index,
      isFirstAddressSuggestion: false,
      data,
    })) || [];

  const mappedAddressSuggestions: ISuggestionItem[] =
    addressSuggestions?.map((data, index) => ({
      type: 'addressSuggestion',
      label: data.geoFormattedAddress,
      key: data.geoFormattedAddress,
      afterLabel: '',
      subLabel: data.geoFederalStateName,
      isFirstPartnerSuggestion: false,
      isFirstAddressSuggestion: !index,
      data,
    })) || [];

  // Remove duplicate labels (seen in the addresses) and reduce number of results.
  const filterAndSlice = (
    suggestions: ISuggestionItem[],
    numberOfEntries = MAXIMUM_DISPLAYED_SUGGESTIONS
  ) =>
    suggestions
      .filter(
        (data, index, suggestionsArray) =>
          index ===
          suggestionsArray.findIndex((data2) => data.label === data2.label)
      )
      .slice(0, numberOfEntries);

  const items = [
    ...mappedPartnerSuggestions.slice(0, MAXIMUM_DISPLAYED_SUGGESTIONS),
    ...filterAndSlice(mappedAddressSuggestions),
  ];

  const onSelect = (_label: string, suggestion: ISuggestionItem) => {
    if (suggestion.type === 'addressSuggestion') {
      setSelectedPartner(undefined);
      setAddressData(suggestion.data as IAddressSuggestionData);
      return;
    }

    setSelectedPartner(suggestion.data as IPartnerFinderData);
  };

  const renderInput = ({
    ...props
  }: HTMLProps<HTMLInputElement>): JSX.Element => (
    <Grid variant="formInputGrid">
      {/* @ts-ignore The types of `as` and `ref` are incompatible with ThemeUIs Input, but it works. */}
      <Input
        name="searchInput"
        placeholder={getTranslation('FORM_FIELD_SEARCH_DISTRIBUTOR_LABEL', T)}
        {...props}
        data-testid="PartnerFinderForm-select"
      />
      <Label htmlFor="searchString">
        {getTranslation('FORM_FIELD_SEARCH_DISTRIBUTOR_LABEL', T)}
      </Label>
    </Grid>
  );

  const renderItem = (
    item: ISuggestionItem,
    isHighlighted: boolean
  ): JSX.Element => (
    <Box sx={styles.autoSuggestionItemWrapper} key={item.key}>
      {item.isFirstPartnerSuggestion ? (
        <Text as="p" variant="caption.small" sx={styles.autoSuggestionHeadline}>
          {getTranslation('PARTNER_FINDER_SUGGESTION_SELECT_DISTRIBUTOR', T)}
        </Text>
      ) : null}
      {item.isFirstAddressSuggestion ? (
        <Text as="p" variant="caption.small" sx={styles.autoSuggestionHeadline}>
          {mappedPartnerSuggestions.length > 0
            ? getTranslation('PARTNER_FINDER_SUGGESTION_SELECT_ADDRESS', T)
            : getTranslation(
                'PARTNER_FINDER_SUGGESTION_SELECT_ADDRESS_WITH_NO_FOUND_DISTRIBUTOR',
                T
              )}
        </Text>
      ) : null}
      <Flex sx={calculatedStyles.autoSuggestionItem(isHighlighted)}>
        {item.type === 'partnerSuggestion' && 'partnerData' in item.data ? (
          <Flex sx={styles.autoSuggestionPartnerAvatarWrapper}>
            <BemAvatar
              forcePartnerId={item.data.partnerData.customerNo}
              size="tiny"
            />
          </Flex>
        ) : null}
        <Flex sx={styles.autoSuggestionItemLabelWrapper}>
          <Text as="p" variant="small" sx={styles.autoSuggestionItemLabel}>
            <strong>{item.label}</strong>
            {item.afterLabel}
          </Text>
          {item.subLabel ? (
            <Text as="p" variant="small" sx={styles.autoSuggestionItemSubLabel}>
              {item.subLabel}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <Grid variant="contentGrid" sx={sx}>
        <Grid
          variant="contentGrid"
          sx={styles.distributorWrapper}
          data-testid="PartnerFinderForm-distributorWrapper"
        >
          <BemHeading
            themeName={moduleTheme || theme}
            as="h3"
            variant="h3"
            sx={styles.heading}
          >
            {getTranslation('PARTNER_FINDER_SEARCH_DISTRIBUTOR_HEADLINE', T)}
          </BemHeading>
          <Text as="span" sx={styles.description}>
            {getTranslation('PARTNER_FINDER_SEARCH_DISTRIBUTOR_COPY_TEXT', T)}
          </Text>
        </Grid>

        <Grid
          variant="contentGrid"
          sx={styles.formWrapper}
          data-testid="PartnerFinderForm-formWrapper"
        >
          <BemHeading
            themeName={moduleTheme || theme}
            as="h3"
            variant="h3"
            sx={styles.heading}
          >
            {getTranslation(
              'PARTNER_FINDER_SEARCH_YOUR_DISTRIBUTOR_HEADLINE',
              T
            )}
          </BemHeading>
          <Text as="span" sx={styles.description}>
            {getTranslation(
              'PARTNER_FINDER_SEARCH_YOUR_DISTRIBUTOR_COPY_TEXT',
              T
            )}
          </Text>
          <Autocomplete
            getItemValue={(item) => item.label}
            items={items}
            wrapperStyle={{ display: 'block', position: 'relative' }}
            menuStyle={{
              border: '1px solid #F2F2F2',
              boxShadow: shadows.smallCardShadow,
              zIndex: 4,
              position: 'absolute',
              top: '55px',
              left: 0,
              maxWidth: '100%',
            }}
            renderInput={renderInput}
            renderItem={renderItem}
            value={searchString}
            onChange={(changeEvent) =>
              setSearchString(changeEvent.target.value)
            }
            onSelect={onSelect}
          />
          <Grid variant="contentGrid">
            <Flex sx={styles.partnerCardWrapper}>
              {isFetchingPartnerByLocation ? <BemSpinner /> : null}
              {selectedPartner ? (
                <PartnerCard partnerData={selectedPartner} />
              ) : null}
            </Flex>
          </Grid>
          <Flex
            sx={styles.formActionArea}
            as="form"
            onSubmit={handleSubmit(
              () => selectedPartner && onSubmit(selectedPartner)
            )}
          >
            <BemButton
              disabled={!selectedPartner || isSubmitting}
              additionalTrackingIdInfo="submit"
              data-testid="PartnerFinderForm-submitButton"
            >
              {getTranslation('FORM_ACTION_SUBMIT_SET_DISTRIBUTOR', T)}
            </BemButton>
          </Flex>
        </Grid>
      </Grid>
    </BemThemeWrapper>
  );
};

export { PartnerFinder };
