import { IGraphqlTranslation, TGraphqlLinkItem } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import { BemAvatar, BemHeading, BemLink } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { getTranslation } from '../../utils/translations';

const styles: IStylesObject = {
  wrapper: {
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    textAlign: 'center',
    pb: 10,
  },
  distributorImage: {
    width: 32,
    height: 32,
    borderRadius: 'full',
    overflow: 'hidden',
    my: 10,
  },
  heading: {
    mb: 6,
  },
  successText: {
    pb: 10,
  },
  supportText: {
    pb: 0,
  },
};

interface IPropsModalContactFormSuccess {
  email: string;
  supportPageLink: TGraphqlLinkItem;
  T: IGraphqlTranslation[];
}
const ModalContactFormSuccess = ({
  email,
  supportPageLink,
  T,
}: IPropsModalContactFormSuccess): JSX.Element => (
  <Grid variant="contentGrid" sx={styles.wrapper}>
    <BemAvatar sx={styles.distributorImage} />
    <BemHeading as="h3" variant="h3" sx={styles.heading}>
      {getTranslation('SUCCESS_HEADLINE', T)}
    </BemHeading>
    <Text as="span" sx={styles.successText}>
      {getTranslation('SUCCESS_COPY_TEXT', T)}
      <br />
      <BemLink
        data-testid="ModalContactFormSuccess-mail"
        to={{
          _key: 'key-externalLink-email',
          _type: 'externalLink',
          label: email,
          target: '',
          externalLink: `mailto:${email}`,
        }}
      />
    </Text>
    <Text as="span" sx={styles.supportText}>
      {getTranslation('SUCCESS_SUPPORT_TEXT', T)}
    </Text>

    <BemLink to={supportPageLink} variant="links.buttonTertiary" />
  </Grid>
);

export { ModalContactFormSuccess };
