import { motion } from 'framer-motion';
import React from 'react';
import { Flex } from 'theme-ui';

interface IPropsIndicator {
  isActive?: boolean;
}

const INDICATOR_OPEN = 'open';
const INDICATOR_CLOSED = 'closed';
const INDICATOR_WIDTH = 28;
const INDICATOR_HEIGHT = 16;

// Points are pairs of x,y absolute coordinates (0,0 is the top left point of the viewBox).
const indicatorPolygonPoints = [
  `0,${INDICATOR_HEIGHT}`,
  `${INDICATOR_WIDTH / 2},0`,
  `${INDICATOR_WIDTH},${INDICATOR_HEIGHT}`,
];
const animationVariants = {
  indicator: {
    [INDICATOR_OPEN]: {
      scale: 1,
    },
    [INDICATOR_CLOSED]: {
      scale: 0,
    },
  },
};

const styles = {
  indicatorRowWrapper: {
    justifyContent: 'center',
    height: `${INDICATOR_HEIGHT}px`,
    color: 'gray.1',
  },
};

const Indicator = ({ isActive }: IPropsIndicator): JSX.Element => (
  <Flex sx={styles.indicatorRowWrapper}>
    <motion.svg
      viewBox={`0 0 ${INDICATOR_WIDTH} ${INDICATOR_HEIGHT}`}
      initial="closed"
      animate={isActive ? INDICATOR_OPEN : INDICATOR_CLOSED}
      variants={animationVariants.indicator}
      style={{
        originX: `${INDICATOR_WIDTH / 2}px`,
        originY: `${INDICATOR_HEIGHT}px`,
      }}
    >
      <polygon points={indicatorPolygonPoints.join(' ')} fill="currentColor" />
    </motion.svg>
  </Flex>
);

export { Indicator };
