import {
  getVideoUrls,
  IGraphqlImage,
  IGraphqlVideo,
  TAdditionalTrackingIdInfo,
} from '@bemer/base';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ForwardedRef, useContext, useRef, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import ReactPlayer from 'react-player/file';
import {
  AspectRatio,
  Box,
  Grid,
  Image,
  Text,
  ThemeUIStyleObject,
} from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ModuleContext } from '../../providers';
import { getViewportAspectRatio } from '../../utils/breakpointIndices';
import { BemButton } from '../Button';
import { BemCloudImage } from '../CloudImage';
import { BemThemeWrapper } from '../ThemeWrapper';

type TPlayButtonPosition =
  | 'center'
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottom'
  | 'topLeft'
  | 'topRight';

interface IPropsBemCloudVideo {
  /**
   * The graphql video object.
   */
  video: IGraphqlVideo;

  /**
   * Overwrite the aspect ratio of a video.
   * The default ratio will be 16 / 9.
   *
   * It is possible to pass different ratios for different viewports in an array: [1/1, 4/3, 16/9]
   */
  forcedAspectRatio?: number | number[];

  /**
   * A flag to hide the play button.
   * This affects also a given play button!
   */
  hidePlayButton?: boolean;

  /**
   * Position of the play button (if not hidden with property `hidePlayButton`).
   * Default is 'center'.
   */
  playButtonPosition?: TPlayButtonPosition;

  /**
   * A custom element which will be displayed instead the default play button.
   */
  playButton?: JSX.Element;

  /**
   * Can be used to change the playing state of the video.
   * Setting it to true will directly show the full length video and start playing it.
   * Default is 'false'.
   */
  playing?: boolean;

  /**
   * Can be used to show the playing preview video all the time instead of only show it on hover.
   * Default is 'false'.
   */
  autoplayPreview?: boolean;

  /**
   * Can be used to choose a preview image for the video.
   * This image will only be visible, when there is no autoplay whatsoever (must have a play button and no autoplayPreview).
   */
  additionalPreviewImage?: IGraphqlImage;

  /**
   * Called when media starts or resumes playing after pausing or buffering.
   */
  onPlay?: () => void;

  /**
   * Called when media is paused.
   */
  onPause?: () => void;

  /**
   * Called when media finishes playing.
   */
  onEnded?: () => void;

  /**
   * Called when an error occurs whilst attempting to play media.
   */
  onError?: (error: Event) => void;

  /**
   * Additional style object.
   */
  sx?: ThemeUIStyleObject;

  /**
   * Additional style object exclusively for the play button wrapper element.
   */
  playButtonWrapperSx?: ThemeUIStyleObject;

  /**
   * An optional test id.
   * Default is "BemCloudVideo"
   */
  testId?: string;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const styles: IStylesObject = {
  wrapper: {
    display: 'grid',
  },
  previewWrapper: {
    height: '100%',
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  errorBox: {
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gap: 0,
    bg: 'gray.2',

    alignItems: 'center',
    justifyContent: 'center',
  },

  errorText: {
    gridRow: 1,
    gridColumn: 1,
    bg: 'gray.2',
    p: 8,
  },
};

const alignPlayButton = (playButtonPosition: TPlayButtonPosition) => {
  switch (true) {
    case playButtonPosition.toLowerCase().includes('bottom'):
      return 'end';
    case playButtonPosition.toLowerCase().includes('top'):
      return 'start';
    default:
      return 'center';
  }
};

const justifyPlayButton = (playButtonPosition: TPlayButtonPosition) => {
  switch (true) {
    case playButtonPosition.toLowerCase().includes('left'):
      return 'start';
    case playButtonPosition.toLowerCase().includes('right'):
      return 'end';
    default:
      return 'center';
  }
};

const calculatedStyles: ICalculatedStylesObject = {
  playButtonWrapper: (playButtonPosition: TPlayButtonPosition) => ({
    position: 'absolute',
    gridRow: 1,
    gridColumn: 1,
    alignSelf: () => alignPlayButton(playButtonPosition),
    justifySelf: () => justifyPlayButton(playButtonPosition),
    zIndex: 1,
  }),

  playButton: (playButtonPosition: TPlayButtonPosition) => ({
    mx: playButtonPosition === 'center' ? 0 : 4,
    my: playButtonPosition === 'center' ? 0 : 4,
  }),
  playerWrapper: (isPreviewVideoPlayerVisible: boolean) => ({
    gridRow: 1,
    gridColumn: 1,
    cursor: isPreviewVideoPlayerVisible ? 'pointer' : 'default',
    bg: 'black',
    '& .react-player__preview': {
      position: 'absolute',
    },

    '& .react-player__shadow': {
      opacity: 0,
    },

    '& .react-player__play-icon': {
      display: 'none',
    },

    '& video': {
      display: 'flex',
      objectFit: 'cover',
      position: 'absolute',
    },
  }),
};

/**
 * Component to render videos hosted and delivered from mux.com.
 *
 * This component uses ReactPlayer https://github.com/CookPete/react-player
 */

// eslint-disable-next-line react/display-name
const BemCloudVideo = React.forwardRef(
  (
    {
      video,
      forcedAspectRatio,
      playButtonPosition = 'center',
      playButton,
      playing = false,
      autoplayPreview = false,
      hidePlayButton = video.hidePlayButton,
      additionalPreviewImage = video.previewImage?.length
        ? video.previewImage[0]
        : undefined,
      onPlay = () => undefined,
      onPause = () => undefined,
      onEnded = () => undefined,
      onError = () => undefined,
      sx,
      playButtonWrapperSx,
      testId = 'BemCloudVideo',
      additionalTrackingIdInfo = '',
    }: IPropsBemCloudVideo,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element | null => {
    if (
      !video?.muxVideo?.asset?.playbackId ||
      !video?.muxVideo?.asset?.previewPlaybackId
    ) {
      return null;
    }

    const hasAdditionalPreviewImage = Boolean(video.previewImage?.length);

    const { playbackId, previewPlaybackId } = video.muxVideo.asset;
    const { videoUrl, videoPreviewUrl, previewImageUrl } = getVideoUrls(
      playbackId,
      previewPlaybackId
    );

    // State and Hooks
    // =============================================================================================
    const [hasVideoPlaybackError, setHasVideoPlaybackError] = useState(false);

    const [showAdditionalPreviewImage, setShowAdditionalPreviewImage] =
      useState(hasAdditionalPreviewImage);
    const [isPreviewVideoPlayerVisible, setIsPreviewVideoPlayerVisible] =
      useState(autoplayPreview && !showAdditionalPreviewImage);

    const [
      hasFullLengthPlayerStartedPlaying,
      setHasFullLengthPlayerStartedPlaying,
    ] = useState(false);

    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [internalIsPlaying, setInternalIsPlaying] = useState(false);
    const reactPlayerRef = useRef<ReactPlayer>(null);

    // Update internal playing state with prop value.
    // Why implemented that way? @see: https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
    // TLDR: useEffect Hook is too expensive for this.
    const [prevIsPlayingProp, setPrevIsPlayingProp] = useState(false);

    if (playing !== prevIsPlayingProp) {
      // Prop "playing" changed since last render. Update internalIsPlaying.
      setInternalIsPlaying(playing);
      setPrevIsPlayingProp(playing);

      // In case the prop "playing" is true and the player has not started playing yet, start it.
      if (playing && !hasFullLengthPlayerStartedPlaying) {
        setHasFullLengthPlayerStartedPlaying(true);
      }
    }

    const { moduleName } = useContext(ModuleContext);

    // URLs for videos and preview images.
    // =============================================================================================
    const videoWrapperElement = useRef<HTMLDivElement>(null);
    const videoAspectRatio = getViewportAspectRatio(16 / 9, forcedAspectRatio);

    // Handlers for player events.
    // =============================================================================================
    const handleClickOnPreview = () => {
      if (!hasFullLengthPlayerStartedPlaying && ReactPlayer.canPlay(videoUrl)) {
        setIsPreviewVideoPlayerVisible(false);
        setInternalIsPlaying(true);
        setHasFullLengthPlayerStartedPlaying(true);
      }
    };

    const handleOnReady = () => {
      if (hidePlayButton) {
        setInternalIsPlaying(true);
      }
    };

    const handleOnPlay = () => {
      setHasVideoPlaybackError(false);
      setInternalIsPlaying(true);
      setHasFullLengthPlayerStartedPlaying(true);
      onPlay();
    };

    const handleOnPause = () => {
      setInternalIsPlaying(false);
      onPause();
    };

    const handleOnEnded = () => {
      setInternalIsPlaying(false);
      onEnded();
    };

    /**
     * ReactPlayer returns several props when encountering errors. We only really care about the second one, which is the actual error object.
     * For further information check https://github.com/CookPete/react-player/issues/627
     *
     * @param errorString provides basic information on the error, e.g. 'hlsError'
     * @param error provides the actual error object with additional information
     */
    const handleOnError = (_errorString: string, error: any) => {
      // if the error isn't fatal, we do not want to interfere with playback
      if (error?.fatal) {
        setHasVideoPlaybackError(true);
        onError(error as Event);
      }
    };

    const handleOnMouseEnterPlayerArea = () => {
      if (hasFullLengthPlayerStartedPlaying || isPreviewVideoPlayerVisible) {
        return;
      }

      if (ReactPlayer.canPlay(videoPreviewUrl)) {
        setShowAdditionalPreviewImage(false);
        setIsPreviewVideoPlayerVisible(true);
      }
    };

    const handleOnMouseLeavePlayerArea = () => {
      if (
        !hasAdditionalPreviewImage &&
        (hasFullLengthPlayerStartedPlaying || autoplayPreview)
      ) {
        return;
      }
      setShowAdditionalPreviewImage(hasAdditionalPreviewImage);
      setIsPreviewVideoPlayerVisible(
        autoplayPreview && !hasAdditionalPreviewImage
      );
    };

    const onStart = () => {
      if (reactPlayerRef.current) {
        reactPlayerRef.current.seekTo(playedSeconds, 'seconds');
      }
    };

    // Prepare components and elements.
    // =============================================================================================
    const componentName = 'BemCloudVideo';

    const getTrackingId = (type?: string) =>
      `${moduleName}-${componentName}${type ? `-${type}` : ''}${
        additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
      }`;

    const defaultPlayButton = (
      <BemButton
        sx={calculatedStyles.playButton(playButtonPosition)}
        onClick={handleClickOnPreview}
        data-testid="BemCloudVideoPlayButton"
        variant="buttons.play"
        data-trackingid={getTrackingId('defaultPlayButton')}
      >
        <FaPlay />
      </BemButton>
    );

    // Until the video has started playing, a play button is shown.
    const showPlayButton =
      !hasVideoPlaybackError &&
      !hidePlayButton &&
      !hasFullLengthPlayerStartedPlaying;

    const playButtonElement = showPlayButton ? (
      <Grid
        sx={{
          ...calculatedStyles.playButtonWrapper(playButtonPosition),
          ...playButtonWrapperSx,
        }}
      >
        {playButton || defaultPlayButton}
      </Grid>
    ) : null;

    // Shared props between preview and full length player.
    const playerProps = {
      width: '100%',
      height: '100%',
      playsinline: true,
      light: false,
      loop: false,
      controls: false,
      config: {
        attributes: {
          onContextMenu: (e: Event) => e.preventDefault(),
          controlsList: 'nodownload',
        },
      },
    };

    // A simple Box to animate (fade in and out) the preview image, preview player and the full length player.
    const MotionBox = motion(Box);
    const motionBoxProps = {
      width: '100%',
      height: '100%',
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    };

    const previewPlayer = (
      <MotionBox
        {...motionBoxProps}
        onClick={handleClickOnPreview}
        sx={styles.previewWrapper}
      >
        <ReactPlayer
          {...playerProps}
          url={videoPreviewUrl}
          loop
          playing
          muted
          data-trackingid={getTrackingId('previewPlayer')}
        />
      </MotionBox>
    );

    const fullLengthVideoPlayer = (
      <ReactPlayer
        {...playerProps}
        ref={reactPlayerRef}
        url={videoUrl}
        playing={internalIsPlaying}
        controls
        onReady={handleOnReady}
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        onEnded={handleOnEnded}
        onError={handleOnError}
        muted={hidePlayButton}
        loop={hidePlayButton}
        onProgress={(event) => {
          setPlayedSeconds(event.playedSeconds);
        }}
        onStart={onStart}
        data-trackingid={getTrackingId('fullLengthVideoPlayer')}
      />
    );

    // This is a Theme UI image component, since the image is generated at cloudinary
    // and not available in sanity (so we can not use BemCloudImage).
    const previewImage = (
      <MotionBox
        {...motionBoxProps}
        onClick={handleClickOnPreview}
        data-trackingid={getTrackingId('previewImage')}
        sx={styles.previewWrapper}
      >
        {additionalPreviewImage && showAdditionalPreviewImage ? (
          <BemCloudImage
            image={additionalPreviewImage}
            forcedAspectRatio={videoAspectRatio}
            sx={styles.previewImage}
          />
        ) : (
          <Image src={previewImageUrl} sx={styles.previewImage} />
        )}
      </MotionBox>
    );

    // Until the video has started playing, a preview image is shown or a preview video on hover.
    const preview =
      !showAdditionalPreviewImage && isPreviewVideoPlayerVisible
        ? previewPlayer
        : previewImage;

    // After the player has started, the full video player is shown all the time.
    const player =
      hasFullLengthPlayerStartedPlaying || hidePlayButton
        ? fullLengthVideoPlayer
        : preview;

    return (
      <Box
        sx={sx}
        data-testid={testId}
        data-trackingid={getTrackingId()}
        onMouseEnter={handleOnMouseEnterPlayerArea}
        onMouseLeave={handleOnMouseLeavePlayerArea}
        ref={ref}
      >
        <BemThemeWrapper themeName={video.themeName}>
          <AspectRatio ratio={videoAspectRatio} sx={styles.wrapper}>
            <Box
              ref={videoWrapperElement}
              sx={calculatedStyles.playerWrapper(isPreviewVideoPlayerVisible)}
            >
              {hasVideoPlaybackError ? (
                <Grid sx={styles.errorBox}>
                  <Text as="p" variant="small" sx={styles.errorText}>
                    Error while playing the video
                  </Text>
                </Grid>
              ) : (
                <AnimatePresence>{player}</AnimatePresence>
              )}
            </Box>
            {playButtonElement}
          </AspectRatio>
        </BemThemeWrapper>
      </Box>
    );
  }
);

export { BemCloudVideo, TPlayButtonPosition, IPropsBemCloudVideo };
