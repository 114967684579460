import {
  aspectRatios,
  IGraphqlM0069,
  IGraphqlM0069Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_CARD_PADDING,
  MOBILE_MODULE_CAPTION_BIG_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';

interface IPropsBemM0069 extends IGraphqlM0069 {}

const PREFIX = 'M0069';

const styles: IStylesObject = {
  textWrapper: {
    gridColumn: ['1 / -1', '1 / span 4', '1 / span 4'],
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: defaultGridGap,
    alignSelf: 'center',
    pb: [MOBILE_MODULE_CAPTION_BIG_PB, 0, 0],
  },
  textWrapperContent: {
    gridColumn: ['1 / -1', '1 / span 4', '1 / span 3'],
  },
  heading: {
    pb: [MOBILE_MODULE_CAPTION_BIG_PB, 8, 10],
  },
  description: {
    pb: 6,
  },
  item: {
    gridColumn: 'span 4',
    gridTemplateRows: 'min-content 1fr min-content',
    gridGap: defaultGridGap,
    pt: [5, 7, 9],
    pb: [4, 8, 10],
    px: [MOBILE_CARD_PADDING, 10, 12],
    bg: 'gray.2',
    height: ['100%', '100%', 'auto'],
  },
  itemImage: {
    width: 24,
    height: 24,
    mx: 'auto',
    borderRadius: 'full',
    border: '1px solid transparent',
    overflow: 'hidden',
  },
  itemDescription: {
    textAlign: 'center',
    pt: 3,
    pb: 12,
  },
  itemTitle: {
    pb: 4,
  },
  itemLink: {
    textAlign: 'center',
    justifySelf: 'center',
  },
};

interface IPropsBemM0069Item {
  item: IGraphqlM0069Item;
  index: number;
}

const Item = ({ item, index }: IPropsBemM0069Item) => (
  <Grid key={item._key} sx={styles.item}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      sx={styles.itemImage}
      additionalTrackingIdInfo={index + 1}
    />
    <Box sx={styles.itemDescription}>
      <BemHeading as="h4" variant="h4" sx={styles.itemTitle}>
        {item.title}
      </BemHeading>
      <Text as="p" variant="small">
        {item.text}
      </Text>
    </Box>
    {item.link?.length ? (
      <BemLink
        to={item.link}
        variant="links.buttonPrimary"
        sx={styles.itemLink}
        data-testid={`${item._key}-link`}
        additionalTrackingIdInfo={index + 1}
      />
    ) : null}
  </Grid>
);

interface IPropsBemM0069Items {
  items: IGraphqlM0069Item[];
}

const ItemsOnDesktop = ({ items }: IPropsBemM0069Items) => (
  <>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </>
);

const BemM0069 = ({
  title,
  text,
  link,
  items,
}: IPropsBemM0069): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <Grid sx={styles.textWrapper}>
        <Box
          sx={styles.textWrapperContent}
          data-testid="BemM0069-textWrapperContent"
        >
          <BemHeading as="h3" variant="caption.big" sx={styles.heading}>
            {title}
          </BemHeading>
          <Text as="p" variant="bodyText" sx={styles.description}>
            {text}
          </Text>
          <BemLink to={link} variant="links.boldUppercase" />
        </Box>
      </Grid>
      {useViewportRenderer([
        <BemTouchSlider
          items={items}
          itemRenderer={(data, index) => (
            <Item item={data.item} index={index} />
          )}
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          hideShadow
        />,
        <Box
          sx={{ gridColumn: '5 / -1' }}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        >
          <BemTouchSlider
            items={items}
            itemRenderer={(data, index) => (
              <Item item={data.item} index={index} />
            )}
            hideShadow
          />
        </Box>,
        <ItemsOnDesktop items={items} key={`${PREFIX}_${VIEWPORTS.DESKTOP}`} />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0069, IPropsBemM0069 };
