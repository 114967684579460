/** @jsx jsx */
import { IGraphqlRichtextTable, TTable, TTableRow } from '@bemer/base';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { Box, jsx, Text, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { BemHeading } from '../Heading';

interface IPropsBemRichtextTable extends IGraphqlRichtextTable {
  sx?: ThemeUIStyleObject;
}

const styles: IStylesObject = {
  th: {
    px: 1,
    py: 3,
    borderStyle: 'solid',
    textAlign: 'left',
    backgroundColor: 'gray.1',
    borderColor: 'gray.4',
    borderWidth: '0.5px',
  },
  td: {
    padding: 1,
    textAlign: 'left',
    borderStyle: 'solid',
    borderColor: 'gray.4',
    borderWidth: '0.5px',
  },
};

const Table = ({
  columns,
  data,
  firstlineAsHeading,
  richtextSx,
}: {
  columns: any;
  data: any;
  firstlineAsHeading: boolean;
  richtextSx?: ThemeUIStyleObject;
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box sx={{ overflow: 'auto', ...richtextSx }}>
      <table
        style={{
          borderCollapse: 'collapse',
          borderSpacing: 0,
          width: '100%',
        }}
      >
        {firstlineAsHeading ? (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={`heading-row-${headerGroup.id}`} id={headerGroup.id}>
                {headerGroup.headers.map((header, columnIndex: number) => (
                  <BemHeading
                    // eslint-disable-next-line react/no-array-index-key
                    key={`column-heading-${columnIndex}`}
                    variant="h4Bold"
                    as="th"
                    sx={styles.th}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </BemHeading>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex: number) => {
            if (firstlineAsHeading && rowIndex === 0) {
              return null;
            }
            return (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={`body-row-${row.id}`}
                id={row.id}
              >
                {row.getAllCells().map((cell) => (
                  <Text
                    // eslint-disable-next-line react/no-array-index-key
                    key={`column-heading-${cell.id}`}
                    sx={styles.td}
                    variant="p"
                    as="td"
                    id={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Text>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

type Column = {
  header?: string;
  accessorKey: string;
};

const mapReactTableColumnsWithHeaderToReactTableData = (
  table: TTable,
  mappedData: any
) => {
  if (!table.rows.length) {
    return [];
  }

  const columns: Column[] = [];
  const columnCount = table.rows[0].cells.length;

  const headerObjectASArray = Object.values(mappedData);

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < columnCount; index++) {
    // accessor is the as cellIndexAsColumnAccessor in the function mapSanityTableToReactTable
    columns.push({
      header: `${headerObjectASArray[index]}`,
      accessorKey: `${index}`,
    });
  }

  return columns;
};

const mapReactTableColumnsToReactTableData = (table: TTable) => {
  if (!table.rows.length) {
    return [];
  }

  const columns = [];
  const columnCount = table.rows[0].cells.length;

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < columnCount; index++) {
    // accessor is the as cellIndexAsColumnAccessor in the function mapSanityTableToReactTable
    columns.push({ accessorKey: `${index}` });
  }

  return columns;
};

const mapSanityTableToReactTable = (table: TTable) => {
  const reactTableData = table.rows.map((row: TTableRow) => {
    const mappedRowData: {
      subRows: undefined;
      [key: string]: any;
    } = { subRows: undefined };

    row.cells.forEach((cell: string, cellIndex: number) => {
      const cellIndexAsColumnAccessor = `${cellIndex}`;
      mappedRowData[cellIndexAsColumnAccessor] = cell;
    });
    return mappedRowData;
  });
  return reactTableData;
};

const BemRichtextTable = ({
  table,
  firstlineAsHeading,
  sx,
}: IPropsBemRichtextTable): JSX.Element => {
  const mappedData = React.useMemo(() => mapSanityTableToReactTable(table), []);
  const mappedColumns = React.useMemo(
    firstlineAsHeading
      ? () =>
          mapReactTableColumnsWithHeaderToReactTableData(table, mappedData[0])
      : () => mapReactTableColumnsToReactTableData(table),
    []
  );
  return (
    <Table
      columns={mappedColumns}
      data={mappedData}
      firstlineAsHeading={firstlineAsHeading}
      richtextSx={sx}
    />
  );
};

export { BemRichtextTable, IPropsBemRichtextTable };
