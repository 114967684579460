import { TThemeName } from '@bemer/base';
import React from 'react';

interface IModuleProviderContract {
  moduleName: string;
  moduleTheme?: TThemeName;
}

interface IPropsBemModuleProvider {
  children: React.ReactNode;
  contract: IModuleProviderContract;
}

// Initialize the context with a default value.
const defaultValue: IModuleProviderContract = {
  moduleName: 'Unnamed-Bem-Module',
  moduleTheme: 'DEFAULT',
};

const ModuleContext = React.createContext(defaultValue);

const BemModuleProvider = ({
  children,
  contract,
}: IPropsBemModuleProvider): JSX.Element => (
  <ModuleContext.Provider value={contract}>{children}</ModuleContext.Provider>
);

export {
  ModuleContext,
  BemModuleProvider,
  IModuleProviderContract,
  IPropsBemModuleProvider,
};
