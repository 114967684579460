import {
  allCountries as countries,
  defaultLocale,
  getCountryLanguageAssignmentsForRegion,
  getObjectKeys,
  ICountryWithLocales,
  IGraphqlM0134,
  IRegionsWithCountries,
  IRegionWithCountries,
  TLocaleId,
  TRegionCode,
} from '@bemer/base';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { MdExpandMore } from 'react-icons/md';
import { Box, Flex, Grid, Heading, Text } from 'theme-ui';
import { BemButton, BemHeading, BemModal } from '../../components';
import {
  GRID_GAP_MOBILE,
  GRID_GAP_TABLET,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useViewportRenderer } from '../../hooks/useViewportRenderer';
import { ApiContext, LocalisationContext, ModalContext } from '../../providers';
import { Country } from './components/Country';

// TODO
// tests?

const SESSION_STORAGE_KEY = 'chosenLocaleByUser';
const MotionBox = motion(Box);

interface IPropsBemM0134 extends IGraphqlM0134 {
  initiallyOpen?: boolean;
}

const styles: IStylesObject = {
  heading: {
    pt: '0_5',
    gridColumn: '1 / -1',
    textAlign: 'center',
    cursor: 'default',
    pb: TOP_HEADLINE_V2_PB,
  },
  contentWrapper: {
    gridColumn: '1 / -1',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: [GRID_GAP_MOBILE, GRID_GAP_TABLET, 8],
  },
  regionWrapper: {
    gridColumn: '7 / span 5',

    '&:first-of-type': {
      gridColumn: '2 / span 5',
      gridRow: 'span 5',
    },
  },
  regionWrapperHandheld: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    borderTop: '1px solid gray',
    '&:first-of-type': {
      borderTop: 'none',
    },
  },
  regionName: {
    cursor: 'default',
    pb: 2,
  },
  regionSelectionHandheld: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 4,
  },
  expanderIcon: {
    position: 'relative',
    m: 0,
    mr: 3,
  },
  countryListHandheld: {
    mt: -2,
    px: 2,
    pb: 4,
  },
  languageRecommendationText: {
    pb: 4,
  },
  languageRecommendationRegionToggle: {
    display: 'block',
    margin: 'auto',
  },
  notification: {
    bg: 'warning.0',
    borderColor: 'warning.4',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 1,
    p: 4,
    mb: 10,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    color: 'warning.7',
  },
  notificationText: {
    pl: 2,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  languageRecommendation: (isMoreLanguagesVisible) => ({
    alignItems: 'center',
    pb: 10,
    justifyContent: 'center',
    position: 'relative',
    mb: isMoreLanguagesVisible ? 10 : 0,
    flexDirection: 'column',

    '&::after': {
      content: '""',
      borderWidth: 'px',
      borderBottomStyle: 'solid',
      borderBottomColor: isMoreLanguagesVisible ? 'gray.3' : 'transparent',
      position: 'absolute',
      bottom: 0,
      width: isMoreLanguagesVisible ? '100%' : '0',
      transition: 'width 200ms ease-in-out 400ms',
    },
  }),
};

const REGION_OPEN = 'open';
const REGION_COLLAPSED = 'collapsed';
const animationVariants = {
  regionContentWrapper: {
    [REGION_OPEN]: {
      height: 'auto',
      opacity: 1,
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
    [REGION_COLLAPSED]: {
      height: 0,
      overflow: 'hidden',
      opacity: 0,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
  },
  expanderIcon: {
    [REGION_OPEN]: {
      rotate: -180,
    },
    [REGION_COLLAPSED]: {
      rotate: 0,
    },
  },
  regionsWrapper: {
    visible: {
      height: 'auto',
      opacity: 1,
      transition: {
        animation: 'ease-in-out',
        duration: DURATION.MEDIUM,
      },
    },
    hidden: {
      height: 0,
      overflow: 'hidden',
      opacity: 0,
      transition: {
        duration: DURATION.MEDIUM,
      },
    },
  },
} as const;

const RegionsOnDesktop = ({
  regions,
  countryLanguageAssignmentsForRegion,
}: IRegionsWithCountries) => (
  <Grid sx={styles.contentWrapper}>
    {regions.map((region: IRegionWithCountries) => (
      <Box
        sx={styles.regionWrapper}
        key={`${region.regionName}-regionWrapper-desktop`}
        data-testid={`${region.regionName}-regionWrapper-desktop`}
      >
        <Heading
          as="h3"
          variant="h3"
          sx={styles.regionName}
          data-testid={`${region.regionName}-desktop`}
        >
          {region.regionName}
        </Heading>
        {region.countries
          .sort((a, b) => a.countryName.localeCompare(b.countryName))
          .map((country) =>
            country.localeIdentifiers.length ? (
              <Country
                country={country}
                countryLanguageAssignmentsForRegion={
                  countryLanguageAssignmentsForRegion
                }
                regionCode={region.regionCode}
                key={`${country.countryName}-wrapper`}
              />
            ) : null
          )}
      </Box>
    ))}
  </Grid>
);

const RegionsOnMobileAndTablet = ({
  regions,
  countryLanguageAssignmentsForRegion,
}: IRegionsWithCountries) => {
  const [activeRegion, setActiveRegion] = useState<string>('');

  const toggle = (region: IRegionWithCountries) => {
    if (activeRegion === region.regionCode) {
      setActiveRegion('');
      return;
    }
    setActiveRegion(region.regionCode);
  };

  const isActive = (region: IRegionWithCountries) =>
    activeRegion === region.regionCode;

  return (
    <Box>
      {regions.map((region) => {
        const boxState = isActive(region) ? REGION_OPEN : REGION_COLLAPSED;
        const dataTestIdSuffix = isActive(region)
          ? '-expanderOpened'
          : '-expanderClosed';
        return (
          <Box
            key={`${region.regionName}-regionWrapper-handheld`}
            data-testid={`${region.regionName}-regionWrapper-handheld`}
            sx={styles.regionWrapperHandheld}
          >
            <Box
              onClick={() => toggle(region)}
              sx={styles.regionSelectionHandheld}
            >
              <Heading
                as="h3"
                variant="h3"
                data-testid={`${region.regionName}-handheld`}
              >
                {region.regionName}
              </Heading>
              <BemButton
                variant="buttons.itemExpanderOutlineSmall"
                sx={styles.expanderIcon}
                data-testid={`${region.regionName}${dataTestIdSuffix}`}
              >
                <MotionBox
                  variants={animationVariants.expanderIcon}
                  animate={boxState}
                  initial="hidden"
                >
                  <MdExpandMore size="1rem" />
                </MotionBox>
              </BemButton>
            </Box>
            <AnimatePresence initial={false}>
              {isActive(region) && (
                <MotionBox
                  animate={boxState}
                  variants={animationVariants.regionContentWrapper}
                  initial={REGION_COLLAPSED}
                  sx={styles.countryListHandheld}
                  key={`${region.regionName}-countryList-handheld`}
                  data-testid={`${region.regionName}-countryList-handheld`}
                  exit={REGION_COLLAPSED}
                >
                  {region.countries.map((country) => (
                    <Country
                      country={country}
                      countryLanguageAssignmentsForRegion={
                        countryLanguageAssignmentsForRegion
                      }
                      regionCode={region.regionCode}
                      key={`${country.countryName}-wrapper`}
                    />
                  ))}
                </MotionBox>
              )}
            </AnimatePresence>
          </Box>
        );
      })}
    </Box>
  );
};

const BemM0134 = ({
  title,
  changeCountryLabels,
  initiallyOpen = false,
}: IPropsBemM0134): JSX.Element => {
  const {
    pageForAllLocaleIdentifiers,
    allActiveLocaleIdentifiers,
    localeIdentifier,
    allRegionCountryLanguageAssignments,
  } = useContext(LocalisationContext);

  const regionsArray: IRegionWithCountries[] =
    allRegionCountryLanguageAssignments.edges.map((node) => node.node);

  const { regionCode } = localeIdentifier.country.region;

  const countryLanguageAssignmentsForRegion =
    getCountryLanguageAssignmentsForRegion(
      regionCode,
      allActiveLocaleIdentifiers,
      pageForAllLocaleIdentifiers
    );

  const getCountryFromLocale = (locale: TLocaleId) => locale.split('_')[1];
  const [currentUserLocale, setCurrentUserLocale] = useState<TLocaleId>();
  const [urlLocale, setUrlLocale] = useState<TLocaleId | undefined>();
  let navigatorLanguage = 'en';

  if (typeof navigator !== 'undefined') {
    navigatorLanguage = navigator.language;
  }

  // Get browser language
  const [browserLocale] = useState<string>(navigatorLanguage);
  const [currentCountry, setCurrentCountry] = useState<
    ICountryWithLocales | undefined
  >();
  const [currentRegionCode, setCurrentRegionCode] = useState<
    TRegionCode | undefined
  >();

  // Compare countries of both locales
  const isDifferentLocation =
    currentUserLocale &&
    urlLocale &&
    getCountryFromLocale(currentUserLocale) !== getCountryFromLocale(urlLocale);

  const [isRegionSelectionVisible, setIsRegionSelectionVisible] =
    useState(false);

  const {
    send,
    current: {
      context: { isM0134Open },
    },
  } = useContext(ModalContext);

  const {
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);

  const { data: visitorSettings } = getVisitorSettings();
  const visitorSettingsError = visitorSettings?.error;

  useEffect(() => {
    if (!visitorSettings) return;

    // Get user locale from backend
    const { locale } = visitorSettings;
    setCurrentUserLocale(locale);

    // 2. Get locale from current page url
    const bemerLocales: TLocaleId[] = getObjectKeys(countries).flatMap(
      (countryKey) => {
        const country = countries[countryKey];
        return country.languages.map(
          (language) =>
            `${language.languageCode}_${country.countryCode}` as TLocaleId
        );
      }
    );

    const pathLocale =
      bemerLocales.find((bemerLocale) =>
        window.location.href.includes(bemerLocale)
      ) || defaultLocale.id;

    setUrlLocale(pathLocale);
  }, [visitorSettings]);

  useEffect(() => {
    const storedUrlLocale = sessionStorage.getItem(SESSION_STORAGE_KEY);

    // update stored user session locale on urlLocale updates as long as it references the same country as the currentUserLocale
    if (urlLocale && !isDifferentLocation) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, urlLocale);
    }
    // Open modal if the visitorService is not available and there is no locale saved in sessionStorage
    // OR
    // Open modal if the visitorSettings are available with no error, the current users country is different to the pageUrl's country and the stored url differs from the pageUrls's locale
    if (
      (visitorSettingsError && !sessionStorage.getItem(SESSION_STORAGE_KEY)) ||
      (visitorSettings &&
        !visitorSettingsError &&
        isDifferentLocation &&
        storedUrlLocale !== urlLocale)
    ) {
      send({ type: 'openLanguageSelectionModal' });
    }

    // Get current region object
    const regionObject = regionsArray.find((region) =>
      region.countries.find((country) =>
        currentUserLocale?.includes(`_${country.countryCode}`)
      )
    );
    // Get current country object
    const countryObject = regionObject?.countries.find((country) =>
      currentUserLocale?.includes(`_${country.countryCode}`)
    );

    if (countryObject) {
      setCurrentCountry(countryObject);
    }
    if (regionObject) {
      setCurrentRegionCode(regionObject.regionCode);
    }
  }, [urlLocale]);

  const userLanguage = browserLocale.split('-')[0] || currentUserLocale || 'en';
  const browserLanguageBasedCountryLabels = changeCountryLabels.find(
    ({ language }) => language === userLanguage
  );

  return (
    <BemModal
      size="full"
      isVisible={isM0134Open || initiallyOpen}
      onClose={() => {
        send({ type: 'closeLanguageSelectionModal' });
        if (urlLocale) {
          sessionStorage.setItem(SESSION_STORAGE_KEY, urlLocale);
        }
      }}
    >
      {isDifferentLocation ? (
        <>
          <Flex sx={styles.notification}>
            <AiOutlineWarning />
            <Text as="p" sx={styles.notificationText}>
              {/* visitor service unavailable: display a default information instead of the country specific content */}
              {!visitorSettingsError
                ? browserLanguageBasedCountryLabels?.localeNotification
                : 'Our localisation service is currently not available.'}
            </Text>
          </Flex>
          {/* visitor service unavailable: hide the recommended country */}
          {!visitorSettingsError ? (
            <Flex
              sx={calculatedStyles.languageRecommendation(
                isRegionSelectionVisible || !isDifferentLocation
              )}
            >
              <Heading
                as="h3"
                variant="h3"
                sx={styles.languageRecommendationText}
              >
                {browserLanguageBasedCountryLabels?.localeRecommendation}
              </Heading>
              {currentCountry && currentRegionCode ? (
                <Country
                  country={currentCountry}
                  countryLanguageAssignmentsForRegion={
                    countryLanguageAssignmentsForRegion
                  }
                  regionCode={currentRegionCode}
                  key={`${currentCountry.countryName}-recommendation-wrapper`}
                />
              ) : null}
            </Flex>
          ) : null}
          {/* visitor service unavailable: hide the change country button */}
          {isRegionSelectionVisible ||
          !isDifferentLocation ||
          visitorSettingsError ? null : (
            <BemButton
              variant="buttons.tertiary"
              onClick={() => setIsRegionSelectionVisible(true)}
              sx={styles.languageRecommendationRegionToggle}
            >
              {browserLanguageBasedCountryLabels?.buttonLabel}
            </BemButton>
          )}
        </>
      ) : null}
      {/* visitor service unavailable: instead open the list of regions, countries and languages to choose from */}
      <MotionBox
        variants={animationVariants.regionsWrapper}
        animate={
          isRegionSelectionVisible ||
          !isDifferentLocation ||
          visitorSettingsError
            ? 'visible'
            : 'hidden'
        }
        initial="hidden"
      >
        <BemHeading
          as="h3"
          variant="h3"
          sx={styles.heading}
          themeName="DEFAULT"
        >
          {title}
        </BemHeading>
        {useViewportRenderer([
          <RegionsOnMobileAndTablet
            key="mobile-regionWrapper"
            regions={regionsArray}
            countryLanguageAssignmentsForRegion={
              countryLanguageAssignmentsForRegion
            }
          />,
          <RegionsOnMobileAndTablet
            key="tablet-regionWrapper"
            regions={regionsArray}
            countryLanguageAssignmentsForRegion={
              countryLanguageAssignmentsForRegion
            }
          />,
          <RegionsOnDesktop
            key="desktop-regionWrapper"
            regions={regionsArray}
            countryLanguageAssignmentsForRegion={
              countryLanguageAssignmentsForRegion
            }
          />,
        ])}
      </MotionBox>
    </BemModal>
  );
};

export { BemM0134, IPropsBemM0134 };
