import { aspectRatios, IGraphqlM0050, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemQuoteWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  GRID_ROW_GAP,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { LocalisationContext } from '../../providers';

interface IPropsBemM0050 extends IGraphqlM0050 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  textWrapper: {
    gridRow: ['auto', 2, 2],
    gridColumn: ['2 / -2', '2 / span 4', '2 / span 4'],
    alignSelf: 'start',
    pt: [0, 16, 24],
    pb: ASIDE_HEADLINE_PB,
  },
  position: {
    gridColumn: 'span 4',
    color: 'textMuted',
    pb: ASIDE_HEADLINE_PB,
  },
  image: {
    gridColumn: ['2 / -2', '2 / -1', '2 / -1'],
    gridRow: ['1', '1 / -2', '1 / -2'],
    pb: ASIDE_MEDIA_PB,
  },
  text: {
    gridColumn: 'span 4',
  },
  card: {
    gridColumn: ['2 / -2', '1 / -3', '1 / -3'],
    zIndex: 1,
    bg: 'white',
    py: [8, 10, 14],
    gridRow: ['2', '2 / -1', '2 / -1'],
    boxShadow: 'cardShadow',
    alignItems: 'center',
    gridTemplateColumns: ['inherit', 'repeat(6, 1fr)', 'repeat(6, 1fr)'],
    gap: defaultGridGap,
    rowGap: GRID_ROW_GAP,
  },
  citation: {
    zIndex: 1,
    gridColumn: ['3 / -3', '2 / -2', '2 / -2'],
    textAlign: 'left',
  },
  imageGrid: {
    gridColumn: ['1 / -1', '6 / -2', '6 / -2'],
    gridTemplateColumns: ['inherit', 'repeat(8, 1fr)', 'repeat(8, 1fr)'],
    gridRow: ['auto', '2', '2'],
    gridTemplateRows: ['none', '1fr min-content 52px', '1fr min-content 52px'],
    gap: defaultGridGap,
    rowGap: GRID_ROW_GAP,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  name: (position) => ({
    gridColumn: 'span 4',
    pb: position ? [3, 4, 5] : ASIDE_HEADLINE_PB,
  }),
};

const BemM0050 = ({
  title,
  media,
  name,
  position,
  text,
  citation,
}: IPropsBemM0050): JSX.Element => {
  const { locale } = useContext(LocalisationContext);
  return (
    <BemModuleWrapper>
      {title ? (
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
      ) : null}
      <Box sx={styles.textWrapper} data-testid="BemM0050-textWrapper">
        <BemHeading as="h3" variant="h3" sx={calculatedStyles.name(!!position)}>
          {name}
        </BemHeading>
        {position ? (
          <BemHeading as="h3" variant="h3" sx={styles.position}>
            {position}
          </BemHeading>
        ) : null}
        <Text as="p" variant="bodyText" sx={styles.text}>
          {text}
        </Text>
      </Box>
      <Grid sx={styles.imageGrid}>
        <BemMedia
          media={media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
          sx={styles.image}
        />
        <Grid sx={styles.card} data-testid="BemM0050-card">
          <Text variant="caption.big" sx={styles.citation}>
            <BemQuoteWrapper localeId={locale.id}>{citation}</BemQuoteWrapper>
          </Text>
        </Grid>
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0050, IPropsBemM0050 };
