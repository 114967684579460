import { getCurrencyForLocale, ILocale } from '@bemer/base';

const getLocalizedAmount = (
  locale: ILocale,
  amount: number | null,
  currency?: string
): string =>
  new Intl.NumberFormat(locale.bcp47Id, {
    style: 'currency',
    // HINT: currency must come from the Business Central (BC)
    // getCurrencyForLocale(locale) should get removed and deleted when getLocalizedAmount gets the currency as parameter every time.
    currency: currency || getCurrencyForLocale(locale),
  }).format(amount !== null ? amount : 0);

export { getLocalizedAmount };
