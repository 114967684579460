import { TGraphqlMediaItem } from '@bemer/base';
import { assign, Machine, StateSchema } from 'xstate';

// event types
const CURRENT_INDEX = 'currentIndex';
const NEXT = 'next';
const PREV = 'prev';

// actions
const SET_CURRENT_ACTIVE_INDEX = 'setCurrentActiveIndex';
const SET_NEXT_CURRENT_ACTIVE_INDEX = 'setNextCurrentActiveIndex';
const SET_PREVIOUS_CURRENT_ACTIVE_INDEX = 'setPreviousCurrentActiveIndex';

// context
interface IBemGalleryMachineContext {
  currentActiveIndex: number;
  items: TGraphqlMediaItem[];
}

// schema
interface IBemGalleryMachineSchema {
  states: {
    idle: StateSchema;
  };
}
// event
type TBemGalleryMachineEvents =
  | { type: typeof CURRENT_INDEX; clickedItemIndex: number }
  | { type: typeof PREV }
  | { type: typeof NEXT };

const items: TGraphqlMediaItem[] = [];

const bemGalleryMachine = Machine<
  IBemGalleryMachineContext,
  IBemGalleryMachineSchema,
  TBemGalleryMachineEvents
>(
  {
    id: 'bemGallery',
    initial: 'idle',
    context: {
      currentActiveIndex: 0,
      items,
    },
    states: {
      idle: {
        on: {
          [NEXT]: {
            actions: [SET_NEXT_CURRENT_ACTIVE_INDEX],
          },
          [PREV]: {
            actions: [SET_PREVIOUS_CURRENT_ACTIVE_INDEX],
          },
          [CURRENT_INDEX]: {
            actions: [SET_CURRENT_ACTIVE_INDEX],
          },
        },
      },
    },
  },
  {
    actions: {
      [SET_CURRENT_ACTIVE_INDEX]: assign((context, event) => ({
        currentActiveIndex:
          event.type === CURRENT_INDEX
            ? event.clickedItemIndex
            : context.currentActiveIndex,
      })),
      [SET_NEXT_CURRENT_ACTIVE_INDEX]: assign((context, _event) => ({
        currentActiveIndex:
          (context.currentActiveIndex + 1) % context.items.length,
      })),
      [SET_PREVIOUS_CURRENT_ACTIVE_INDEX]: assign((context, _event) => ({
        currentActiveIndex:
          (context.currentActiveIndex - 1 + context.items.length) %
          context.items.length,
      })),
    },
  }
);

export { bemGalleryMachine, CURRENT_INDEX, NEXT, PREV };
export type { TGraphqlMediaItem };
