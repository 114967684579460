import {
  getMarketingSiteHostUrlForRegion,
  ICountryLanguageAssignment,
  ICountryWithLocales,
  TRegionCode,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import { BemLink } from '../../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../../gatsby-plugin-theme-ui/moduleTypes';

interface IBemM0134Country {
  country: ICountryWithLocales;
  countryLanguageAssignmentsForRegion: ICountryLanguageAssignment[];
  regionCode: TRegionCode;
  _key?: string;
}

const styles: IStylesObject = {
  countryNameLink: {
    flex: 1,
    '&:hover + div': {
      '.firstLinkButton': {
        bg: 'black',
        p: {
          color: 'bluishGray.0',
        },
      },
    },
  },
  countryLink: {
    p: 1,
    '&:hover': {
      div: {
        bg: 'black',
        p: {
          color: 'bluishGray.0',
        },
      },
    },
  },
  countryName: {
    height: [9, 9, 7],
    display: 'flex',
    alignItems: 'center',
    pr: 4,
  },
  countryNameDisabled: {
    color: 'textMuted',
    cursor: 'not-allowed',
  },
  buttonList: {
    gridGap: 1,
    gridAutoFlow: 'column',
  },
  linkButton: {
    bg: 'bluishGray.0',
    width: [9, 9, 7],
    height: [9, 9, 7],
    borderRadius: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  country: (hasLanguages) => ({
    p: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'fit-content',
    borderRadius: 1,
    cursor: hasLanguages ? 'pointer' : 'not-allowed',
    '&:hover': {
      bg: hasLanguages ? 'bluishGray.1' : 'transparent',
    },
  }),
};

const Country = ({
  country,
  countryLanguageAssignmentsForRegion,
  regionCode: currentCountryRegionCode,
}: IBemM0134Country): JSX.Element => {
  const defaultLocaleForCountryAndLanguage = country.localeIdentifiers.find(
    (localeIdentifier) => localeIdentifier.default
  )?.localeId;

  // check if country has at least one active language and sort default locale to index[0]
  const activeLocaleIdentifiersForCountry = country.localeIdentifiers
    .filter(
      (localeIdentifier) => localeIdentifier.default && localeIdentifier.active
    )
    .concat(
      country.localeIdentifiers.filter(
        (localeIdentifier) =>
          !localeIdentifier.default && localeIdentifier.active
      )
    );

  const hasCountryActiveLanguages = !!activeLocaleIdentifiersForCountry.length;

  // if defaultLocaleForCountryAndLanguage is an active localeIdentifier use that as link on country name click
  // otherwise use the first of its active locals for it

  const defaultLanguageAssignment = countryLanguageAssignmentsForRegion.filter(
    (item) => item.localeId === defaultLocaleForCountryAndLanguage
  );

  const defaultPageUrl = defaultLanguageAssignment.length
    ? defaultLanguageAssignment[0].pageUrl
    : `${getMarketingSiteHostUrlForRegion(
        currentCountryRegionCode
      )}/${defaultLocaleForCountryAndLanguage}`;

  return (
    <Box
      sx={calculatedStyles.country(hasCountryActiveLanguages)}
      data-testid={`${country.countryName}-wrapper`}
    >
      {/* COUNTRY NAME */}
      {hasCountryActiveLanguages ? (
        <BemLink
          to={{
            _key: `${country.countryCode}`,
            _type: 'externalLink',
            label: `${country.countryName}`,
            target: '_self',
            externalLink: defaultPageUrl,
          }}
          sx={styles.countryNameLink}
        >
          <Box sx={styles.countryName}>
            <Text as="p">{country.countryName}</Text>
          </Box>
        </BemLink>
      ) : (
        <Box sx={styles.countryName}>
          <Text as="p" sx={styles.countryNameDisabled}>
            {country.countryName}
          </Text>
        </Box>
      )}

      {/* LANGUAGES */}

      <Grid sx={styles.buttonList}>
        {activeLocaleIdentifiersForCountry.map((language, index) => {
          const countryLanguageAssignmentForRegion =
            countryLanguageAssignmentsForRegion.find(({ pageUrl }) =>
              pageUrl.includes(`${language.localeId}`)
            );
          // if exact route also exists in a different country of the same region set link to that route
          const countryLanguageLink = countryLanguageAssignmentForRegion
            ? countryLanguageAssignmentForRegion.pageUrl
            : `${getMarketingSiteHostUrlForRegion(currentCountryRegionCode)}/${
                language.localeId
              }`;

          return (
            <BemLink
              key={`${language.languageCode}-link`}
              to={{
                _key: `${language.languageCode}`,
                _type: 'externalLink',
                label: `${language.languageCode}`,
                target: '_self',
                externalLink: countryLanguageLink,
              }}
              sx={styles.countryLink}
            >
              <Box
                sx={styles.linkButton}
                className={index === 0 ? 'firstLinkButton' : ''}
              >
                <Text as="p">{language.languageCode}</Text>
              </Box>
            </BemLink>
          );
        })}
      </Grid>
    </Box>
  );
};

export { IBemM0134Country, Country };
