import { IGraphqlM0002B } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemQuoteWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { LocalisationContext } from '../../providers';

interface IPropsBemM0002B extends IGraphqlM0002B {}

const styles: IStylesObject = {
  wrapper: {
    bg: 'white',
    py: 8,
    mb: 0,
    boxShadow: 'smallCardShadow',
  },
  linkWrapper: {
    gridColumn: '3 / span 2',
  },
  testimonialWrapper: {
    gridColumn: '6 / span 6',

    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: defaultGridGap,
    pt: 2,
  },
  testimonialImage: {
    gridColumn: 'span 3',
  },
  testimonialTextWrapper: {
    gridColumn: 'span 3',
  },
  testimonialData: {
    color: 'textMuted',
    fontWeight: 'bold',
  },
  linkList: {
    listStyle: 'none',
    marginLeft: 0,
    paddingLeft: 0,
  },
  link: {
    variant: 'text.flyoutText',
    display: 'inline-block',
  },
};

const BemM0002B = ({
  title,
  links,
  testimonialImage,
  testimonialText,
  testimonialName,
  testimonialJobTitle,
}: IPropsBemM0002B): JSX.Element => {
  const { locale } = useContext(LocalisationContext);
  return (
    <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0002B">
      <Box sx={styles.linkWrapper} data-testid="BemM0002B-linkList">
        <BemHeading as="h4" variant="h4" sx={{ pb: 7 }}>
          {title}
        </BemHeading>
        <Box as="ul" sx={styles.linkList}>
          {links.map((link, index) => (
            <Box as="li" key={link._key} sx={{ pb: 5 }}>
              <BemLink
                to={link}
                sx={styles.link}
                additionalTrackingIdInfo={index + 1}
              >
                {link.label}
              </BemLink>
            </Box>
          ))}
        </Box>
      </Box>
      <Grid sx={styles.testimonialWrapper} data-testid="BemM0002B-quote">
        <BemCloudImage image={testimonialImage} sx={styles.testimonialImage} />
        <Box sx={styles.testimonialTextWrapper}>
          <Text as="p" variant="bodyText" sx={{ pb: 8 }}>
            <BemQuoteWrapper localeId={locale.id}>
              {testimonialText}
            </BemQuoteWrapper>
          </Text>
          <Text variant="bodyText" sx={styles.testimonialData}>
            {testimonialName}, <br />
            {testimonialJobTitle}
          </Text>
        </Box>
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0002B, IPropsBemM0002B };
