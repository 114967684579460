import { IGraphqlInternalLink, TAdditionalTrackingIdInfo } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ModuleContext } from '../../providers';
import { BemLink } from '../Link';

interface IBemBreadcrumbButton {
  onClick: () => void;
  label: string;
  _type: 'button';
  _key: string;
}

interface IPropsBreadcrumb {
  links: (IGraphqlInternalLink | IBemBreadcrumbButton)[];
  sx?: ThemeUIStyleObject;
  limitationOfPath?: number;
  addPaddingTop?: boolean;
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const COMMON_VARIANT_LINK = 'buttons.breadcrumbLink';
const COMMON_VARIANT_ACTIVE = 'buttons.breadcrumbText';

const styles: IStylesObject = {
  label: {
    fontWeight: 'bold',
    pb: 3,
  },
  link: {
    cursor: 'pointer',
    pb: 3,
  },
  divider: {
    px: '1_5',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (addPaddingTop) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    pt: addPaddingTop ? 3 : 0,
  }),
};

const getLimitedLinks = ({
  links,
  limitationOfPath,
}: {
  links: (IGraphqlInternalLink | IBemBreadcrumbButton)[];
  limitationOfPath: number;
}) => links.slice(-limitationOfPath);

const isLastBreadcrumb = ({
  array,
  index,
}: {
  array: (IGraphqlInternalLink | IBemBreadcrumbButton)[];
  index: number;
}) => index === array.length - 1;

const BemBreadcrumb = ({
  sx,
  links,
  limitationOfPath = 0,
  addPaddingTop = false,
  ...rest
}: IPropsBreadcrumb): JSX.Element => {
  const hasValidLimitationOfPath =
    limitationOfPath > 0 && limitationOfPath < links.length;
  const visibleLinks = hasValidLimitationOfPath
    ? getLimitedLinks({ links, limitationOfPath })
    : links;

  const { moduleName } = useContext(ModuleContext);
  const componentName = 'BemBreadcrumb';
  const trackingId = `${moduleName}-${componentName}`;

  return (
    <Box sx={sx} {...rest} data-testid="BemBreadcrumb">
      <Flex sx={calculatedStyles.wrapper(addPaddingTop)}>
        <Text
          as="nav"
          variant={COMMON_VARIANT_ACTIVE}
          sx={{
            ...styles.label,
            ...styles.link,
            '> *': {
              display: 'inline',
            },
          }}
        >
          {visibleLinks.map((link, index, array) => (
            <React.Fragment key={link._key}>
              {!isLastBreadcrumb({ array, index }) ? (
                <>
                  {link._type === 'button' ? (
                    <Box
                      as="span"
                      variant={COMMON_VARIANT_LINK}
                      onClick={link.onClick}
                      data-trackingid={`${trackingId}-breadcrumb-item-${
                        index + 1
                      }`}
                    >
                      {link.label}
                    </Box>
                  ) : (
                    <BemLink
                      sx={styles.label}
                      to={link}
                      additionalTrackingIdInfo={`breadcrumb-item-${index + 1}`}
                    >
                      <Box
                        as="span"
                        variant={COMMON_VARIANT_LINK}
                        sx={styles.link}
                      >
                        {link.label}
                      </Box>
                    </BemLink>
                  )}

                  <Box
                    variant={COMMON_VARIANT_LINK}
                    sx={{ ...styles.label, ...styles.divider }}
                  >
                    /
                  </Box>
                </>
              ) : (
                <Box as="span" sx={styles.label}>
                  {link.label}
                </Box>
              )}
            </React.Fragment>
          ))}
        </Text>
      </Flex>
    </Box>
  );
};

export { BemBreadcrumb, IPropsBreadcrumb, IBemBreadcrumbButton };
