import { IGraphqlM0095, IGraphqlM0095Item } from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  GRID_ROW_GAP,
  MOBILE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0095 extends IGraphqlM0095 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: ['2 / -2', '2 / -2', '2 / span 4'],
    pb: ASIDE_HEADLINE_PB,
  },
  item: {
    gridColumn: ['2 / -2', '2 / -2', '7 / -2'],
    gridTemplateColumns: [
      'repeat(12, 1fr)',
      'repeat(12, 1fr)',
      'repeat(7, 1fr)',
    ],
    gap: defaultGridGap,
    rowGap: GRID_ROW_GAP,
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 16, 20],
    '&:last-of-type': {
      pb: 0,
    },
  },
  itemImage: {
    gridColumn: ['1 / -1', '1 / span 3', '1 / span 2'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  itemTextSection: {
    gridColumn: ['1 / -1', '5 / -1', '4 / span 4'],
  },
  itemHeading: {
    pb: [MOBILE_HEADLINE_PB, 8, 9],
  },
};

const getMedia = (item: IGraphqlM0095Item, index: number): JSX.Element =>
  item.link?.length ? (
    <BemLink
      to={item.link}
      sx={styles.itemImage}
      data-testid={item._key}
      additionalTrackingIdInfo={index + 1}
    >
      <BemMedia media={item.media[0]} additionalTrackingIdInfo={index + 1} />
    </BemLink>
  ) : (
    <BemMedia media={item.media[0]} sx={styles.itemImage} />
  );

const BemM0095 = ({ title, items }: IPropsBemM0095): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    {items.map((item, index) => (
      <Grid key={item._key} sx={styles.item}>
        {getMedia(item, index)}
        <Box
          sx={styles.itemTextSection}
          data-testid={`BemM0095-itemTextSection-${item._key}`}
        >
          <BemHeading as="h3" variant="h4" sx={styles.itemHeading}>
            {item.title}
          </BemHeading>
          <Text as="p" variant="bodyText">
            {item.text}
          </Text>
        </Box>
      </Grid>
    ))}
  </BemModuleWrapper>
);

export { BemM0095, IPropsBemM0095 };
