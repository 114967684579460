import { IGraphqlM0014 } from '@bemer/base';
import React from 'react';
import { Box } from 'theme-ui';
import {
  BemAccordionWithExpander,
  BemActionArea,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0014 extends IGraphqlM0014 {}

const styles: IStylesObject = {
  headingSection: {
    gridColumn: ['2 / -2', '2 / span 4', '2 / span 4'],
  },
  heading: {
    pt: 2,
  },
  contentSection: {
    gridColumn: ['1 / -1', '7 / span 7', '7 / span 7'],
    pt: [0, 2, 2],
  },
};

const BemM0014 = ({ title, link, items }: IPropsBemM0014): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.headingSection}>
      <BemHeading
        as="h2"
        variant="h2WithSeparatorForHeadlineWithoutPaddingBottom"
        sx={styles.heading}
      >
        {title}
      </BemHeading>
      {link?.length ? (
        <BemActionArea data-testid="BemM0014Link">
          <BemLink to={link} variant="links.buttonSecondary" />
        </BemActionArea>
      ) : null}
    </Box>
    <Box sx={styles.contentSection} data-testid="BemM0014-contentSection">
      <BemAccordionWithExpander items={items} activeIndex={0} />
    </Box>
  </BemModuleWrapper>
);

export { BemM0014, IPropsBemM0014 };
