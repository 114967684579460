import {
  aspectRatios,
  IGraphqlM0141,
  RATIOS,
  THEME_NAMES,
  TThemeName,
} from '@bemer/base';
import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { Box, Text } from 'theme-ui';
import {
  BemButton,
  BemCloudImage,
  BemCloudVideo,
  BemHeading,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  ON_MEDIA_HEADLINE_V1_PB,
  TABLET_MEDIA_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useViewportRenderer } from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0141 extends IGraphqlM0141 {}

const styles: IStylesObject = {
  playButtonWrapper: {
    textAlign: 'center',
  },
  textWrapperOnMedia: {
    gridRow: [2, 1, 1],
    textAlign: 'center',
    alignSelf: 'center',
    gridColumn: '1 / -1',
    zIndex: 1,
  },
  textWrapperBelowMedia: {
    gridColumn: ['2 / -2', '2 / -2', 'none'],
    textAlign: 'center',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  media: (isVideo, hasText) => {
    const paddingBottomOnMobile = hasText ? MOBILE_MEDIA_PB : 0;
    const paddingBottomOnTablet = isVideo && hasText ? TABLET_MEDIA_PB : 0;

    return {
      gridColumn: '1 / -1',
      gridRow: 1,
      pb: [paddingBottomOnMobile, paddingBottomOnTablet, 0],
    };
  },
  subTitle: (isVideo) => {
    const paddingBottomDesktop = isVideo ? 8 : 0;

    return {
      pb: [0, 0, paddingBottomDesktop],
    };
  },
  heading: (hasMedia) => ({
    pb: ON_MEDIA_HEADLINE_V1_PB,
    label: hasMedia ? '' : 'heroStartsWithText',
  }),
};

interface IPropsTitle {
  title?: string;
  hasMedia?: boolean;
  themeName?: TThemeName | TThemeName[];
}
const Title = ({ title, hasMedia, themeName }: IPropsTitle) =>
  title ? (
    <BemHeading
      as="h1"
      variant="h1"
      sx={calculatedStyles.heading(hasMedia)}
      themeName={themeName}
    >
      {title}
    </BemHeading>
  ) : null;

interface IPropsSubtitle {
  text?: string;
  isVideo?: boolean;
}
const Subtitle = ({ text, isVideo }: IPropsSubtitle) =>
  text ? (
    <Text
      as="p"
      variant="caption.small"
      sx={calculatedStyles.subTitle(isVideo)}
    >
      {text}
    </Text>
  ) : null;

const BemM0141 = ({
  title,
  text,
  media,
}: IPropsBemM0141): JSX.Element | null => {
  const [playing, setPlaying] = useState(false);

  const isVideo = media?.length ? media[0]?._type !== 'imageWithAlt' : false;

  let optionalMediaTheme: TThemeName = THEME_NAMES.DARK_TEXT;
  if (media?.length) {
    if (media[0]._type === 'videoSet') {
      optionalMediaTheme = getLanguageSpecificVideo(media[0]).themeName;
    } else {
      optionalMediaTheme = media[0].themeName;
    }
  }
  const hasText = title || text;

  const textThemes = [
    THEME_NAMES.DARK_TEXT,
    isVideo ? THEME_NAMES.DARK_TEXT : optionalMediaTheme,
    optionalMediaTheme,
  ];

  const getTextOnMedia = () => (
    <Box sx={styles.textWrapperOnMedia} data-testid="M0141-textOnMedia">
      <Title themeName={textThemes} title={title} hasMedia={!!media?.length} />
      <Subtitle text={text} isVideo={isVideo} />
    </Box>
  );

  const getTextBelowMedia = () => (
    <Box sx={styles.textWrapperBelowMedia} data-testid="M0141-textBelowMedia">
      <Title themeName={textThemes} title={title} />
      <Subtitle text={text} isVideo={isVideo} />
    </Box>
  );

  return (
    <BemModuleWrapper>
      <BemThemeWrapper themeName={textThemes}>
        {media?.length ? null : getTextOnMedia()}
        {media?.length &&
        (media[0]._type === 'videoSet' || media[0]._type === 'video') ? (
          <BemCloudVideo
            video={
              media[0]._type === 'videoSet'
                ? getLanguageSpecificVideo(media[0])
                : media[0]
            }
            sx={calculatedStyles.media(isVideo, hasText)}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_2].ratio}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onEnded={() => setPlaying(false)}
            playing={playing}
            autoplayPreview
            playButton={
              <Box sx={styles.playButtonWrapper}>
                {useViewportRenderer([null, null, getTextOnMedia()])}
                <BemButton
                  variant="buttons.play"
                  data-testid="BemCloudVideoPlayButton"
                  onClick={() => setPlaying(true)}
                >
                  <FaPlay />
                </BemButton>
              </Box>
            }
          />
        ) : null}
        {media?.length && media[0]._type === 'imageWithAlt' ? (
          <>
            <BemCloudImage
              image={media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_2].ratio}
              sx={calculatedStyles.media(isVideo, hasText)}
            />

            {useViewportRenderer([null, getTextOnMedia(), getTextOnMedia()])}
          </>
        ) : null}
        {media?.length
          ? useViewportRenderer([
              getTextBelowMedia(),
              isVideo ? getTextBelowMedia() : null,
              null,
            ])
          : null}
      </BemThemeWrapper>
    </BemModuleWrapper>
  );
};

export { BemM0141, IPropsBemM0141 };
