import {
  aspectRatios,
  IGraphqlImage,
  RATIOS,
  TAdditionalTrackingIdInfo,
  TThemeName,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text, ThemeUIStyleObject } from 'theme-ui';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { BemCloudImage } from '../CloudImage';
import { BemHeading } from '../Heading';

type TBemRoundImageVariants = 'row' | 'column-centered' | 'column-left-aligned';
interface IPropsBemRoundImage {
  title?: string;
  text?: string;
  image: IGraphqlImage;
  sx?: ThemeUIStyleObject;
  variant?: TBemRoundImageVariants;
  themeName?: TThemeName | TThemeName[];
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const styles: IStylesObject = {
  title: {
    pb: 5,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  roundImage: (variant: TBemRoundImageVariants) =>
    ({
      gridTemplateColumns: variant.startsWith('row')
        ? 'min-content 1fr'
        : 'auto',
      gridTemplateRows: variant.startsWith('column')
        ? 'min-content min-content'
        : 'auto',
      gap: defaultGridGap,
      textAlign: variant.startsWith('column-centered') ? 'center' : 'left',
    } as const),
  imageWrapper: (variant: TBemRoundImageVariants) => ({
    width: 20,
    height: 20,
    borderRadius: 'full',
    overflow: 'hidden',
    justifySelf: variant.startsWith('column-centered') ? 'center' : 'start',
  }),
  textWrapper: (variant: TBemRoundImageVariants) => ({
    alignSelf: 'center',
    pt: variant.startsWith('column') ? 4 : 0,
  }),
};

const BemRoundImage = ({
  title,
  text,
  image,
  sx,
  variant = 'row',
  additionalTrackingIdInfo,
  themeName,
}: IPropsBemRoundImage): JSX.Element => (
  <Grid sx={{ ...calculatedStyles.roundImage(variant), ...sx }}>
    <Box sx={calculatedStyles.imageWrapper(variant)}>
      <BemCloudImage
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        image={image}
        additionalTrackingIdInfo={additionalTrackingIdInfo}
      />
    </Box>
    {(title || text) && (
      <Box sx={{ ...calculatedStyles.textWrapper(variant) }}>
        {title ? (
          <BemHeading
            as="h4"
            variant="h4"
            sx={styles.title}
            themeName={themeName}
          >
            {title}
          </BemHeading>
        ) : null}
        {text ? <Text as="p">{text}</Text> : null}
      </Box>
    )}
  </Grid>
);

export { BemRoundImage, IPropsBemRoundImage, TBemRoundImageVariants };
