import {
  aspectRatios,
  IGraphqlM0127,
  IGraphqlM0127Item,
  RATIOS,
  THEME_NAMES,
  TThemeName,
} from '@bemer/base';
import React, { useContext, useRef } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_FORWARD,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemPagination,
  BemThemeWrapper,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0127 extends IGraphqlM0127 {}

const PREFIX = 'M0127';

const M0127_VISIBLE_ITEMS_TABLET = 2;
const M0127_VISIBLE_ITEMS_DESKTOP = 3;

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
    pt: 1,
  },
  description: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  image: {
    gridColumn: ['2 / -2', '2 / 8', '2 / 8'],
    pb: [MOBILE_MEDIA_PB, 10, 14],
  },
  title2: {
    pb: ASIDE_HEADLINE_PB,
  },
  textWrapper: {
    gridColumn: ['2 / -2', '9 / -2', '9 / -2'],
    alignSelf: 'center',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 16, 16],
  },
  sliderWrapper: {
    gridColumn: ['1 / -1', '3 / -1', '3 / -1'],
    ml: [0, -4, -4],
  },
  title3: {
    gridColumn: ['2 / span 8', '3 / span 7', '3 / span 7'],
  },
  pagination: {
    gridColumn: '10 / -2',
    justifySelf: 'end',
    display: ['none', 'block', 'block'],
  },
  itemWrapper: {
    scrollSnapAlign: 'start',
    height: '100%',
    px: 4,
    py: 6,
    '&:first-of-type': {
      pl: [0, 4, 4],
    },
  },
  item: {
    height: '100%',
    gridTemplateRows: 'min-content min-content 1fr',
    boxShadow: 'smallCardShadow',
    px: 4,
    pt: 8,
    pb: 12,
    bg: 'background',
  },
  itemImage: {
    borderRadius: 'full',
    overflow: 'hidden',
    width: 20,
    height: 20,
    justifySelf: 'center',
  },
  itemTitle: {
    textAlign: 'center',
    pt: [4, 6, 8],
  },
  itemText: {
    pt: [3, 4, 5],
    textAlign: 'center',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (hasBackgroundColor: boolean) => ({
    pt: hasBackgroundColor ? MODULE_WITH_BACKGROUND_COLOR_PT : 0,
    pb: hasBackgroundColor ? MODULE_WITH_BACKGROUND_COLOR_PB : 0,
  }),
  itemsWrapper: (columns: boolean) => ({
    overflow: 'hidden',
    gridColumn: ['1 / -1', '2 / -1', '2 / -1'],
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: [
      'none',
      `repeat(${columns}, 42%)`,
      `repeat(${columns}, 30%)`,
    ],
    gap: 0,
    scrollSnapType: 'x proximity',
  }),
};

interface IPropsBemM0127Item {
  item: IGraphqlM0127Item;
  index: number;
}
interface IPropsBemM0127Items {
  items: IGraphqlM0127Item[];
  itemContainer: React.Ref<HTMLDivElement>;
}

const Item = ({ item, index }: IPropsBemM0127Item) => (
  <Box key={item._key} sx={styles.itemWrapper} data-testid={item._key}>
    <Grid sx={styles.item}>
      <BemMedia
        media={item.media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        sx={styles.itemImage}
        additionalTrackingIdInfo={index + 1}
      />
      <BemHeading as="h4" variant="h5" sx={styles.itemTitle}>
        {item.title}
      </BemHeading>
      <Text as="p" variant="small" sx={styles.itemText}>
        {item.text}
      </Text>
    </Grid>
  </Box>
);

const ItemsOnTabletsOrLarger = ({
  items,
  itemContainer,
}: IPropsBemM0127Items) => (
  <Grid sx={calculatedStyles.itemsWrapper(items.length)} ref={itemContainer}>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </Grid>
);

const BemM0127 = ({
  title1,
  text1,
  title2,
  text2,
  media,
  title3,
  items,
  theme: moduleTheme,
}: IPropsBemM0127): JSX.Element => {
  const itemContainer = useRef<HTMLDivElement>(null);
  const [theme] = useContext(ThemeHierarchyContext);
  const appliedTheme = moduleTheme || theme;
  const themesWithBackground: TThemeName[] = [
    THEME_NAMES.HUMAN_LINE,
    THEME_NAMES.VETERINARY_LINE,
    THEME_NAMES.NEUTRAL,
  ];
  const hasBackgroundColor = themesWithBackground.includes(appliedTheme);

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    if (!itemContainer?.current?.firstElementChild) {
      return;
    }
    const itemWidth =
      itemContainer.current.firstElementChild.getBoundingClientRect().width;
    const containerOffsetLeft = itemContainer.current.scrollLeft;

    const containerScrollTo =
      animationDirection === ANIMATION_DIRECTION_FORWARD
        ? containerOffsetLeft + itemWidth
        : containerOffsetLeft - itemWidth;

    itemContainer.current.scrollTo({
      left: containerScrollTo,
      behavior: 'smooth',
    });
  };

  const getPagination = (visibleItems: number) =>
    items.length > visibleItems ? (
      <BemPagination
        onClick={scrollToItem}
        items={items}
        visibleItems={visibleItems}
        isCarousel={false}
        withText={false}
        sx={styles.pagination}
      />
    ) : null;
  return (
    <BemThemeWrapper themeName={appliedTheme}>
      <BemModuleWrapper
        sx={calculatedStyles.wrapper(hasBackgroundColor)}
        data-testid="BemM0127-bemModuleWrapper"
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title1}
        </BemHeading>
        {text1 ? (
          <Text as="p" variant="caption.small" sx={styles.description}>
            {text1}
          </Text>
        ) : null}
        <BemMedia
          media={media[0]}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
          sx={styles.image}
          additionalTrackingIdInfo="main"
        />
        <Box sx={styles.textWrapper}>
          <BemHeading as="h3" variant="h3" sx={styles.title2}>
            {title2}
          </BemHeading>
          <Text as="p" variant="caption.small">
            {text2}
          </Text>
        </Box>
        <BemHeading as="h3" variant="h3" sx={styles.title3}>
          {title3}
        </BemHeading>
        {useViewportRenderer([
          null,
          getPagination(M0127_VISIBLE_ITEMS_TABLET),
          getPagination(M0127_VISIBLE_ITEMS_DESKTOP),
        ])}
        <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
          <Box sx={styles.sliderWrapper}>
            {useViewportRenderer([
              <BemTouchSlider
                key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
                items={items}
                itemRenderer={({ item }, index) => (
                  <Item item={item} index={index} />
                )}
                hideShadow
              />,
              <ItemsOnTabletsOrLarger
                items={items}
                itemContainer={itemContainer}
                key={`${PREFIX}_${VIEWPORTS.TABLET}`}
              />,
              <ItemsOnTabletsOrLarger
                items={items}
                itemContainer={itemContainer}
                key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
              />,
            ])}
          </Box>
        </BemThemeWrapper>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0127, IPropsBemM0127 };
