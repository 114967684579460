import { IGraphqlM0144 } from '@bemer/base';
import React, { useContext } from 'react';
import {
  BemModuleWrapper,
  BemRichtext,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0144 extends IGraphqlM0144 {}

const styles: IStylesObject = {
  wrapper: {
    pt: [8, 10, 12],
    mb: 0,
  },
  text: {
    pb: [8, 10, 12],
    gridColumn: '2 / -2',
    borderColor: 'textMuted',
    borderBottom: 'solid 1px',
    color: 'textMuted',
    textAlign: 'justify',
  },
};

const BemM0144 = ({
  blocks,
  theme: moduleTheme,
}: IPropsBemM0144): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        isFullWidth
        sx={styles.wrapper}
        data-testid="BemM0144ModuleWrapper"
      >
        <BemRichtext sx={styles.text} blocks={blocks} />
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0144, IPropsBemM0144 };
