import {
  aspectRatios,
  IGraphqlM0051,
  IGraphqlM0051Item,
  RATIOS,
} from '@bemer/base';
import React, { useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Box, Grid, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_FORWARD,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemPagination,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import {
  defaultGridGap,
  GRID_GAP_DESKTOP,
  GRID_GAP_TABLET,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_TEXT_PB,
  MOBILE_MODULE_TEXT_PB,
  MOBILE_TEXT_PB,
  PAGINATION_PADDING,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { getPaginationColumnWidth } from '../../utils/paginationColumnWidthHelpers';

interface IPropsBemM0051 extends IGraphqlM0051 {}

interface IPropsBemM0051Item {
  item: IGraphqlM0051Item;
  index: number;
}
interface IPropsBemM0051Items {
  items: IGraphqlM0051Item[];
  itemContainer: React.Ref<HTMLDivElement>;
}

const PREFIX = 'M0051';

const M0051_VISIBLE_ITEMS = 3;

const styles: IStylesObject = {
  titleWrapper: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  textWrapper: {
    gridColumn: '4 / -4',
    textAlign: 'center',
    pb: [MOBILE_MODULE_TEXT_PB, 0, 0],
  },
  pagination: {
    gridColumn: '1 / 14',
    justifySelf: 'right',
    pb: [
      0,
      PAGINATION_PADDING - GRID_GAP_TABLET,
      PAGINATION_PADDING - GRID_GAP_DESKTOP,
    ],
  },
  name: {
    pt: 4,
    pb: [2, 0, 0],
  },
  jobTitle: {
    color: 'gray.4',
    pb: [4, 2, 2],
  },
  hint: {
    gridColumn: ['2 / -2', '4 / -4', '4 / -4'],
    color: 'gray.4',
    pt: 3,
    textAlign: 'center',
  },
  linkWrapper: {
    pb: [0, 10, 10],
  },
  item: {
    p: [4, 0, 0],
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gridGap: defaultGridGap,
    display: 'grid',
    scrollSnapAlign: 'start',
    height: '100%',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemsWrapper: (numberOfItems: number) => ({
    gridColumn: '2 / -2',
    gridAutoFlow: 'column',
    gridTemplateColumns: [
      `repeat(${numberOfItems}, 100%)`,
      `repeat(${numberOfItems}, ${getPaginationColumnWidth(
        M0051_VISIBLE_ITEMS
      )})`,
      `repeat(${numberOfItems}, ${getPaginationColumnWidth(
        M0051_VISIBLE_ITEMS
      )})`,
    ],
    gap: [defaultGridGap, '2%', '2%'],
    scrollSnapType: 'x proximity',
    overflow: 'hidden',
  }),
  textWrapper: (isPaginationVisible: boolean) => ({
    gridColumn: '4 / -4',
    textAlign: 'center',
    pb: isPaginationVisible ? [MOBILE_TEXT_PB, 0, 0] : ASIDE_TEXT_PB,
  }),
};

const Item = ({ item, index }: IPropsBemM0051Item) => (
  <Box sx={styles.item} data-testid={item._key}>
    <BemMedia
      media={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_3].ratio}
      playButtonPosition="bottomLeft"
      additionalTrackingIdInfo={index + 1}
    />
    <Text as="h5" variant="h5" sx={styles.name}>
      {item.name}
    </Text>
    <Text as="p" variant="bodyText" sx={styles.jobTitle}>
      {item.jobTitle}
    </Text>
    {item.downloadLink ? (
      <Box sx={styles.linkWrapper}>
        <BemLink
          to={item.downloadLink}
          variant="links.buttonSecondary"
          iconAfter={<FaDownload />}
          additionalTrackingIdInfo={index + 1}
          data-testid={`${item._key}-link`}
        />
      </Box>
    ) : null}
  </Box>
);

const ItemsOnTabletOrLarger = ({
  items,
  itemContainer,
}: IPropsBemM0051Items) => (
  <Grid
    sx={calculatedStyles.itemsWrapper(items.length)}
    variant="contentGrid"
    ref={itemContainer}
    data-testid="BemM0051-contentGrid"
  >
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </Grid>
);

const renderItem = ({ item, index }: IPropsBemM0051Item) => (
  <Item item={item} index={index} />
);

const BemM0051 = ({
  title,
  text,
  items,
  hint,
}: IPropsBemM0051): JSX.Element => {
  const itemContainer = useRef<HTMLDivElement>(null);

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    if (!itemContainer?.current?.firstElementChild) {
      return;
    }
    const itemWidth =
      itemContainer.current.firstElementChild.getBoundingClientRect().width;
    const containerOffsetLeft = itemContainer.current.scrollLeft;
    const containerScrollTo =
      animationDirection === ANIMATION_DIRECTION_FORWARD
        ? containerOffsetLeft + itemWidth
        : containerOffsetLeft - itemWidth;

    itemContainer.current.scrollTo({
      left: containerScrollTo,
      behavior: 'smooth',
    });
  };

  const getPagination = (visibleItems: number) =>
    items.length > visibleItems ? (
      <BemPagination
        onClick={scrollToItem}
        items={items}
        visibleItems={visibleItems}
        isCarousel={false}
        withText={false}
        sx={styles.pagination}
      />
    ) : null;

  return (
    <BemModuleWrapper>
      <BemHeading as="h2" variant="h2" sx={styles.titleWrapper}>
        {title}
      </BemHeading>
      <Text
        as="p"
        variant="caption.small"
        sx={calculatedStyles.textWrapper(items.length > M0051_VISIBLE_ITEMS)}
      >
        {text}
      </Text>
      {useViewportRenderer([
        null,
        getPagination(M0051_VISIBLE_ITEMS),
        getPagination(M0051_VISIBLE_ITEMS),
      ])}
      {useViewportRenderer([
        <BemTouchSlider
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          items={items}
          itemRenderer={(data, index) => renderItem({ item: data.item, index })}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          itemContainer={itemContainer}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          itemContainer={itemContainer}
        />,
      ])}

      <Text sx={styles.hint} as="p" variant="tiny">
        {hint}
      </Text>
    </BemModuleWrapper>
  );
};

export { BemM0051, IPropsBemM0051, IPropsBemM0051Item, IPropsBemM0051Items };
