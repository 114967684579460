import { IGraphqlM0122 } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MOBILE_HEADLINE_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0122 extends IGraphqlM0122 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: ['2 / -2', '2 / span 3', '2 / span 3'],
    pb: [MOBILE_HEADLINE_PB, 0, 0],
  },
  textWrapper: {
    gridColumn: ['2 / -2', '7 / -2', '7 / -2'],
  },
  text: {
    pt: [0, 3, 3],
  },
};

const BemM0122 = ({ title, text, link }: IPropsBemM0122): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    <Box sx={styles.textWrapper}>
      <Text as="p" variant="bodyText" sx={styles.text}>
        {text}
      </Text>
      {link?.length ? (
        <BemActionArea data-testid="BemM0122Link">
          <BemLink to={link} variant="links.buttonPrimary" />
        </BemActionArea>
      ) : null}
    </Box>
  </BemModuleWrapper>
);

export { BemM0122, IPropsBemM0122 };
