import { buildInternalRoute, IM0002Nav } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Box, Flex, Text } from 'theme-ui';
import { BemButton, BemLink } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { LocalisationContext } from '../../providers';
import { isPartialMatch } from '../../utils/isPartialMatch';

const ITEM_TEXT_OPEN = 'open';
const ITEM_TEXT_COLLAPSED = 'collapsed';

const styles: IStylesObject = {
  expandableListItem: {
    width: '100%',
    py: 2,
  },
  expandableLinkWrapper: {
    pb: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandableLinkLabel: {
    pb: 2,
    display: 'flex',
    flexBasis: '100%',
  },
  expandableLink: {
    color: 'text',
    width: '100%',
    '&:hover, &.active': {
      color: 'text',
    },
  },
  expandButton: {
    cursor: 'pointer',
    color: 'text',
    p: 0,
    m: 0,

    position: 'relative',
  },
  expandIcon: {
    color: 'text',
    width: 4,
    height: 4,
    display: 'block',
  },
  subNavigationLink: {
    color: 'text',
    border: 'none',
    borderLeft: '1px solid',
    borderLeftColor: 'transparent',
    pl: 4,
    py: 2,
    display: 'block',
    fontWeight: 400,
    '&.active': {
      color: 'text',
      borderLeftColor: 'currentColor',
      bg: 'background.6',
      fontWeight: 700,
    },
    '&:hover': {
      color: 'text',
    },
  },
  noList: {
    m: 0,
    p: 0,
    listStyle: 'none',
  },
};
const variants = {
  itemExpander: {
    [ITEM_TEXT_OPEN]: {
      rotate: -180,
    },
    [ITEM_TEXT_COLLAPSED]: {
      rotate: 0,
    },
  },
  textWrapper: {
    [ITEM_TEXT_OPEN]: {
      height: 'auto',
      pointerEvents: 'auto',
      visibility: 'visible',
      overflow: 'hidden',
      transition: {
        staggerChildren: 0.05,
        animation: 'ease-in-out',
      },
    },
    [ITEM_TEXT_COLLAPSED]: {
      height: 0,
      transitionEnd: {
        pointerEvents: 'none',
        visibility: 'hidden',
        overflow: 'hidden',
      },
      transition: {
        delay: DURATION.SHORT,
        duration: DURATION.SHORT,
      },
    },
  },
  text: {
    [ITEM_TEXT_OPEN]: {
      opacity: 1,
    },
    [ITEM_TEXT_COLLAPSED]: {
      opacity: 0,

      transitions: {
        delay: DURATION.MEDIUM,
        animation: 'ease-in-out',
      },
    },
  },
};

const AnimatedBox = motion(Box);

const calculatedStyles: ICalculatedStylesObject = {
  expandableLink: (isActive: boolean) =>
    isActive
      ? {
          color: 'text',
          fontWeight: 700,
          width: '100%',
        }
      : {},
};

interface IPropsExpandableMenu {
  item: IM0002Nav;
  isOpen: boolean;
  send: ({ type, value }: { type: string; value: string }) => void;
  index: number;
}
const ExpandableMenu = ({
  item,
  isOpen,
  send,
  index,
}: IPropsExpandableMenu): JSX.Element => {
  const { localeIdentifier } = useContext(LocalisationContext);

  const nestedLinks = item.flyoutModule?.module[0].links || [];
  const hasNestedLinks = Boolean(nestedLinks?.length && nestedLinks.length > 0);
  const expandableLinkIsActive = isPartialMatch(
    buildInternalRoute(item.to, localeIdentifier),
    window.location.pathname
  );
  return (
    <Box as="li" key={item._key} sx={styles.expandableListItem}>
      <Flex sx={styles.expandableLinkWrapper}>
        <Text as="div" sx={styles.expandableLinkLabel}>
          <BemLink
            to={item}
            sx={{
              ...styles.expandableLink,
              ...calculatedStyles.expandableLink(expandableLinkIsActive),
            }}
            additionalTrackingIdInfo={index + 1}
          >
            {item.label}
          </BemLink>
        </Text>

        {hasNestedLinks ? (
          <BemButton
            variant="buttons.itemExpanderOutlineSmall"
            onClick={() => send({ type: 'click', value: item._key })}
            sx={styles.expandButton}
            additionalTrackingIdInfo={index + 1}
            data-testid={`expandButton-${item._key}`}
          >
            <AnimatedBox
              variants={variants.itemExpander}
              animate={isOpen ? ITEM_TEXT_OPEN : ITEM_TEXT_COLLAPSED}
            >
              <Box as={MdExpandMore} sx={styles.expandIcon} />
            </AnimatedBox>
          </BemButton>
        ) : null}
      </Flex>
      <AnimatedBox
        animate={isOpen ? ITEM_TEXT_OPEN : ITEM_TEXT_COLLAPSED}
        // @ts-ignore
        variants={variants.textWrapper}
        initial="collapsed"
        as="ul"
        sx={styles.noList}
        data-testid={`textWrapper-${item._key}`}
      >
        {nestedLinks.map((link, nestedLinksIndex) => (
          <AnimatedBox as="li" key={link._key} variants={variants.text}>
            <Text sx={styles.subNavigationLink}>
              <BemLink
                sx={styles.subNavigationLink}
                partiallyActive
                to={link}
                variant="links.nav"
                additionalTrackingIdInfo={`${index + 1}-${
                  nestedLinksIndex + 1
                }`}
              >
                {link.label}
              </BemLink>
            </Text>
          </AnimatedBox>
        ))}
      </AnimatedBox>
    </Box>
  );
};

export { ExpandableMenu };
