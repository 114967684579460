import { IPartnerFinderData } from '@bemer/base';
import { useContext } from 'react';
import { ApiContext } from '../providers';

const useGetPartnerData = (): IPartnerFinderData | null | undefined => {
  const {
    partner: { getPartnerByHandle },
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);

  const { data: visitorSettings } = getVisitorSettings();
  const { data: partnerData } = getPartnerByHandle(
    visitorSettings?.partnerHandle,
    {
      enabled: !!visitorSettings?.partnerHandle,
    }
  );

  return partnerData;
};

export { useGetPartnerData };
