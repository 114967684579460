import { aspectRatios, IGraphqlM0053, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MOBILE_MEDIA_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0053 extends IGraphqlM0053 {}

const styles: IStylesObject = {
  wrapper: {
    gridColumn: ['2 / -2', '2 / span 4', '2 / span 4'],
    alignSelf: 'center',
    gridRow: 2,
  },
  video: {
    gridColumn: ['2 / -2', '7 / -2', '7 / -2'],
    gridRow: [1, 2, 2],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
};

const BemM0053 = ({
  text,
  downloadLink,
  media,
}: IPropsBemM0053): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.wrapper} data-testid="BemM0053-textLinkWrapper">
      <Text as="p" variant="caption.big" sx={styles.text}>
        {text}
      </Text>
      {downloadLink ? (
        <BemActionArea data-testid="BemM0053-downloadLinkWrapper">
          <BemLink to={downloadLink} variant="links.buttonSecondary" />
        </BemActionArea>
      ) : null}
    </Box>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_3].ratio}
      sx={styles.video}
      autoplayPreview
    />
  </BemModuleWrapper>
);

export { BemM0053, IPropsBemM0053 };
