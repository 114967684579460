import {
  aspectRatios,
  IGraphqlM0075,
  IGraphqlM0075Item,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext, useRef } from 'react';
import { Box, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_FORWARD,
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemPagination,
  BemThemeWrapper,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';
import { ThemeHierarchyContext } from '../../providers';
import { isTablet } from '../../utils/breakpointIndices';
import { getPaginationColumnWidth } from '../../utils/paginationColumnWidthHelpers';

interface IPropsBemM0075 extends IGraphqlM0075 {}

const PREFIX = 'M0075';

const M0075_VISIBLE_ITEMS_TABLET = 3;
const M0075_VISIBLE_ITEMS_DESKTOP = 4;

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: ['2 / -2', '2 / span 9', '2 / span 10'],
    textAlign: 'left',
    pb: TOP_HEADLINE_V2_PB,
    gridRow: 1,
  },
  item: {
    bg: 'background',
    pt: [10, 8, 10],
    px: [10, 8, 10],
    pb: [10, 8, 10],
    scrollSnapAlign: 'start',
    display: 'block',
    height: '100%',
  },
  itemImage: {
    textAlign: 'center',
    pb: [10, 8, 10],
  },
  itemText: {
    textAlign: 'center',
    color: 'black',
  },
  pagination: {
    gridColumn: ['span 3', 'span 3', 'span 2'],
    gridRow: 1,
    justifySelf: 'end',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemsWrapper: (columns: number) => ({
    gridColumn: '2 / -1',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: `repeat(${columns}, ${
      isTablet()
        ? getPaginationColumnWidth(M0075_VISIBLE_ITEMS_TABLET)
        : getPaginationColumnWidth(M0075_VISIBLE_ITEMS_DESKTOP)
    })`,
    gap: [0, '2%', '2%'],
    scrollSnapType: 'x proximity',
    overflow: 'hidden',
    pr: defaultGridGap,
  }),
};

interface IPropsBemM0075Item {
  item: IGraphqlM0075Item;
  index: number;
}
interface IPropsBemM0075Items {
  items: IGraphqlM0075Item[];
  itemContainer: React.Ref<HTMLDivElement>;
}

const Item = ({ item, index }: IPropsBemM0075Item) => (
  <BemLink
    key={item._key}
    to={item.link}
    sx={styles.item}
    additionalTrackingIdInfo={index + 1}
    data-testid={item._key}
  >
    <BemCloudImage
      image={item.image}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      sx={styles.itemImage}
      additionalTrackingIdInfo={index + 1}
    />
    <Text as="p" variant="bodyText" sx={styles.itemText}>
      {item.text}
    </Text>
  </BemLink>
);

const ItemsOnTabletOrLarger = ({
  items,
  itemContainer,
}: IPropsBemM0075Items) => (
  <Box sx={calculatedStyles.itemsWrapper(items.length)} ref={itemContainer}>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </Box>
);

const BemM0075 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0075): JSX.Element => {
  const itemContainer = useRef<HTMLDivElement>(null);
  const [theme] = useContext(ThemeHierarchyContext);

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    if (!itemContainer?.current?.firstElementChild) {
      return;
    }

    const itemWidth =
      itemContainer.current.firstElementChild.getBoundingClientRect().width;
    const containerOffsetLeft = itemContainer.current.scrollLeft;

    const containerScrollTo =
      animationDirection === ANIMATION_DIRECTION_FORWARD
        ? containerOffsetLeft + itemWidth
        : containerOffsetLeft - itemWidth;

    itemContainer.current.scrollTo({
      left: containerScrollTo,
      behavior: 'smooth',
    });
  };

  const getPagination = (visibleItems: number) =>
    items.length > visibleItems ? (
      <BemPagination
        onClick={scrollToItem}
        visibleItems={visibleItems}
        sx={styles.pagination}
        items={items}
        isCarousel={false}
        withText={false}
      />
    ) : null;

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0075-bemModuleWrapper"
      >
        <BemHeading as="h2" variant="h1" sx={styles.heading}>
          {title}
        </BemHeading>
        {useViewportRenderer([
          null,
          getPagination(M0075_VISIBLE_ITEMS_TABLET),
          getPagination(M0075_VISIBLE_ITEMS_DESKTOP),
        ])}
        <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
          {useViewportRenderer([
            <BemTouchSlider
              items={items}
              itemRenderer={({ item }, index) => (
                <Item item={item} index={index} />
              )}
              key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
            />,
            <ItemsOnTabletOrLarger
              items={items}
              key={`${PREFIX}_${VIEWPORTS.TABLET}`}
              itemContainer={itemContainer}
            />,
            <ItemsOnTabletOrLarger
              items={items}
              key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
              itemContainer={itemContainer}
            />,
          ])}
        </BemThemeWrapper>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0075, IPropsBemM0075 };
