import {
  aspectRatios,
  IGraphqlBaseObject,
  IGraphqlM0117,
  IGraphqlProduct,
  RATIOS,
  TGraphqlLink,
} from '@bemer/base';
import React, { useContext, useRef } from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_FORWARD,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemPagination,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import {
  defaultGridGap,
  GRID_GAP_DESKTOP,
  GRID_GAP_TABLET,
} from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_CARD_PADDING,
  MOBILE_HEADLINE_PB,
  PAGINATION_PADDING,
  PAGINATION_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ApiContext, LocalisationContext } from '../../providers';
import { getLocalizedAmount } from '../../utils/localisation';

interface IPropsBemM0117 extends IGraphqlM0117 {}

interface IPropsBemM0117ExtendedItem extends IGraphqlBaseObject {
  product: IGraphqlProduct;
  link?: TGraphqlLink;
  productPrice?: number | null;
  productCurrency?: string;
}

interface IPropsBemM0117ProductCard {
  extendedItem: IPropsBemM0117ExtendedItem;
  index: number;
}

interface IPropsBemM0117ExtendedItems {
  items: IPropsBemM0117ExtendedItem[];
  productContainer: React.Ref<HTMLDivElement>;
}

const PREFIX = 'M0117';

const M0117_VISIBLE_ITEMS_TABLET = 2;
const M0117_VISIBLE_ITEMS_DESKTOP = 4;

const styles: IStylesObject = {
  headingWrapper: {
    gridColumn: '2 / -2',
    pb: [MOBILE_HEADLINE_PB, 0, 0],
    justifyContent: 'space-between',
  },
  heading: {
    pb: PAGINATION_PB,
  },
  productCard: {
    height: ['100%', 'auto', 'auto'],
    gridTemplateRows: 'auto 1fr',
    gridGap: defaultGridGap,
    '& > *': {
      pb: 2,
    },
    pb: [MOBILE_CARD_PADDING, 0, 0],
    scrollSnapAlign: 'start',
    alignSelf: 'start',
  },
  itemText: {
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  itemPrice: {
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  itemButtonWrapper: {
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  pagination: {
    pb: [
      0,
      PAGINATION_PADDING - GRID_GAP_TABLET,
      PAGINATION_PADDING - GRID_GAP_DESKTOP,
    ],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  productsWrapper: (columns: number) => ({
    gridColumn: '2 / -2',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: [
      `repeat(${columns}, 100%)`,
      `repeat(${columns}, calc(50% - ${2 * GRID_GAP_TABLET}px))`,
      `repeat(${columns}, calc(25% - ${3 * GRID_GAP_DESKTOP}px))`,
    ],
    gap: defaultGridGap,
    scrollSnapType: 'x proximity',
    overflow: 'hidden',
  }),
};

const ProductCard = ({ extendedItem, index }: IPropsBemM0117ProductCard) => {
  const { locale } = useContext(LocalisationContext);
  return (
    <Grid
      key={extendedItem.product._id}
      data-testid={extendedItem.product._id}
      sx={styles.productCard}
    >
      <BemMedia
        media={extendedItem.product.media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        additionalTrackingIdInfo={index + 1}
      />
      <Text as="p" sx={styles.itemText}>
        <strong>{extendedItem.product.name}</strong>
      </Text>
      {extendedItem.productPrice ? (
        <Text as="p" sx={styles.itemText}>
          {getLocalizedAmount(
            locale,
            extendedItem.productPrice,
            extendedItem.productCurrency
          )}
        </Text>
      ) : null}
      <Box sx={styles.itemButtonWrapper}>
        {extendedItem.link?.length ? (
          <BemLink
            to={extendedItem.link}
            variant="links.buttonGhostDark"
            additionalTrackingIdInfo={index + 1}
            data-testid={`${extendedItem.product._id}-link`}
          />
        ) : null}
      </Box>
    </Grid>
  );
};

const ItemsOnMobile = ({ items }: IPropsBemM0117ExtendedItems) => (
  <BemTouchSlider
    key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
    itemRenderer={({ item }, index) => (
      <ProductCard extendedItem={item} index={index} />
    )}
    items={items}
  />
);

const ItemsOnTabletOrLarger = ({
  items,
  productContainer,
}: IPropsBemM0117ExtendedItems) => (
  <Grid
    ref={productContainer}
    sx={calculatedStyles.productsWrapper(items.length)}
  >
    {items.map((item, index) => (
      <ProductCard extendedItem={item} index={index} key={item._key} />
    ))}
  </Grid>
);

const BemM0117 = ({ title, items }: IPropsBemM0117): JSX.Element => {
  const { locale } = useContext(LocalisationContext);

  const {
    products: { getProducts },
  } = useContext(ApiContext);
  const { data } = getProducts(locale);
  const extendedItems: IPropsBemM0117ExtendedItem[] = items.map((item) => {
    const apiProduct =
      data && data.find((d) => d.productId === item.product.productId);
    return {
      ...item,
      productPrice: apiProduct?.price,
      productCurrency: apiProduct?.currency,
    };
  });
  const productContainer = useRef<HTMLDivElement>(null);

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    if (!productContainer?.current?.firstElementChild) {
      return;
    }
    const itemWidth =
      productContainer.current.firstElementChild.getBoundingClientRect().width;
    const containerOffsetLeft = productContainer.current.scrollLeft;
    const containerScrollTo =
      animationDirection === ANIMATION_DIRECTION_FORWARD
        ? containerOffsetLeft + itemWidth
        : containerOffsetLeft - itemWidth;

    productContainer.current.scrollTo({
      left: containerScrollTo,
      behavior: 'smooth',
    });
  };

  const getPagination = (visibleItems: number) =>
    items.length > visibleItems ? (
      <BemPagination
        onClick={scrollToItem}
        items={items}
        visibleItems={visibleItems}
        isCarousel={false}
        withText={false}
        sx={styles.pagination}
      />
    ) : null;

  return (
    <BemModuleWrapper>
      <Flex sx={styles.headingWrapper}>
        <BemHeading as="h3" variant="h3" sx={styles.heading}>
          {title}
        </BemHeading>
        {useViewportRenderer([
          null,
          getPagination(M0117_VISIBLE_ITEMS_TABLET),
          getPagination(M0117_VISIBLE_ITEMS_DESKTOP),
        ])}
      </Flex>
      {useViewportRenderer([
        <ItemsOnMobile
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          items={extendedItems}
          productContainer={productContainer}
        />,
        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          items={extendedItems}
          productContainer={productContainer}
        />,
        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          items={extendedItems}
          productContainer={productContainer}
        />,
      ])}
    </BemModuleWrapper>
  );
};

export { BemM0117, IPropsBemM0117, IPropsBemM0117ExtendedItems };
