import { assign, Machine, StateSchema } from 'xstate';

interface IPaginationContext {
  isBackwardButtonDisabled: boolean;
  isForwardButtonDisabled: boolean;
  activeIndex: number;
  items: any[];
  isCarousel: boolean;
  visibleItems: number;
}

interface IPaginationSchema {
  states: {
    default: StateSchema;
  };
}

type TPaginationEvents = { type: 'backwardButton' } | { type: 'forwardButton' };

const defaultItems: any[] = [];

const bemPaginationMachine = Machine<
  IPaginationContext,
  IPaginationSchema,
  TPaginationEvents
>(
  {
    id: 'pagination',
    initial: 'default',
    context: {
      isBackwardButtonDisabled: false,
      isForwardButtonDisabled: false,
      items: defaultItems,
      activeIndex: 0,
      isCarousel: true,
      visibleItems: 1,
    },
    states: {
      default: {
        entry: ['setBackwardButtonIsDisabled', 'setForwardButtonIsDisabled'],
        on: {
          backwardButton: {
            actions: [
              'decreaseActiveIndex',
              'setBackwardButtonIsDisabled',
              'setForwardButtonIsDisabled',
            ],
          },
          forwardButton: {
            actions: [
              'increaseActiveIndex',
              'setForwardButtonIsDisabled',
              'setBackwardButtonIsDisabled',
            ],
          },
        },
      },
    },
  },
  {
    actions: {
      setBackwardButtonIsDisabled: assign(
        ({ activeIndex, isCarousel }, _event) => {
          if (isCarousel) return {};
          return {
            isBackwardButtonDisabled: activeIndex === 0,
          };
        }
      ),
      setForwardButtonIsDisabled: assign(
        ({ items, isCarousel, activeIndex, visibleItems }, _event) => {
          if (isCarousel) return {};
          const maxIndex = items.length - visibleItems;
          return {
            isForwardButtonDisabled: maxIndex === activeIndex || maxIndex < 1,
          };
        }
      ),
      increaseActiveIndex: assign(({ activeIndex, items }, _event) => ({
        activeIndex: items.length - 1 === activeIndex ? 0 : activeIndex + 1,
      })),
      decreaseActiveIndex: assign(({ activeIndex, items }, _event) => ({
        activeIndex: activeIndex === 0 ? items.length - 1 : activeIndex + -1,
      })),
    },
  }
);

export { bemPaginationMachine, TPaginationEvents, IPaginationContext };
