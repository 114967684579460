import {
  aspectRatios,
  IGraphqlM0032,
  RATIOS,
  THEME_NAMES,
  TThemeName,
} from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0032 extends IGraphqlM0032 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  caption: {
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: [8, 20, 28],
  },
  contentItem: {
    alignItems: 'center',
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 24, 24],

    '&:last-of-type': {
      pb: 0,
    },
  },
  itemHeading: {
    pb: ASIDE_HEADLINE_PB,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (hasBackgroundColor: boolean) => ({
    bg: hasBackgroundColor ? 'background' : 'white',
    pt: hasBackgroundColor ? MODULE_WITH_BACKGROUND_COLOR_PT : 0,
    pb: hasBackgroundColor ? MODULE_WITH_BACKGROUND_COLOR_PB : 0,
  }),
  image: (isEven: boolean) => ({
    gridRow: 1,
    gridColumn: isEven
      ? ['1 / -1', '7 / -1', '7 / -1']
      : ['1 / -1', '1 / span 6', '1 / span 6'],
    pb: ASIDE_MEDIA_PB,
  }),
  text: (isEven: boolean) => ({
    gridRow: [2, 1, 1],
    gridColumn: isEven
      ? ['1 / -1', '1 / span 5', '1 / span 5']
      : ['1 / -1', '8 / span 5', '8 / span 5'],
  }),
};

const BemM0032 = ({
  title,
  caption,
  items,
  theme: moduleTheme,
}: IPropsBemM0032): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  const appliedTheme = moduleTheme || theme;
  const themesWithBackground: TThemeName[] = [
    THEME_NAMES.HUMAN_LINE,
    THEME_NAMES.VETERINARY_LINE,
    THEME_NAMES.NEUTRAL,
  ];
  const hasBackgroundColor = themesWithBackground.includes(appliedTheme);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={calculatedStyles.wrapper(hasBackgroundColor)}
        data-testid="BemM0032-bemModuleWrapper"
      >
        <Grid variant="contentGrid">
          <BemHeading as="h1" variant="h1" sx={styles.heading}>
            {title}
          </BemHeading>
          {caption ? (
            <Text
              as="p"
              data-testid="BemM0032Caption"
              variant="caption.big"
              sx={styles.caption}
            >
              {caption}
            </Text>
          ) : null}
        </Grid>

        {items.map((item, index) => (
          <Grid
            variant="contentGrid"
            sx={styles.contentItem}
            key={item._key}
            data-testid={item._key}
          >
            <Box sx={calculatedStyles.text(index % 2)}>
              <BemHeading as="h2" variant="h2" sx={styles.itemHeading}>
                {item.title}
              </BemHeading>
              <Text as="p" variant="caption.small">
                {item.text}
              </Text>
              {item.link?.length ? (
                <BemActionArea>
                  <BemLink
                    to={item.link}
                    variant="links.buttonPrimary"
                    additionalTrackingIdInfo={index + 1}
                  />
                </BemActionArea>
              ) : null}
            </Box>
            <BemMedia
              media={item.media[0]}
              forcedAspectRatio={aspectRatios[RATIOS.RATIO_6_5].ratio}
              sx={calculatedStyles.image(index % 2)}
              testId={`BemM0032Image_${item._key}_${index}_${item.media[0]._key}`}
              additionalTrackingIdInfo={index + 1}
            />
          </Grid>
        ))}
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0032, IPropsBemM0032 };
