import { TGraphqlLink, TGraphqlLinkItem } from '@bemer/base';
import { isValidUrl } from './urlUtils';

const extendLinkWithPartnerHandle = (
  link: TGraphqlLinkItem | TGraphqlLink,
  partnerHandle?: string
): TGraphqlLinkItem => {
  const linkItem = Array.isArray(link) ? link[0] : link;
  // internalLinks cannot be extended and if there is no partner provided, there is no need to extend
  if (
    linkItem._type !== 'externalLink' ||
    !partnerHandle ||
    !isValidUrl(linkItem?.externalLink)
  ) {
    return linkItem;
  }

  const linkWithPartner = new URL(linkItem.externalLink);
  linkWithPartner.searchParams.append('partner', partnerHandle);

  return {
    ...linkItem,
    externalLink: linkWithPartner.href,
  };
};

export { extendLinkWithPartnerHandle };
