import { TEvent } from '@bemer/base';
import { Feature, FeatureCollection, Geometry } from '@turf/helpers';

interface IFeature<G = Geometry> extends Feature<G> {
  place_name: string;
}
interface IFeatureCollection<G = Geometry> extends FeatureCollection<G> {
  features: IFeature<G>[];
}

const removeDuplicateFeatures = (visibleFeatures: IFeature[]): IFeature[] => {
  const ids = [
    ...new Set(
      visibleFeatures.map((feature: IFeature) => feature?.properties?.id)
    ),
  ];
  return ids.map(
    (id) =>
      visibleFeatures.filter((feature) => feature?.properties?.id === id)[0]
  );
};

/**
 * this will create a GeoJSON object with all event properties added as properties
 *
 * it will return a `FeatureCollection` a list of all events in GeoJSON format
 */
const buildGeoJson = (events: TEvent[]): IFeatureCollection => {
  const geoEvents: IFeature[] = events.map(
    ({
      id,
      venue,
      name,
      // dateStart, dateEnd, eventType, coaches
    }) => ({
      id: `event.${id}`,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [venue.lng, venue.lat],
      },
      place_name: name,
      properties: {
        id,
        // name,
        // dateStart,
        // dateEnd,
        // eventType: eventType.id,
        // coaches: coaches.map((coach: ICoach) => coach.name),
        // coachesIDs: coaches.map((coach: ICoach) => coach.navisionCustomerId),
        // venue: venue.name,
        // city: venue.city,
      },
    })
  );

  return {
    type: 'FeatureCollection',
    features: geoEvents,
  };
};

export { removeDuplicateFeatures, buildGeoJson, IFeature, IFeatureCollection };
