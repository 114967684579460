import {
  aspectRatios,
  IGraphqlM0046,
  IGraphqlM0046Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemRoundImage,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP,
  GRID_ROW_GAP_TO_ZERO,
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  MOBILE_MODULE_TEXT_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0046 extends IGraphqlM0046 {}

const PREFIX = 'M0046';

const styles: IStylesObject = {
  title1: {
    gridColumn: '1 / -1',
    textAlign: 'start',
    pt: [0, 8, 8],
    pb: [6, 6, 6],
  },
  text1: {
    gridColumn: '1 / -1',
    textAlign: 'start',
  },
  textWrapper: {
    gridColumn: ['2 / -2', '2 / span 5', '2 / span 5'],
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: defaultGridGap,
    rowGap: GRID_ROW_GAP,
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
  },
  image: {
    gridColumn: ['2 / -2', '8 / -2', '8 / -2'],
    gridRow: [3, 'auto', 'auto'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
    gridRowGap: GRID_ROW_GAP_TO_ZERO,
  },
  items: {
    gridColumn: ['1 / -1', '2 / span 5', '2 / span 5'],
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: defaultGridGap,
    pt: [0, 16, 16],
    pb: [MOBILE_MODULE_INNER_SECTION_PB, 0, 0],
    gridRow: [2, 'auto', 'auto'],
  },
  secondTextWrapper: {
    gridColumn: ['2 / -2', '8 / -2', '8 / -2'],
    pt: [0, 20, 20],
    pl: [0, 12, 12],
  },
  title2: {
    pt: [0, 14, 14],
  },
  text2: {
    pb: [MOBILE_MODULE_TEXT_PB, 0, 0],
  },
  itemImage: {
    justifySelf: 'start',
    gridRow: 1,
    borderRadius: 'full',
    overflow: 'hidden',
    width: [20, 20, 24],
    height: [20, 20, 24],
    ml: 'auto',
    mr: 'auto',
  },
  itemText: {
    gridRow: 2,
    textAlign: 'center',
    mr: 'auto',
    mb: [0, 0, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemImage: (index) => {
    let gridColumn = 1;
    if (index === 1) {
      gridColumn = 3;
    } else if (index === 2) {
      gridColumn = 5;
    }
    return {
      ...styles.itemImage,
      gridColumn,
    };
  },
  itemText: (index) => {
    let gridColumn = 1;
    if (index === 1) {
      gridColumn = 3;
    } else if (index === 2) {
      gridColumn = 5;
    }
    return {
      ...styles.itemText,
      gridColumn,
    };
  },
};

interface IPropsItem {
  item: IGraphqlM0046Item;
}
interface IPropsItems {
  items: IGraphqlM0046Item[];
}

const Item = ({ item }: IPropsItem) => (
  <BemRoundImage
    key={item._key}
    image={item.image}
    title={item.text}
    variant="column-centered"
  />
);

const ItemsOnTabletOrLarger = ({ items }: IPropsItems) => (
  <>
    {items.map((item, index) => (
      <React.Fragment key={item._key}>
        <BemCloudImage
          image={item.image}
          sx={calculatedStyles.itemImage(index)}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        />
        <BemHeading as="h5" variant="h5" sx={calculatedStyles.itemText(index)}>
          {item.text}
        </BemHeading>
      </React.Fragment>
    ))}
  </>
);

const ItemsOnMobile = ({ items }: IPropsItems) => (
  <BemTouchSlider
    key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
    items={items}
    itemRenderer={Item}
    hideShadow
    itemWidth="45%"
  />
);

const BemM0046 = ({
  title1,
  text1,
  media,
  items,
  title2,
  text2,
}: IPropsBemM0046): JSX.Element => (
  <BemModuleWrapper>
    <Grid sx={styles.textWrapper} data-testid="BemM0046-textWrapper">
      <BemHeading as="h2" variant="h2" sx={styles.title1}>
        {title1}
      </BemHeading>
      <Text as="p" sx={styles.text1} variant="bodyText">
        {text1}
      </Text>
    </Grid>
    <BemMedia
      media={media[0]}
      sx={styles.image}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
    />

    <Grid sx={styles.items}>
      {useViewportRenderer([
        <ItemsOnMobile key={`${PREFIX}_${VIEWPORTS.MOBILE}`} items={items} />,

        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          items={items}
        />,
        <ItemsOnTabletOrLarger
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          items={items}
        />,
      ])}
    </Grid>
    <Box sx={styles.secondTextWrapper}>
      <Text as="p" variant="bodyText" sx={styles.text2}>
        {text2}
      </Text>
      <BemHeading as="h2" variant="h2" sx={styles.title2}>
        {title2}
      </BemHeading>
    </Box>
  </BemModuleWrapper>
);

export { BemM0046, IPropsBemM0046 };
