import { IGraphqlM0054, THEME_NAMES } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemCloudPdf,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemThemeWrapper,
  getExternalLinkForPdf,
} from '../../components';
import { GRID_GAP_MOBILE } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_ASIDE_ACTION_AREA_PT,
  MOBILE_MEDIA_PB,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';
import { isTablet } from '../../utils/breakpointIndices';

interface IPropsBemM0054 extends IGraphqlM0054 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  item: {
    bg: 'background',
    alignItems: 'center',
    py: [4, 8, 16],
    mb: [GRID_GAP_MOBILE, 0, 0],
  },
  textWrapper: {
    gridColumn: ['2 / -2', '5 / span 7', '5 / span 4'],
  },
  title: {
    pb: [6, 8, 8],
  },
  actionArea: {
    gridColumn: ['2 / -2', '5 / -2', '10 / -1'],
    alignSelf: ['auto', 'end', 'auto'],
    pt: [MOBILE_ASIDE_ACTION_AREA_PT, 6, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  pdf: (isTabletViewport: boolean) => ({
    gridColumn: ['2 / -2', '2 / span 2', '2 / span 2'],
    gridRow: ['auto', 'span 2', 'auto'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
    h5: {
      display: isTabletViewport ? 'none' : 'block',
    },
  }),
};

const BemM0054 = ({
  title,
  items,
  theme: moduleTheme,
}: IPropsBemM0054): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemModuleWrapper>
      <BemHeading
        as="h2"
        variant="h2"
        sx={styles.heading}
        // There is always dark heading on a white background in this module.
        themeName={THEME_NAMES.DARK_TEXT}
      >
        {title}
      </BemHeading>
      <BemThemeWrapper themeName={moduleTheme || theme}>
        {items.map((item, index) => (
          <Grid
            variant="contentGrid"
            sx={styles.item}
            key={item._key}
            data-testid={item._key}
          >
            <BemCloudPdf
              pdf={item.pdf}
              hideDownloadLink
              sx={calculatedStyles.pdf(isTablet())}
              variant="book"
              additionalTrackingIdInfo={index + 1}
              data-testid={`${item._key}-pdf`}
            />
            <Box
              sx={styles.textWrapper}
              data-testid={`${item._key}-textWrapper`}
            >
              <BemHeading as="h4" variant="h4" sx={styles.title}>
                {item.title}
              </BemHeading>
              <Text as="p" sx={styles.text}>
                {item.text}
              </Text>
            </Box>
            <Box sx={styles.actionArea} data-testid={`${item._key}-actionArea`}>
              <BemLink
                to={getExternalLinkForPdf(item.pdf)}
                variant="links.buttonPrimary"
                additionalTrackingIdInfo={index + 1}
                data-testid={`${item._key}-link`}
              />
            </Box>
          </Grid>
        ))}
      </BemThemeWrapper>
    </BemModuleWrapper>
  );
};

export { BemM0054, IPropsBemM0054 };
