import { IGraphqlM0052, TGraphqlModulePosition } from '@bemer/base';
import React, { useContext } from 'react';
import { BemBreadcrumb, BemModuleWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  BREADCRUMB_PY,
  getMarginStylesForModulePosition,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { PathNamesContext } from '../../providers';

interface IPropsBemM0052 extends IGraphqlM0052 {}

const styles: IStylesObject = {
  breadcrumb: {
    gridColumn: '2 / -2',
    alignItems: 'center',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (modulePosition: TGraphqlModulePosition) => ({
    ...getMarginStylesForModulePosition(modulePosition),
    pt: BREADCRUMB_PY,
    pb: modulePosition === 'aboveHero' ? BREADCRUMB_PY : 0,
    px: [2, 3, 4],
  }),
};

const BemM0052 = ({ modulePosition }: IPropsBemM0052): JSX.Element => {
  const pathNames = useContext(PathNamesContext);

  return (
    <BemModuleWrapper sx={calculatedStyles.wrapper(modulePosition)}>
      <BemBreadcrumb
        links={pathNames}
        limitationOfPath={2}
        addPaddingTop
        sx={styles.breadcrumb}
      />
    </BemModuleWrapper>
  );
};

export { BemM0052, IPropsBemM0052 };
