import { createModel } from 'xstate/lib/model';

const mobileNavModel = createModel(
  {
    activeElement: undefined as string | undefined,
  },
  {
    events: {
      click: (value: string) => ({ value }),
    },
  }
);

const machine = mobileNavModel.createMachine(
  {
    context: mobileNavModel.initialContext,
    initial: 'closed',
    states: {
      open: {
        on: {
          click: [
            {
              target: 'closed',
              actions: ['resetActiveElement'],
              cond: 'isElementAlreadyActive',
            },
            {
              actions: 'setActiveElement',
            },
          ],
        },
      },
      closed: {
        always: {
          target: 'open',
          cond: 'hasPredefinedActiveElement',
        },
        on: {
          click: {
            target: 'open',
            actions: 'setActiveElement',
          },
        },
      },
    },
  },
  {
    actions: {
      resetActiveElement: mobileNavModel.assign({
        activeElement: undefined,
      }),
      setActiveElement: mobileNavModel.assign({
        activeElement: (_context, event) => event.value,
      }),
    },
    guards: {
      hasPredefinedActiveElement: (context, _event) =>
        Boolean(context.activeElement),
      isElementAlreadyActive: (context, event) =>
        context.activeElement === event.value,
    },
  }
);

export { machine, mobileNavModel };
