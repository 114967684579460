import { CURATOR_ENDPOINT, IGraphqlM0066 } from '@bemer/base';
import React, { useEffect } from 'react';
import { Box, Flex, Grid } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V2_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0066 extends IGraphqlM0066 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: ['1 / -1', '1 / span 8', '1 / span 8'],
    pb: TOP_HEADLINE_V2_PB,
  },
  communityLink: {
    gridColumn: ['1 / -1', 'span 4', 'span 4'],
    justifyContent: ['flex-start', 'flex-end', 'flex-end'],
    pb: [4, 4, 16],
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '1.8em',
    display: 'inline-block',
  },
  curatorWrapper: {
    gridColumn: '2 / -1',
  },
};

const BemM0066 = ({
  title,
  downloadLink,
  icon,
  containerId,
  feedId,
}: IPropsBemM0066): JSX.Element => {
  useEffect(() => {
    const injectCuratorScript = () => {
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.src = `${CURATOR_ENDPOINT}${feedId}.js`;
      document.body.appendChild(scriptElement);
    };
    injectCuratorScript();
  }, []);

  const iconBeforeLink = <BemCloudImage sx={styles.icon} image={icon} />;

  return (
    <BemModuleWrapper isFullWidth>
      <Grid variant="contentGrid">
        <BemHeading as="h2" variant="h1" sx={styles.heading}>
          {title}
        </BemHeading>
        <Flex sx={styles.communityLink}>
          <BemLink
            iconBefore={iconBeforeLink}
            to={downloadLink}
            variant="links.boldUppercase"
          />
        </Flex>
      </Grid>
      <Box sx={styles.curatorWrapper}>
        <Box id={containerId} data-testid={`${containerId}-${feedId}`} />
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0066, IPropsBemM0066 };
