import { IGraphqlM0135, IGraphqlM0135Item } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemModuleWrapper,
  BemRoundImage,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0135 extends IGraphqlM0135 {}

const PREFIX = 'M0135';

const styles: IStylesObject = {
  title: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  text: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  item: {
    gridColumn: 'span 4',
    overflow: 'hidden',
    pt: [4, 0, 0],
    pb: 3,
  },
};

interface IPropsBemM0135Item {
  item: IGraphqlM0135Item;
  avatarContentCentered?: boolean;
  index: number;
}
interface IPropsBemM0135Items {
  items: IGraphqlM0135Item[];
  avatarContentCentered?: boolean;
}

const Item = ({ item, avatarContentCentered, index }: IPropsBemM0135Item) => (
  <BemRoundImage
    key={item._key}
    title={item.title}
    text={item.text}
    image={item.image}
    sx={styles.item}
    variant={avatarContentCentered ? 'column-centered' : 'column-left-aligned'}
    additionalTrackingIdInfo={index + 1}
  />
);

const renderItem = ({
  item,
  avatarContentCentered,
  index,
}: IPropsBemM0135Item) => (
  <BemRoundImage
    key={item._key}
    title={item.title}
    text={item.text}
    image={item.image}
    sx={styles.item}
    variant={avatarContentCentered ? 'column-centered' : 'column-left-aligned'}
    additionalTrackingIdInfo={index + 1}
  />
);

const ItemsOnTabletOrLarger = ({
  items,
  avatarContentCentered,
}: IPropsBemM0135Items) => (
  <>
    {items.map((item, index) => (
      <Item
        item={item}
        key={item._key}
        avatarContentCentered={avatarContentCentered}
        index={index}
      />
    ))}
  </>
);

const BemM0135 = ({
  title,
  text,
  avatarContentCentered,
  items,
}: IPropsBemM0135): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h1" sx={styles.title}>
      {title}
    </BemHeading>
    <Text as="p" variant="caption.big" sx={styles.text}>
      {text}
    </Text>
    <Grid variant="contentGrid">
      {useViewportRenderer([
        <BemTouchSlider
          items={items}
          itemRenderer={({ item }, index) =>
            renderItem({ item, avatarContentCentered, index })
          }
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          hideShadow
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          avatarContentCentered={avatarContentCentered}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          avatarContentCentered={avatarContentCentered}
        />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0135, IPropsBemM0135 };
