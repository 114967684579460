import { aspectRatios, IGraphqlM0137, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  MOBILE_MEDIA_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0137 extends IGraphqlM0137 {}

const styles: IStylesObject = {
  image: {
    gridColumn: ['2 / -2', '2 / span 7', '2 / span 7'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  textWrapper: {
    gridColumn: ['2 / -2', '10 / -2', '10 / -2'],
    alignSelf: 'center',
  },
  title: {
    pb: ASIDE_HEADLINE_PB,
  },
};

const BemM0137 = ({
  title,
  text,
  link,
  media,
}: IPropsBemM0137): JSX.Element => (
  <BemModuleWrapper>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_4].ratio}
      sx={styles.image}
    />
    <BemThemeWrapper
      themeName={
        // TODO: refactor quick fixes for having videos, images and video sets
        media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(media[0]).themeName
          : media[0].themeName
      }
    >
      <Box sx={styles.textWrapper}>
        <BemHeading
          as="h2"
          variant="h2"
          sx={styles.title}
          themeName={
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName
          }
        >
          {title}
        </BemHeading>
        <Text as="p" variant="caption.small">
          {text}
        </Text>
        {link?.length ? (
          <BemActionArea data-testid="BemM0137Link">
            <BemLink to={link} variant="links.buttonPrimary" />
          </BemActionArea>
        ) : null}
      </Box>
    </BemThemeWrapper>
  </BemModuleWrapper>
);

export { BemM0137, IPropsBemM0137 };
