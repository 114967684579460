import { IGraphqlM0145Item } from '@bemer/base';
import { alpha } from '@theme-ui/color';
import { motion } from 'framer-motion';
import React from 'react';
import { Box } from 'theme-ui';
import { ICalculatedStylesObject } from '../../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsMarker {
  item: IGraphqlM0145Item;
  onHoverStart: () => void;
  onHoverEnd: () => void;
  onClick: () => void;
  markerRef: React.RefObject<HTMLDivElement>;
}

const MotionBox = motion(Box);

const calculatedStyles: ICalculatedStylesObject = {
  marker: (positionX: number, positionY: number) => ({
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    zIndex: 3,
    top: `${positionY}%`,
    left: `${positionX}%`,
    bg: 'gray.0',
    borderRadius: 'full',
    borderWidth: [1, 2, 2],
    borderStyle: 'solid',
    width: [4, 6, 8],
    height: [4, 6, 8],
    borderColor: alpha('gray.8', 0.25),
    cursor: 'pointer',
    boxShadow: 'smallCardShadow',
    '&:hover': {
      boxShadow: 'bigCardShadow',
    },
  }),
};

const Marker = ({
  item,
  onHoverStart,
  onHoverEnd,
  onClick,
  markerRef,
}: IPropsMarker): JSX.Element => (
  <MotionBox
    as="button"
    ref={markerRef}
    onHoverStart={onHoverStart}
    onHoverEnd={onHoverEnd}
    sx={calculatedStyles.marker(item.positionX, item.positionY)}
    onClick={onClick}
    data-testid={`${item._key}-marker`}
  />
);

export { Marker, IPropsMarker };
