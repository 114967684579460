import { IGraphqlM0087, TGraphqlRichTextBlocks } from '@bemer/base';
import { useMachine } from '@xstate/react';
import { motion } from 'framer-motion';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import { BemHeading, BemLink, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { debugXState } from '../../utils/xStateHelper';
import { m0087Machine } from './BemM0087.machine';
import { Lines } from './Lines';
import { Panel } from './Panel';
import { ProductTypes } from './ProductTypes';

interface IPropsBemM0087 extends IGraphqlM0087 {}

const MotionBox = motion(Box);

const styles: IStylesObject = {
  wrapper: {
    rowGap: [0, 0, 0],
    pt: 6,
  },
  title: {
    gridColumn: ['1 / -1', '1 / 8', '1 / 8'],
    pb: [8, 12, 24],
  },
  accordionBackground: {
    gridColumn: '1 / -1',
    gridRow: '3',
    bg: 'gray.1',
  },
  accordion: {
    gridColumn: '2 / -2',
    gridRow: '3',
  },
  additionalInfoText: {
    display: 'block',
    pb: [6, 8, 10],
    pt: [6, 8, 10],
  },
  additionalInfoHeading: {
    alignItems: 'center',
    pt: [6, 8, 10],
  },
  additionalInfoGrid: {
    mb: ['10', '16', '20'],
  },
  additionalInfoBox: {
    gridColumn: ['1 / -1', 'span 6', 'span 6'],
    pb: [4, 0, 0],

    '&:last-of-type': {
      pb: [0, 0, 0],
    },
  },
};

const ACCORDION_OPEN = 'open';
const ACCORDION_CLOSED = 'closed';
const animationVariants = {
  accordion: {
    [ACCORDION_OPEN]: {
      opacity: 1,
      height: 'auto',
      transitionEnd: {
        overflowY: 'visible',
      },

      transition: {
        delayChildren: DURATION.MEDIUM,
        animation: 'ease-in-out',
      },
    },
    [ACCORDION_CLOSED]: {
      opacity: 0,
      height: 0,
      overflowY: 'hidden',
      transition: {
        staggerChildren: DURATION.MEDIUM,
      },
    },
  },
} as const;

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0087 = ({ title, lines }: IPropsBemM0087): JSX.Element => {
  const [current, send] = useMachine(m0087Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      lines,
    },
  });

  const productTypes = current.context.lines[
    current.context.selectedLineIndex
  ].productTypes.filter((productType) => Boolean(productType.name));

  const additionalTrackingInfoLine = `line-${
    current.context.selectedLineIndex + 1
  }`;
  const additionalTrackingInfoProductType = current.context
    .selectedProductTypeIndex
    ? `-productType-${current.context.selectedProductTypeIndex + 1}`
    : '';
  const additionalTrackingInfoLineAndProductType = `${additionalTrackingInfoLine}${additionalTrackingInfoProductType}`;

  return (
    <BemModuleWrapper sx={styles.wrapper}>
      <Grid variant="contentGrid">
        <BemHeading variant="h1" as="h2" sx={styles.title}>
          {title}
        </BemHeading>
        <Lines
          items={lines}
          send={send}
          activeLine={current.context.selectedLineIndex}
        />
        <ProductTypes
          items={productTypes}
          activeIndex={current.context.selectedProductTypeIndex}
          send={send}
          activeLine={current.context.selectedLineIndex}
        />
      </Grid>

      <Box sx={styles.accordionBackground} />
      {productTypes.map((productType, productTypeIndex) => (
        <MotionBox
          key={productType._key}
          data-testid={`m0087ProductType_${productType._key}`}
          sx={styles.accordion}
          initial="closed"
          animate={
            current.context.selectedProductTypeIndex === productTypeIndex
              ? ACCORDION_OPEN
              : ACCORDION_CLOSED
          }
          variants={animationVariants.accordion}
        >
          <Panel
            onToggle={() => send({ type: 'toggleVideoPanel' })}
            items={productType.videos || []}
            title={productType.sectionVideoTitle}
            current={current}
            type="video"
            additionalTrackingIdInfo={`${additionalTrackingInfoLineAndProductType}-panel-1`}
          />
          <Panel
            onToggle={() => send({ type: 'toggleManualPanel' })}
            items={productType.pdfs || []}
            title={productType.sectionManualTitle}
            current={current}
            type="manual"
            additionalTrackingIdInfo={`${additionalTrackingInfoLineAndProductType}-panel-2`}
          />
          <Panel
            onToggle={() => send({ type: 'toggleTechnicalSpecificationPanel' })}
            items={
              (productType.technicalSpecification as unknown as TGraphqlRichTextBlocks[]) ||
              []
            }
            title={productType.sectionTechnicalSpecificationTitle}
            current={current}
            type="technicalSpecification"
            additionalTrackingIdInfo={`${additionalTrackingInfoLineAndProductType}-panel-3`}
          />

          <BemHeading variant="h4" as="h3" sx={styles.additionalInfoHeading}>
            {productType.sectionAdditionalInfoTitle}
          </BemHeading>
          <Grid variant="contentGrid" sx={styles.additionalInfoGrid}>
            {productType.additionalInfo.map((info) => (
              <Box key={info._key} sx={styles.additionalInfoBox}>
                <Text sx={styles.additionalInfoText}>{info.text}</Text>
                <BemLink
                  to={info.link}
                  data-testid={info.link[0].label}
                  variant="links.boldUppercase"
                />
              </Box>
            ))}
          </Grid>
        </MotionBox>
      ))}
    </BemModuleWrapper>
  );
};

export { BemM0087, IPropsBemM0087 };
