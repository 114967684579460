import { aspectRatios, IGraphqlM0056Item, RATIOS } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { Box, Text, ThemeUIStyleObject } from 'theme-ui';
import { BemCloudVideo, BemQuoteWrapper } from '../../components';
import { BemButton } from '../../components/Button';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { LocalisationContext } from '../../providers';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

const MotionBox = motion(Box);

const CAPTION_WRAPPER_ACTIVE = 'active';
const CAPTION_WRAPPER_INACTIVE = 'inactive';

const calculatedStyles: ICalculatedStylesObject = {
  videoWrapper: (isActiveItem) => ({
    scrollSnapAlign: 'start',
    pointerEvents: isActiveItem ? 'auto' : 'none',
  }),
};

const styles = {
  itemCaptionWrapper: {
    background: 'white',
    gridColumn: '1 / -1',
    gridRow: 1,
    p: 6,
    m: 6,
    alignSelf: 'flex-end',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    minHeight: '6.5rem',
  },
  itemVideo: {
    gridRow: 1,
    gridColumn: '1 / -1',
  },
  textWrapper: {
    pl: 6,
  },
  itemName: {
    pb: 4,
  },
  playButtonWrapper: {
    width: '100%',
  },
};

const animationVariants = {
  captionWrapper: {
    [CAPTION_WRAPPER_ACTIVE]: {
      opacity: 1,
    },
    [CAPTION_WRAPPER_INACTIVE]: {
      opacity: 0,
    },
  },
};

interface IPropsVideoCard {
  item: IGraphqlM0056Item;
  isActive: boolean;
  sx?: ThemeUIStyleObject;
  showDefaultPlaybutton?: boolean;
  index: number;
}

const VideoCard = ({
  item,
  isActive,
  sx,
  showDefaultPlaybutton,
  index,
}: IPropsVideoCard): JSX.Element => {
  const [playing, setPlaying] = useState(false);
  const { locale } = useContext(LocalisationContext);

  useEffect(() => {
    if (playing && !isActive) {
      setPlaying(false);
    }
  }, [isActive]);

  const additionalTrackingIdInfo = index + 1;

  return (
    <Box sx={{ ...calculatedStyles.videoWrapper(isActive), ...sx }}>
      {item.media[0]._type !== 'imageWithAlt' ? (
        <BemCloudVideo
          video={
            item.media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(item.media[0])
              : item.media[0]
          }
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
          sx={styles.itemVideo}
          playButtonWrapperSx={styles.playButtonWrapper}
          playing={playing}
          playButtonPosition="bottomLeft"
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onEnded={() => setPlaying(false)}
          playButton={
            showDefaultPlaybutton ? undefined : (
              <MotionBox
                sx={styles.itemCaptionWrapper}
                variants={animationVariants.captionWrapper}
                animate={
                  isActive ? CAPTION_WRAPPER_ACTIVE : CAPTION_WRAPPER_INACTIVE
                }
                onClick={() => setPlaying(!playing)}
              >
                <BemButton
                  variant="buttons.play"
                  additionalTrackingIdInfo={additionalTrackingIdInfo}
                >
                  <FaPlay />
                </BemButton>
                <Box sx={styles.textWrapper}>
                  {item.captionText ? (
                    <Text as="p" variant="bodyText">
                      <BemQuoteWrapper localeId={locale.id}>
                        {item.captionText}
                      </BemQuoteWrapper>
                    </Text>
                  ) : null}
                  {item.name ? (
                    <Text variant="h5" as="h5" sx={styles.itemName}>
                      {item.name}
                    </Text>
                  ) : null}
                  {item.jobTitle ? (
                    <Text as="p" variant="bodyText">
                      {item.jobTitle}
                    </Text>
                  ) : null}
                </Box>
              </MotionBox>
            )
          }
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />
      ) : null}
    </Box>
  );
};

export { VideoCard };
