import { IGraphqlM0056, IGraphqlM0056Item } from '@bemer/base';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { asEffect, useMachine } from '@xstate/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemPagination,
  BemQuoteWrapper,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_TEXT_PB,
  MOBILE_CARD_PADDING,
  MOBILE_MEDIA_PB,
  PAGINATION_PT,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
  VIEWPORT_INDEX,
} from '../../hooks/useViewportRenderer';
import { LocalisationContext } from '../../providers';
import { debugXState } from '../../utils/xStateHelper';
import { m0056Machine } from './BemM0056.machine';
import { ImageCard } from './ImageCard';
import { VideoCard } from './VideoCard';

interface IPropsBemM0056 extends IGraphqlM0056 {}

const PREFIX = 'M0056';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const MotionBox = motion(Box);

const DESCRIPTION_TEXT_ENTER = 'enter';
const DESCRIPTION_TEXT_CENTER = 'center';
const DESCRIPTION_TEXT_EXIT = 'exit';

const styles: IStylesObject = {
  contentGridItem: {
    gridRow: [2, 3, 3],
  },
  contentWrapper: {
    gridColumn: ['2 / -2', '1 / span 5', '1 / span 5'],
  },
  sliderWrapper: {
    gridColumn: ['2 / -2', '8 / -1', '8 / -1'],
    gridRow: '3',
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  item: {
    pb: MOBILE_CARD_PADDING,
  },
  itemMedia: {
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  itemText2: {
    color: 'textMuted',
    fontWeight: 'bold',
    pb: [4, 0, 0],
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  itemText: {
    px: [MOBILE_CARD_PADDING, 0, 0],
    pb: ASIDE_TEXT_PB,
  },
  itemBoldText: {
    fontWeight: 'bold',
    pb: 5,
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  pagination: {
    pt: PAGINATION_PT,
  },
  itemName: {
    pb: 4,
  },
  actionArea: {
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  mediaWrapper: (columns: number) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 80%)`,
    gap: defaultGridGap,
    overflow: 'hidden',
    scrollSnapType: 'x proximity',
    selfAlign: 'start',
    gridAutoFlow: 'column',
  }),
  itemHeading: (isTextBelowHeading) => ({
    pb: isTextBelowHeading ? ASIDE_HEADLINE_PB : 0,
    px: [MOBILE_CARD_PADDING, 0, 0],
  }),
  textOnMedia: (isTextBelowCaption) => ({
    px: [4, 0, 0],
    pb: isTextBelowCaption ? ASIDE_TEXT_PB : [0, 0, 0],
  }),
};

interface IPropsBemM0056Item {
  item: IGraphqlM0056Item;
}

const TextOnMedia = ({ item }: IPropsBemM0056Item) => {
  const { locale } = useContext(LocalisationContext);
  const isTextBelowCaption = !!item.text2;

  return (
    <Box sx={calculatedStyles.textOnMedia(isTextBelowCaption)}>
      {item.captionText ? (
        <BemQuoteWrapper localeId={locale.id}>
          {item.captionText}
        </BemQuoteWrapper>
      ) : null}
      {item.name ? (
        <Text variant="h4" as="h5" sx={styles.itemName}>
          {item.name}
        </Text>
      ) : null}
      {item.jobTitle ? (
        <Text as="p" variant="bodyText">
          {item.jobTitle}
        </Text>
      ) : null}
    </Box>
  );
};
interface IPropsMediaComponent {
  item: IGraphqlM0056Item;
  isActive: boolean;
  index: number;
}

const MediaComponent = ({ item, isActive, index }: IPropsMediaComponent) => {
  if (!item.media?.length) {
    return null;
  }
  return item.media[0]._type === 'imageWithAlt' ? (
    <ImageCard
      item={item}
      sx={styles.itemMedia}
      isActive={isActive}
      isMobileOrTabletView={useBreakpointIndex() < VIEWPORT_INDEX.DESKTOP}
      index={index}
    />
  ) : (
    <VideoCard
      item={item}
      isActive={isActive}
      sx={styles.itemMedia}
      showDefaultPlaybutton={useBreakpointIndex() < VIEWPORT_INDEX.DESKTOP}
      index={index}
    />
  );
};

const ItemText = ({ item }: IPropsBemM0056Item) => {
  const { locale } = useContext(LocalisationContext);

  const isViewPortSmallerDesktop =
    useBreakpointIndex() < VIEWPORT_INDEX.DESKTOP;
  const isTextBelowHeading =
    isViewPortSmallerDesktop || !!item.text || !!item.boldText || !!item.text2;
  const showTextOnMedia =
    isViewPortSmallerDesktop &&
    (!!item.captionText || !!item.name || !!item.jobTitle);

  return (
    <>
      {item.title ? (
        <BemHeading
          as="h3"
          variant={
            isTextBelowHeading
              ? 'h2WithSeparator'
              : 'h2WithSeparatorForHeadlineWithoutPaddingBottom'
          }
          sx={calculatedStyles.itemHeading(isTextBelowHeading)}
        >
          {item.title}
        </BemHeading>
      ) : null}
      {item.textInQuotes && item.text ? (
        <Text as="p" variant="h5" sx={styles.itemText}>
          <BemQuoteWrapper localeId={locale.id}>{item.text}</BemQuoteWrapper>
        </Text>
      ) : null}

      {!item.textInQuotes && item.text ? (
        <Text as="p" variant="h5" sx={styles.itemText}>
          {item.text}
        </Text>
      ) : null}

      {showTextOnMedia ? <TextOnMedia item={item} /> : null}

      {item.boldText ? (
        <Text as="p" variant="h5" sx={styles.itemBoldText}>
          {item.boldText}
        </Text>
      ) : null}
      {item.text2 ? (
        <Text as="p" variant="h5" sx={styles.itemText2}>
          {item.text2}
        </Text>
      ) : null}
      {item.link?.length ? (
        <BemActionArea sx={styles.actionArea}>
          <BemLink
            variant="links.buttonSecondary"
            to={item.link}
            data-testid={`${item._key}-onMobile-link`}
          />
        </BemActionArea>
      ) : null}
    </>
  );
};

const ItemOnMobile = ({ item }: IPropsBemM0056Item, index: number) => (
  <Box sx={styles.item} data-testid={`${item._key}-onMobile`}>
    <MediaComponent item={item} key={item._key} index={index} isActive />
    <ItemText item={item} />
  </Box>
);

interface IPropsItemsOnTabletOrLarger {
  items: IGraphqlM0056Item[];
  containerRef: React.Ref<HTMLDivElement>;
  current: any;
}

const ItemsOnTabletOrLarger = ({
  items,
  containerRef,
  current,
}: IPropsItemsOnTabletOrLarger) => (
  <Grid sx={calculatedStyles.mediaWrapper(items.length)} ref={containerRef}>
    {items.map((item, index) => (
      <MediaComponent
        item={item}
        key={item._key}
        isActive={current.context.currentActiveIndex === index}
        index={index}
      />
    ))}
  </Grid>
);

const textVariants = {
  [DESCRIPTION_TEXT_ENTER]: {
    opacity: 0,
    transition: { duration: DURATION.LONG },
  },
  [DESCRIPTION_TEXT_CENTER]: {
    opacity: 1,
    transition: { duration: DURATION.LONG },
  },
  [DESCRIPTION_TEXT_EXIT]: {
    opacity: 0,
    transition: { duration: DURATION.LONG },
  },
};

const motionBoxTextProps = {
  variants: textVariants,
  initial: DESCRIPTION_TEXT_ENTER,
  animate: DESCRIPTION_TEXT_CENTER,
  exit: DESCRIPTION_TEXT_EXIT,
};

const ItemTextOnTabletOrLarger = ({ item }: IPropsBemM0056Item) => (
  <Grid key={item._key} variant="contentGrid" sx={styles.contentGridItem}>
    <MotionBox sx={styles.contentWrapper} {...motionBoxTextProps}>
      <Box>
        <ItemText item={item} />
      </Box>
    </MotionBox>
  </Grid>
);

const BemM0056 = ({
  title,
  items,
  showPaginationText,
}: IPropsBemM0056): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [current, send] = useMachine(m0056Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      items,
    },
    actions: {
      scrollTo: asEffect((context) => {
        if (containerRef?.current?.firstElementChild) {
          const scrollPosition =
            context.currentActiveIndex *
            containerRef.current.firstElementChild.getBoundingClientRect()
              .width;

          containerRef.current.scrollTo({
            left: scrollPosition,
            behavior: 'smooth',
          });
        }
      }),
    },
  });

  const activeItem = items[current.context.currentActiveIndex];

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    send({ type: animationDirection });
  };

  const pagination = (
    <BemPagination
      onClick={scrollToItem}
      items={items}
      isCarousel
      sx={styles.pagination}
      withText={showPaginationText || false}
    />
  );

  return (
    <BemModuleWrapper>
      {title ? (
        <BemHeading
          as="h2"
          variant="h1WithSeparatorCentered"
          sx={styles.heading}
        >
          {title}
        </BemHeading>
      ) : null}

      <AnimatePresence initial={false}>
        {useViewportRenderer([
          null,
          <ItemTextOnTabletOrLarger
            item={activeItem}
            key={`${PREFIX}_itemText_${VIEWPORTS.TABLET}`}
          />,
          <ItemTextOnTabletOrLarger
            item={activeItem}
            key={`${PREFIX}_itemText_${VIEWPORTS.DESKTOP}`}
          />,
        ])}

        <Box sx={styles.sliderWrapper}>
          {useViewportRenderer([
            <BemTouchSlider
              key={`${PREFIX}_slider_${VIEWPORTS.MOBILE}`}
              items={items}
              itemRenderer={ItemOnMobile}
            />,
            <ItemsOnTabletOrLarger
              items={items}
              key={`${PREFIX}_slider_${VIEWPORTS.TABLET}`}
              containerRef={containerRef}
              current={current}
            />,
            <ItemsOnTabletOrLarger
              items={items}
              key={`${PREFIX}_slider_${VIEWPORTS.DESKTOP}`}
              containerRef={containerRef}
              current={current}
            />,
          ])}
          {items.length > 1
            ? useViewportRenderer([null, pagination, pagination])
            : null}
        </Box>
      </AnimatePresence>
    </BemModuleWrapper>
  );
};

export { BemM0056, IPropsBemM0056 };
