/** @jsx jsx */
import { GatsbyLinkProps, Link } from 'gatsby';
import { AnchorHTMLAttributes, FunctionComponent } from 'react';
import { jsx } from 'theme-ui';
import { TLinkVariant } from '../../gatsby-plugin-theme-ui/links';

interface IInternalLink
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    GatsbyLinkProps<Record<string, unknown>> {
  variant: TLinkVariant;
  sx?: any;
}

/**
 * internal link should never be used on its own, use `BemLink` instead, which
 * should decide when to use the InternalLink Component and when to use the
 * Theme-ui based Link component.
 *
 * this component exists on its own to be able to apply sx and the variant prop
 * similar existing theme-ui components, by using the JSX Pragma
 */

const InternalLink: FunctionComponent<IInternalLink> = ({
  children,
  to,
  variant,
  sx,
  ...rest
}) => (
  <Link
    to={to}
    sx={{ variant, ...sx }}
    {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
  >
    {children}
  </Link>
);
export { InternalLink as default, IInternalLink };
