import {
  defaultLocale,
  defaultLocaleIdentifier,
  IGraphqlAllRegionCountryLanguageAssignment,
  IGraphqlLocaleIdentifier,
  IGraphqlPageEdge,
  ILocale,
} from '@bemer/base';
import React from 'react';

interface ILocalisationProviderContract {
  locale: ILocale;
  localeIdentifier: IGraphqlLocaleIdentifier;
  allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[];
  pageForAllLocaleIdentifiers: IGraphqlPageEdge[];
  allRegionCountryLanguageAssignments: IGraphqlAllRegionCountryLanguageAssignment;
}

interface IPropsBemLocalisationProvider {
  children: React.ReactNode;
  contract: ILocalisationProviderContract;
}

// Initialize the context with a default value.
const defaultValue: ILocalisationProviderContract = {
  locale: defaultLocale,
  localeIdentifier: defaultLocaleIdentifier,
  allActiveLocaleIdentifiers: [],
  pageForAllLocaleIdentifiers: [],
  allRegionCountryLanguageAssignments: { edges: [] },
};
const LocalisationContext = React.createContext(defaultValue);

const BemLocalisationProvider = ({
  children,
  contract,
}: IPropsBemLocalisationProvider): JSX.Element => {
  // possible TODO: make this a constant to import?
  const allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[] = [];
  contract.allRegionCountryLanguageAssignments.edges.map((region) =>
    region.node.countries.map((country) =>
      country.localeIdentifiers.map((localeIdentifier) =>
        allActiveLocaleIdentifiers.push(localeIdentifier)
      )
    )
  );
  return (
    <LocalisationContext.Provider
      value={{
        locale: contract.locale,
        localeIdentifier: contract.localeIdentifier,
        allActiveLocaleIdentifiers,
        pageForAllLocaleIdentifiers: contract.pageForAllLocaleIdentifiers,
        allRegionCountryLanguageAssignments:
          contract.allRegionCountryLanguageAssignments,
      }}
    >
      {children}
    </LocalisationContext.Provider>
  );
};

export {
  LocalisationContext,
  BemLocalisationProvider,
  ILocalisationProviderContract,
  IPropsBemLocalisationProvider,
};
