import { IGraphqlM0106 } from '@bemer/base';
import React, { useContext } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Box } from 'theme-ui';
import { BemLink, BemModuleWrapper, BemThemeWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0106 extends IGraphqlM0106 {}

const styles: IStylesObject = {
  linkWrapper: {
    gridColumn: '2 / -2',
  },
  link: {
    py: 3,
  },
};

const BemM0106 = ({
  link,
  theme: moduleTheme,
}: IPropsBemM0106): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);

  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper data-testid="BemM0106-bemModuleWrapper">
        <Box sx={styles.linkWrapper}>
          <BemLink
            to={link}
            variant="links.nav"
            iconBefore={<FaArrowLeft />}
            sx={styles.link}
          />
        </Box>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0106, IPropsBemM0106 };
