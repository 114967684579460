import { assign, Machine, StateSchema } from 'xstate';

type TNavEvents =
  | { type: 'mouseenter'; payload: string }
  | { type: 'mouseleave' }
  | { type: 'decline' };
interface INavStates {
  states: {
    closed: StateSchema<any>;
    delay: StateSchema<any>;
    open: StateSchema<any>;
  };
}
interface INavContext {
  newElement: string;
  activeElement: string;
}

const OPEN_MOUSEENTER_DELAY = 200;

const navMachine = Machine<INavContext, INavStates, TNavEvents>(
  {
    id: 'nav',
    initial: 'closed',
    context: {
      newElement: '',
      activeElement: '',
    },
    states: {
      closed: {
        on: {
          mouseenter: {
            target: 'open',
            actions: ['setNewElement', 'activateElement'],
          },
          mouseleave: {},
        },
      },
      delay: {
        after: {
          [OPEN_MOUSEENTER_DELAY]: {
            target: 'open',
            actions: ['activateElement'],
          },
        },
        on: {
          decline: {
            target: 'open',
          },
        },
      },
      open: {
        on: {
          mouseenter: {
            target: 'delay',
            actions: ['setNewElement'],
            cond: 'isFlyoutAlreadyOpen',
          },
          mouseleave: {
            target: 'closed',
            actions: ['deactivateElement'],
          },
        },
      },
    },
  },
  {
    guards: {
      isFlyoutAlreadyOpen: (context, event) =>
        event.type === 'mouseenter' && context.activeElement !== event.payload,
    },
    actions: {
      setNewElement: assign((_context, event) => ({
        newElement: event.type === 'mouseenter' ? event.payload : '',
      })),
      deactivateElement: assign((_context) => ({
        activeElement: '',
      })),
      activateElement: assign((context) => ({
        activeElement: context.newElement,
      })),
    },
  }
);

export { navMachine };
