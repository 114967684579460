import {
  aspectRatios,
  IGraphqlM0076,
  IGraphqlM0076Item,
  RATIOS,
} from '@bemer/base';
import React, { useContext, useRef } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_FORWARD,
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemPagination,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  GRID_ROW_GAP_TO_ZERO,
  MOBILE_FORM_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';
import { LocalisationContext } from '../../providers';

interface IPropsBemM0076 extends IGraphqlM0076 {}

const PREFIX = 'M0076';

const M0076_VISIBLE_ITEMS = 2;
const SHADOW_PADDING_BOTTOM = 6;

const styles: IStylesObject = {
  leftColWrapper: {
    gridColumn: ['2 / -2', '2 / span 6', '2 / span 4'],
    gridTemplateRows: 'min-content min-content auto',
    rowGap: GRID_ROW_GAP_TO_ZERO,
  },
  pagination: {
    alignSelf: 'end',
    pb: SHADOW_PADDING_BOTTOM,
  },
  actionArea: {
    mb: [MOBILE_FORM_PB, 0, 0],
  },
  sliderWrapper: {
    gridColumn: ['2 / -2', '8 / -2', '6 / -2'],
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
    gridRow: ['auto', 1, 1],
    selfAlign: 'start',
    ml: [-4, 0, 0],
  },
  itemWrapper: {
    scrollSnapAlign: 'start',
    gridRow: ['auto', 1, 1],
    px: [0, 0, 4],
    pb: SHADOW_PADDING_BOTTOM,
    height: '100%',
  },
  item: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'min-content 1fr min-content',
    boxShadow: 'smallCardShadow',
  },
  itemImage: {
    gridColumn: '1 / -1',
    pb: 4,
  },
  itemText: {
    py: 2,
    px: 5,
  },
  date: {
    alignSelf: 'end',
    px: 5,
    pt: 5,
    pb: 8,
    color: 'textMuted',
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  itemsWrapper: (columns: boolean) => ({
    overflow: 'hidden',
    gridColumn: '1 / -1',
    gridAutoFlow: 'column',
    gridTemplateColumns: `repeat(${columns}, 45%)`,
    gap: 0,
    scrollSnapType: 'x proximity',
  }),
};

const getLocalDate = (isoDate: string) => {
  const { locale } = useContext(LocalisationContext);
  const date = new Date(isoDate);
  return date.toLocaleDateString(locale.bcp47Id, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
interface IPropsBemM0076Item {
  item: IGraphqlM0076Item;
  index: number;
}
interface IPropsBemM0076Items {
  items: IGraphqlM0076Item[];
  itemContainer: React.Ref<HTMLDivElement>;
}

const Item = ({ item, index }: IPropsBemM0076Item) => (
  <Box sx={styles.itemWrapper} key={item._key} data-testid={item._key}>
    <BemLink
      to={item.link}
      sx={styles.item}
      additionalTrackingIdInfo={index + 1}
      data-testid={`${item._key}-link`}
    >
      <BemMedia
        media={item.media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_3].ratio}
        sx={styles.itemImage}
        additionalTrackingIdInfo={index + 1}
      />
      <Text variant="caption.small" sx={styles.itemText}>
        {item.title}
      </Text>
      <Text variant="small" sx={styles.date} data-testid={`date${item.date}`}>
        {getLocalDate(item.date)}
      </Text>
    </BemLink>
  </Box>
);

const ItemsOnDesktop = ({ items, itemContainer }: IPropsBemM0076Items) => (
  <Grid sx={calculatedStyles.itemsWrapper(items.length)} ref={itemContainer}>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </Grid>
);

const BemM0076 = ({ title, link, items }: IPropsBemM0076): JSX.Element => {
  const itemContainer = useRef<HTMLDivElement>(null);

  const scrollToItem = (animationDirection: TAnimationDirection) => {
    if (!itemContainer?.current?.firstElementChild) {
      return;
    }

    const itemWidth =
      itemContainer.current.firstElementChild.getBoundingClientRect().width;
    const containerOffsetLeft = itemContainer.current.scrollLeft;

    const containerScrollTo =
      animationDirection === ANIMATION_DIRECTION_FORWARD
        ? containerOffsetLeft + itemWidth
        : containerOffsetLeft - itemWidth;

    itemContainer.current.scrollTo({
      left: containerScrollTo,
      behavior: 'smooth',
    });
  };

  const getPagination = (visibleItems: number) => (
    <BemPagination
      items={items}
      visibleItems={visibleItems}
      sx={styles.pagination}
      onClick={scrollToItem}
      isCarousel={false}
      withText={false}
    />
  );

  return (
    <BemModuleWrapper>
      <Grid sx={styles.leftColWrapper} data-testid="BemM0076-leftColWrapper">
        <BemHeading as="h2" variant="h1">
          {title}
        </BemHeading>
        <BemActionArea sx={styles.actionArea}>
          {link?.length ? (
            <BemLink
              to={link}
              variant="links.buttonSecondary"
              data-testid="BemM0076-link"
            />
          ) : null}
        </BemActionArea>

        {useViewportRenderer([null, null, getPagination(M0076_VISIBLE_ITEMS)])}
      </Grid>
      <Grid sx={styles.sliderWrapper} data-testid="BemM0076-sliderWrapper">
        {useViewportRenderer([
          <BemTouchSlider
            items={items}
            itemRenderer={({ item }, index) => (
              <Item item={item} index={index} />
            )}
            key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
            hideShadow
          />,
          <BemTouchSlider
            items={items}
            itemRenderer={({ item }, index) => (
              <Item item={item} index={index} />
            )}
            key={`${PREFIX}_${VIEWPORTS.TABLET}`}
            hideShadow
            itemWidth="90%"
          />,
          <ItemsOnDesktop
            items={items}
            itemContainer={itemContainer}
            key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          />,
        ])}
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0076, IPropsBemM0076 };
