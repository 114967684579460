import { aspectRatios, IGraphqlM0143, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { INNER_HEADLINE_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0143 extends IGraphqlM0143 {}

const styles: IStylesObject = {
  image: {
    gridColumn: '1 / -1',
  },
  contentGrid: {
    gridColumn: '2 / -2',
    gridTemplateColumns: ['1fr', 'repeat(3, 1fr)', 'repeat(3, 1fr)'],
    pt: [6, 8, 10],
    pb: [12, 20, 24],
  },
  item: {
    pb: [6, 0, 0],
    '&:last-of-type': {
      pb: 0,
    },
  },
  itemHeading: {
    pb: INNER_HEADLINE_PB,
  },
};

const BemM0143 = ({
  media,
  items,
  theme: moduleTheme,
}: IPropsBemM0143): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper data-testid="BemM0143ModuleWrapper">
        <BemMedia
          media={media[0]}
          sx={styles.image}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_2].ratio}
          autoplayPreview
        />
        <Grid sx={styles.contentGrid} variant="contentGrid">
          {items.map(({ title, text, _key }) => (
            <Box key={_key} sx={styles.item}>
              <BemHeading as="h3" variant="h3" sx={styles.itemHeading}>
                {title}
              </BemHeading>
              <Text as="p">{text}</Text>
            </Box>
          ))}
        </Grid>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0143, IPropsBemM0143 };
