import { IGraphqlM0107 } from '@bemer/base';
import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { BemLink, BemLogo, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0107 extends IGraphqlM0107 {}

const styles: IStylesObject = {
  wrapper: {
    py: [0, 2, 4],
    boxShadow: 'smallCardShadow',
  },
  logoWrapper: {
    gridColumn: '2 / span 2',
  },
  logo: {
    color: 'black',
  },
  linksWrapper: {
    gridColumn: ['2 / -2', '5 / -2', '4 / -2'],
    justifyContent: ['flex-start', 'flex-end', 'flex-end'],
  },
  contactWrapper: {
    display: 'flex',
    alignItems: 'center',
    mr: [0, 4, 16],
  },
  labelContact: {
    pr: 2,
    display: ['none', 'inline', 'inline'],
  },
  number: {
    color: 'text',
  },
  support: {
    alignSelf: 'center',
    border: 'none',
    mr: 0,
  },
};

const BemM0107 = ({
  label,
  number,
  supportLink,
  logoLink,
}: IPropsBemM0107): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper}>
    <BemLink
      to={logoLink}
      data-testid="m0107-logoLink"
      sx={styles.logoWrapper}
      additionalTrackingIdInfo="Logo"
    >
      <BemLogo sx={styles.logo} />
    </BemLink>

    <Flex sx={styles.linksWrapper} data-testid="m0107-linksWrapper">
      <Box sx={styles.contactWrapper}>
        <Text variant="navWithoutHover" sx={styles.labelContact}>
          {label}
        </Text>
        <BemLink
          to={number}
          sx={styles.number}
          variant="links.nav"
          additionalTrackingIdInfo={1}
        />
      </Box>
      <BemLink
        to={supportLink}
        data-testid="m0107-supportLink"
        sx={styles.support}
        variant="links.nav"
        additionalTrackingIdInfo={2}
      >
        <Text variant="navWithoutHover">{supportLink[0].label}</Text>
      </BemLink>
    </Flex>
  </BemModuleWrapper>
);

export { BemM0107, IPropsBemM0107 };
