import { aspectRatios, IGraphqlM0063, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Flex, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemRoundImage,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  ON_MEDIA_HEADLINE_V2_PB,
  ON_MEDIA_TEXT_V2_PB,
  TABLET_MEDIA_PB,
  TABLET_MODULE_INNER_SECTION_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0063 extends IGraphqlM0063 {}

const styles: IStylesObject = {
  image: {
    gridRow: 1,
    gridColumn: '1 / -1',
    height: '100%',
    gridTemplateColumns: '1fr',
    pb: [MOBILE_MEDIA_PB, TABLET_MEDIA_PB, 0],
  },
  contentWrapper: {
    gridRow: ['auto', 'auto', 1],
    zIndex: 1,
    gridColumn: ['2 / -2', '2 / -2', '9 / -2'],
    pt: [0, 0, 20],
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    pb: ON_MEDIA_HEADLINE_V2_PB,
  },
  text: {
    pb: ON_MEDIA_TEXT_V2_PB,
  },
  button: {
    width: ['100%', 'auto', 'auto'],
    mb: [MOBILE_MODULE_INNER_SECTION_PB, TABLET_MODULE_INNER_SECTION_PB, 0],
  },
  roundImage: {
    mt: 'auto',
    pb: 16,
  },
};

const BemM0063 = ({
  media,
  title,
  text,
  link,
  items,
}: IPropsBemM0063): JSX.Element => (
  <BemModuleWrapper>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_2_1].ratio}
      sx={styles.image}
    />
    <BemThemeWrapper
      themeName={[
        THEME_NAMES.DARK_TEXT,
        THEME_NAMES.DARK_TEXT,
        // TODO: refactor quick fixes for having videos, images and video sets
        media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(media[0]).themeName
          : media[0].themeName,
      ]}
    >
      <Flex sx={styles.contentWrapper}>
        <BemHeading
          as="h2"
          variant="h2"
          sx={styles.title}
          themeName={[
            THEME_NAMES.DARK_TEXT,
            THEME_NAMES.DARK_TEXT,
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName,
          ]}
        >
          {title}
        </BemHeading>
        {text ? (
          <Text as="p" variant="bodyText" sx={styles.text}>
            {text}
          </Text>
        ) : null}
        {link?.length ? (
          <BemLink
            to={link}
            variant="links.buttonPrimary"
            sx={styles.button}
            data-testid="BemM0063-link"
          />
        ) : null}
        {items?.[0] ? (
          <BemRoundImage
            title={items[0].title}
            text={items[0].text}
            image={items[0].image}
            sx={styles.roundImage}
            themeName={[
              THEME_NAMES.DARK_TEXT,
              THEME_NAMES.DARK_TEXT,
              // TODO: refactor quick fixes for having videos, images and video sets
              media[0]._type === 'videoSet'
                ? getLanguageSpecificVideo(media[0]).themeName
                : media[0].themeName,
            ]}
          />
        ) : null}
      </Flex>
    </BemThemeWrapper>
  </BemModuleWrapper>
);

export { BemM0063, IPropsBemM0063 };
