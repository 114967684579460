import {
  aspectRatios,
  getPdfUrls,
  IGraphqlExternalLink,
  IGraphqlPdf,
  RATIOS,
  TAdditionalTrackingIdInfo,
  THEME_NAMES,
} from '@bemer/base';
import React, { ForwardedRef, useContext } from 'react';
import { AspectRatio, Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ModuleContext } from '../../providers/ModuleProvider/BemModuleProvider';
import { isValidSanityAsset } from '../../utils/sanityAssets';
import { BemHeading } from '../Heading';
import { BemLink } from '../Link';
import { BemLogo } from '../Logo';
import { BemThemeWrapper } from '../ThemeWrapper';

const VARIANT_CARD = 'card';
const VARIANT_BOOK = 'book';
const CARD_ASPECT_RATIO = aspectRatios[RATIOS.RATIO_3_2].ratio;
const BOOK_ASPECT_RATIO = aspectRatios[RATIOS.RATIO_5_7].ratio;

type TBemBemCloudPdfVariants = typeof VARIANT_CARD | typeof VARIANT_BOOK;

interface IPropsBemCloudPdf {
  /**
   * The graphql pdf object.
   */
  pdf: IGraphqlPdf;

  /**
   * Option to hide the link to download the pdf.
   */
  hideDownloadLink?: boolean;

  /**
   * Overwrite the aspect ratio of a pdf.
   * The default ratio will be 3 / 2.
   */
  forcedAspectRatio?: number;

  /**
   * Changes the style:
   * - card: a light card style
   * - book: a dark book style
   */
  variant?: TBemBemCloudPdfVariants;

  /**
   * Additional style object.
   */
  sx?: ThemeUIStyleObject;

  /**
   * A optional test id.
   * Default is "BemCloudPdf"
   */
  testId?: string;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const styles: IStylesObject = {
  innerWrapper: {
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    borderColor: 'cloudPdfBorderLeft',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    pt: 8,
    pb: 6,
    px: 6,
    hyphens: 'auto',
  },
  logo: {
    '&[data-version="tiny"], &[data-version="tiny"]>svg': {
      width: 7,
      height: 7,
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (variant: TBemBemCloudPdfVariants) => ({
    bg:
      variant === VARIANT_CARD
        ? 'cloudPdfBackground'
        : 'cloudPdfBackgroundVariantBook',
    boxShadow: variant === VARIANT_CARD ? 'smallCardShadow' : 'inherit',
  }),

  title: (variant: TBemBemCloudPdfVariants) => ({
    fontWeight: 'normal',
    color: variant === VARIANT_CARD ? 'inherit' : 'gray.2',
    fontSize: variant === VARIANT_CARD ? 'inherit' : '12px',
    lineHeight: variant === VARIANT_CARD ? 'inherit' : '1.3',
    textTransform: variant === VARIANT_CARD ? 'inherit' : 'uppercase',
  }),

  footer: (hideDownloadLink: boolean) => ({
    justifyContent: hideDownloadLink ? 'flex-end' : 'space-between',
    alignItems: 'center',
  }),
};

/**
 * Helper to get a ExternalLink object (IGraphqlExternalLink) for a Pdf object (IGraphqlPdf).
 *
 * This helper can be used in other components to generate a link to the pdf.
 *
 * @param pdf
 * @param aspectRatio optional
 */
const getExternalLinkForPdf = (
  pdf: IGraphqlPdf,
  aspectRatio = CARD_ASPECT_RATIO
): IGraphqlExternalLink => {
  const { url, originalFilename } = pdf.asset;
  const { pdfUrl } = getPdfUrls(url, originalFilename, aspectRatio);

  return {
    _key: pdf._key,
    _type: 'externalLink',
    externalLink: pdfUrl,
    label: pdf.label,
    target: '_blank',
  };
};

/**
 * this component will load assets hosted on sanity from cloudinary
 *
 * this requires some settings in cloudinary to work, go to settings > upload and configure **Auto upload mapping:**
 * the following settings need to be applied
 *
 * Folder: `marketing_site_remote_files`
 * URL Prefix: `https://cdn.sanity.io/files/milq733q/playground/`
 *
 * this will upload all assets from sanity to cloudinary by mapping those urls, eg.
 * https://res.cloudinary.com/bemergroup/image/upload/marketing_site_remote_files_playground/sample.pdf
 * https://cdn.sanity.io/files/milq733q/playground/sample.pdf
 *
 * ## requirements
 *
 * the component will check of url for some requirements:
 *
 * 1. does the url contain `cdn.sanity.io`
 * 2. does the url contain the configured `SANITY_STUDIO_API_PROJECT_ID` which should be provided using env vars.
 * 3. does the url contain the configured `SANITY_STUDIO_API_DATASET` which should be provided using env vars.
 *
 * if any of those requirements returns false, a warning is displayed in the console.
 *
 * if the provided asset cannot be uploaded, a 404 will be thrown when requesting the asset.
 *
 */
// eslint-disable-next-line react/display-name
const BemCloudPdf = React.forwardRef(
  (
    {
      pdf,
      hideDownloadLink,
      forcedAspectRatio,
      variant = VARIANT_CARD,
      sx,
      testId = 'BemCloudPdf',
      additionalTrackingIdInfo = '',
      ...props
    }: IPropsBemCloudPdf,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element | null => {
    if (!isValidSanityAsset(pdf.asset)) {
      return null;
    }

    const { moduleName } = useContext(ModuleContext);
    const componentName = 'BemCloudPdf';
    const trackingId = `${moduleName}-${componentName}${
      additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
    }`;

    const isCardVariant = variant === VARIANT_CARD;

    let aspectRatio = isCardVariant ? CARD_ASPECT_RATIO : BOOK_ASPECT_RATIO;
    if (forcedAspectRatio) {
      aspectRatio = forcedAspectRatio;
    }

    return (
      <Box
        ref={ref}
        sx={sx}
        data-testid={testId}
        {...props}
        data-trackingid={trackingId}
      >
        <Box sx={calculatedStyles.wrapper(variant)}>
          <AspectRatio ratio={aspectRatio} sx={styles.innerWrapper}>
            <BemHeading
              as="h5"
              variant="h5"
              sx={calculatedStyles.title(variant)}
            >
              {pdf.title}
            </BemHeading>

            <Flex sx={calculatedStyles.footer(hideDownloadLink)}>
              {!hideDownloadLink ? (
                <BemLink
                  sx={styles.button}
                  variant={
                    isCardVariant ? 'links.cloudPdf' : 'links.cloudPdfBook'
                  }
                  to={getExternalLinkForPdf(pdf, aspectRatio)}
                  additionalTrackingIdInfo={additionalTrackingIdInfo}
                />
              ) : null}
              <BemThemeWrapper themeName={THEME_NAMES.DEFAULT}>
                <BemLogo sx={styles.logo} version="tiny" />
              </BemThemeWrapper>
            </Flex>
          </AspectRatio>
        </Box>
      </Box>
    );
  }
);

export {
  BemCloudPdf,
  IPropsBemCloudPdf,
  getExternalLinkForPdf,
  TBemBemCloudPdfVariants,
};
