import {
  IAddressSuggestionData,
  IEventTypeResponse,
  ILocale,
  IPartnerFinderData,
  IProductResponseData,
  IVisitorSendContactRequestPayload,
  TEvent,
  TVisitorSendContactRequestResponseData,
  TVisitorSettingsResponseData,
  TVisitorSettingsUpdateData,
} from '@bemer/base';
import React from 'react';

type TMutateFunction<TPayload, TResponseData, TError = unknown> = (
  payload: TPayload,
  options?: {
    onSuccess?: (
      data: TResponseData,
      variables: TPayload
    ) => Promise<unknown> | void;
    onError?: (error: TError, variables: TPayload) => Promise<unknown> | void;
  }
) => void;

interface IUseQueryOptions {
  enabled: boolean;
}
interface IApiProviderContract {
  address: {
    getAddressSuggestions: (
      searchString?: string,
      options?: IUseQueryOptions
    ) => {
      isFetching: boolean;
      data?: IAddressSuggestionData[];
    };
  };
  partner: {
    getPartnerByHandle: (
      handle?: string,
      options?: IUseQueryOptions
    ) => {
      isFetching: boolean;
      data?: IPartnerFinderData;
    };
    getPartnersByLocation: (
      latitude?: number,
      longitude?: number,
      partnerLevel?: number,
      options?: IUseQueryOptions
    ) => {
      isFetching: boolean;
      data?: IPartnerFinderData[];
    };
    getPartnerSuggestions: (
      searchString?: string,
      partnerLevel?: number,
      options?: IUseQueryOptions
    ) => {
      isFetching: boolean;
      data?: IPartnerFinderData[];
    };
    // old getPartnerByHandle function is treated like a hook but doesn't have correct "use" notation
    // we should steadly look into transitioning existing hook functions to be properly treated like hooks
    useGetPartnerByHandle: (
      handle?: string,
      options?: IUseQueryOptions
    ) => {
      isFetching: boolean;
      data?: IPartnerFinderData;
    };
  };
  products: {
    getProducts: (locale: ILocale) => {
      isFetching: boolean;
      data?: IProductResponseData[];
    };
    getProduct: (
      locale: ILocale,
      productId: string
    ) => {
      isFetching: boolean;
      data?: IProductResponseData;
    };
  };
  visitor: {
    getVisitorSettings: (options?: IUseQueryOptions) => {
      isFetching: boolean;
      data?: TVisitorSettingsResponseData;
    };
    mutationVisitorSettings: () => {
      mutate: TMutateFunction<
        TVisitorSettingsUpdateData,
        TVisitorSettingsResponseData
      >;
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
      data?: TVisitorSettingsResponseData;
    };
    mutationSendContactRequest: () => {
      mutate: TMutateFunction<
        IVisitorSendContactRequestPayload,
        TVisitorSendContactRequestResponseData
      >;
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
      data?: TVisitorSendContactRequestResponseData;
    };
  };
  events: {
    getEvents: (onlineOnly: boolean) => {
      isFetching: boolean;
      data?: { events: TEvent[] };
    };
    getEventTypes: () => {
      isFetching: boolean;
      data?: IEventTypeResponse[];
    };
  };
}

interface IPropsBemApiProvider {
  children: React.ReactNode;
  contract: IApiProviderContract;
}

// Initialize the context with a default value.
const defaultValue: IApiProviderContract = {
  address: {
    getAddressSuggestions: () => ({ isFetching: false, data: [] }),
  },
  partner: {
    getPartnerByHandle: () => ({ isFetching: false, data: undefined }),
    getPartnersByLocation: () => ({ isFetching: false, data: [] }),
    getPartnerSuggestions: () => ({ isFetching: false, data: [] }),
    useGetPartnerByHandle: () => ({ isFetching: false, data: undefined }),
  },
  products: {
    getProducts: () => ({ isFetching: false, data: [] }),
    getProduct: () => ({ isFetching: false, data: undefined }),
  },
  events: {
    getEvents: () => ({ isFetching: false, data: { events: [] } }),
    getEventTypes: () => ({ isFetching: false, data: [] }),
  },
  visitor: {
    getVisitorSettings: () => ({ isFetching: false, data: undefined }),
    mutationVisitorSettings: () => ({
      mutate: () => {},
      isLoading: false,
      isError: false,
      isSuccess: false,
      data: undefined,
    }),
    mutationSendContactRequest: () => ({
      mutate: () => {},
      isLoading: false,
      isError: false,
      isSuccess: false,
      data: false,
    }),
  },
};
const ApiContext = React.createContext(defaultValue);

const BemApiProvider = ({
  children,
  contract,
}: IPropsBemApiProvider): JSX.Element => (
  <ApiContext.Provider value={contract}>{children}</ApiContext.Provider>
);

export {
  ApiContext,
  BemApiProvider,
  IUseQueryOptions,
  IApiProviderContract,
  IPropsBemApiProvider,
};
