import { aspectRatios, IGraphqlM0002C, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Card, Grid, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0002C extends IGraphqlM0002C {}

const styles: IStylesObject = {
  wrapper: {
    py: 8,
    background: 'white',
    mb: 0,
    boxShadow: 'smallCardShadow',
  },
  linkWrapper: {
    gridColumn: '3 / span 2',
  },
  textWrapper: {
    gridColumn: '3 / span 10',
  },
  linkList: {
    listStyle: 'none',
    marginLeft: 0,
    paddingLeft: 0,
  },
  cardGrid: {
    gridColumn: '6/ -2',
    gridTemplateColumns: '1fr 1fr',
    gap: defaultGridGap,
  },
  link: {
    variant: 'text.flyoutText',
    display: 'inline-block',
  },
};

const BemM0002C = ({ title, links, teasers }: IPropsBemM0002C): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper} data-testid="BemM0002C">
    <Box sx={styles.linkWrapper} data-testid="BemM0002C-linkList">
      <BemHeading as="h4" variant="h5" sx={{ pb: 7 }}>
        {title}
      </BemHeading>
      <Box as="ul" sx={styles.linkList}>
        {links.map((link, index) => (
          <Box as="li" key={link._key} sx={{ pb: 5 }}>
            <BemLink
              to={link}
              sx={styles.link}
              additionalTrackingIdInfo={index + 1}
            >
              {link.label}
            </BemLink>
          </Box>
        ))}
      </Box>
    </Box>
    <Grid sx={styles.cardGrid} data-testid="BemM0002C-teasers">
      {teasers.map((teaser, index) => {
        const link = {
          ...teaser,
          label: '',
        };
        return (
          <BemLink
            to={link}
            key={teaser._key}
            additionalTrackingIdInfo={index + 1}
          >
            <Card>
              <BemCloudImage
                image={teaser.image}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
                sx={{ pb: 6 }}
              />
              <Text variant="topline" as="p" sx={{ pb: 4 }}>
                {teaser.topline}
              </Text>
              <Text variant="flyoutText" as="p">
                {teaser.title}
              </Text>
            </Card>
          </BemLink>
        );
      })}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0002C, IPropsBemM0002C };
