import {
  buildInternalRoute,
  IGraphqlExternalLink,
  IGraphqlInternalLink,
  TThemeName,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { Box, Divider, Flex, Text } from 'theme-ui';
import {
  BemBackdrop,
  BemLink,
  BemThemeWrapper,
  TBackdropState,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import {
  LocalisationContext,
  NavigationContext,
  ThemeHierarchyContext,
} from '../../providers';
import { ModalContext } from '../../providers/ModalProvider';
import { isPartialMatch } from '../../utils/isPartialMatch';
import { debugXState } from '../../utils/xStateHelper';
import { ExpandableMenu } from './BemM0002-expandableMenu';
import { machine } from './BemM0002-mobile.machine';

const AnimatedBox = motion(Box);

interface IPropsBemM0002Mobile {
  theme?: TThemeName;
}

const styles: IStylesObject = {
  accountLinks: {
    color: 'textMuted',
    border: 'none',
    borderLeft: '1px solid',
    borderLeftColor: 'transparent',
    pl: 0,
    py: 2,
    display: 'block',
    fontWeight: 'bold',
    '&.active': {
      color: 'text',
      borderLeftColor: 'currentColor',
      bg: 'background.6',
    },
    '&:hover': {
      color: 'text',
    },
  },
  mobileNav: {
    height: '100vh',
    width: ['100vw', '50vw', 'auto'],
    position: 'fixed',
    boxShadow: 'cardShadow',
    backgroundColor: 'background',
    backgroundImage: (theme) =>
      theme.colors?.backgroundGradientStart &&
      theme.colors?.backgroundGradientEnd
        ? `linear-gradient(90deg, ${theme.colors.backgroundGradientStart}, ${theme.colors.backgroundGradientEnd})`
        : 'none',
    overflow: 'auto',
    zIndex: 2000,
    top: 0,
    px: 4,
    pb: 4,
    '& + div': {
      "[class*='heroStartsWithText']": {
        pt: [6, 12, 24],
      },
    },
  },
  noList: {
    m: 0,
    p: 0,
    listStyle: 'none',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 16,
    alignItems: 'center',
    mx: -2,
    color: 'text',
    cursor: 'pointer',
  },
  backdrop: {
    position: 'sticky',
  },
  iconWrapper: {
    height: 16,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lineLink: {
    width: '100%',
    color: 'text',
    px: 4,
    py: 2,
    bg: 'background.7',
    borderRightWidth: 'px',
    borderRightStyle: 'solid',
    borderRightColor: 'gray.2',
    '&.active': {
      bg: 'transparent',
      color: 'text',
      display: 'block',
    },
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  lineWrapper: {
    mb: 4,
    mx: -4,
    textAlign: 'center',
    borderBottomWidth: 'px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.2',
  },
  accountLink: {
    width: '100%',
    py: 2,
  },
  accountLinkListItem: {
    py: 4,
  },
  divider: {
    mb: 4,
    color: 'text',
  },
};

const transitions = {
  mobileNav: {
    open: {
      duration: DURATION.MEDIUM,
      ease: 'easeOut',
    },
    closed: {
      duration: DURATION.SHORT,
      ease: 'easeOut',
    },
  },
};

const variants = {
  mobileNav: {
    open: {
      right: 0,
    },
    closed: {
      right: '-100%',
    },
  },

  closeIcon: {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  lineName: (isActive: boolean) => ({
    display: 'block',
    py: 2,
    color: 'text',
    fontWeight: isActive ? 700 : 400,
    '.active &': {
      color: 'text',
      fontWeight: 700,
    },
  }),
};

const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0002Mobile = ({
  theme: moduleTheme,
}: IPropsBemM0002Mobile): JSX.Element => {
  const { current, send } = useContext(NavigationContext);
  const [theme] = useContext(ThemeHierarchyContext);
  const { locale, localeIdentifier } = useContext(LocalisationContext);
  const { send: modalProviderSend } = useContext(ModalContext);

  const openModalM0134 = () => {
    modalProviderSend({ type: 'openLanguageSelectionModal' });
  };

  const windowPathName =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const activeElement = current.context?.navigationLinks.filter(
    (link: IGraphqlInternalLink) =>
      isPartialMatch(
        buildInternalRoute(link.to, localeIdentifier),
        windowPathName
      )
  )?.[0]?._key;

  const [currentMobileNav, sendMobileNav] = useMachine(machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      activeElement,
    },
  });

  useEffect(() => {
    sendMobileNav({ type: 'click', value: activeElement });
  }, [activeElement]);

  const activeLineLink = current.context?.lineLinks.filter(
    (link: IGraphqlInternalLink) =>
      isPartialMatch(
        buildInternalRoute(link.to, localeIdentifier),
        windowPathName
      )
  )?.[0]?._key;
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemBackdrop
        state={current?.value as TBackdropState}
        sx={styles.backdrop}
        handleClose={() => {
          send({ type: 'closeMobileNav' });
        }}
      />

      <AnimatedBox
        as="div"
        sx={styles.mobileNav}
        initial="closed"
        animate={current?.value}
        transition={transitions.mobileNav}
        variants={variants.mobileNav}
        data-testid="BemM0002-mobile"
      >
        <Flex sx={styles.iconWrapper}>
          <AnimatedBox
            as="div"
            variants={variants.closeIcon}
            sx={styles.closeButton}
            onClick={() =>
              send({
                type: 'toggleMobileNav',
              })
            }
          >
            <Box style={{ height: '1.8rem', width: '1.8rem' }}>
              <IoCloseOutline size="100%" color="currentColor" />
            </Box>
          </AnimatedBox>
        </Flex>

        <Flex sx={styles.lineWrapper}>
          {current.context?.lineLinks?.map((item, index) => {
            const isActive = item._key === activeLineLink;
            return (
              <BemLink
                key={item._key}
                to={item}
                partiallyActive
                sx={styles.lineLink}
                additionalTrackingIdInfo={index + 1}
              >
                <Text as="span" sx={calculatedStyles.lineName(isActive)}>
                  {item.label}
                </Text>
              </BemLink>
            );
          })}
        </Flex>

        <Box as="ul" sx={styles.noList}>
          {current.context?.navigationLinks?.map((item, index) => (
            <ExpandableMenu
              key={item._key}
              item={item}
              isOpen={item._key === currentMobileNav.context.activeElement}
              // @ts-ignore
              send={sendMobileNav}
              index={index}
            />
          ))}
        </Box>
        {current.context?.accountLinks.length ? (
          <>
            <Divider sx={styles.divider} />
            <Box as="ul" sx={styles.noList}>
              <Box as="li" sx={styles.accountLinkListItem}>
                {current.context?.accountLinks?.map(
                  (item: IGraphqlInternalLink | IGraphqlExternalLink) => (
                    <BemLink
                      sx={styles.accountLinks}
                      partiallyActive
                      to={item}
                      key={item._key}
                    >
                      <Text as="div">{item.label}</Text>
                    </BemLink>
                  )
                )}
              </Box>
            </Box>
            <Text
              variant="links.nav"
              sx={styles.accountLink}
              onClick={() => {
                openModalM0134();
                send({ type: 'closeMobileNav' });
              }}
            >
              {locale.languageCode.toUpperCase()} | {locale.countryCode}
            </Text>
          </>
        ) : null}
      </AnimatedBox>
    </BemThemeWrapper>
  );
};

export { BemM0002Mobile, IPropsBemM0002Mobile };
