import { ThemeUIContextValue, useThemeUI } from 'theme-ui';
import { TTheme } from '../index';

interface IUseThemeHook extends Omit<ThemeUIContextValue, 'theme'> {
  theme: TTheme;
}

const useTheme = useThemeUI as unknown as () => IUseThemeHook;

export { useTheme };
