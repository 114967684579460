import { aspectRatios, IGraphqlM0043, RATIOS } from '@bemer/base';
import React from 'react';
import { BemGallery, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0043 extends IGraphqlM0043 {}

const styles: IStylesObject = {
  gallery: {
    gridColumn: '2 / -2',
  },
};

const BemM0043 = ({ items, showThumbnails }: IPropsBemM0043): JSX.Element => (
  <BemModuleWrapper>
    <BemGallery
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_15_8].ratio}
      items={items}
      navigationButtonsStyle="inside"
      showCarousel={showThumbnails && !isMobile()}
      sx={styles.gallery}
    />
  </BemModuleWrapper>
);

export { BemM0043, IPropsBemM0043 };
