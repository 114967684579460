import { IGraphqlM0004 } from '@bemer/base';
import React from 'react';
import { Text } from 'theme-ui';
import { BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0004 extends IGraphqlM0004 {}

const styles: IStylesObject = {
  text: {
    textAlign: 'center',
    gridColumn: ['3 / -3', '4 / span 8', '4 / span 8'],
  },
};

const BemM0004 = ({ text }: IPropsBemM0004): JSX.Element => (
  <BemModuleWrapper>
    <Text as="p" variant="caption.big" sx={styles.text}>
      {text}
    </Text>
  </BemModuleWrapper>
);

export { BemM0004, IPropsBemM0004 };
