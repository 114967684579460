import { IGraphqlM0035, IGraphqlM0035Item } from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useEffect } from 'react';
import { Box, Grid, Select, Text } from 'theme-ui';
import {
  BemButton,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemPill,
  BemTouchSlider,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_FORM_PB,
  MOBILE_HEADLINE_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { debugXState } from '../../utils/xStateHelper';
import { m0035Machine } from './BemM0035.machine';

interface IPropsBemM0035 extends IGraphqlM0035 {}

interface IPropsM0035ProductCard {
  item: IGraphqlM0035Item;
  index: number;
}

interface IPropsBemM0035Items {
  items: IGraphqlM0035Item[];
}

const PREFIX = 'M0035';

const styles: IStylesObject = {
  heading: {
    gridColumn: ['1 / -1', '1 / span 4'],
    textAlign: 'left',
    pt: 2,
    pb: [MOBILE_HEADLINE_PB, 10, 14],
    '&:only-child': {
      gridColumn: ['1 / -1', '1 / -1', '1 / -1'],
    },
  },
  segmentedControls: {
    gridColumn: ['1 / -1', '7 / -1', '5 / -1'],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    pb: MOBILE_FORM_PB,
  },
  headWrapper: {
    gridColumn: '2 / -2',
  },
  text: {
    py: [4, 8, 10],
    gridColumn: '1 / -1',
  },
  filterButtons: {
    display: ['none', 'none', 'flex'],
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'flex-end',
  },
  filterSelect: {
    width: '100%',
    pt: [0, 2, 2],
    pb: [2, 0, 0],
    display: ['block', 'block', 'none'],
    svg: {
      ml: -8,
    },
  },
  cardsWrapper: {
    pl: 0,
  },
  productImage: {
    width: '100%',
  },
  productCard: {
    gridTemplateRows: 'auto 1fr',
    gridGap: defaultGridGap,
    height: '100%',
    gridColumn: 'span 4',
  },
  linkWrapper: {
    height: ['100%', 'auto', 'auto'],
    gridColumn: 'span 4',
  },
  productCardMedia: {
    backgroundColor: 'gray.1',
    py: [4, 4, 8],
    px: [10, 10, 20],
  },
  productCardContent: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    pt: 2,
    px: [4, 0, 0],
    pb: [4, 8, 12],
  },
  productCardActionsWrapper: {
    pt: [0, 3, 0],
    pb: 4,
    px: [4, 0, 0],
  },
  productCardActions: {
    width: ['100%', '100%', '50%'],
  },
  productTitle: {
    pb: 2,
  },
  productDescription: {
    color: 'gray.5',
    pb: [2, 4, 4],
  },
  productCategory: {
    textTransform: 'uppercase',
    backgroundColor: 'gray.2',
    color: 'text',
  },
};

const ProductCard = ({ item, index }: IPropsM0035ProductCard) => (
  <Grid as="li" sx={styles.productCard}>
    <Box sx={styles.productCardMedia}>
      <BemMedia
        media={item.product.media[0]}
        withTransparentBackground
        sx={styles.productImage}
        additionalTrackingIdInfo={index + 1}
      />
    </Box>
    <Grid sx={styles.productCardContent}>
      {item.product.productSubCategories ? (
        <Box>
          {item.product.productSubCategories?.map((cat) => (
            <BemPill size="tiny" key={cat._id} sx={styles.productCategory}>
              {cat.name}
            </BemPill>
          ))}
        </Box>
      ) : null}

      <BemHeading variant="h5" sx={styles.productTitle}>
        {item.product.name}
      </BemHeading>
      <Text as="p" variant="small" sx={styles.productDescription}>
        {item.description}
      </Text>
      <Box sx={styles.productCardActionsWrapper}>
        {item.link?.length ? (
          <BemButton
            variant="buttons.primarySmall"
            sx={styles.productCardActions}
            data-testid={`${item._key}-button`}
          >
            {item.link[0].label}
          </BemButton>
        ) : null}
      </Box>
    </Grid>
  </Grid>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0035Items) => (
  <>
    {items.map((item, index) =>
      item.link?.length ? (
        <BemLink
          to={item.link}
          key={item._key}
          sx={styles.linkWrapper}
          additionalTrackingIdInfo={index + 1}
        >
          <ProductCard item={item} index={index} />
        </BemLink>
      ) : (
        <Box key={item._key} sx={styles.linkWrapper}>
          <ProductCard item={item} index={index} />
        </Box>
      )
    )}
  </>
);

const ENABLE_DEBUG_XSTATE = false;
// set param to true for state machine debugging
debugXState(ENABLE_DEBUG_XSTATE);

const BemM0035 = ({
  title,
  text,
  showCategories,
  allItemsButtonLabel,
  items,
}: IPropsBemM0035): JSX.Element => {
  const [current, send] = useMachine(m0035Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      allItems: items,
      currentFilter: 'all',
      filteredItems: items,
    },
  });

  useEffect(() => {
    send({
      type: 'updateData',
      allItems: items ?? [],
    });
  }, [items]);

  return (
    <BemModuleWrapper>
      <Grid
        variant="contentGrid"
        sx={styles.headWrapper}
        data-testid="headWrapper"
      >
        <BemHeading as="h2" variant="h1" sx={styles.heading}>
          {title}
        </BemHeading>

        {showCategories && current.context.allFilters.length ? (
          <Box sx={styles.segmentedControls}>
            <Box sx={styles.filterSelect}>
              <Select
                onChange={(e) =>
                  send({ type: 'setFilter', filterValue: e.target.value })
                }
                value={current.context.currentFilter}
              >
                <option value="all">{allItemsButtonLabel}</option>
                {current.context.allFilters.map((filter) => (
                  <option key={`option_${filter}`} value={filter}>
                    {filter}
                  </option>
                ))}
              </Select>
            </Box>
            <Box sx={styles.filterButtons}>
              <BemButton
                onClick={() => send({ type: 'setFilter', filterValue: 'all' })}
                variant={
                  current.context.currentFilter === 'all'
                    ? 'buttons.primarySmall'
                    : 'buttons.lightSmall'
                }
              >
                {allItemsButtonLabel}
              </BemButton>
              {current.context.allFilters.map((filter) => (
                <BemButton
                  key={`button_${filter}`}
                  onClick={() =>
                    send({ type: 'setFilter', filterValue: filter })
                  }
                  variant={
                    current.context.currentFilter === filter
                      ? 'buttons.primarySmall'
                      : 'buttons.lightSmall'
                  }
                >
                  {filter}
                </BemButton>
              ))}
            </Box>
          </Box>
        ) : null}
        <Text as="p" variant="caption.small" sx={styles.text}>
          {text}
        </Text>
      </Grid>
      <Grid
        as="ul"
        variant="contentGrid"
        sx={styles.cardsWrapper}
        data-testid="cardsWrapper"
      >
        {useViewportRenderer([
          <BemTouchSlider
            key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
            items={current.context.filteredItems}
            itemRenderer={({ item }, index) =>
              item.link?.length ? (
                <BemLink
                  to={item.link}
                  key={item._key}
                  sx={styles.linkWrapper}
                  additionalTrackingIdInfo={index + 1}
                >
                  <ProductCard item={item} index={index} />
                </BemLink>
              ) : (
                <Box key={item._key} sx={styles.linkWrapper}>
                  <ProductCard item={item} index={index} />
                </Box>
              )
            }
          />,
          <ItemsOnTabletOrLarger
            items={current.context.filteredItems}
            key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          />,
          <ItemsOnTabletOrLarger
            items={current.context.filteredItems}
            key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          />,
        ])}
      </Grid>
    </BemModuleWrapper>
  );
};

export {
  BemM0035,
  IPropsBemM0035,
  IPropsM0035ProductCard,
  IPropsBemM0035Items,
};
