/**
 * Returns the column width for items with pagination depending on the number of visible items.
 * This function is designed to accept a whole number, assumes that the next item should NOT pe partially shown on screen and that there is a grid-gap of 2%.
 *
 * @example:
 * // returns '23.5%'
 *    getPaginationColumnWidth(4);
 * @example:
 * // returns '32%'
 *    getPaginationColumnWidth(3);
 * @example:
 * // returns '49%'
 *    getPaginationColumnWidth(2);
 *
 * @param visibleItems
 *  min: 1
 *  max: 10
 * @returns {String} Returns the value of columnWidth of items in itemsWrapper that use Pagination or '100%' if the value exceeds the expected range.
 */

const minVisibleItems = 1;
const maxVisibleItems = 10;

const getPaginationColumnWidth = (visibleItems: number): string => {
  if (visibleItems < minVisibleItems || visibleItems > maxVisibleItems)
    return '100%';
  return `${100 / visibleItems - ((visibleItems - 1) * 2) / visibleItems}%`;
};

export { getPaginationColumnWidth };
