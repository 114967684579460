import { IGraphqlM0151 } from '@bemer/base';
import { ScrollAnimation } from '@hppycdng/bemer-component-library';
import React from 'react';
import { Box } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';
import { BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';

interface IPropsBemM0151 extends IGraphqlM0151 {}

const durationMap: Record<string, string> = {
  short: '300vh',
  default: '500vh',
  long: '700vh',
} as const;

const BemM0151 = ({ videoUrl, duration }: IPropsBemM0151): JSX.Element => {
  const scrollContainerId = uuidv4();

  // For an explanation on why we need this 'transform: translate()' prop here,
  // read the comments in https://bergwerkio.atlassian.net/browse/BBT-160
  const styles: IStylesObject = {
    scrollContainer: {
      width: '100vw',
      height: '100%',
      '> div': {
        '> div': {
          transform: 'translate(0px, -100vh)',
        },
      },
    },
  };

  return (
    <BemModuleWrapper isFullWidth>
      <Box sx={styles.scrollContainer} id={scrollContainerId}>
        <ScrollAnimation
          video={videoUrl}
          scrollElementProps={{
            style: {
              height: durationMap[duration],
              gridColumn: 'span 14',
            },
          }}
        />
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0151, IPropsBemM0151 };
