import { aspectRatios, IGraphqlM0003, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_MEDIA_PB,
  MULTIPLE_BUTTONS_BUTTON_MB,
  MULTIPLE_BUTTONS_BUTTON_MR,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ApiContext } from '../../providers';
import { extendLinkWithPartnerHandle } from '../../utils/extendLinkWithPartnerHandle';

interface IPropsBemM0003 extends IGraphqlM0003 {}

const styles: IStylesObject = {
  textWrapper: {
    gridRow: [2, 1, 1],
    gridColumn: ['2 / -2', '3 / 7', '3 / 7'],
    alignSelf: 'center',
  },
  button: {
    mr: MULTIPLE_BUTTONS_BUTTON_MR,
    mb: MULTIPLE_BUTTONS_BUTTON_MB,

    '&:last-of-type': {
      mr: 0,
      mb: 0,
    },
  },
  video: {
    gridColumn: ['1 / -1', '8 / -1', '8 / -1'],
    mb: ASIDE_MEDIA_PB,
  },
};

const BemM0003 = ({ media, actions, title }: IPropsBemM0003): JSX.Element => {
  const {
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);
  const { data: visitorSettings } = getVisitorSettings();
  const links = actions?.length
    ? actions.map((link, index: number) => {
        const linkWithPartner = extendLinkWithPartnerHandle(
          link,
          visitorSettings?.partnerHandle || undefined
        );
        return (
          <BemLink
            key={linkWithPartner._key}
            to={linkWithPartner}
            variant={
              index === 0 ? 'links.buttonPrimary' : 'links.buttonSecondary'
            }
            sx={styles.button}
          />
        );
      })
    : null;

  return (
    <BemModuleWrapper>
      <Box sx={styles.textWrapper} data-testid="BemM0003-textWrapper">
        <BemHeading
          as="h1"
          variant="h1WithSeparatorForHeadlineWithoutPaddingBottom"
        >
          {title}
        </BemHeading>
        {links?.length ? (
          <BemActionArea data-testid="BemM0003-actions">{links}</BemActionArea>
        ) : null}
      </Box>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
        playButtonPosition="bottomLeft"
        sx={styles.video}
        autoplayPreview
      />
    </BemModuleWrapper>
  );
};

export { BemM0003, IPropsBemM0003 };
