import { ContextFrom, EventFrom, sendParent } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { ActorRefFrom } from 'xstate/lib/types';

const MOUSE_ENTER_MARKER_DELAY = 400;

const eventCardModel = createModel(
  {
    isActive: false as boolean,
    tmpEventId: undefined as string | undefined,
  },
  {
    events: {
      MOUSE_ENTER: (eventId: string) => ({ eventId }),
      MOUSE_ENTER_MARKER: (id: string) => ({ id }),
      MOUSE_LEAVE_MARKER: () => ({}),
      MOUSE_LEAVE: () => ({}),
      MOUSE_CLICK_MARKER: (id: string) => ({ id }),
    },
  }
);

type TEventCardMachineContext = ContextFrom<typeof eventCardModel>;
type TEventCardMachineEvent = EventFrom<typeof eventCardModel>;

const eventCardMachine = eventCardModel.createMachine(
  {
    initial: 'idle',
    context: eventCardModel.initialContext,
    states: {
      idle: {
        on: {
          MOUSE_ENTER: {
            target: 'active',
            actions: ['setActive'],
          },
          MOUSE_ENTER_MARKER: {
            target: 'delay',
            actions: ['setTmpEventId'],
          },
          MOUSE_LEAVE_MARKER: {
            actions: ['setInactive', 'sendMouseLeaveMarkerEventToParent'],
          },
          MOUSE_CLICK_MARKER: {
            actions: ['setActive'],
          },
        },
      },
      delay: {
        after: {
          [MOUSE_ENTER_MARKER_DELAY]: {
            target: 'idle',
            actions: ['setActive', 'sendMouseEnterMarkerEventToParent'],
          },
        },
        on: {
          MOUSE_LEAVE_MARKER: {
            target: 'idle',
            actions: ['setInactive', 'sendMouseLeaveMarkerEventToParent'],
          },
        },
      },
      active: {
        on: {
          MOUSE_LEAVE: {
            target: 'idle',
            actions: ['setInactive'],
          },
        },
      },
    },
  },
  {
    actions: {
      sendMouseEnterMarkerEventToParent: sendParent(
        (context: TEventCardMachineContext, _event) => ({
          type: 'MOUSE_ENTER_MARKER',
          id: context.tmpEventId,
        })
      ),
      sendMouseLeaveMarkerEventToParent: sendParent((_context, _event) => ({
        type: 'MOUSE_LEAVE_MARKER',
      })),
      setTmpEventId: eventCardModel.assign(
        (_context, event: TEventCardMachineEvent) => ({
          tmpEventId:
            event.type === 'MOUSE_ENTER_MARKER' ? event.id : undefined,
        })
      ),
      setActive: eventCardModel.assign((_context, _event) => ({
        isActive: true,
      })),
      setInactive: eventCardModel.assign((_context, _event) => ({
        isActive: false,
      })),
    },
  }
);

type TEventCardMachineActorRef = ActorRefFrom<typeof eventCardMachine>;

export {
  eventCardMachine,
  TEventCardMachineContext,
  TEventCardMachineEvent,
  TEventCardMachineActorRef,
};
