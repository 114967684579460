import { useMachine } from '@xstate/react';
import React from 'react';
import { ContextFrom, EventFrom, Interpreter, State } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { debugXState } from '../../utils/xStateHelper';

interface IPropsBemModalProvider {
  children: React.ReactNode;
}

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const modalMachineModel = createModel(
  {
    isM0134Open: false,
    isM0077Open: false,
  },
  {
    events: {
      openLanguageSelectionModal: () => ({}),
      openContactModal: () => ({}),
      closeLanguageSelectionModal: () => ({}),
      closeContactModal: () => ({}),
    },
  }
);

const modalMachine = modalMachineModel.createMachine(
  {
    id: 'modalMachine',
    initial: 'closed',
    context: modalMachineModel.initialContext,
    states: {
      closed: {
        on: {
          openLanguageSelectionModal: {
            target: 'open',
            actions: ['toggleLanguageSelectionModalVisibility'],
          },
          openContactModal: {
            target: 'open',
            actions: ['toggleContactModalVisibility'],
          },
        },
      },
      open: {
        on: {
          closeLanguageSelectionModal: {
            target: 'closed',
            actions: ['toggleLanguageSelectionModalVisibility'],
          },
          closeContactModal: {
            target: 'closed',
            actions: ['toggleContactModalVisibility'],
          },
        },
      },
    },
  },
  {
    actions: {
      toggleLanguageSelectionModalVisibility: modalMachineModel.assign(
        (context) => ({
          isM0134Open: !context.isM0134Open,
        }),
        'openLanguageSelectionModal'
      ),
      toggleContactModalVisibility: modalMachineModel.assign(
        (context) => ({
          isM0077Open: !context.isM0077Open,
        }),
        'openContactModal'
      ),
    },
    guards: {},
  }
);
type TMachineContext = ContextFrom<typeof modalMachineModel>;
type TMachineEvent = EventFrom<typeof modalMachineModel>;
type TMachineState = State<TMachineContext, TMachineEvent>;

interface IModalContext {
  current: TMachineState;
  send: Interpreter<TMachineContext, any, TMachineEvent>['send'];
}
// Initialize the context with a default value.
const defaultValue: IModalContext = {
  current: {
    context: {},
  } as TMachineState,
  send: (_event: any) => ({} as TMachineState),
};
const ModalContext = React.createContext<IModalContext>(defaultValue);

const BemModalProvider = ({
  children,
}: IPropsBemModalProvider): JSX.Element | null => {
  const [current, send] = useMachine(modalMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {} as TMachineContext,
  });

  return (
    <ModalContext.Provider value={{ current, send }}>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, BemModalProvider, IPropsBemModalProvider };
