import {
  aspectRatios,
  IGraphqlM0031,
  IGraphqlM0031Item,
  RATIOS,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  ANIMATION_DIRECTION_BACKWARD,
  ANIMATION_DIRECTION_FORWARD,
  BemCloudImage,
  BemCloudVideo,
  BemHeading,
  BemModuleWrapper,
  BemPagination,
  BemTouchSlider,
  TAnimationDirection,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import {
  MOBILE_CARD_PADDING,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';
import { debugXState } from '../../utils/xStateHelper';
import { m0031Machine } from './BemM0031.machine';

interface IPropsBemM0031 extends IGraphqlM0031 {}

const PREFIX = 'M0031';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const MotionBox = motion(Box);

const MOTION_BOX_ENTER = 'enter';
const MOTION_BOX_CENTER = 'center';
const MOTION_BOX_EXIT = 'exit';

const styles: IStylesObject = {
  contentWrapper: {
    gridColumn: '1 / 6',
    alignSelf: 'center',
  },
  contentGridItem: {
    gridRow: 2,
  },
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  itemHeading: {
    pb: [6, 8, 8],
    pt: [6, 0, 0],
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  itemSubHeading: {
    color: 'textMuted',
    pb: [6, 10, 12],
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
  imageWrapper: {
    gridColumn: '8 / -1',
    gridRow: '2',
    height: '100%',
    gridTemplateColumns: '1fr',
    display: 'grid',
  },
  paginationWrapper: {
    gridColumn: '1 / -1',
    alignSelf: 'end',
  },
};

const mobileStyles: IStylesObject = {
  wrapper: {
    pb: MOBILE_CARD_PADDING,
  },
  itemText: {
    pb: 4,
    px: [MOBILE_CARD_PADDING, 0, 0],
  },
};

interface IPropsMediaComponent {
  item: IGraphqlM0031Item;
  index: number;
}
const MediaComponent = ({ item, index }: IPropsMediaComponent) => {
  if (!item.media?.length) {
    return null;
  }
  return item.media[0]._type === 'imageWithAlt' ? (
    <BemCloudImage
      image={item.media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_6_5].ratio}
      additionalTrackingIdInfo={index + 1}
    />
  ) : (
    <BemCloudVideo
      video={
        item.media[0]._type === 'videoSet'
          ? getLanguageSpecificVideo(item.media[0])
          : item.media[0]
      }
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_6_5].ratio}
      additionalTrackingIdInfo={index + 1}
    />
  );
};

const ItemMobile = ({ item, index }: IPropsMediaComponent) => (
  <Box sx={mobileStyles.wrapper}>
    <MediaComponent item={item} index={index} />
    <BemHeading as="h2" variant="h2" sx={styles.itemHeading}>
      {item.name}
    </BemHeading>
    <Text as="h4" variant="h4" sx={styles.itemSubHeading}>
      {item.jobTitle}
    </Text>
    <Text variant="caption.small" as="p" sx={mobileStyles.itemText}>
      {item.text}
    </Text>
  </Box>
);

const BemM0031 = ({ items, title }: IPropsBemM0031): JSX.Element => {
  const [current, send] = useMachine(m0031Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {
      activeElementKey: items[0]._key,
    },
  });

  const [direction, setDirections] = useState<TAnimationDirection>(
    ANIMATION_DIRECTION_FORWARD
  );

  const {
    context: { activeElementKey },
  } = current;

  const handleClick = (
    animationDirection: TAnimationDirection,
    activeItemKey: string
  ) => {
    setDirections(animationDirection);
    send({ type: 'click', payload: activeItemKey });
  };

  const variants = {
    [MOTION_BOX_ENTER]: (animDirection: string) => ({
      x: animDirection === ANIMATION_DIRECTION_FORWARD ? 1000 : -1000,
      opacity: 0,
    }),
    [MOTION_BOX_CENTER]: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    [MOTION_BOX_EXIT]: (animDirection: string) => ({
      zIndex: 0,
      x: animDirection === ANIMATION_DIRECTION_BACKWARD ? 1000 : -1000,
      opacity: 0,
    }),
  };

  const motionBoxProps = {
    custom: direction,
    variants,
    initial: MOTION_BOX_ENTER,
    animate: MOTION_BOX_CENTER,
    exit: MOTION_BOX_EXIT,
    transition: {
      x: { type: 'spring', stiffness: 300, damping: 30 },
      opacity: { duration: DURATION.SHORT },
    },
  };

  const itemsOnTabletOrBigger = (
    <AnimatePresence initial={false}>
      {items.map((item, index) => (
        <React.Fragment key={item._key}>
          {activeElementKey === item._key && (
            <>
              <Grid variant="contentGrid" sx={styles.contentGridItem}>
                <MotionBox sx={styles.contentWrapper} {...motionBoxProps}>
                  <Box>
                    <BemHeading as="h2" variant="h2" sx={styles.itemHeading}>
                      {item.name}
                    </BemHeading>
                    <Text as="h4" variant="h4" sx={styles.itemSubHeading}>
                      {item.jobTitle}
                    </Text>
                    <Text variant="caption.small" as="p">
                      {item.text}
                    </Text>
                  </Box>
                </MotionBox>
                {items.length > 1 ? (
                  <Box sx={styles.paginationWrapper}>
                    <BemPagination
                      items={items}
                      onClick={handleClick}
                      activeItemKey={activeElementKey}
                    />
                  </Box>
                ) : null}
              </Grid>
              <MotionBox sx={styles.imageWrapper} {...motionBoxProps}>
                <MediaComponent item={item} index={index} />
              </MotionBox>
            </>
          )}
        </React.Fragment>
      ))}
    </AnimatePresence>
  );

  return (
    <BemModuleWrapper sx={styles.wrapper}>
      <BemHeading as="h2" variant="h1" sx={styles.heading}>
        {title}
      </BemHeading>
      {useViewportRenderer([
        <BemTouchSlider
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          items={items}
          itemRenderer={({ item }, index) => (
            <ItemMobile item={item} index={index} />
          )}
        />,
        itemsOnTabletOrBigger,
        itemsOnTabletOrBigger,
      ])}
    </BemModuleWrapper>
  );
};

export { BemM0031, IPropsBemM0031 };
