import { motion } from 'framer-motion';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import opacities from '../../gatsby-plugin-theme-ui/opacities';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';

const BACKDROP_OPEN = 'open';
const BACKDROP_CLOSED = 'closed';

const styles: IStylesObject = {
  modalBackdrop: {
    height: '100vh',
    width: '100vw',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    position: 'fixed',
    bg: 'backdrop',
    opacity: opacities.backdropOpacity,
  },
};

const variants = {
  backdrop: {
    [BACKDROP_OPEN]: {
      opacity: opacities.backdropOpacity,
      pointerEvents: 'auto' as const,
      transition: {
        duration: DURATION.SHORT,
      },
    },

    [BACKDROP_CLOSED]: {
      opacity: 0,
      pointerEvents: 'none' as const,
      transition: {
        duration: DURATION.SHORT,
      },
    },
  },
};
const AnimatedBox = motion(Box);

type TBackdropState = 'open' | 'closed';
interface IPropsBackdrop {
  state: TBackdropState;
  handleClose?: () => void;
  sx: ThemeUIStyleObject;
}

const BemBackdrop = ({
  state,
  handleClose = () => {},
  sx,
}: IPropsBackdrop): JSX.Element => (
  <>
    <Helmet>
      {state === BACKDROP_OPEN ? (
        <style type="text/css">
          {'body { max-height: 100vw; overflow: hidden; }'}
        </style>
      ) : (
        <style type="text/css">
          {'body { max-height: 0; overflow: visible; }'}
        </style>
      )}
    </Helmet>
    {state === BACKDROP_OPEN ? (
      <AnimatedBox
        initial={BACKDROP_CLOSED}
        onClick={handleClose}
        sx={{ ...styles.modalBackdrop, ...sx }}
        variants={variants.backdrop}
        animate={state}
      />
    ) : null}
  </>
);

export { BemBackdrop, TBackdropState };
