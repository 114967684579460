import { IGraphqlTranslation } from '@bemer/base';

const getTranslation = (
  key: string,
  translations: IGraphqlTranslation[]
): string => {
  if (!translations || translations.length === 0) {
    return 'NO TRANSLATIONS PROVIDED';
  }

  const foundTranslation = translations.find(
    (translation) => translation.key === key
  );
  if (foundTranslation && foundTranslation.translation) {
    return foundTranslation.translation;
  }

  console.warn(`NO TRANSLATION AVAILABLE FOR ${key}`);
  return key;
};

export { getTranslation };
