import { IGraphqlM0065, IGraphqlM0065Item } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';

interface IPropsBemM0065 extends IGraphqlM0065 {}

const PREFIX = 'M0065';

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'center',
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  grid: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(270px, 1fr))',
    gridAutoRows: '1fr',
  },
  item: {
    bg: 'gray.2',
    p: [4, 9, 9],
    pb: [12, 20, 24],
    display: 'block',
    height: '100%',
    wordWrap: 'break-word',
  },
  cardHeading: {
    color: 'gray.6',
    pb: 6,
  },
};

interface IPropsBemM0065Item {
  item: IGraphqlM0065Item;
  index: number;
}
interface IPropsBemM0065Items {
  items: IGraphqlM0065Item[];
}

const Item = ({ item, index }: IPropsBemM0065Item) => (
  <BemLink
    to={item.link}
    sx={styles.item}
    additionalTrackingIdInfo={index + 1}
    data-testid={item._key}
  >
    <Text as="p" variant="small" sx={styles.cardHeading}>
      {item.location}
    </Text>
    <BemHeading as="p" variant="h5">
      {item.link[0].label}
    </BemHeading>
  </BemLink>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0065Items) => (
  <>
    {items.map((item, index) => (
      <Item item={item} key={item._key} index={index} />
    ))}
  </>
);

const BemM0065 = ({ title, items }: IPropsBemM0065): JSX.Element => (
  <BemModuleWrapper>
    <BemHeading as="h2" variant="h1" sx={styles.heading}>
      {title}
    </BemHeading>
    <Grid variant="contentGrid" sx={styles.grid}>
      {useViewportRenderer([
        <BemTouchSlider
          items={items}
          itemRenderer={(data, index) => (
            <Item item={data.item} index={index} />
          )}
          key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
          itemWidth="45%"
          hideShadow
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        />,
        <ItemsOnTabletOrLarger
          items={items}
          key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        />,
      ])}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0065, IPropsBemM0065 };
