import {
  BEMER_EVENT_PORTAL_URL,
  IGraphqlTranslation,
  TEvent,
} from '@bemer/base';
import React, { useContext } from 'react';
import { GoLocation } from 'react-icons/go';
import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { BemAvatar, BemPill } from '../../../components';
import { IStylesObject } from '../../../gatsby-plugin-theme-ui/moduleTypes';
import { useGetPartnerData } from '../../../hooks/useGetPartnerData';
import { LocalisationContext } from '../../../providers';
import { getTranslation } from '../../../utils/translations';
import {
  formatLocation,
  formatPrice,
  getLocalDateFormatted,
  isEventAdvertised,
} from '../utils/events';

const styles: IStylesObject = {
  advertised: {
    background: 'secondary.4',
  },
  highlighted: {
    background: 'skyblue',
  },
  topRow: {
    justifyContent: 'space-between',
    pb: 5,
    alignItems: 'center',
  },
  date: {
    color: 'gray.5',
  },
  avatar: {
    mr: 2,
    borderRadius: 'full',
  },
  pill: {
    alignSelf: 'flex-start',

    "[class*='advertised'] &": {
      background: 'white',
    },
  },
  name: {
    pb: 6,
  },
  bottomRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  locationWrapper: {
    alignItems: 'center',
    flex: 1,
  },
  locationIcon: {
    color: 'gray.5',
    pr: '1_5',
    lineHeight: 0,
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    pt: 1,
    pb: 1,
    flex: 1,
    pr: 1,
  },
};

interface IPropsEventCardDetails {
  event: TEvent;
  T: IGraphqlTranslation[];
  sx?: ThemeUIStyleObject;
}

const EventCardDetails = ({
  event,
  T,
  sx,
}: IPropsEventCardDetails): JSX.Element | null => {
  const {
    name,
    dateStartISO8601,
    dateEndISO8601,
    eventType,
    minPrice,
    venue,
    id,
    isBemerEvent = false,
    creator,
  } = event;

  const partnerData = useGetPartnerData();
  const { locale } = useContext(LocalisationContext);
  const partnerId = isBemerEvent ? creator.navisionCustomerId : undefined;

  const isAdvertised = isEventAdvertised(event, partnerData);

  const goToDetailPage = () => {
    const DETAIL_PAGE_URL = `${BEMER_EVENT_PORTAL_URL}/${locale.id}/events/${id}`;
    // eslint-disable-next-line no-restricted-globals
    if (window) {
      window.open(DETAIL_PAGE_URL, '_blank');
    }
  };

  return (
    <Box
      sx={sx}
      onClick={goToDetailPage}
      data-testid="EventCardDetails-wrapper"
    >
      <Flex sx={styles.topRow}>
        <Text as="p" variant="text.small" sx={styles.date}>
          {getLocalDateFormatted(dateStartISO8601, dateEndISO8601)}
        </Text>
        <Flex>
          {isAdvertised ? (
            <BemAvatar
              size="tiny"
              forcePartnerId={partnerId}
              sx={styles.avatar}
            />
          ) : null}
          <BemPill sx={styles.pill} backgroundColor="gray" color="primary">
            {getTranslation(`EVENT_TYPE_${eventType}`, T)}
          </BemPill>
        </Flex>
      </Flex>
      <Text as="p" variant="bodyText" sx={styles.name}>
        {name}
      </Text>
      <Flex sx={styles.bottomRow}>
        <Flex sx={styles.locationWrapper}>
          {venue.venueType === 'OPTION_PHYSICAL_LOCATION' &&
          (venue.country || venue.city) ? (
            <>
              <Box sx={styles.locationIcon}>
                <GoLocation />
              </Box>
              <Text as="p" variant="text.small" sx={styles.locationText}>
                {formatLocation(venue)}
              </Text>
            </>
          ) : null}
          {venue.venueType === 'OPTION_ONLINE' ? (
            <Text as="p" variant="text.small">
              {getTranslation('ONLINE_EVENT', T)}
            </Text>
          ) : null}
        </Flex>
        <Text as="p" variant="text.small">
          {formatPrice({ priceString: minPrice, locale, T })}
        </Text>
      </Flex>
    </Box>
  );
};

export { EventCardDetails as default };
