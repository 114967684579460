import { createModel } from 'xstate/lib/model';
import { TBackdropState } from '../../components/Backdrop';

const OPEN_MOUSEENTER_DELAY = 300;
const BACKDROP_CLOSED = 'closed';
const BACKDROP_OPEN = 'open';

const m0145MachineModel = createModel(
  {
    activeMarker: -1,
    backdropVisibility: BACKDROP_CLOSED as TBackdropState,
  },
  {
    events: {
      mouseLeave: () => ({}),
      decline: () => ({}),
      mouseEnter: (data: { activeMarker: number }) => ({
        data,
      }),
      mouseClickOpen: (data: { activeMarker: number }) => ({
        data,
      }),
      mouseClickClose: () => ({}),
    },
  }
);

const m0145Machine = m0145MachineModel.createMachine(
  {
    id: 'm0145Machine',
    initial: 'closed',
    context: m0145MachineModel.initialContext,
    states: {
      closed: {
        on: {
          mouseEnter: {
            target: 'open',
            actions: ['activateMarker'],
          },
          mouseClickOpen: {
            target: 'open',
            actions: ['activateMarker', 'openBackdrop'],
          },
        },
      },
      delay: {
        after: {
          [OPEN_MOUSEENTER_DELAY]: {
            target: 'closed',
            actions: ['deactivateMarker'],
          },
        },
        on: {
          decline: {
            target: 'open',
          },
          mouseEnter: {
            target: 'open',
            actions: ['activateMarker'],
          },
        },
      },
      open: {
        on: {
          mouseLeave: {
            target: 'delay',
          },
          mouseClickClose: {
            target: 'closed',
            actions: ['deactivateMarker', 'closeBackdrop'],
          },
          mouseClickOpen: {
            actions: ['activateMarker'],
          },
        },
      },
    },
  },
  {
    actions: {
      activateMarker: m0145MachineModel.assign(
        {
          activeMarker: (_context, event) => event.data.activeMarker,
        },
        'mouseEnter'
      ),
      deactivateMarker: m0145MachineModel.assign(
        {
          activeMarker: (_context) => -1,
        },
        'mouseLeave'
      ),
      openBackdrop: m0145MachineModel.assign(
        {
          backdropVisibility: (_context) => BACKDROP_OPEN,
        },
        'mouseClickOpen'
      ),
      closeBackdrop: m0145MachineModel.assign(
        {
          backdropVisibility: (_context) => BACKDROP_CLOSED,
        },
        'mouseClickOpen'
      ),
    },
    guards: {
      isAlreadyOpen: (context, event) =>
        event.type === 'mouseEnter' &&
        context.activeMarker !== event.data.activeMarker,
    },
  }
);

export { m0145Machine };
