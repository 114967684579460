import { aspectRatios, IGraphqlM0074, RATIOS } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { GRID_GAP_MOBILE } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0074 extends IGraphqlM0074 {}

const styles: IStylesObject = {
  wrapper: {
    gridTemplateRows: ['auto', '1fr 80px min-content', '1fr 160px min-content'],
  },
  image: {
    gridRow: ['1', '1 / -2', '1 / -2'],
    gridColumn: '1 / -1',
    mb: [GRID_GAP_MOBILE, 0, 0],
  },
  heading: {
    gridRow: '1',
    zIndex: 1,
    alignSelf: 'center',
    textAlign: 'center',
    gridColumn: '1 / -1',
  },
  contentGridText: {
    gridRow: ['2', '2 / -1', '2 / -1'],
    zIndex: 1,
    py: ['10', '16', '24'],
    bg: 'white',
    boxShadow: 'cardShadow',
    gridColumn: ['2 / -2', '3 / -3', '2 / -2'],
  },
  text: {
    gridColumn: '2 / -2',
  },
};

const BemM0074 = ({ title, text, media }: IPropsBemM0074): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper}>
    <BemMedia
      media={media[0]}
      sx={styles.image}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_17_8].ratio}
    />
    {title ? (
      <BemThemeWrapper
        themeName={
          // TODO: refactor quick fixes for having videos, images and video sets
          media[0]._type === 'videoSet'
            ? getLanguageSpecificVideo(media[0]).themeName
            : media[0].themeName
        }
      >
        <BemHeading
          as="h1"
          variant="h1"
          sx={styles.heading}
          data-testid="BemM0074Title"
          themeName={
            // TODO: refactor quick fixes for having videos, images and video sets
            media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(media[0]).themeName
              : media[0].themeName
          }
        >
          {title}
        </BemHeading>
      </BemThemeWrapper>
    ) : null}
    <Grid variant="contentGrid" sx={styles.contentGridText}>
      <Text variant="caption.big" sx={styles.text}>
        {text}
      </Text>
    </Grid>
  </BemModuleWrapper>
);

export { BemM0074, IPropsBemM0074 };
