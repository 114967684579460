/**
 * Checks if the given string is a valid URL.
 *
 * @param url
 */
const isValidUrl = (url: string): boolean => {
  try {
    return !!new URL(url);
  } catch (_) {
    return false;
  }
};

const getHashParams = (): URLSearchParams => {
  // Remove the leading # from the hash string.
  const hashString = window.location.hash.slice(1);
  return new URLSearchParams(hashString);
};

const removeHash = (): void => {
  window.history.pushState(
    '',
    window.document.title,
    window.location.pathname + window.location.search
  );
};

// isWindowDefined is only meant for use with setHash and it is exported as privateIsWindowDefined for testing
type TMockWindow = undefined;
const isWindowDefined = (windowObject: Window | TMockWindow): boolean => {
  const isWindowObjectDefined = typeof windowObject !== 'undefined';
  if (!isWindowObjectDefined)
    console.warn(
      'setHash() is not supported in this environment as there is no window defined.'
    );
  return isWindowObjectDefined;
};
const privateIsWindowDefined = isWindowDefined;
const setHash = (hash: string): void => {
  if (!isWindowDefined(window)) return;
  window.location.hash = hash;
};

const getHash = (): string => {
  if (!isWindowDefined(window)) return '';
  return window.location.hash.substring(1);
};

const setHashParams = (hashParams: URLSearchParams): void => {
  const newHashString = hashParams.toString();
  if (!newHashString) {
    removeHash();
    window.location.hash = '';
    return;
  }
  window.location.hash = newHashString;
};

/**
 * Checks if the given token is in the list of hash tokens of the current location.
 * If a value is given, the check will also compare the value.
 *
 * @param token
 * @param value
 */
const isTokenInUrlHash = (token: string, value?: string): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  const hashParams = getHashParams();
  return value ? hashParams.get(token) === value : hashParams.has(token);
};

/**
 * Adds the given token to the list of hash tokens of the current location.
 *
 * @param token
 * @param value
 */
const addTokenToUrlHash = (token: string, value = ''): void => {
  if (typeof window === 'undefined') {
    return;
  }
  const hashParams = getHashParams();
  hashParams.set(token, value);
  setHashParams(hashParams);
};

/**
 * Removes the given token from the list of hash tokens of the current location.
 *
 * @param token
 */
const removeTokenFromUrlHash = (token: string): void => {
  if (typeof window === 'undefined' || !window.location.hash) {
    return;
  }
  const hashParams = getHashParams();
  hashParams.delete(token);
  setHashParams(hashParams);
};

export {
  isValidUrl,
  isTokenInUrlHash,
  addTokenToUrlHash,
  removeTokenFromUrlHash,
  setHash,
  getHash,
  privateIsWindowDefined,
  removeHash,
};
