import { aspectRatios, IGraphqlM0087ProductType, RATIOS } from '@bemer/base';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import { BemCloudImage, BemTouchSlider } from '../../components';
import { GRID_ROW_GAP_TO_ZERO } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ModuleContext } from '../../providers/ModuleProvider/BemModuleProvider';
import { TM0087Events } from './BemM0087.machine';
import { Indicator } from './Indicator';

const PREFIX = 'M0087_ProductTypes';
const PRODUCT_TYPE_BUTTON_OPEN = 'open';
const PRODUCT_TYPE_BUTTON_CLOSED = 'closed';
const animationVariants = {
  productTypeButton: {
    [PRODUCT_TYPE_BUTTON_OPEN]: {
      opacity: 1,
    },
    [PRODUCT_TYPE_BUTTON_CLOSED]: {
      opacity: 0.6,
    },
  },
};
const styles = {
  productTypes: {
    gridColumn: '1 / -1',
    justifyContent: 'space-around',
  },
  imageWrapper: {
    width: 24,
    height: 24,
    mx: 'auto',
    borderRadius: 'full',
    overflow: 'hidden',
    border: '3px solid',
    borderColor: 'transparent',
    mb: 8,
    '&:focus-visible': {
      outline: 'none',
      borderColor: 'primary',
    },
  },
  productType: {
    textAlign: 'center' as const,
    gridTemplateRows: 'min-content 1fr min-content',
    rowGap: GRID_ROW_GAP_TO_ZERO,
    cursor: 'pointer',
    flex: 1,
  },
  text: {
    color: 'inherit',
    pb: 10,
  },
};

const MotionBox = motion(Box);

interface IPropsProductTypes {
  items: IGraphqlM0087ProductType[];
  activeIndex?: number;
  send: (arg0: TM0087Events) => void;
  activeLine: number;
}
interface IPropsProductType {
  item: IGraphqlM0087ProductType;
  activeIndex?: number;
  send: (arg0: TM0087Events) => void;
  index: number;
  activeLine: number;
}

const Item = ({
  item,
  activeIndex,
  send,
  index,
  activeLine,
}: IPropsProductType) => {
  const { moduleName } = useContext(ModuleContext);
  const componentName = 'ProductType';
  const additionalTrackingIdInfo = `line-${activeLine + 1}-productType-${
    index + 1
  }`;
  const trackingId = `${moduleName}-${componentName}${
    additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
  }`;
  return (
    <Grid
      variant="tertiary"
      sx={styles.productType}
      onClick={() =>
        send({
          type: 'setProductType',
          key: item._key,
        })
      }
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          send({
            type: 'setProductType',
            key: item._key,
          });
        }
      }}
      data-trackingid={trackingId}
    >
      <MotionBox
        sx={styles.imageWrapper}
        animate={
          activeIndex === index
            ? PRODUCT_TYPE_BUTTON_OPEN
            : PRODUCT_TYPE_BUTTON_CLOSED
        }
        variants={animationVariants.productTypeButton}
        role="button"
        tabIndex={0}
        data-testid={item._key}
      >
        <BemCloudImage
          image={item.image}
          forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
          additionalTrackingIdInfo={additionalTrackingIdInfo}
        />
      </MotionBox>
      <Text as="div" variant="navWithoutHover" sx={styles.text}>
        {item.name}
      </Text>
      <Indicator isActive={activeIndex === index} />
    </Grid>
  );
};

const getRenderItemMobile = ({
  item,
  activeIndex,
  send,
  index,
  activeLine,
}: IPropsProductType): JSX.Element => (
  <Item
    item={item}
    activeIndex={activeIndex}
    send={send}
    index={index}
    activeLine={activeLine}
  />
);

const ItemsOnTabletOrLarger = ({
  items,
  activeIndex,
  send,
  activeLine,
}: IPropsProductTypes): JSX.Element => (
  <>
    {items.map((item, index) => (
      <Item
        key={item._key}
        item={item}
        activeIndex={activeIndex}
        send={send}
        index={index}
        activeLine={activeLine}
      />
    ))}
  </>
);

const ItemsOnMobile = ({
  items,
  activeIndex,
  send,
  activeLine,
}: IPropsProductTypes): JSX.Element => (
  <BemTouchSlider
    items={items}
    itemRenderer={(data, index) =>
      getRenderItemMobile({
        item: data.item,
        activeIndex,
        send,
        index,
        activeLine,
      })
    }
    hideShadow
    itemWidth="45%"
  />
);

const ProductTypes = ({
  items,
  activeIndex,
  send,
  activeLine,
}: IPropsProductTypes): JSX.Element => (
  <Flex sx={styles.productTypes}>
    {useViewportRenderer([
      <ItemsOnMobile
        key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        items={items}
        activeIndex={activeIndex}
        send={send}
        activeLine={activeLine}
      />,
      <ItemsOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        items={items}
        activeIndex={activeIndex}
        send={send}
        activeLine={activeLine}
      />,
      <ItemsOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        items={items}
        activeIndex={activeIndex}
        send={send}
        activeLine={activeLine}
      />,
    ])}
  </Flex>
);
export { ProductTypes, IPropsProductTypes };
