import { aspectRatios, IGraphqlM0042, RATIOS } from '@bemer/base';
import React from 'react';
import { Text } from 'theme-ui';
import { BemHeading, BemMedia, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_MEDIA_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0042 extends IGraphqlM0042 {}

const styles: IStylesObject = {
  wrapper: {
    alignItems: 'center',
  },
  heading: {
    gridColumn: '2 / -2',
    textAlign: ['left', 'center', 'center'],
    pb: TOP_HEADLINE_V1_PB,
    gridRow: [2, 'auto', 'auto'],
  },
  image: {
    gridColumn: ['2 / -2', '2 / span 6', '2 / span 6'],
    gridRow: [1, 'auto', 'auto'],
    pb: ASIDE_MEDIA_PB,
  },
  text: {
    gridColumn: ['2 / -2', '9 / span 5', '9 / span 5'],
    gridRow: [3, 'auto', 'auto'],
  },
};

const BemM0042 = ({ title, text, media }: IPropsBemM0042): JSX.Element => (
  <BemModuleWrapper sx={styles.wrapper}>
    <BemHeading as="h2" variant="h2" sx={styles.heading}>
      {title}
    </BemHeading>
    <BemMedia
      media={media[0]}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_5].ratio}
      sx={styles.image}
    />
    <Text as="p" variant="bodyText" sx={styles.text}>
      {text}
    </Text>
  </BemModuleWrapper>
);

export { BemM0042, IPropsBemM0042 };
