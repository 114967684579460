import {
  aspectRatios,
  IGraphqlM0020,
  IGraphqlM0020Item,
  RATIOS,
  TGraphqlMediaItem,
  THEME_NAMES,
} from '@bemer/base';
import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import {
  defaultGridGap,
  MAIN_COLUMN_WIDTH,
} from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_HEADLINE_PB,
  ASIDE_MEDIA_PB,
  MOBILE_MODULE_TEXT_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer/index';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0020 extends IGraphqlM0020 {}
interface IPropsBemM0020Item extends IGraphqlM0020Item {
  index: number;
}

const PREFIX = 'M0020';

const styles: IStylesObject = {
  heading: {
    pt: [0, 12, 12],
    pb: ASIDE_HEADLINE_PB,
  },
  text: {
    pb: [MOBILE_MODULE_TEXT_PB, 12, 16],
  },
  video: {
    pb: defaultGridGap,
  },
  leftBox: {
    gridColumn: '1 / span 6',
  },
  rightBox: {
    gridColumn: 'span 8',
  },
  leftInnerGrid: {
    gridTemplateColumns: `minmax(0, 1fr) repeat(5, ${MAIN_COLUMN_WIDTH})`,
    gap: defaultGridGap,
  },
  rightInnerGrid: {
    gridTemplateColumns: `repeat(7, ${MAIN_COLUMN_WIDTH}) minmax(0, 1fr)`,
    gap: defaultGridGap,
  },
  textWrapper: {
    gridColumn: '2 / span 4',
    gridRow: '1',
  },
  itemWrapper: {
    gridColumn: 'span 5',
    pb: ASIDE_MEDIA_PB,
  },
  itemImage: {
    gridColumn: '1',
    gridRow: '1',
  },
  itemHeading: {
    gridColumn: '1',
    gridRow: [2, 2, 1],
    alignSelf: 'end',
    zIndex: 2,
    px: [0, 0, 8],
    pb: [0, 0, 6],
  },
};

const stylesMobile: IStylesObject = {
  wrapper: {
    gridColumn: '2 / -2',
  },
  text: {
    pb: MOBILE_MODULE_TEXT_PB,
  },
  video: {
    pb: MOBILE_MODULE_TEXT_PB,
  },
};

const Item = ({ _key, media, text, index }: IPropsBemM0020Item) => (
  <BemThemeWrapper
    key={_key}
    themeName={[
      THEME_NAMES.DARK_TEXT,
      THEME_NAMES.DARK_TEXT,
      // TODO: refactor quick fixes for having videos, images and video sets
      media[0]._type === 'videoSet'
        ? getLanguageSpecificVideo(media[0]).themeName
        : media[0].themeName,
    ]}
  >
    <Grid sx={styles.itemWrapper}>
      <BemHeading
        as="p"
        variant="h4"
        sx={styles.itemHeading}
        themeName={[
          THEME_NAMES.DARK_TEXT,
          THEME_NAMES.DARK_TEXT,
          // TODO: refactor quick fixes for having videos, images and video sets
          media[0]._type === 'videoSet'
            ? getLanguageSpecificVideo(media[0]).themeName
            : media[0].themeName,
        ]}
      >
        {text}
      </BemHeading>
      <BemMedia
        media={media[0]}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_5_4].ratio}
        sx={styles.itemImage}
        additionalTrackingIdInfo={index + 1}
      />
    </Grid>
  </BemThemeWrapper>
);

interface IPropsViewport {
  title: string;
  text: string;
  media: TGraphqlMediaItem;
  items: IGraphqlM0020Item[];
  key: string;
}

const ViewOnMobile = ({ title, text, media, items }: IPropsViewport) => (
  <BemModuleWrapper>
    <Box sx={stylesMobile.wrapper} data-testid="BemM0020-mobileWrapper">
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      <Text as="p" variant="caption.small" sx={stylesMobile.text}>
        {text}
      </Text>
      <BemMedia
        media={media}
        sx={stylesMobile.video}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
        additionalTrackingIdInfo="hero"
      />
      {items && items[0] ? <Item {...items[0]} index={0} /> : null}
      {items && items[1] ? <Item {...items[1]} index={1} /> : null}
    </Box>
  </BemModuleWrapper>
);

const ViewOnTabletOrLarger = ({
  title,
  text,
  media,
  items,
}: IPropsViewport) => (
  <BemModuleWrapper>
    <Box sx={styles.leftBox} data-testid="BemM0020-leftBox">
      <Grid sx={styles.leftInnerGrid}>
        <Box sx={styles.textWrapper}>
          <BemHeading as="h2" variant="h2" sx={styles.heading}>
            {title}
          </BemHeading>
          <Text as="p" variant="caption.small" sx={styles.text}>
            {text}
          </Text>
        </Box>
      </Grid>
      {items && items[0] ? <Item {...items[0]} index={0} /> : null}
    </Box>
    <Box sx={styles.rightBox} data-testid="BemM0020-rightBox">
      <BemMedia
        media={media}
        sx={styles.video}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
        autoplayPreview
        additionalTrackingIdInfo="hero"
      />
      <Grid sx={styles.rightInnerGrid}>
        {items && items[1] ? <Item {...items[1]} index={1} /> : null}
      </Grid>
    </Box>
  </BemModuleWrapper>
);

const BemM0020 = ({
  title,
  text,
  media,
  items,
}: IPropsBemM0020): JSX.Element | null => (
  <>
    {useViewportRenderer([
      <ViewOnMobile
        key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
        title={title}
        text={text}
        media={media[0]}
        items={items}
      />,
      <ViewOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.TABLET}`}
        title={title}
        text={text}
        media={media[0]}
        items={items}
      />,
      <ViewOnTabletOrLarger
        key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
        title={title}
        text={text}
        media={media[0]}
        items={items}
      />,
    ])}
  </>
);

export { BemM0020, IPropsBemM0020 };
