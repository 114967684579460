import {
  aspectRatios,
  IGraphqlM0140,
  RATIOS,
  THEME_NAMES,
  TThemeName,
} from '@bemer/base';
import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { Box } from 'theme-ui';
import {
  BemButton,
  BemCloudImage,
  BemCloudVideo,
  BemHeading,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  ON_MEDIA_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { useViewportRenderer } from '../../hooks/useViewportRenderer';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0140 extends IGraphqlM0140 {}

const styles: IStylesObject = {
  titleOnMedia: {
    mb: ON_MEDIA_HEADLINE_V1_PB,
  },
  titleBelowMedia: {
    gridColumn: ['2 / -2', 'none', 'none'],
    textAlign: 'center',
    pt: [MOBILE_MEDIA_PB, 0, 0],
  },
  media: {
    gridColumn: '1 / -1',
    gridRow: 1,
  },
  contentWrapper: {
    gridRow: 1,
    gridColumn: '1 / -1',
    textAlign: 'center',
    alignSelf: 'center',
    zIndex: 1,
  },
};

interface IPropsTitle {
  title?: string;
  sx?: any;
  dataTestId?: string;
  themeName: TThemeName | TThemeName[];
}
const Title = ({ title, sx, dataTestId, themeName }: IPropsTitle) =>
  title ? (
    <BemHeading
      as="h1"
      variant="h1"
      sx={sx}
      data-testid={dataTestId}
      themeName={themeName}
    >
      {title}
    </BemHeading>
  ) : null;

const BemM0140 = ({ title, media }: IPropsBemM0140): JSX.Element | null => {
  if (!media?.length) {
    return null;
  }
  const [playing, setPlaying] = useState(false);

  const usedTheme = [
    THEME_NAMES.DARK_TEXT,
    // TODO: refactor quick fixes for having videos, images and video sets
    media[0]._type === 'videoSet'
      ? getLanguageSpecificVideo(media[0]).themeName
      : media[0].themeName,
    media[0]._type === 'videoSet'
      ? getLanguageSpecificVideo(media[0]).themeName
      : media[0].themeName,
  ];

  const getTitleOnMedia = () => (
    <Title
      title={title}
      sx={styles.titleOnMedia}
      dataTestId="M0140-titleOnMedia"
      themeName={usedTheme}
    />
  );

  const getTitleBelowMedia = () => (
    <Title
      title={title}
      sx={styles.titleBelowMedia}
      dataTestId="M0140-titleBelowMedia"
      themeName={usedTheme}
    />
  );

  return (
    <BemModuleWrapper>
      {media[0]._type === 'videoSet' || media[0]._type === 'video' ? (
        // TODO: alter this check, when single videos are not allowed anymore
        <BemThemeWrapper themeName={usedTheme}>
          <BemCloudVideo
            video={
              media[0]._type === 'videoSet'
                ? getLanguageSpecificVideo(media[0])
                : media[0]
            }
            sx={styles.media}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_3].ratio}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onEnded={() => setPlaying(false)}
            playing={playing}
            autoplayPreview
            playButton={
              <Box sx={styles.contentWrapper}>
                {useViewportRenderer([
                  null,
                  getTitleOnMedia(),
                  getTitleOnMedia(),
                ])}
                <BemButton
                  variant="buttons.play"
                  onClick={() => setPlaying(true)}
                  data-testid="BemCloudVideoPlayButton"
                >
                  <FaPlay />
                </BemButton>
              </Box>
            }
          />
          {useViewportRenderer([getTitleBelowMedia(), null, null])}
        </BemThemeWrapper>
      ) : null}
      {media[0]._type === 'imageWithAlt' ? (
        <BemThemeWrapper themeName={media[0].themeName}>
          <BemCloudImage
            image={media[0]}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_7_3].ratio}
            sx={styles.media}
          />
          <Box sx={styles.contentWrapper}>
            <Title title={title} themeName={media[0].themeName} />
          </Box>
        </BemThemeWrapper>
      ) : null}
    </BemModuleWrapper>
  );
};

export { BemM0140, IPropsBemM0140 };
