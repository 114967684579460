import {
  buildLocale,
  buildPathNamesLink,
  buildThemes,
  fetchAddressSuggestions,
  fetchPartnerByHandle,
  fetchPartnersByLocation,
  fetchPartnerSuggestions,
  getEvents,
  getEventTypes,
  getProduct,
  getProducts,
  getVisitorSettings,
  IGraphqlAllRegionCountryLanguageAssignment,
  IGraphqlLocaleIdentifier,
  IGraphqlPageEdge,
  ILocale,
  IVisitorSendContactRequestPayload,
  NoHandleError,
  sendContactRequest,
  TInternalRoutePageTree,
  TThemeName,
  TVisitorSendContactRequestResponseData,
  TVisitorSettingsResponseData,
  TVisitorSettingsUpdateData,
  updateVisitorSettings,
} from '@bemer/base';
import {
  BemApiProvider,
  BemLocalisationProvider,
  BemNavigationProvider,
  BemPathNamesProvider,
  BemThemeHierarchyProvider,
  IUseQueryOptions,
} from '@bemer/ui-library';
import { BemModalProvider } from '@bemer/ui-library/src/providers/ModalProvider';
import React, { ReactNode, useCallback } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { VisitorSettings } from '..';
import { IPageContext } from '../../interfaces';

const queryClient = new QueryClient();

interface IAppPageWrapper {
  allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[];
  pageContext: IPageContext;
  pathNames?: TInternalRoutePageTree;
  themes?: any;
  isSubNavigationHidden?: boolean;
  doPartnerHandleRedirect?: boolean;
  children: ReactNode;
  pageForAllLocaleIdentifiers: IGraphqlPageEdge[];
  allRegionCountryLanguageAssignments: IGraphqlAllRegionCountryLanguageAssignment;
}

/**
 * temporary custom hook for transitioning unconventional hooks to official "use" hook notation
 */
const useGetPartnerByHandle = (
  handle?: string,
  options?: IUseQueryOptions,
  pageLocale?: ILocale
) => {
  const fetch = useCallback(async () => {
    try {
      if (!handle) return undefined;
      return await fetchPartnerByHandle(handle, pageLocale);
    } catch (error: any) {
      if (error instanceof NoHandleError) {
        console.error(error.serializeErrors());
      } else {
        console.error(error);
      }
      return undefined;
    }
  }, [handle, pageLocale]);
  return useQuery(['getPartnerByHandle', handle, pageLocale], fetch, options);
};

const AppPageWrapper = ({
  pageContext,
  allActiveLocaleIdentifiers,
  pathNames,
  themes,
  isSubNavigationHidden = false,
  children,
  pageForAllLocaleIdentifiers,
  allRegionCountryLanguageAssignments,
}: IAppPageWrapper): JSX.Element => {
  const pageLocale = buildLocale(pageContext.localeIdentifier.localeId);
  const doPartnerHandleRedirect = pageContext.doPartnerHandleRedirect || false;

  const localisationContract = {
    locale: pageLocale,
    localeIdentifier: pageContext.localeIdentifier,
    allActiveLocaleIdentifiers,
    pageForAllLocaleIdentifiers,
    allRegionCountryLanguageAssignments,
  };

  const pathNamesLinks = pathNames ? buildPathNamesLink(pathNames) : [];

  const themeHierarchy: TThemeName[] = themes ? buildThemes(themes) : [];

  const apiContract = {
    address: {
      getAddressSuggestions: (
        searchString?: string,
        options?: IUseQueryOptions
      ) =>
        useQuery(
          ['getAddressSuggestions', searchString, pageLocale],
          () => fetchAddressSuggestions(searchString, pageLocale),
          options
        ),
    },
    partner: {
      getPartnerByHandle: (handle?: string, options?: IUseQueryOptions) =>
        useQuery(
          ['getPartnerByHandle', handle, pageLocale],
          () => fetchPartnerByHandle(handle, pageLocale),
          options
        ),
      useGetPartnerByHandle: (handle?: string, options?: IUseQueryOptions) =>
        useGetPartnerByHandle(handle, options, pageLocale),
      getPartnersByLocation: (
        latitude?: number,
        longitude?: number,
        partnerLevel?: number,
        options?: IUseQueryOptions
      ) =>
        useQuery(
          ['getPartnerByLatLng', latitude, longitude, pageLocale, partnerLevel],
          () =>
            fetchPartnersByLocation(
              latitude,
              longitude,
              pageLocale,
              partnerLevel
            ),
          options
        ),
      getPartnerSuggestions: (
        searchString?: string,
        partnerLevel?: number,
        options?: IUseQueryOptions
      ) =>
        useQuery(
          ['getPartnerSuggestions', searchString, pageLocale, partnerLevel],
          () => fetchPartnerSuggestions(searchString, pageLocale, partnerLevel),
          options
        ),
    },
    products: {
      getProducts: (locale: ILocale) =>
        useQuery(['getProducts', locale], () => getProducts(locale)),
      getProduct: (locale: ILocale, productId: string) =>
        useQuery(['getProduct', locale, productId], () =>
          getProduct(locale, productId)
        ),
    },
    events: {
      getEvents: (onlineOnly: boolean) =>
        useQuery(['getEvents', onlineOnly], () => getEvents(onlineOnly)),
      getEventTypes: () => useQuery(['getEventTypes'], () => getEventTypes()),
    },
    visitor: {
      getVisitorSettings: (options?: IUseQueryOptions) =>
        useQuery(['getVisitorSettings'], getVisitorSettings, options),
      mutationVisitorSettings: () =>
        useMutation<
          TVisitorSettingsResponseData,
          unknown,
          TVisitorSettingsUpdateData
        >(
          'updateVisitorSettings',
          (payload) => updateVisitorSettings(payload),
          {
            onSuccess: (updatedVisitorSettings) => {
              queryClient.setQueryData(
                ['getVisitorSettings'],
                updatedVisitorSettings
              );
            },
          }
        ),
      mutationSendContactRequest: () =>
        useMutation<
          TVisitorSendContactRequestResponseData,
          unknown,
          IVisitorSendContactRequestPayload
        >('sendContactRequest', (payload) => sendContactRequest(payload)),
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <VisitorSettings
        allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
        doPartnerHandleRedirect={doPartnerHandleRedirect}
        locale={pageLocale}
      />
      <BemLocalisationProvider contract={localisationContract}>
        <BemApiProvider contract={apiContract}>
          <BemPathNamesProvider contract={pathNamesLinks}>
            <BemNavigationProvider
              isSubNavigationHidden={isSubNavigationHidden}
            >
              <BemModalProvider>
                <BemThemeHierarchyProvider contract={themeHierarchy}>
                  {children}
                </BemThemeHierarchyProvider>
              </BemModalProvider>
            </BemNavigationProvider>
          </BemPathNamesProvider>
        </BemApiProvider>
      </BemLocalisationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export { AppPageWrapper, IAppPageWrapper };
