import { aspectRatios, IGraphqlM0024, RATIOS } from '@bemer/base';
import React from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import {
  BemAccordionWithExpander,
  BemHeading,
  BemMedia,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  ASIDE_MEDIA_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0024 extends IGraphqlM0024 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '1 / -1',
    pb: TOP_HEADLINE_V1_PB,
  },
  image: {
    gridColumn: ['1 / -1', 'span 3', 'span 3'],
    pb: ASIDE_MEDIA_PB,
  },
  items: {
    gridColumn: ['1 / -1', '5 / -1', '5 / -1'],
    position: 'relative',
  },
  productItem: {
    justifyContent: 'space-between',
    overflow: 'unset',
  },
  accordionWrapper: {
    pt: [0, 10, 10],
  },
};

const BemM0024 = ({
  title,
  media,
  productNumberTranslation,
  product,
  items,
}: IPropsBemM0024): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      <BemHeading as="h2" variant="h2" sx={styles.heading}>
        {title}
      </BemHeading>
      <BemMedia
        media={media[0]}
        sx={styles.image}
        forcedAspectRatio={aspectRatios[RATIOS.RATIO_3_2].ratio}
        coverAvailableSpace={false}
        withTransparentBackground
      />
      <Box sx={styles.items} data-testid="BemM0024-items">
        <Flex sx={{ ...styles.item, ...styles.productItem }}>
          <BemHeading as="h3" variant="h4">
            {productNumberTranslation}
          </BemHeading>
          <Text as="span" variant="caption.small">
            {product.productId}
          </Text>
        </Flex>
        <BemAccordionWithExpander items={items} sx={styles.item} />
      </Box>
    </Grid>
  </BemModuleWrapper>
);

export { BemM0024, IPropsBemM0024 };
