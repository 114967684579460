import { IGraphqlM0119 } from '@bemer/base';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Flex, Grid, Text } from 'theme-ui';
import { BemHeading, BemLink, BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_HEADLINE_PB,
  TABLET_HEADLINE_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0119 extends IGraphqlM0119 {}

const styles: IStylesObject = {
  headingGrid: {
    gridRow: 1,
  },
  itemsGrid: {
    gridRow: ['auto', 'auto', 1],
  },
  heading: {
    gridColumn: ['1 / -1', '1 / -1', '1 / span 3'],
    pt: [0, 0, 4],
    pb: [MOBILE_HEADLINE_PB, TABLET_HEADLINE_PB, 0],
  },
  item: {
    gridColumn: ['1 / -1', '1 / -1', '5 / -1'],
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderColor: 'gray.2',
    pb: [6, 12, 12],
    pt: [6, 8, 8],
    overflow: 'hidden',
  },
  textWrapper: {
    flexDirection: ['column', 'row', 'row'],
  },
  itemTextMuted: {
    color: 'textMuted',
    pl: [0, 2, 2],
    pt: [2, 0, 0],
  },
  itemArrow: {
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    color: 'black',
  },
};

const BemM0119 = ({ title, items }: IPropsBemM0119): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid" sx={styles.headingGrid}>
      <BemHeading as="h2" variant="h1" sx={styles.heading}>
        {title}
      </BemHeading>
    </Grid>
    <Grid variant="contentGrid" sx={styles.itemsGrid}>
      {items.map((item, index) => (
        <BemLink
          to={item.link}
          data-testid="BemM0119-link"
          key={item._key}
          sx={styles.item}
          additionalTrackingIdInfo={index + 1}
        >
          <Flex sx={styles.textWrapper}>
            <Text as="span" variant="caption.small">
              {item.link[0].label}
            </Text>
            <Text as="span" variant="caption.small" sx={styles.itemTextMuted}>
              {item.text}
            </Text>
          </Flex>
          <Flex sx={styles.itemArrow}>
            <FaArrowRight />
          </Flex>
        </BemLink>
      ))}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0119, IPropsBemM0119 };
