import {
  getImageUrls,
  IGraphqlImage,
  TAdditionalTrackingIdInfo,
} from '@bemer/base';
import React, {
  ForwardedRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Image, ThemeUIStyleObject } from 'theme-ui';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ModuleContext } from '../../providers/ModuleProvider/BemModuleProvider';
import { getViewportAspectRatio } from '../../utils/breakpointIndices';
import { isValidSanityAsset } from '../../utils/sanityAssets';

interface IPropsBemCloudImage {
  /**
   * The graphql image object.
   */
  image: IGraphqlImage;

  /**
   * Overwrite the aspect ratio of an image.
   * The default ratio is the ratio of the uploaded image.
   *
   * It is possible to pass different ratios for different viewports in an array: [1/1, 4/3, 16/9]
   */
  forcedAspectRatio?: number | number[];

  /**
   * Optional flag to tell the image to use all the available space in the context where te image is rendered.
   * Most of the time this is the grid area where the image is placed into.
   *
   * Default: true
   */
  coverAvailableSpace?: boolean;

  /**
   * Optional flag to tell cloudinary to render the background of the images transparent.
   *
   * Default: false
   */
  withTransparentBackground?: boolean;

  /**
   * Optional css class name.
   */
  className?: string;

  /**
   * Additional style object.
   */
  sx?: ThemeUIStyleObject;

  /**
   * A optional test id.
   * Default is "BemCloudImage"
   */
  testId?: string;

  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const calculatedStyles: ICalculatedStylesObject = {
  wrapper: (imageAspectRatio: number) => ({
    position: 'relative',

    overflow: 'hidden',

    width: '100%',
    height: 0,
    paddingBottom: `${100 / imageAspectRatio}%`,
  }),
  image: (coverAvailableSpace: boolean) => ({
    display: 'block',
    position: 'absolute',

    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    width: '100%',
    height: coverAvailableSpace ? '100%' : 'auto',
    objectFit: 'cover',
  }),
};

/**
 * Component to render images hosted on sanity and delivered from cloudinary.
 *
 * This requires some settings in cloudinary to work,
 * Go to settings > upload and configure **Auto upload mapping:**
 * The following settings need to be applied:
 *
 * Folder: `marketing_site_remote_images`
 * URL Prefix: `https://cdn.sanity.io/images/milq733q/playground/`
 *
 * This will upload all images from sanity to cloudinary by mapping those urls, eg.
 * https://res.cloudinary.com/bemergroup/image/upload/marketing_site_remote_images/sample.jpg
 * https://cdn.sanity.io/images/milq733q/playground/sample.jpg
 *
 *
 * If the provided image cannot be uploaded, a hardcoded fallback image will be returned.
 *
 * To avoid Cumulative Layout Shift (CLS), you should always provide an `aspectRatio`, either by
 * using the graphql image query for fluid images or by applying `forcedAspectRatio`. this will
 * reserve the space available for the image without updating the area once the image is loaded.
 *
 * * ## Responsive load behavior
 *
 * Images will be loaded in `100px` steps eg. `100px`, `200px`, `300px` wide, if we get the width
 * of the surrounding box.
 * Those images are loaded based on the space available to them, there are no media queries involved.
 *
 * Default width of images is 500
 */

// eslint-disable-next-line react/display-name
const BemCloudImage = React.forwardRef(
  (
    {
      image,
      forcedAspectRatio,
      coverAvailableSpace = true,
      withTransparentBackground = false,
      className,
      sx,
      testId = 'BemCloudImage',
      additionalTrackingIdInfo = '',
    }: IPropsBemCloudImage,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element | null => {
    if (!image || !isValidSanityAsset(image.asset)) {
      return null;
    }

    const {
      url,
      metadata: {
        dimensions: { aspectRatio },
        lqip,
      },
    } = image.asset;

    const imageElement = useRef<HTMLImageElement>(null);
    const [imageUrl, setImageUrl] = useState(lqip);

    const imageAspectRatio = getViewportAspectRatio(
      aspectRatio,
      forcedAspectRatio
    );

    const { moduleName } = useContext(ModuleContext);

    useEffect(() => {
      if (!imageElement?.current) {
        return;
      }

      setImageUrl(
        getImageUrls(
          url,
          imageElement.current,
          imageAspectRatio,
          withTransparentBackground
        ).imageUrl
      );
    }, [imageElement, url, imageAspectRatio]);

    const componentName = 'BemCloudImage';
    const trackingId = `${moduleName}-${componentName}${
      additionalTrackingIdInfo ? `-${additionalTrackingIdInfo}` : ''
    }`;

    return (
      <Box
        ref={ref}
        sx={sx}
        className={className}
        data-testid={testId}
        data-trackingid={trackingId}
      >
        <Box sx={calculatedStyles.wrapper(imageAspectRatio)}>
          <Image
            ref={imageElement}
            src={imageUrl}
            alt={image.alt}
            sx={calculatedStyles.image(coverAvailableSpace)}
          />
        </Box>
      </Box>
    );
  }
);

export { BemCloudImage, IPropsBemCloudImage };
