import React, { ReactNode } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { useTheme } from '../../gatsby-plugin-theme-ui/utils/useTheme';

interface IPropsBemLogo {
  sx?: ThemeUIStyleObject;
  label?: ReactNode;
  version?: 'tiny';
}

const styles: IStylesObject = {
  wrapper: {
    fill: 'logoColor',
    width: '136px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&[data-version="tiny"]': {
      width: '64px',
    },
  },
};

const BemLogo = ({ sx, label, version }: IPropsBemLogo): JSX.Element => {
  const {
    theme: { colors },
  } = useTheme();

  return (
    <Box
      sx={{
        ...styles.wrapper,
        ...sx,
      }}
      data-version={version}
      data-testid="BemLogo"
    >
      {version === 'tiny' ? (
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M58.744273,3.55459234 C55.9906683,1.52789517 52.0412328,2.22630817 50.0907893,5.00068038 C49.4047032,5.97784527 48.863352,7.07973651 48.2658161,8.11079363 L43.4108849,16.4809104 C42.7101492,15.4868188 42.0094439,14.491953 41.3079492,13.4978462 C40.5387172,12.4051849 39.8202654,11.0938578 38.7938385,10.2137142 C35.8685376,7.70652597 31.2915385,8.50120112 29.3626216,11.8438404 L3.69250557,56.320904 C2.62604657,58.1697264 3.54928886,60.605291 5.58676448,61.2652052 C7.17453596,61.7803695 8.82546004,61.0488469 9.64169208,59.6343048 L34.7959,16.0512299 C35.9601696,17.741427 37.0674501,19.497069 38.3302894,21.1156577 C39.6693368,22.8312373 41.4488736,23.9131353 43.6988667,23.7837787 C45.8964858,23.6575039 47.4696381,22.4270152 48.6562388,20.6752139 C50.7645789,17.5651007 52.5402142,14.1901109 54.4590813,10.9598711 L54.4590813,36.8024878 L54.4590813,36.8232857 C54.4590813,36.8340641 54.4621782,36.8440683 54.4621782,36.854862 C54.4652296,37.0358032 54.4860274,37.2136931 54.5160401,37.3869377 C54.5276079,37.4531568 54.5368835,37.5193912 54.5522466,37.5848361 C54.5861304,37.7265195 54.629229,37.8635879 54.6800547,37.9975595 C55.0304301,38.9492963 55.7881397,39.6939049 56.7429583,40.0280822 C56.7960763,40.0473316 56.8499685,40.061966 56.903876,40.0789079 C57.0393959,40.118181 57.1741721,40.153598 57.3158707,40.1766882 C57.4644615,40.202101 57.6154053,40.2167354 57.7678368,40.2221247 C57.7955116,40.2228989 57.8225336,40.2305956 57.8502236,40.2305956 C57.8610172,40.2305956 57.869473,40.2275139 57.8802514,40.2275139 C58.0742938,40.2267396 58.2706741,40.2044085 58.467783,40.1682324 C58.5086045,40.1605205 58.5493956,40.1528238 58.5894429,40.1443528 C58.6287007,40.1351229 58.6671995,40.1312821 58.7064877,40.1204885 C59.6274377,39.8802354 60.2103391,39.4143939 60.5861426,38.8237806 C60.9957994,38.2601285 61.2452522,37.5732682 61.2452522,36.8232705 L61.2452522,8.33565329 C61.1597988,6.47297068 60.2673434,4.67652239 58.7442578,3.55459234"
            style={{ fill: colors.logoAccentColor }}
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg
          viewBox="0 0 329.46 70.01"
          width="329.46px"
          height="70.01px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M225.68,43.79c0,2.36-1.91,4.27-4.27,4.27H186.8c-5.9,0-10.98-3.51-13.27-8.55c-0.84-1.84-1.3-3.88-1.3-6.02 s0.47-4.19,1.3-6.02c0.67-1.48,1.58-2.82,2.68-3.99c-1.26-1.29-2.26-2.84-2.91-4.56c-0.55-1.45-0.85-3.02-0.85-4.66 s0.3-3.21,0.84-4.66c1.89-4.99,6.71-8.55,12.36-8.55h35.74c2.36,0,4.27,1.91,4.27,4.27s-1.91,4.27-4.27,4.27h-35.74 c-2.57,0-4.66,2.09-4.66,4.66c0,2.58,2.09,4.66,4.66,4.66h18.65c2.36,0,4.27,1.91,4.27,4.27c0,2.36-1.91,4.27-4.27,4.27H186.8 c-3.32,0-6.02,2.7-6.02,6.02c0,3.32,2.7,6.02,6.02,6.02h34.61C223.77,39.51,225.68,41.43,225.68,43.79z" />
          <path d="M53.45,33.49c0,2.15-0.46,4.19-1.3,6.02c-2.29,5.04-7.37,8.55-13.27,8.55H18.52c-2.36,0-4.27-1.91-4.27-4.27 c0-2.36,1.91-4.27,4.27-4.27h20.37c3.32,0,6.02-2.7,6.02-6.02c0-3.32-2.7-6.02-6.02-6.02H21.37c-2.36,0-4.27-1.91-4.27-4.27 c0-2.36,1.91-4.27,4.27-4.27h18.65c2.57,0,4.66-2.09,4.66-4.66c0-2.57-2.09-4.66-4.66-4.66H8.55v34.18c0,2.36-1.91,4.27-4.27,4.27 S0,46.15,0,43.79V5.33c0-2.36,1.91-4.27,4.27-4.27h35.74c5.65,0,10.48,3.55,12.36,8.55c0.55,1.45,0.84,3.02,0.84,4.66 s-0.3,3.21-0.85,4.66c-0.65,1.72-1.64,3.27-2.91,4.56c1.1,1.16,2.01,2.51,2.68,3.99C52.99,29.31,53.45,31.35,53.45,33.49z" />
          <path d="M113.37,5.33c0,2.36-1.91,4.27-4.27,4.27H72.05c-2.58,0-4.67,2.09-4.67,4.66c0,2.58,2.09,4.67,4.67,4.67h18.64 c2.36,0,4.27,1.91,4.27,4.27s-1.91,4.27-4.27,4.27H73.17c-3.32,0-6.02,2.7-6.02,6.02c0,3.33,2.7,6.02,6.02,6.02h30.96l-4.93,8.55 H73.17c-5.89,0-10.97-3.51-13.26-8.55c-0.84-1.83-1.3-3.87-1.3-6.02c0-2.14,0.46-4.18,1.3-6.02c0.67-1.48,1.58-2.82,2.68-3.99 c-1.26-1.29-2.26-2.83-2.9-4.55c-0.55-1.45-0.85-3.02-0.85-4.67c0-1.64,0.3-3.21,0.84-4.66c1.89-4.99,6.71-8.54,12.37-8.54h37.05 C111.46,1.06,113.37,2.97,113.37,5.33z" />
          <path d="M284.29,32.53c0,2.36-1.91,4.27-4.28,4.27c-2.21,0-4.03-1.67-4.25-3.82c-0.01-0.04-0.01-0.09-0.01-0.13 c-0.26-3.03-2.88-5.38-5.99-5.38h-17.53c-2.36,0-4.28-1.91-4.28-4.27c0-2.36,1.91-4.27,4.28-4.27h18.66c2.58,0,4.67-2.09,4.67-4.66 c0-2.57-2.09-4.66-4.67-4.66h-31.49v34.18c0,2.36-1.91,4.27-4.28,4.27c-2.36,0-4.28-1.91-4.28-4.27V5.33 c0-2.36,1.91-4.27,4.28-4.27h35.77c5.66,0,10.49,3.55,12.37,8.55c0.55,1.45,0.84,3.02,0.84,4.66s-0.3,3.21-0.85,4.66 c-0.65,1.72-1.65,3.27-2.91,4.56c1.1,1.16,2.02,2.51,2.69,3.99c0.65,1.43,1.08,2.98,1.23,4.62 C284.28,32.24,284.29,32.38,284.29,32.53z" />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M256.96,47c-5.43,0-9.23,3.53-9.23,8.59c0,7.76,6.85,8.36,8.95,8.36c1.22,0,2.69-0.29,3.55-0.57 c0.72-0.34,1.61-0.91,2.12-1.34l0.04-0.04v-5.77c0-0.58-0.19-0.81-0.69-0.81c-0.54,0-1.4,0.14-2.15,0.27 c-0.25,0.04-0.49,0.08-0.7,0.11l-0.11,0.04l0.01,0.12c0.05,0.62,0.1,1.26,0.1,2.21v3.16c-0.36,0.13-1.09,0.24-1.71,0.24 c-2.82,0-5.66-1.91-5.66-6.17c0-3.54,2.06-5.74,5.38-5.74c1.22,0,2.52,0.27,3.56,0.73c0.15,0.05,0.3,0.07,0.45,0.07 c0.75,0,1.52-0.63,1.52-1.85v-0.05l-0.04-0.04C261.78,47.99,259.92,47,256.96,47z"
          />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M276.49,59.35l-0.02-0.03c-0.8-1.37-1.51-2.56-2.15-3.19c0.94-0.49,2.95-1.66,2.95-4.08 c0-3.22-2.08-4.79-6.37-4.79c-1.18,0-3.71,0.29-4.34,0.44l-0.1,0.02l0.01,0.11c0.05,0.71,0.05,1.33,0.05,2.57v8.54 c0,2.11-0.02,3.63-0.08,4.66l-0.01,0.13h0.13c0.55,0,2-0.03,2.72-0.1c0.45-0.06,0.7-0.2,0.7-0.79v-5.72c0.05,0,0.09,0,0.14,0 c0.05,0,0.1,0.01,0.17,0.01c0.82,0,1.21,0.22,1.93,1.54c0.97,1.9,1.62,3.12,2.28,3.97c0.67,0.84,1.28,1.14,2.34,1.14 c0.86,0,1.95-0.07,2.2-0.29l0.03-0.03l0.05-0.23l-0.04-0.05C278.45,62.46,277.76,61.53,276.49,59.35z M273.68,52.33 c0,1.48-0.91,2.24-1.46,2.46c-0.2,0.09-0.81,0.16-1.51,0.16c-0.27,0-0.53-0.01-0.73-0.03v-5.34c0.29-0.05,0.62-0.05,0.95-0.05 C272.68,49.52,273.68,50.54,273.68,52.33z"
          />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M288.69,47c-5.08,0-8.49,3.46-8.49,8.62c0,4.15,2.58,8.34,8.34,8.34c5.8,0,8.44-4.47,8.44-8.62 C296.97,50.43,293.58,47,288.69,47z M293.26,55.54c0,3.79-1.67,6.05-4.46,6.05c-2.39,0-4.8-1.94-4.8-6.28 c0-4.39,2.36-5.94,4.39-5.94h0.03C291.76,49.37,293.26,52.47,293.26,55.54z"
          />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M311.54,47.41c-0.49,0-0.69,0.21-0.69,0.74v7.6c0,4.86-1.73,5.54-3.6,5.54c-2.42,0-3.6-1.71-3.6-5.23v-7.14 c0-1.19-0.05-1.6-0.66-1.6c-0.58,0-2.36,0.13-2.85,0.21l-0.12,0.02l0.01,0.12c0.05,0.53,0.08,1.6,0.08,3.28v5.18 c0,5.36,2.15,7.85,6.78,7.85c3.04,0,7.09-0.86,7.09-8.31v-3.57c0-2.09,0.02-3.61,0.08-4.63l0.01-0.13h-0.13 C313.38,47.3,312.27,47.33,311.54,47.41z"
          />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M322.71,47.25c-1.84,0-3.57,0.17-4.28,0.28l-0.11,0.02l0.01,0.12c0.1,1.22,0.1,2.27,0.1,3.48v11.98 c0,0.54,0.35,0.58,0.71,0.58c0.63,0,2.34-0.07,2.81-0.21l0.09-0.03l-0.02-0.3c-0.03-0.5-0.07-1.24-0.07-2.83V49.68 c0.29-0.04,0.6-0.04,0.87-0.04c0.9,0,2.99,0.29,2.99,2.93c0,2.67-1.86,2.96-2.65,2.96c-0.34,0-0.69-0.03-0.83-0.07l-0.16-0.05 v0.17c0,0.88,0.48,1.72,0.94,1.95c0.4,0.2,1,0.24,1.43,0.24c1.6-0.02,4.92-1.33,4.92-5.22C329.46,49.04,327.19,47.25,322.71,47.25z"
          />
          <path
            style={{ fill: colors.logoAccentColor }}
            d="M143.85,17.93l8.02-13.9c1.36-2.31,3.81-3.89,6.65-4.02C158.65,0,158.78,0,158.91,0 c1.63,0,3.14,0.47,4.41,1.29c2.26,1.45,3.75,3.98,3.75,6.86v35.63c0,2.36-1.91,4.28-4.27,4.28s-4.28-1.92-4.28-4.28V9.6 l-7.56,13.11c-0.54,0.93-1.25,1.74-2.09,2.39c-1.38,1.07-3.11,1.71-4.99,1.71c-1.63,0-3.14-0.47-4.41-1.29 c-0.9-0.58-1.67-1.32-2.28-2.2c-0.03-0.04-0.05-0.07-0.08-0.11l-4.25-6.06c0,0-21.2,36.73-29.22,50.62 c-0.07,0.12-0.14,0.24-0.22,0.36c-0.77,1.13-2.07,1.88-3.54,1.88c-2.36,0-4.27-1.91-4.27-4.27c0-0.74,0.19-1.43,0.52-2.04 c0.06-0.12,0.13-0.23,0.21-0.35c8.05-13.97,29.47-51.08,29.53-51.18c0.52-0.84,1.19-1.59,1.97-2.19c1.38-1.07,3.11-1.71,4.99-1.71 c1.63,0,3.14,0.48,4.41,1.3c0.89,0.57,1.67,1.31,2.28,2.18C139.59,11.85,144.14,18.34,143.85,17.93z"
          />
        </svg>
      )}
      {label}
    </Box>
  );
};

export { BemLogo, IPropsBemLogo };
