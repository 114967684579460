import { IGraphqlM0150 } from '@bemer/base';
import { Widget } from '@typeform/embed-react';
import React, { CSSProperties, useContext } from 'react';
import { Box } from 'theme-ui';
import { BemModuleWrapper } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { LocalisationContext } from '../../providers';

interface IPropsBemM0150 extends IGraphqlM0150 {}

const styles: IStylesObject = {
  formWrapper: {
    gridColumn: 'span 14',
    zIndex: 1,
    // when using theme ui Embed or the standard React Iframe, the typeform document grabs the focus when finished loading, therefore we have to use the typeform plugin
    // unfortunately the typeform widget doesn't display the correct height, so we have to use this hacky solution with pb
    // ratio 9/16 on mobile (16/9 = 0.17778), 16/9 on tablet and desktop (9/16 = 0.5625)
    pb: ['177.78%', '56.25%', '56.25%'],
    position: 'relative',
  },
  form: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
};

const BemM0150 = ({ languageSpecificForms }: IPropsBemM0150): JSX.Element => {
  const { languageCode } = useContext(LocalisationContext).localeIdentifier;
  const languageFormId = languageSpecificForms.filter(
    (languageSpecificForm) => languageSpecificForm.language === languageCode
  )[0].formId;
  return (
    <BemModuleWrapper>
      <Box sx={styles.formWrapper}>
        <Widget id={languageFormId} style={styles.form as CSSProperties} />
      </Box>
    </BemModuleWrapper>
  );
};

export { BemM0150, IPropsBemM0150 };
