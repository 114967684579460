import { ContextFrom, EventFrom, State } from 'xstate';
import { createModel } from 'xstate/lib/model';

const EVENTS = {
  OPEN_MODAL: 'openModal',
  CLOSE_MODAL: 'closeModal',
  SUCCESSFULLY_SUBMITTED_PARTNER_FORM: 'successfullySubmittedPartnerForm',
  SUCCESSFULLY_SUBMITTED_CONTACT_FORM: 'successfullySubmittedContactForm',
  CLICK_ON_CHANGE_DISTRIBUTOR: 'clickOnChangeDistributor',
} as const;

const STATES = {
  NO_MODAL: 'noModal',
  PARTNER_FINDER_FORM_MODAL: 'partnerFinderFormModal',
  CHANGE_PARTNER_PROCESSING_MODAL: 'changePartnerProcessingModal',
  CONTACT_FORM_MODAL: 'contactFormModal',
  CONTACT_FORM_SUCCESS_MODAL: 'contactFormSuccessModal',
} as const;

const ACTIONS = {
  MUTATE_EMAIL: 'mutateEmail',
} as const;

const m0077Model = createModel(
  {
    email: '',
  },
  {
    events: {
      [EVENTS.OPEN_MODAL]: (partnerHandle?: string) => ({ partnerHandle }),
      [EVENTS.CLOSE_MODAL]: () => ({}),
      [EVENTS.SUCCESSFULLY_SUBMITTED_PARTNER_FORM]: () => ({}),
      [EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM]: (email: string) => ({
        email,
      }),
      [EVENTS.CLICK_ON_CHANGE_DISTRIBUTOR]: () => ({}),
    },
  }
);

type TMachineContext = ContextFrom<typeof m0077Model>;
type TMachineEvent = EventFrom<typeof m0077Model>;
type TMachineState = State<TMachineContext, TMachineEvent>;

const m0077Machine = m0077Model.createMachine(
  {
    context: m0077Model.initialContext,
    initial: STATES.NO_MODAL,
    states: {
      [STATES.NO_MODAL]: {
        on: {
          [EVENTS.OPEN_MODAL]: [
            {
              target: STATES.CONTACT_FORM_MODAL,
              cond: (_, event) => Boolean(event.partnerHandle),
            },
            {
              target: STATES.PARTNER_FINDER_FORM_MODAL,
            },
          ],
        },
      },
      [STATES.PARTNER_FINDER_FORM_MODAL]: {
        on: {
          [EVENTS.CLOSE_MODAL]: {
            target: STATES.NO_MODAL,
          },
          [EVENTS.SUCCESSFULLY_SUBMITTED_PARTNER_FORM]: {
            target: STATES.CHANGE_PARTNER_PROCESSING_MODAL,
          },
        },
      },
      [STATES.CHANGE_PARTNER_PROCESSING_MODAL]: {
        // This is a final state and can not be changed.
      },
      [STATES.CONTACT_FORM_MODAL]: {
        on: {
          [EVENTS.CLOSE_MODAL]: {
            target: STATES.NO_MODAL,
          },
          [EVENTS.CLICK_ON_CHANGE_DISTRIBUTOR]: {
            target: STATES.PARTNER_FINDER_FORM_MODAL,
          },
          [EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM]: {
            actions: ACTIONS.MUTATE_EMAIL,
            target: STATES.CONTACT_FORM_SUCCESS_MODAL,
          },
        },
      },
      [STATES.CONTACT_FORM_SUCCESS_MODAL]: {
        on: {
          [EVENTS.CLOSE_MODAL]: {
            target: STATES.NO_MODAL,
          },
        },
      },
    },
  },
  {
    actions: {
      [ACTIONS.MUTATE_EMAIL]: m0077Model.assign(
        {
          email: (_, event) => event.email,
        },
        EVENTS.SUCCESSFULLY_SUBMITTED_CONTACT_FORM
      ),
    },
  }
);

export {
  m0077Model,
  m0077Machine,
  TMachineContext,
  TMachineEvent,
  TMachineState,
  STATES,
};
