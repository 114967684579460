import {
  IGraphqlExternalLink,
  IGraphqlInternalLink,
  IM0002Nav,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import React from 'react';
import { ContextFrom, EventFrom, Interpreter, State } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { debugXState } from '../../utils/xStateHelper';

interface IPropsBemNavigationProvider {
  isSubNavigationHidden?: boolean;
  children: React.ReactNode;
}

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

const navigationMachineModel = createModel(
  {
    navigationLinks: [] as IM0002Nav[],
    lineLinks: [] as IGraphqlInternalLink[],
    accountLinks: [] as (IGraphqlInternalLink | IGraphqlExternalLink)[],
    isNavOpen: false,
    isM0001Sticky: false,
    isM0002Sticky: false,
    isM0019Sticky: false,
    isM0001Rendered: false,
  },
  {
    events: {
      toggleMobileNav: () => ({}),
      closeMobileNav: () => ({}),
      updateData: (data: IM0002Nav[]) => ({ data }),
      updateMetaData: (data: {
        lineLinks: IGraphqlInternalLink[];
        accountLinks: (IGraphqlInternalLink | IGraphqlExternalLink)[];
      }) => ({ data }),
      updateIsM0019Sticky: (data: { isSticky: boolean }) => ({
        data,
      }),
      updateIsM0002Sticky: (data: { isSticky: boolean }) => ({
        data,
      }),
      updateIsM0001Sticky: (data: { isSticky: boolean }) => ({
        data,
      }),
      updateIsM0001Rendered: (data: { isRendered: boolean }) => ({
        data,
      }),
    },
  }
);

const navigationMachine = navigationMachineModel.createMachine(
  {
    id: 'navigationMachine',
    initial: 'closed',
    context: navigationMachineModel.initialContext,
    states: {
      closed: {
        on: {
          toggleMobileNav: {
            target: 'open',
          },
          updateData: [
            {
              target: 'open',
              actions: ['storeNavigationData'],
              cond: 'hasLineLinks',
            },
            {
              actions: ['storeNavigationData'],
            },
          ],
          updateMetaData: [
            {
              target: 'open',
              actions: ['storeMetaLinks'],
              cond: 'hasNavigationLinks',
            },
            {
              actions: ['storeMetaLinks'],
            },
          ],
          updateIsM0019Sticky: {
            actions: ['toggleIsM0019Sticky'],
          },
          updateIsM0002Sticky: {
            actions: ['toggleIsM0002Sticky'],
          },
          updateIsM0001Sticky: {
            actions: ['toggleIsM0001Sticky'],
          },
          updateIsM0001Rendered: {
            actions: ['toggleIsM0001Rendered'],
          },
        },
      },
      open: {
        on: {
          toggleMobileNav: {
            target: 'closed',
          },
          closeMobileNav: {
            target: 'closed',
          },
        },
      },
    },
  },
  {
    actions: {
      storeNavigationData: navigationMachineModel.assign(
        {
          navigationLinks: (_context, event) => event.data,
        },
        'updateData'
      ),
      storeMetaLinks: navigationMachineModel.assign(
        (_context, event) => ({
          lineLinks: event.data.lineLinks,
          accountLinks: event.data.accountLinks,
        }),
        'updateMetaData'
      ),
      toggleIsM0019Sticky: navigationMachineModel.assign(
        (_context, event) => ({
          isM0019Sticky: event.data.isSticky,
        }),
        'updateIsM0019Sticky'
      ),
      toggleIsM0002Sticky: navigationMachineModel.assign(
        (_context, event) => ({
          isM0002Sticky: event.data.isSticky,
        }),
        'updateIsM0019Sticky'
      ),
      toggleIsM0001Sticky: navigationMachineModel.assign(
        (_context, event) => ({
          isM0001Sticky: event.data.isSticky,
        }),
        'updateIsM0001Sticky'
      ),
      toggleIsM0001Rendered: navigationMachineModel.assign(
        (_context, event) => ({
          isM0001Rendered: event.data.isRendered,
        }),
        'updateIsM0001Rendered'
      ),
    },
    guards: {
      hasNavigationLinks: (context, _event) =>
        Boolean(context.navigationLinks.length) && context.isNavOpen,
      hasLineLinks: (context, _event) =>
        Boolean(context.lineLinks.length) && context.isNavOpen,
    },
  }
);
type TMachineContext = ContextFrom<typeof navigationMachineModel>;
type TMachineEvent = EventFrom<typeof navigationMachineModel>;
type TMachineState = State<TMachineContext, TMachineEvent>;

interface INavigationContext {
  current: TMachineState;
  send: Interpreter<TMachineContext, any, TMachineEvent>['send'];
  isSubNavigationHidden: boolean;
}
// Initialize the context with a default value.
const defaultValue: INavigationContext = {
  current: {} as TMachineState,
  send: (_event: any) => ({} as TMachineState),
  isSubNavigationHidden: false,
};
const NavigationContext = React.createContext<INavigationContext>(defaultValue);

const BemNavigationProvider = ({
  isSubNavigationHidden = false,
  children,
}: IPropsBemNavigationProvider): JSX.Element | null => {
  const [current, send] = useMachine(navigationMachine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
    context: {} as TMachineContext,
  });

  return (
    <NavigationContext.Provider
      value={{ current, send, isSubNavigationHidden }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export {
  NavigationContext,
  BemNavigationProvider,
  IPropsBemNavigationProvider,
};
