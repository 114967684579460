import { aspectRatios, IGraphqlM0139, RATIOS } from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { TOP_HEADLINE_V1_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ThemeHierarchyContext } from '../../providers';

interface IPropsBemM0139 extends IGraphqlM0139 {}

const styles: IStylesObject = {
  background: {
    gridColumn: '2 / -2',
    bg: 'background',
    pt: [8, 20, 32],
  },
  image: {
    gridColumn: ['2 / -2', '1 / span 4', '2 / span 4'],
    gridRow: [2, 1, 1],
    height: '100%',
    display: ['auto', 'flex', 'flex'],
    alignItems: ['auto', 'self-end', 'self-end'],
  },
  card: {
    gridColumn: ['2 / -2', '6 / -2', '7 / -2'],
    gridRow: 1,
  },
  title: {
    pb: TOP_HEADLINE_V1_PB,
  },
  text: {
    pb: TOP_HEADLINE_V1_PB,
  },
  link: {
    mb: [8, 10, 20],
  },
};

const BemM0139 = ({
  media,
  title,
  text,
  link,
  theme: moduleTheme,
}: IPropsBemM0139): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemModuleWrapper>
      <BemThemeWrapper themeName={moduleTheme || theme}>
        <Grid
          variant="contentGrid"
          sx={styles.background}
          data-testid="BemM0139ContentGrid"
        >
          <BemMedia
            media={media[0]}
            forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
            sx={styles.image}
          />
          <Box sx={styles.card}>
            <BemHeading as="h2" variant="h2" sx={styles.title}>
              {title}
            </BemHeading>
            <Text as="p" variant="bodyText" sx={styles.text}>
              {text}
            </Text>
            {link?.length ? (
              <BemLink
                to={link}
                variant="links.buttonGhostDark"
                sx={styles.link}
                data-testid="BemM0139Link"
              />
            ) : null}
          </Box>
        </Grid>
      </BemThemeWrapper>
    </BemModuleWrapper>
  );
};

export { BemM0139, IPropsBemM0139 };
