import { assign, EventObject, Machine, StateSchema } from 'xstate';

interface IAccordionSchema {
  states: {
    idle: StateSchema<any>;
  };
}

type TAccordionEvents = { type: 'click'; payload: string };

interface IAccordionContext {
  activeElement: string;
}

interface IMouseEventPayload extends EventObject {
  payload?: string;
}

const blockMachine = Machine<
  IAccordionContext,
  IAccordionSchema,
  TAccordionEvents
>(
  {
    id: 'blocks',
    initial: 'idle',
    context: {
      activeElement: '',
    },
    states: {
      idle: {
        on: {
          click: {
            actions: ['activateElement'],
            cond: 'isBlockOpen',
          },
        },
      },
    },
  },
  {
    guards: {
      isBlockOpen: (context: IAccordionContext, event: IMouseEventPayload) =>
        context.activeElement !== event.payload,
    },
    actions: {
      activateElement: assign((_context, event) => ({
        activeElement: event.type === 'click' ? event.payload : '',
      })),
    },
  }
);

export {
  blockMachine,
  IAccordionContext,
  TAccordionEvents,
  IAccordionSchema,
  IMouseEventPayload,
};
