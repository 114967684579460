import React, { ForwardedRef, forwardRef, ReactNode, useContext } from 'react';
import { Grid, ThemeUIStyleObject } from 'theme-ui';
import { MAX_CONTENT_WIDTH } from '../../gatsby-plugin-theme-ui/grids';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { DURATION } from '../../gatsby-plugin-theme-ui/transitions';
import { MODULE_MB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { ModuleContext } from '../../providers';

interface IPropsBemModuleWrapper {
  id?: string;
  /**
   * Flag to render the content across the full page width.
   *
   * Default: false
   */
  isFullWidth?: boolean;

  sx?: ThemeUIStyleObject;
  children: ReactNode;
}

const calculatedStyles: ICalculatedStylesObject = {
  base: (isFullWidth: boolean) => ({
    mb: MODULE_MB,
    maxWidth: isFullWidth ? 'full' : MAX_CONTENT_WIDTH,
    transition: `max-width ${DURATION.SHORT}s ease`,
    backgroundImage: (theme) =>
      theme.colors?.backgroundGradientStart &&
      theme.colors?.backgroundGradientEnd
        ? `linear-gradient(90deg, ${theme.colors.backgroundGradientStart}, ${theme.colors.backgroundGradientEnd})`
        : 'none',
  }),
};

// eslint-disable-next-line react/display-name
const BemModuleWrapper = forwardRef(
  (
    { isFullWidth = false, children, sx, ...props }: IPropsBemModuleWrapper,
    ref: ForwardedRef<HTMLDivElement> | undefined
  ): JSX.Element => {
    const { moduleName } = useContext(ModuleContext);

    return (
      <Grid
        variant="fullWidthGrid"
        sx={{
          label: moduleName,
          ...calculatedStyles.base(isFullWidth),
          ...sx,
        }}
        data-trackingid={moduleName}
        {...props}
        ref={ref}
      >
        {children}
      </Grid>
    );
  }
);

export { BemModuleWrapper, IPropsBemModuleWrapper };
