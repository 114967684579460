import { IGraphqlM0094 } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import { BemHeading, BemModuleWrapper } from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MOBILE_HEADLINE_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { isMobile } from '../../utils/breakpointIndices';

interface IPropsBemM0094 extends IGraphqlM0094 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: '2 / -2',
    justifySelf: 'start',
    pb: [MOBILE_HEADLINE_PB, 4, 4],
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  table: (columns: number) => ({
    gridColumn: '2 / -2',
    gridGap: 0,
    gridTemplateColumns: `repeat(${columns}, 1fr)`,

    borderBottomWidth: 'px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.2',

    borderLeftWidth: 'px',
    borderLeftStyle: 'solid',
    borderLeftColor: 'gray.2',
  }),
  cell: (
    hasContent: boolean,
    isInFirstRow: boolean,
    isInFirstCol: boolean
  ) => ({
    borderTopWidth: 'px',
    borderTopColor: isInFirstRow || hasContent ? 'gray.2' : 'transparent',

    borderRightWidth: 'px',
    borderRightStyle: 'solid',
    borderRightColor: 'gray.2',
    minWidth: 'auto',
    borderTopStyle: [isInFirstCol ? 'solid' : 'none', 'solid', 'solid'],
    whiteSpace: 'pre-wrap',
    p: [4, 6, 6],
    pb: [isInFirstCol ? 0 : 4, 6, 6],

    fontWeight: isInFirstCol ? 'bold' : 'normal',
  }),
};

const BemM0094 = ({ title, table }: IPropsBemM0094): JSX.Element | null => {
  if (!table?.rows || table.rows.length === 0) {
    return null;
  }

  const { rows } = table;
  const columnCount = isMobile() ? 1 : rows[0].cells.length;

  return (
    <BemModuleWrapper>
      <BemHeading as="h3" variant="h3" sx={styles.heading}>
        {title}
      </BemHeading>
      <Grid sx={calculatedStyles.table(columnCount)}>
        {rows.map(({ cells, _key }, rowIndex) =>
          cells.map((cellContent, currentCellIndex) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={`${_key}_${currentCellIndex}`}
              data-testid={`${_key}_${currentCellIndex}`}
              sx={calculatedStyles.cell(
                !!cellContent,
                rowIndex === 0,
                currentCellIndex === 0
              )}
            >
              {cellContent}
            </Text>
          ))
        )}
      </Grid>
    </BemModuleWrapper>
  );
};

export { BemM0094, IPropsBemM0094 };
