import {
  buildInternalRoute,
  getCountryLanguageAssignmentsForRegion,
  getMarketingSiteHostUrlForRegion,
  GOOGLE_TAG_MANAGER_URL,
  ICountryLanguageAssignment,
  IGraphqlLocaleIdentifier,
  IGraphqlM0147,
  IGraphqlPageEdge,
  TGraphqlPageSeo,
  TInternalRoutePageTree,
  TSeoFieldKey,
} from '@bemer/base';
import { LocalisationContext } from '@bemer/ui-library';
import { graphql, StaticQuery } from 'gatsby';
import React, { ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { IJsonDataVersionStats } from '../../interfaces/IJsonDataVersionStats';
import { IPageContext } from '../../interfaces/IPageContext';

interface IPropsAppHelmet {
  title: string;
  pageContext: IPageContext;
  allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[];
  pageForAllLocaleIdentifiers?: IGraphqlPageEdge[];
  pathNames?: TInternalRoutePageTree;
  seo?: TGraphqlPageSeo;
  children?: ReactNode;
  codeInjectionModules?: IGraphqlM0147[];
}

const createScriptTagsFromSanity = (codeInjectionModules?: IGraphqlM0147[]) => {
  // Do nothing when SSR.
  if (typeof window === 'undefined') {
    return;
  }

  if (!codeInjectionModules || !codeInjectionModules.length) return;

  const scriptTagStrings = codeInjectionModules.map(
    (condeInjectionModule) => condeInjectionModule?.code
  );

  scriptTagStrings.forEach((scriptTagString) => {
    const element = document.createElement('div');
    element.innerHTML = scriptTagString || '';

    const scriptTagCollection = element.getElementsByTagName('script');
    const scriptTag = scriptTagCollection[0];

    const attributes = scriptTag.getAttributeNames();
    const script = document.createElement('script');

    attributes.forEach((attribute) => {
      const attributeValue = scriptTag.getAttribute(attribute);
      if (!attributeValue) return;
      script.setAttribute(attribute, attributeValue);
    });

    script.innerHTML = scriptTag.innerHTML;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
};

const buildHrefLangLinksForPage = (
  countryLanguageAssignments: ICountryLanguageAssignment[]
): JSX.Element[] | null => {
  // If there is only one additional language to the master no href links need to be set
  if (countryLanguageAssignments.length <= 1) return null;
  return countryLanguageAssignments.map((countryLanguageAssignment) => (
    <link
      key={countryLanguageAssignment.localeId}
      rel="alternate"
      href={countryLanguageAssignment.pageUrl}
      hrefLang={countryLanguageAssignment.localeId}
    />
  ));
};

const AppHelmet = ({
  title = 'Bemer',
  pathNames,
  pageContext,
  allActiveLocaleIdentifiers,
  pageForAllLocaleIdentifiers,
  seo,
  children = undefined,
  codeInjectionModules,
}: IPropsAppHelmet): JSX.Element => {
  createScriptTagsFromSanity(codeInjectionModules);

  const { locale } = useContext(LocalisationContext);
  const canRenderGoogleScripts = !!process.env.GATSBY_GOOGLE_TAG_MANAGER_ID;
  const { regionCode } = pageContext.localeIdentifier.country.region;
  const hostUrl = getMarketingSiteHostUrlForRegion(regionCode);

  const internalPageRoute = buildInternalRoute(
    pathNames,
    pageContext.localeIdentifier
  );
  const canonicalUrl = `${hostUrl}${internalPageRoute}`;

  const countryLanguageAssignmentsForRegion =
    getCountryLanguageAssignmentsForRegion(
      regionCode,
      allActiveLocaleIdentifiers,
      pageForAllLocaleIdentifiers
    );

  const hrefLangLinkTags = buildHrefLangLinksForPage(
    countryLanguageAssignmentsForRegion
  );
  const googleTagManagerAuth = process.env.GATSBY_GOOGLE_TAG_MANAGER_AUTH_KEY
    ? `&gtm_auth=${process.env.GATSBY_GOOGLE_TAG_MANAGER_AUTH_KEY}&gtm_preview=env-3&gtm_cookies_win=x`
    : '';
  const googleScripts = canRenderGoogleScripts
    ? [
        <script key="google_tag_manager">{`
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='${GOOGLE_TAG_MANAGER_URL}/gtm.js?id='+i+dl+'${googleTagManagerAuth}';
          f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}');
        `}</script>,
        <noscript key="google_tag_manager_no_script">
          {`
            <iframe
              title="Google tag manager"
              src="${GOOGLE_TAG_MANAGER_URL}/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}${googleTagManagerAuth}"
              height="0"
              width="0"
              style="display:none;visibility:hidden">
            </iframe>
          `}
        </noscript>,
      ]
    : null;

  return (
    <StaticQuery
      query={graphql`
        query metaQuery {
          versionStatsData: allDataJson {
            edges {
              node {
                buildDate
                version
                versionDomainAndNumber
              }
            }
          }
        }
      `}
      render={({ versionStatsData }) => {
        const buildDate =
          versionStatsData.edges.filter(
            (edge: IJsonDataVersionStats) => !!edge.node.buildDate
          )[0]?.node.buildDate || 'no build date found';

        const htmlAttributes = {
          lang: locale.languageCode,
        };

        const META_ROBOTS_CONTENT = {
          INDEX: 'index',
          NO_INDEX: 'noindex',
          FOLLOW: 'follow',
          NO_FOLLOW: 'nofollow',
        } as const;

        type TMetaRobotsContent =
          typeof META_ROBOTS_CONTENT[keyof typeof META_ROBOTS_CONTENT];
        const metaRobotsContent: TMetaRobotsContent[] = [];

        return (
          <Helmet htmlAttributes={htmlAttributes}>
            {seo &&
              (Object.keys(seo) as TSeoFieldKey[]).map((key) => {
                if (seo[key] === null) {
                  return null;
                }

                if (key.startsWith('og') || key.startsWith('twitter')) {
                  return (
                    <meta
                      key={key}
                      property={key.replace('_', ':')}
                      content={seo[key]}
                    />
                  );
                }

                if (key.startsWith('robots')) {
                  const value = seo[key]
                    ? key.split('robots_')[1]
                    : key.split('robots_no')[1];
                  metaRobotsContent.push(value as TMetaRobotsContent);
                  return null;
                }

                return <meta key={key} name={key} content={seo[key]} />;
              })}
            <meta name="robots" content={metaRobotsContent.join(', ')} />
            <meta name="buildDate" content={buildDate} />
            <meta name="google" content="notranslate" />
            <meta httpEquiv="Content-Language" content={locale.languageCode} />
            {googleScripts}
            <link rel="canonical" href={canonicalUrl} />
            {hrefLangLinkTags}
            <link
              href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
              rel="stylesheet"
            />
            <title>{title}</title>;{children}
          </Helmet>
        );
      }}
    />
  );
};

export { AppHelmet, IPropsAppHelmet };
