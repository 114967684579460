import { getPartnerImageUrls, TAdditionalTrackingIdInfo } from '@bemer/base';
import React, { useContext, useEffect, useState } from 'react';
import { Image, ThemeUIStyleObject } from 'theme-ui';
import { ICalculatedStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ApiContext } from '../../providers';

type TBemAvatarSize = 'tiny' | 'small' | 'medium' | 'large';

interface IPropsBemAvatar {
  /**
   * Optional partner id to skip the check for the partner id via partnerHandle from the visitor settings.
   */
  forcePartnerId?: string;
  sx?: ThemeUIStyleObject;
  size?: TBemAvatarSize;
  /**
   * Optional index / number / string for the tracking ID.
   */
  additionalTrackingIdInfo?: TAdditionalTrackingIdInfo;
}

const calculatedStyles: ICalculatedStylesObject = {
  avatar: (size: number) => ({
    width: size,
    borderRadius: 'full',
    overflow: 'hidden',
  }),
};

/**
 * Helper to get ThemeUi size scales for the different AvatarSizes
 * @param size
 */
const getAvatarSize = (size: TBemAvatarSize) => {
  switch (true) {
    case size === 'tiny':
      return 6;
    case size === 'small':
      return 12;
    case size === 'medium':
      return 16;
    case size === 'large':
    default:
      return 24;
  }
};

const BemAvatar = ({
  forcePartnerId,
  sx,
  size = 'large',
}: IPropsBemAvatar): JSX.Element | null => {
  const [partnerProfileImageUrl, setPartnerProfileImageUrl] = useState('');
  const avatarSizeThemeUi = getAvatarSize(size);
  const avatarSizePixel = avatarSizeThemeUi * 4;

  if (forcePartnerId) {
    const partnerImageUrl = getPartnerImageUrls(
      forcePartnerId,
      avatarSizePixel
    ).imageUrl;

    if (partnerImageUrl !== partnerProfileImageUrl) {
      setPartnerProfileImageUrl(partnerImageUrl);
    }
  }

  const {
    partner: { getPartnerByHandle },
    visitor: { getVisitorSettings },
  } = useContext(ApiContext);

  const { data: visitorSettings } = getVisitorSettings({
    enabled: !forcePartnerId,
  });
  const { data: partnerData } = getPartnerByHandle(
    visitorSettings?.partnerHandle,
    {
      enabled: !forcePartnerId && !!visitorSettings?.partnerHandle,
    }
  );

  useEffect(() => {
    if (!partnerData) {
      return;
    }

    const partnerImageUrl = getPartnerImageUrls(
      partnerData?.partnerData?.customerNo,
      avatarSizePixel
    ).imageUrl;

    if (partnerImageUrl !== partnerProfileImageUrl) {
      setPartnerProfileImageUrl(partnerImageUrl);
    }
  }, [partnerData]);

  return partnerProfileImageUrl ? (
    <Image
      src={partnerProfileImageUrl}
      sx={{ ...calculatedStyles.avatar(avatarSizeThemeUi), ...sx }}
    />
  ) : null;
};

export { BemAvatar, IPropsBemAvatar, TBemAvatarSize };
