import { useBreakpointIndex } from '@theme-ui/match-media';

/**
 * Returns the component that is supposed to be shown at a certain breakpoint.
 *
 * @param array gets array containing different components for different viewports
 * The breakpoint index uses the index of the array to render the right component (e.g. 0 = mobileView, 1 = tabletView, 2 = desktopView)
 */

const VIEWPORTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

const VIEWPORT_INDEX = Object.fromEntries(
  Object.keys(VIEWPORTS).map((viewport, index) => [viewport, index])
);

type TViewportRendererComponent = JSX.Element | null;

const useViewportRenderer = (
  components: TViewportRendererComponent[]
): JSX.Element | null => components[useBreakpointIndex()];

export { VIEWPORTS, VIEWPORT_INDEX, useViewportRenderer };
