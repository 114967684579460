import { IGraphqlM0048 } from '@bemer/base';
import React from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemActionArea,
  BemHeading,
  BemLink,
  BemModuleWrapper,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { MOBILE_FORM_PB } from '../../gatsby-plugin-theme-ui/utils/sharedStyles';

interface IPropsBemM0048 extends IGraphqlM0048 {}

const styles: IStylesObject = {
  headingLinkWrapper: {
    gridColumn: ['2 / -2', '3 / span 3', '3 / span 3'],
  },
  text: {
    gridColumn: ['2 / -2', '7 / span 7', '7 / span 7'],
    pt: 4,
  },
  hint: {
    pt: 9,
    gridColumn: ['2 / -2', '7 / span 6', '7 / span 7'],
    color: 'textMuted',
  },
  link: {
    mb: [MOBILE_FORM_PB, 0, 0],
  },
};

const BemM0048 = ({ title, text, link, hint }: IPropsBemM0048): JSX.Element => (
  <BemModuleWrapper>
    <Box sx={styles.headingLinkWrapper}>
      <BemHeading as="h2" variant="h2">
        {title}
      </BemHeading>
      {link?.length ? (
        <BemActionArea>
          <BemLink
            key={link[0]._key}
            to={link}
            variant="links.buttonPrimary"
            sx={styles.link}
            data-testid="BemM0048-link"
          />
        </BemActionArea>
      ) : null}
    </Box>
    <Text sx={styles.text} variant="caption.big">
      {text}
    </Text>
    {hint ? (
      <Text data-testid="BemM0048Hint" sx={styles.hint} variant="small">
        {hint}
      </Text>
    ) : null}
  </BemModuleWrapper>
);

export { BemM0048, IPropsBemM0048 };
