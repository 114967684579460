import { aspectRatios, IGraphqlM0027, RATIOS, THEME_NAMES } from '@bemer/base';
import React from 'react';
import { Grid, Text } from 'theme-ui';
import {
  BemHeading,
  BemLink,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
} from '../../components';
import { defaultGridGap } from '../../gatsby-plugin-theme-ui/grids';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MOBILE_MODULE_INNER_SECTION_PB,
  TOP_HEADLINE_V1_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import { getLanguageSpecificVideo } from '../../utils/languageSpecificVideoHelper';

interface IPropsBemM0027 extends IGraphqlM0027 {}

const styles: IStylesObject = {
  heading: {
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
    textAlign: 'center',
    pb: TOP_HEADLINE_V1_PB,
  },
  text: {
    gridColumn: ['1 / -1', '2 / -2', '2 / -2'],
    textAlign: 'center',
    pb: MOBILE_MODULE_INNER_SECTION_PB,
  },
  link: {
    gridColumn: ['span 12', 'span 6', 'span 6'],
    pb: [MOBILE_MEDIA_PB, 0, 0],
  },
  linkGrid: {
    gridTemplateColumns: [
      'repeat(12, 1fr)',
      'repeat(6, 1fr)',
      'repeat(6, 1fr)',
    ],
    gap: defaultGridGap,
  },
  linkImage: {
    gridColumn: ['span 4', '1 / -1', '1 / -1'],
    gridRow: '1',
  },
  linkLabel: {
    gridColumn: ['6 / -1', '2 / -2', '2 / -2'],
    gridRow: '1',
    zIndex: 1,
    textAlign: ['left', 'center', 'center'],
    pt: [0, 20, 20],
  },
};

const BemM0027 = ({ title, text, items }: IPropsBemM0027): JSX.Element => (
  <BemModuleWrapper>
    <Grid variant="contentGrid">
      {title ? (
        <BemHeading
          data-testid="BemM0027Title"
          as="h2"
          variant="h2"
          sx={styles.heading}
        >
          {title}
        </BemHeading>
      ) : null}
      {text ? (
        <Text
          data-testid="BemM0027Text"
          as="p"
          variant="caption.big"
          sx={styles.text}
        >
          {text}
        </Text>
      ) : null}
      {items.map((item, index) => (
        <BemThemeWrapper
          key={item._key}
          themeName={[
            THEME_NAMES.DARK_TEXT,
            // TODO: refactor quick fixes for having videos, images and video sets
            item.media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(item.media[0]).themeName
              : item.media[0].themeName,
            item.media[0]._type === 'videoSet'
              ? getLanguageSpecificVideo(item.media[0]).themeName
              : item.media[0].themeName,
          ]}
        >
          <BemLink
            to={item.link}
            sx={styles.link}
            additionalTrackingIdInfo={index + 1}
          >
            <Grid sx={styles.linkGrid}>
              <BemMedia
                media={item.media[0]}
                sx={styles.linkImage}
                forcedAspectRatio={aspectRatios[RATIOS.RATIO_8_9].ratio}
                additionalTrackingIdInfo={index + 1}
              />
              <BemHeading
                as="h3"
                variant="h2"
                sx={styles.linkLabel}
                themeName={[
                  THEME_NAMES.DARK_TEXT,
                  // TODO: refactor quick fixes for having videos, images and video sets
                  item.media[0]._type === 'videoSet'
                    ? getLanguageSpecificVideo(item.media[0]).themeName
                    : item.media[0].themeName,
                  item.media[0]._type === 'videoSet'
                    ? getLanguageSpecificVideo(item.media[0]).themeName
                    : item.media[0].themeName,
                ]}
              >
                {item.link[0].label}
              </BemHeading>
            </Grid>
          </BemLink>
        </BemThemeWrapper>
      ))}
    </Grid>
  </BemModuleWrapper>
);

export { BemM0027, IPropsBemM0027 };
