import {
  aspectRatios,
  IGraphqlM0072,
  IGraphqlM0072Item,
  RATIOS,
  THEME_NAMES,
} from '@bemer/base';
import React, { useContext } from 'react';
import { Box, Text } from 'theme-ui';
import {
  BemHeading,
  BemMedia,
  BemModuleWrapper,
  BemThemeWrapper,
  BemTouchSlider,
} from '../../components';
import {
  ICalculatedStylesObject,
  IStylesObject,
} from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  MOBILE_MEDIA_PB,
  MODULE_WITH_BACKGROUND_COLOR_PB,
  MODULE_WITH_BACKGROUND_COLOR_PT,
  TOP_HEADLINE_V2_PB,
} from '../../gatsby-plugin-theme-ui/utils/sharedStyles';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';
import { ThemeHierarchyContext } from '../../providers';
import { isDesktop } from '../../utils/breakpointIndices';

interface IPropsBemM0072 extends IGraphqlM0072 {}

const PREFIX = 'M0072';

const styles: IStylesObject = {
  wrapper: {
    pt: MODULE_WITH_BACKGROUND_COLOR_PT,
    pb: MODULE_WITH_BACKGROUND_COLOR_PB,
  },
  heading: {
    gridColumn: ['2 / -2', '3 / -3', '3 / -3'],
    textAlign: 'center',
    pb: TOP_HEADLINE_V2_PB,
  },
  title: {
    pb: 4,
    px: [4, 0, 0],
  },
  text: {
    color: 'textSecondary',
    px: [4, 0, 0],
  },
  video: {
    pb: [MOBILE_MEDIA_PB, 7, 9],
    color: 'textMuted',
  },
  hint: {
    gridColumn: ['2 / -2', '3 / -3', '4 / -4'],
    textAlign: 'center',
    color: 'textSecondary',
    pt: 3,
  },
};

const calculatedStyles: ICalculatedStylesObject = {
  boxWrapper: () => {
    const nthChild = isDesktop()
      ? '&:nth-of-type(3n - 2)'
      : '&:nth-of-type(2n - 1)';

    return {
      bg: ['background', 'transparent', 'transparent'],
      gridColumn: [null, 'span 6', 'span 4'],
      pb: [8, 10, 12],

      [nthChild]: {
        gridColumn: [null, '2 / span 6', '2 / span 4'],
      },
    };
  },
};

interface IPropsBemM0072Items {
  items: IGraphqlM0072Item[];
}
interface IPropsBemM0072Item {
  item: IGraphqlM0072Item;
  index: number;
}

const Item = ({ item, index }: IPropsBemM0072Item) => (
  <Box
    key={item._key}
    data-testid={item._key}
    sx={calculatedStyles.boxWrapper()}
  >
    <BemMedia
      media={item.media[0]}
      sx={styles.video}
      additionalTrackingIdInfo={index + 1}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_16_9].ratio}
    />
    <BemHeading as="h5" variant="h5" sx={styles.title}>
      {item.title}
    </BemHeading>
    <Text as="p" variant="bodyText" sx={styles.text}>
      {item.text}
    </Text>
  </Box>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0072Items) => (
  <>
    {items.map((item, index) => (
      <Item key={item._key} item={item} index={index} />
    ))}
  </>
);

const ItemsOnMobile = ({ items }: IPropsBemM0072Items) => (
  <BemThemeWrapper
    themeName={THEME_NAMES.DEFAULT}
    key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
  >
    <BemTouchSlider
      items={items}
      itemRenderer={({ item }, index) => <Item item={item} index={index} />}
    />
  </BemThemeWrapper>
);

const BemM0072 = ({
  title,
  items,
  hint,
  theme: moduleTheme,
}: IPropsBemM0072): JSX.Element => {
  const [theme] = useContext(ThemeHierarchyContext);
  return (
    <BemThemeWrapper themeName={moduleTheme || theme}>
      <BemModuleWrapper
        sx={styles.wrapper}
        data-testid="BemM0072-bemModuleWrapper"
      >
        <BemHeading as="h2" variant="h2" sx={styles.heading}>
          {title}
        </BemHeading>
        {useViewportRenderer([
          <ItemsOnMobile key={`${PREFIX}_${VIEWPORTS.MOBILE}`} items={items} />,
          <ItemsOnTabletOrLarger
            items={items}
            key={`${PREFIX}_${VIEWPORTS.TABLET}`}
          />,
          <ItemsOnTabletOrLarger
            items={items}
            key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
          />,
        ])}
        <Text as="p" variant="tiny" sx={styles.hint}>
          {hint}
        </Text>
      </BemModuleWrapper>
    </BemThemeWrapper>
  );
};

export { BemM0072, IPropsBemM0072 };
