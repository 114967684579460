import {
  IGraphqlM0077,
  IPartnerFinderData,
  IVisitorSendContactRequestPayload,
} from '@bemer/base';
import { useMachine } from '@xstate/react';
import React, { useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { BemModal, BemModuleWrapper, BemSpinner } from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import { ApiContext } from '../../providers';
import {
  addTokenToUrlHash,
  isTokenInUrlHash,
  removeTokenFromUrlHash,
} from '../../utils/urlUtils';
import { debugXState } from '../../utils/xStateHelper';
import {
  m0077Machine,
  m0077Model,
  STATES,
  TMachineEvent,
  TMachineState,
} from './BemM0077.machine';
import { FloatingAvatarButton } from './FloatingAvatarButton';
import { ModalContactForm } from './ModalContactForm';
import { ModalContactFormSuccess } from './ModalContactFormSuccess';
import { PartnerFinder } from './PartnerFinder';

// set param to true for state machine debugging
const ENABLE_DEBUG_XSTATE = false;
debugXState(ENABLE_DEBUG_XSTATE);

interface IPropsBemM0077 extends IGraphqlM0077 {
  machineRef?: { current: TMachineState; send: (event: TMachineEvent) => void };
}

const styles: IStylesObject = {
  wrapper: {
    height: 0, // Makes this element not visible, so it can not cover click areas of other modules.
    mb: 0,
  },

  submittingPartnerFinderData: {
    my: 10,
    mx: 'auto',
  },
};

const OPEN_CONTACT_FORM_HASH_NAME = 'openContactForm';

/**
 * Module renders partnerFinderForm or contactForm within a modal.
 *
 * This module is copied by M0152 which handles the same usecase on a page without opening a modal.
 */
const BemM0077 = ({
  image,
  blocksFormLegalText,
  supportPageLink,
  translations: T,
  machineRef,
}: IPropsBemM0077): JSX.Element | null => {
  const [localCurrent, localSend] = useMachine(m0077Machine, {
    devTools: process.env.NODE_ENV !== 'production' && ENABLE_DEBUG_XSTATE,
  });

  const current = machineRef ? machineRef.current : localCurrent;
  const send = machineRef ? machineRef.send : localSend;

  const {
    visitor: {
      getVisitorSettings,
      mutationSendContactRequest,
      mutationVisitorSettings,
    },
  } = useContext(ApiContext);

  const { isFetching: isFetchingGetVisitorSettings, data: visitorSettings } =
    getVisitorSettings();

  const mutationContactRequest = mutationSendContactRequest();
  const mutationVisitorData = mutationVisitorSettings();

  /**
   * This function will update the visitorSettings in the backend.
   * If the request is successful, M0077 will go into an final state
   * called STATE.CHANGE_PARTNER_PROCESSING_MODAL and display an spinner forever.
   *
   * That is intended! In the background the backend will update the partner for the
   * current visitorID and send a new response for the getVisitorSettings() query (react-query
   * will update the data) containing the new partnerHandle.
   *
   * Then the packages/web-app-marketing-site/src/components/VisitorSettings/VisitorSettings.tsx
   * logic will kick in and redirect the user to the partner page. That is a hard redirect and
   * the whole app will be destroyed.
   *
   * @param data
   */
  const handlePartnerFormSubmit = (data: IPartnerFinderData) =>
    new Promise((resolve) => {
      const payload = { partnerHandle: data.partnerData.handle };
      mutationVisitorData.mutate(payload, {
        onSuccess: (result) => {
          send(m0077Model.events.successfullySubmittedPartnerForm());
          addTokenToUrlHash(OPEN_CONTACT_FORM_HASH_NAME);
          resolve(result);
        },
      });
    });

  const handleContactFormSubmit: SubmitHandler<
    IVisitorSendContactRequestPayload
  > = (data) => {
    const log = false;
    const extendedData = { ...data, log };
    return new Promise((resolve) => {
      mutationContactRequest.mutate(extendedData, {
        onSuccess: (result) => {
          send(m0077Model.events.successfullySubmittedContactForm(data.email));
          resolve(result);
        },
      });
    });
  };

  // The visitorSettings are required for this module to display anything.
  if (isFetchingGetVisitorSettings && !visitorSettings) {
    return null;
  }

  // In case no modal is open and the URL contains a specific hash token, the modal will be opened.
  if (
    current.value === STATES.NO_MODAL &&
    isTokenInUrlHash(OPEN_CONTACT_FORM_HASH_NAME)
  ) {
    send(m0077Model.events.openModal(visitorSettings?.partnerHandle));
  }

  const handleClickOnAvatar = () => {
    send(m0077Model.events.openModal(visitorSettings?.partnerHandle));
  };

  const handleChangeDistributor = () => {
    send(m0077Model.events.clickOnChangeDistributor());
  };

  const handleModalClose = () => {
    if (current.value === STATES.CHANGE_PARTNER_PROCESSING_MODAL) {
      return;
    }

    removeTokenFromUrlHash(OPEN_CONTACT_FORM_HASH_NAME);
    send(m0077Model.events.closeModal());
  };

  // Set the modal content depending on the state of the state machine.
  let modalContent;
  switch (current.value) {
    case STATES.PARTNER_FINDER_FORM_MODAL: {
      modalContent = (
        <PartnerFinder
          onSubmit={handlePartnerFormSubmit}
          onCancel={handleModalClose}
          T={T}
        />
      );
      break;
    }
    case STATES.CHANGE_PARTNER_PROCESSING_MODAL: {
      modalContent = <BemSpinner sx={styles.submittingPartnerFinderData} />;
      break;
    }
    case STATES.CONTACT_FORM_MODAL: {
      modalContent = (
        <ModalContactForm
          T={T}
          blocksFormLegalText={blocksFormLegalText}
          onSubmit={handleContactFormSubmit}
          onCancel={handleModalClose}
          onChangeDistributor={handleChangeDistributor}
        />
      );
      break;
    }
    case STATES.CONTACT_FORM_SUCCESS_MODAL: {
      modalContent = (
        <ModalContactFormSuccess
          email={current.context.email}
          T={T}
          supportPageLink={supportPageLink[0]}
        />
      );
      break;
    }
    case STATES.NO_MODAL: {
      // Render no modal.
      break;
    }
    default:
      throw new Error(`State machine is in undefined state ${current.value}`);
  }
  return (
    <>
      <BemModal
        isVisible={!!modalContent}
        onClose={handleModalClose}
        data-testid="ModalContactForm"
      >
        {modalContent}
      </BemModal>

      <BemModuleWrapper sx={styles.wrapper}>
        <FloatingAvatarButton image={image} onClick={handleClickOnAvatar} />
      </BemModuleWrapper>
    </>
  );
};

export { BemM0077, IPropsBemM0077 };
