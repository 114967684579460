import {
  aspectRatios,
  IGraphqlM0021,
  IGraphqlM0021Item,
  RATIOS,
} from '@bemer/base';
import React from 'react';
import { Flex, Text } from 'theme-ui';
import {
  BemCloudImage,
  BemModuleWrapper,
  BemTouchSlider,
} from '../../components';
import { IStylesObject } from '../../gatsby-plugin-theme-ui/moduleTypes';
import {
  useViewportRenderer,
  VIEWPORTS,
} from '../../hooks/useViewportRenderer';

interface IPropsBemM0021 extends IGraphqlM0021 {}

const PREFIX = 'M0021';
const MAX_ITEMS_PER_ROW = 5;

const styles: IStylesObject = {
  itemListWrapper: {
    gridColumn: '3 / -3',
    justifyContent: 'center',
  },
  itemWrapper: {
    width: `${100 / MAX_ITEMS_PER_ROW}%`,
    pl: ['1', '1_5', '2'],
    pr: ['1', '1_5', '2'],
    justifyContent: 'center',
  },
  item: {
    gridColumn: 'span 2',
    pb: 2,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    wordWrap: 'break-word',

    '&:first-of-type': {
      gridColumn: '3 / span 2',
    },

    [`&:nth-of-type(${MAX_ITEMS_PER_ROW}n+${MAX_ITEMS_PER_ROW + 1})`]: {
      gridColumn: '3 / span 2',
    },
  },

  image: {
    width: [20, 20, 28],
    mb: [4, 8, 10],
    overflow: 'hidden',
    borderRadius: 'full',
  },
};

interface IPropsBemM0021Item {
  item: IGraphqlM0021Item;
}
interface IPropsBemM0021Items {
  items: IGraphqlM0021Item[];
}

const Item = ({ item }: IPropsBemM0021Item) => (
  <Flex sx={styles.item}>
    <BemCloudImage
      image={item.image}
      forcedAspectRatio={aspectRatios[RATIOS.RATIO_1_1].ratio}
      sx={styles.image}
    />
    <Text as="p">{item.text}</Text>
  </Flex>
);

const ItemsOnTabletOrLarger = ({ items }: IPropsBemM0021Items) =>
  items.length > MAX_ITEMS_PER_ROW - 1 ? (
    <>
      {items.map((item) => (
        <Item key={item._key} item={item} />
      ))}
    </>
  ) : (
    <Flex
      sx={styles.itemListWrapper}
      data-testid="BemM0021-listItemFlexWrapper"
    >
      {items.map((item) => (
        <Flex key={item._key} sx={styles.itemWrapper}>
          <Item key={item._key} item={item} />
        </Flex>
      ))}
    </Flex>
  );

const BemM0021 = ({ items }: IPropsBemM0021): JSX.Element => (
  <BemModuleWrapper>
    {useViewportRenderer([
      <BemTouchSlider
        items={items}
        itemRenderer={Item}
        hideShadow
        itemWidth="45%"
        key={`${PREFIX}_${VIEWPORTS.MOBILE}`}
      />,
      <ItemsOnTabletOrLarger
        items={items}
        key={`${PREFIX}_${VIEWPORTS.TABLET}`}
      />,
      <ItemsOnTabletOrLarger
        items={items}
        key={`${PREFIX}_${VIEWPORTS.DESKTOP}`}
      />,
    ])}
  </BemModuleWrapper>
);

export { BemM0021, IPropsBemM0021, IPropsBemM0021Item };
